<template>
  <layout-content :total="total">
    <!-- 面包屑的插槽 -->
    <breadcrumb slot="breadcrumb"></breadcrumb>
    <div slot="content">
      <!-- 搜索和新增删除 -->
      <div class="d-f a-c j-b">
        <a-form-model ref="queryForm" :model="queryParams" layout="inline">
          <a-form-model-item label="学生姓名" prop="name">
            <a-input
              v-model="queryParams.name"
              placeholder="请输入学生姓名"
              allowClear
              @keyup.enter.native="handleQuery"
            />
          </a-form-model-item>
          <a-form-model-item label="班级名称" prop="classId">
            <a-select
              v-model="queryParams.classId"
              placeholder="请选择班级名称"
              style="width: 180px"
              :getPopupContainer="(triggerNode) => triggerNode.parentNode"
              allowClear
            >
              <a-select-option :value="item.id" v-for="item in classList" :key="item.id">
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item>
            <a-button
              type="primary"
              @click="handleQuery"
              v-hasPermi="['teacherStudent:student:query']"
              >搜索</a-button
            >
            <a-button
              @click="resetQuery"
              class="ml10"
              v-hasPermi="['teacherStudent:student:query']"
              >重置</a-button
            >
          </a-form-model-item>
        </a-form-model>
        <div>
          <a-button
            type="primary"
            @click="handleAdd"
            icon="plus"
            v-hasPermi="['teacherStudent:student:add']"
            >新增</a-button
          >
          <a-dropdown>
            <a-menu slot="overlay" @click="handleMenuClick">
              <a-menu-item key="add" v-hasPermi="['teacherStudent:student:add']">
                <a-icon type="file-add" /> 批量导入
              </a-menu-item>
              <a-menu-item key="export" v-hasPermi="['teacherStudent:student:export']">
                <a-icon type="export" /> 批量导出
              </a-menu-item>
            </a-menu>
            <a-button class="ml10"><a-icon type="snippets" /> 导入导出 </a-button>
          </a-dropdown>
          <a-button
            @click="handleDelete"
            icon="delete"
            class="ml10"
            :disabled="multiple"
            v-hasPermi="['basicInfo:building:remove']"
          >
            批量删除
          </a-button>
        </div>
      </div>
      <!--表格-->
      <a-table
        :columns="columns"
        rowKey="id"
        :data-source="data"
        :pagination="false"
        :row-selection="{
          selectedRowKeys: selectedRowKeys,
          onChange: onSelectChange,
        }"
        class="mt20"
      >
        <span slot="avatar" slot-scope="text, record">
          <img
            :src="text"
            v-if="text"
            width="32px"
            height="32px"
            style="border-radius: 50%"
          />
          <img
            :src="require('@/assets/images/' + handleAvatar(record))"
            v-else
            width="32px"
            height="32px"
          />
        </span>
        <span slot="gender" slot-scope="text">
          {{ dictTranslation(sexList, text) }}
        </span>
        <span slot="action" slot-scope="text, record">
          <a @click="handleUpdate(record)" v-hasPermi="['teacherStudent:teacher:edit']"
            >编辑</a
          >
          <a-divider type="vertical" />
          <a @click="handleDelete(record)" v-hasPermi="['teacherStudent:teacher:remove']"
            >删除</a
          >
        </span>
      </a-table>
      <!--分页-->
      <div class="d-f mt20">
        <a-pagination
          v-model="queryParams.page"
          :pageSize="queryParams.limit"
          :default-current="1"
          :total="total"
          :hideOnSinglePage="true"
          showLessItems
          @change="onChange"
          :item-render="itemRender"
          style="margin-left: auto"
        />
      </div>
      <!--新增和编辑的弹出框-->
      <a-modal
        v-model="visible"
        :title="title"
        @ok="submit"
        @cancel="handleCancel"
        width="700px"
      >
        <a-form-model
          ref="ruleForm"
          :model="form"
          :rules="rules"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
        >
          <a-row>
            <a-col :span="12">
              <a-form-model-item label="学号" prop="studentNum">
                <a-input v-model="form.studentNum" placeholder="请输入学号" />
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item label="学生姓名" prop="name">
                <a-input v-model="form.name" placeholder="请输入用户名" />
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item label="性别" prop="gender">
                <a-select
                  v-model="form.gender"
                  placeholder="请选择性别"
                  style="width: 100%"
                  :getPopupContainer="(triggerNode) => triggerNode.parentNode"
                >
                  <a-select-option
                    :value="item.valueData"
                    v-for="item in sexList"
                    :key="item.id"
                  >
                    {{ item.valueName }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item label="所属班级" prop="classId">
                <a-select
                  v-model="form.classId"
                  placeholder="请选择角色"
                  style="width: 100%"
                  :getPopupContainer="(triggerNode) => triggerNode.parentNode"
                >
                  <a-select-option
                    :value="item.id"
                    v-for="item in classList"
                    :key="item.id"
                  >
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item label="年龄" prop="age">
                <a-input-number
                  v-model="form.age"
                  placeholder="请输入年龄"
                  :min="1"
                  style="width: 100%"
                />
              </a-form-model-item>
            </a-col>
            <a-col :span="24">
              <a-form-model-item
                label="头像"
                prop="avatar"
                :label-col="{ span: 4, offset: 0 }"
              >
                <!-- 图片剪裁以及预览 -->
                <a-row>
                  <a-col :span="24" :style="{ height: '100px', width: '100px' }">
                    <vue-cropper
                      ref="cropper"
                      :img="options.avatar"
                      :info="true"
                      :autoCrop="options.autoCrop"
                      :autoCropWidth="options.autoCropWidth"
                      :autoCropHeight="options.autoCropHeight"
                      :fixedBox="options.fixedBox"
                      @realTime="realTime"
                      outputType="png"
                    />
                    <div class="avatar-upload-preview" v-if="canShow">
                      <img :src="previews.url" :style="previews.img" />
                    </div>
                    <div class="avatar-upload-preview" v-else>
                      <img :src="form.avatar" class="avatar" />
                    </div>
                  </a-col>
                </a-row>
                <br />
                <!-- 上传，上下左右旋转以及放大缩小 -->
                <a-row>
                  <a-col :lg="2" :md="2">
                    <a-upload
                      action="#"
                      :http-request="requestUpload"
                      :showUploadList="false"
                      :before-upload="beforeUpload"
                      accept="image/*"
                    >
                      <a-button>选择<a-icon type="upload" /></a-button>
                    </a-upload>
                  </a-col>
                  <a-col :lg="{ span: 1, offset: 7 }" :md="2">
                    <a-button icon="plus" @click="changeScale(1)"></a-button>
                  </a-col>
                  <a-col :lg="{ span: 1, offset: 3 }" :md="2">
                    <a-button icon="minus" @click="changeScale(-1)"></a-button>
                  </a-col>
                  <a-col :lg="{ span: 1, offset: 3 }" :md="2">
                    <a-button icon="undo" @click="rotateLeft()"></a-button>
                  </a-col>
                  <a-col :lg="{ span: 1, offset: 3 }" :md="2">
                    <a-button icon="redo" @click="rotateRight()"></a-button>
                  </a-col>
                </a-row>
              </a-form-model-item>
            </a-col>
          </a-row>
        </a-form-model>
      </a-modal>
      <!--批量导入的弹出框-->
      <a-modal
        v-model="largeAddVisible"
        title="批量导入"
        @ok="handleUpload"
        width="580px"
      >
        <a-spin :spinning="confirmLoading">
          <a-form-model
            ref="addForm"
            :model="addForm"
            :rules="addRules"
            :label-col="{ span: 5 }"
            :wrapper-col="{ span: 19 }"
          >
            <a-form-model-item label="所属班级" prop="classId">
              <a-select
                v-model="addForm.classId"
                placeholder="请选择所属班级"
                :getPopupContainer="(triggerNode) => triggerNode.parentNode"
              >
                <a-select-option
                  :value="item.id"
                  v-for="item in classList"
                  :key="item.id"
                  >{{ item.name }}</a-select-option
                >
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="选择文件" prop="file" ref="file">
              <a-upload-dragger
                :file-list="fileList"
                :remove="handleRemove"
                @change="handleChange"
                :before-upload="beforeUploadFile"
                accept=".xlsx,.xls"
                :multiple="false"
              >
                <p class="ant-upload-drag-icon"><a-icon type="inbox" /></p>
                <p class="ant-upload-text">点击或将文件拖到这里上传</p>
                <p class="ant-upload-hint">支持扩展名： .xlsx .xls</p>
              </a-upload-dragger>
              请先下载模板后上传，点击此处<a @click="download">下载模板</a>
            </a-form-model-item>
          </a-form-model>
        </a-spin>
      </a-modal>
    </div>
  </layout-content>
</template>

<script>
import LayoutContent from "@/views/layout/content";
import Breadcrumb from "~c/Breadcrumb";
import {
  getStudentList,
  getStudent,
  studentRemove,
  studentAddOrUpdate,
  studentDownloadTemplate,
  studentExport,
  studentImport,
} from "@/api/teacher-student/student.js";
import { getClassList } from "@/api/basic-info/class.js";
import { VueCropper } from "vue-cropper";
const columns = [
  {
    title: "头像",
    dataIndex: "avatar",
    align: "center",
    scopedSlots: { customRender: "avatar" },
  },
  {
    title: "学号",
    dataIndex: "studentNum",
    align: "center",
  },
  {
    title: "学生姓名",
    dataIndex: "name",
    align: "center",
  },
  {
    title: "班级名称",
    dataIndex: "className",
    align: "center",
  },
  {
    title: "性别",
    dataIndex: "gender",
    align: "center",
    scopedSlots: { customRender: "gender" },
  },
  {
    title: "操作",
    dataIndex: "action",
    align: "center",
    scopedSlots: { customRender: "action" },
  },
];
export default {
  name: "Student",
  components: { LayoutContent, Breadcrumb, VueCropper },
  data() {
    return {
      total: null,
      // 查询参数
      queryParams: {
        //当前页
        page: 1,
        //一页几个
        limit: 10,
      },
      //表格的表头文字
      columns,
      //表格的数据
      data: [],
      //多选的数据
      selectedRowKeys: [],
      // 非多个禁用
      multiple: true,
      //新增和编辑的表单
      form: {},
      //表单规则
      rules: {
        studentNum: [
          {
            required: true,
            message: "请输入学号",
            whitespace: true,
            trigger: ["change", "blur"],
          },
          {
            min: 0,
            max: 20,
            message: "学号不能超过20个汉字",
            trigger: ["change", "blur"],
          },
        ],
        name: [
          {
            required: true,
            message: "请输入学生姓名",
            whitespace: true,
            trigger: ["change", "blur"],
          },
        ],
        gender: [
          {
            required: true,
            message: "请选择性别",
            whitespace: true,
            trigger: ["change", "blur"],
          },
        ],
        classId: [
          {
            required: true,
            message: "请选择所属班级",
            whitespace: true,
            trigger: ["change", "blur"],
          },
        ],
      },
      //label和wrapper的占位
      labelCol: { span: 8 },
      wrapperCol: { span: 14 },
      //新增和编辑弹出框的标题
      title: "",
      //新增和编辑弹出框的显示隐藏
      visible: false,
      //班级列表
      classList: [],
      //性别列表
      sexList: [],
      //盒子图片裁剪
      options: {
        avatar: "", //裁剪图片的地址
        autoCrop: true, // 是否默认生成截图框
        autoCropWidth: 64, // 默认生成截图框宽度
        autoCropHeight: 64, // 默认生成截图框高度
        fixedBox: true, // 固定截图框大小 不允许改变
      },
      //盒子图片预览
      previews: {},
      //盒子图片文件
      file: null,
      //是否展示预览图片
      canShow: true,
      //批量导入的显示隐藏
      largeAddVisible: false,
      //批量导入的表单
      addForm: {},
      //批量导入的表单规则
      addRules: {
        classId: [
          {
            required: true,
            message: "请选择所属班级",
            whitespace: true,
            trigger: ["change", "blur"],
          },
        ],
        file: [{ required: true, message: "请选择文件", trigger: "change" }],
      },
      //批量导入的loading
      confirmLoading: false,
      //文件列表
      fileList: [],
    };
  },
  methods: {
    //处理表格随机头像
    handleAvatar(record) {
      //女生
      if (record.gender === "2") {
        let random = Math.round(Math.random());
        return ["student_random_avatar1.png", "student_random_avatar2.png"][random];
      }
      //男生
      else if (record.gender === "1") {
        let random = Math.round(Math.random());
        return ["student_random_avatar3.png", "student_random_avatar4.png"][random];
      }
      //未知
      else {
        return "student_random_avatar5.png";
      }
    },
    //获取班级列表
    getClassList() {
      getClassList({ isPage: false }).then((res) => {
        this.classList = res.data;
      });
    },
    //获取性别列表
    getSexList() {
      this.getDictDataList("system_user_sex").then((res) => {
        this.sexList = res.data;
      });
    },
    //获取学生列表
    getStudentList() {
      getStudentList(this.queryParams).then((res) => {
        this.total = res.count;
        this.data = res.data;
        this.multiple = !this.selectedRowKeys.length;
      });
    },
    //搜索
    handleQuery() {
      this.queryParams.page = 1;
      this.getStudentList();
    },
    //搜索重置
    resetQuery() {
      this.queryParams.page = 1;
      this.$refs.queryForm.resetFields();
      this.getStudentList();
    },
    //复选框状态改变
    onSelectChange(selectedRowKeys, records) {
      this.selectedRowKeys = selectedRowKeys;
      this.multiple = !selectedRowKeys.length;
    },
    /** 新增按钮操作 */
    handleAdd() {
      this.form = {};
      this.options.avatar = "";
      this.canShow = true;
      this.visible = true;
      this.title = "新增学生";
    },
    /** 修改按钮操作 */
    async handleUpdate(row) {
      let response = await getStudent(row.id);
      this.form = response.data;
      Object.keys(this.form).forEach((key) => {
        if (this.form[key] === null) {
          delete this.form[key];
        }
      });
      this.options.avatar = "";
      this.canShow = false;
      this.visible = true;
      this.title = "修改学生";
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      this.$confirm({
        title: "您确认删除您选中的数据吗？",
        onOk: () => {
          //单个删除
          if (row.id) {
            let arr = [];
            arr.push(row.id);
            studentRemove(arr).then((res) => {
              this.$message.success("删除成功");
              this.getStudentList();
            });
          }
          //批量删除
          else {
            studentRemove(this.selectedRowKeys).then((res) => {
              this.$message.success("删除成功");
              this.selectedRowKeys = [];
              this.getStudentList();
            });
          }
        },
      });
    },
    //新增或编辑确定
    submit() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          //修改
          if (this.form.id) {
            //没传图片
            if (!this.canShow) {
              let formdata = this.formdataify(this.form);
              studentAddOrUpdate(formdata).then((res) => {
                this.$message.success("修改成功");
                this.visible = false;
                this.getStudentList();
              });
            } else {
              this.$refs.cropper.getCropBlob((data) => {
                //修改命名
                const time = new Date().getTime();
                const randomName = time + "_" + this.file.name;
                // blob转file
                let file = new window.File([data], randomName, {
                  type: data.type,
                });
                this.form.file = file;
                let formdata = this.formdataify(this.form);
                studentAddOrUpdate(formdata).then((res) => {
                  this.$message.success("修改成功");
                  this.visible = false;
                  this.getStudentList();
                });
              });
            }
          }
          //上传
          else {
            //没传图片
            if (this.options.avatar === "") {
              let formdata = this.formdataify(this.form);
              studentAddOrUpdate(formdata).then((res) => {
                this.$message.success("新增成功");
                this.visible = false;
                this.getStudentList();
              });
            } else {
              this.$refs.cropper.getCropBlob((data) => {
                const time = new Date().getTime();
                const randomName = time + "_" + this.file.name;
                // blob转file
                let file = new window.File([data], randomName, {
                  type: data.type,
                });
                this.form.file = file;
                let formdata = this.formdataify(this.form);
                studentAddOrUpdate(formdata).then((res) => {
                  this.$message.success("新增成功");
                  this.visible = false;
                  this.getStudentList();
                });
              });
            }
          }
        }
      });
    },
    //新增或编辑取消
    handleCancel() {
      //   this.$refs.ruleForm.resetFields();
      this.visible = false;
    },
    //下拉菜单
    handleMenuClick(e) {
      //批量导入
      if (e.key == "add") {
        this.largeAddVisible = true;
        this.confirmLoading = false;
        this.fileList = [];
        this.addForm = {};
      }
      //批量导出
      else {
        this.$confirm({
          title: "您确认导出学生数据吗？",
          onOk: () => {
            studentExport({}, `student_export_${new Date().getTime()}.xlsx`);
          },
        });
      }
    },
    //分页状态改变
    onChange(pageNumber) {
      this.queryParams.page = pageNumber;
      this.getStudentList();
    },
    //改变分页上一步下一步的文字链接
    itemRender(current, type, originalElement) {
      if (type === "prev") {
        return <a>上一页</a>;
      } else if (type === "next") {
        return <a class="next">下一页</a>;
      }
      return originalElement;
    },
    // 覆盖默认的上传行为
    requestUpload() {},
    // 向左旋转
    rotateLeft() {
      this.$refs.cropper.rotateLeft();
    },
    // 向右旋转
    rotateRight() {
      this.$refs.cropper.rotateRight();
    },
    // 图片缩放
    changeScale(num) {
      num = num || 1;
      this.$refs.cropper.changeScale(num);
    },
    // 上传预处理
    beforeUpload(file) {
      if (file.type.indexOf("image/") == -1) {
        this.$message.error("文件格式错误，请上传图片类型,如：JPG，PNG后缀的文件。");
      } else {
        //如果编辑的时侯上传头像
        if (this.canShow === false) {
          this.canShow = true;
        }
        this.file = file;
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          this.options.avatar = reader.result;
        };
      }
    },
    // 实时预览
    realTime(data) {
      this.previews = data;
    },
    //下载模板
    download() {
      studentDownloadTemplate({}, `student_${new Date().getTime()}.xlsx`);
    },
    //移除选择的文件
    handleRemove(file) {
      const index = this.fileList.indexOf(file);
      const newFileList = this.fileList.slice();
      newFileList.splice(index, 1);
      this.fileList = newFileList;
    },
    //文件状态改变
    handleChange(info) {
      this.$nextTick(() => {
        if (this.fileList.length > 0) {
          this.$refs.file.clearValidate();
          this.addForm.file = info.file;
        } else {
          this.addForm.file = null;
        }
      });
    },
    //上传后提交前的操作
    beforeUploadFile(file) {
      if (this.fileList.length > 0) {
        this.$message.error("您只能选择一个文件上传，请删除后继续上传");
        return false;
      } else {
        let fileExtension = file.name.substring(file.name.lastIndexOf(".") + 1);
        const isExcel = fileExtension === "xlsx" || fileExtension === "xls";
        if (!isExcel) {
          this.$message.error("文件格式错误");
        }
        const isLt50M = file.size / 1024 / 1024 < 50;
        if (!isLt50M) {
          this.$message.error("文件不能大于50M");
        }
        if (isLt50M && isExcel) {
          this.fileList = [...this.fileList, file];
        } else {
          this.fileList = [];
          this.$refs.file.validate();
        }
        return false;
      }
    },
    //上传
    handleUpload() {
      this.$refs["addForm"].validateField("classId", (error) => {
        if (!error) {
          if (this.fileList.length > 0) {
            this.confirmLoading = true;
            let formData = this.formdataify(this.addForm);
            studentImport(formData).then((res) => {
              this.$message.success("上传成功");
              this.largeAddVisible = false;
              this.confirmLoading = false;
              this.getStudentList();
            });
          } else {
            this.$message.error("请上传文件");
          }
        }
      });
    },
    //json对象转换为formData格式
    formdataify(params) {
      const formData = new FormData();
      Object.keys(params).forEach((key) => {
        formData.append(key, params[key]);
      });
      return formData;
    },
  },
  created() {
    this.getClassList();
    this.getSexList();
    this.getStudentList();
  },
};
</script>

<style scoped lang="less">
.avatar-upload-preview {
  position: absolute;
  top: 16px;
  left: 180px;
  width: 64px;
  height: 64px;
  box-shadow: 0 0 4px #ccc;
  overflow: hidden;
  border-radius: 50%;
  .avatar {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
::v-deep .ant-descriptions-item-content {
  text-align: center;
  width: 50%;
}
::v-deep .ant-descriptions-item-label {
  text-align: center;
}
</style>
