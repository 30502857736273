<template>
  <layout-content :total="total">
    <!-- 面包屑的插槽 -->
    <breadcrumb slot="breadcrumb"></breadcrumb>
    <div slot="content">
      <!-- 搜索和新增删除 -->
      <div class="d-f a-c j-b">
        <a-form-model ref="queryForm" :model="queryParams" layout="inline">
          <a-form-model-item label="用户名" prop="username">
            <a-input
              v-model="queryParams.username"
              placeholder="请输入用户名"
              allowClear
              @keyup.enter.native="handleQuery"
            />
          </a-form-model-item>
          <a-form-model-item label="所属租户" prop="tenantId">
            <a-select
              v-model="queryParams.tenantId"
              placeholder="请选择所属租户"
              style="width: 180px"
              allowClear
              :getPopupContainer="(triggerNode) => triggerNode.parentNode"
              @change="
                () => {
                  this.$forceUpdate();
                }
              "
            >
              <a-select-option
                :value="item.tenantId"
                v-for="item in tenantList"
                :key="item.tenantId"
              >
                {{ item.tenantName }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item>
            <a-button type="primary" @click="handleQuery" v-hasPermi="['acl:user:query']"
              >搜索</a-button
            >
            <a-button @click="resetQuery" class="ml10" v-hasPermi="['acl:user:query']"
              >重置</a-button
            >
          </a-form-model-item>
        </a-form-model>
        <div>
          <a-button
            type="primary"
            @click="handleAdd"
            icon="plus"
            v-hasPermi="['acl:user:add']"
            >新增</a-button
          >
          <a-button
            @click="handleDelete"
            icon="delete"
            class="ml10"
            :disabled="multiple"
            v-hasPermi="['acl:user:remove']"
          >
            批量删除
          </a-button>
        </div>
      </div>
      <!--表格-->
      <a-table
        :columns="columns"
        rowKey="id"
        :data-source="data"
        :pagination="false"
        class="mt20"
        :row-selection="{
          selectedRowKeys: selectedRowKeys,
          onChange: onSelectChange,
        }"
      >
        <span slot="headImgUrl" slot-scope="text">
          <img :src="text" v-if="text" width="32px" style="border-radius: 50%" />
          <img
            src="@/assets/images/enter_avatar.png"
            width="32px"
            v-else
            style="border-radius: 50%"
          />
        </span>
        <span slot="roles" slot-scope="text">
          <a-tag color="blue" v-for="item in text" :key="item.id">
            {{ item.name }}
          </a-tag>
        </span>
        <span slot="tenantName" slot-scope="text, record">
          {{ text }}（{{ record.tenantId }}）
        </span>
        <span slot="enabled" slot-scope="text">
          <a-tag :color="text ? '#009FE8' : '#f5222d'">
            {{ text | filterEnabled }}
          </a-tag>
        </span>
        <span slot="action" slot-scope="text, record">
          <a @click="handleUpdate(record)" v-hasPermi="['acl:user:edit']">编辑</a>
          <a-divider type="vertical" />
          <a @click="handleDelete(record)" v-hasPermi="['acl:user:remove']">删除</a>
        </span>
      </a-table>
      <!--分页-->
      <div class="d-f mt20">
        <a-pagination
          v-model="queryParams.page"
          :pageSize="queryParams.limit"
          :default-current="1"
          :total="total"
          :hideOnSinglePage="true"
          showLessItems
          @change="onChange"
          :item-render="itemRender"
          style="margin-left: auto"
        />
      </div>
      <!--新增和编辑的弹出框-->
      <a-modal
        v-model="visible"
        :title="title"
        @ok="submit"
        @cancel="handleCancel"
        width="700px"
      >
        <a-form-model
          ref="ruleForm"
          :model="form"
          :rules="rules"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
        >
          <a-row>
            <a-col :span="12">
              <a-form-model-item label="用户名" prop="username">
                <a-input v-model="form.username" placeholder="请输入用户名" />
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item label="密码" :prop="password">
                <a-input
                  v-model="form.password"
                  placeholder="请输入密码"
                  type="password"
                  autocomplete="new-password"
                  @change="changePassword"
                />
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item label="手机号" prop="mobile">
                <a-input v-model="form.mobile" placeholder="请输入手机号" />
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item label="角色" prop="role">
                <a-select
                  v-model="form.role"
                  placeholder="请选择角色"
                  style="width: 100%"
                  :getPopupContainer="(triggerNode) => triggerNode.parentNode"
                  @change="changeRole"
                >
                  <a-select-option
                    :value="item.id"
                    v-for="item in rolesList"
                    :key="item.id"
                  >
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="24" v-if="isRegionRole">
              <a-form-model-item
                label="选择区域"
                prop="regionCodes"
                :label-col="{ span: 4, offset: 0 }"
              >
                <treeselect
                  v-model="form.regionCodes"
                  :multiple="true"
                  :options="regionOptions"
                  :normalizer="normalizer"
                  placeholder="选择区域"
                  :flat="true"
                  :autoSelectDescendants="true"
                  :autoDeselectDescendants="true"
                />
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item label="所属租户" prop="tenantId">
                <a-select
                  v-model="form.tenantId"
                  placeholder="请选择所属租户"
                  style="width: 100%"
                  :getPopupContainer="(triggerNode) => triggerNode.parentNode"
                >
                  <a-select-option
                    :value="item.tenantId"
                    v-for="item in tenantList"
                    :key="item.tenantId"
                  >
                    {{ item.tenantName }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="12" v-if="isSchoolRole">
              <a-form-model-item label="学校编号" prop="schoolNum">
                <a-input v-model="form.schoolNum" placeholder="请输入学校编号" />
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item label="状态" prop="enabled">
                <a-radio-group v-model="form.enabled">
                  <a-radio :value="true"> 启用 </a-radio>
                  <a-radio :value="false"> 禁用 </a-radio>
                </a-radio-group>
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item label="性别" prop="sex">
                <a-select
                  v-model="form.sex"
                  placeholder="请选择性别"
                  style="width: 100%"
                  :getPopupContainer="(triggerNode) => triggerNode.parentNode"
                  @change="
                    () => {
                      this.$forceUpdate();
                    }
                  "
                >
                  <a-select-option :value="0"> 未知 </a-select-option>
                  <a-select-option :value="1"> 男</a-select-option>
                  <a-select-option :value="2"> 女</a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item label="真实姓名" prop="realName">
                <a-input v-model="form.realName" placeholder="请输入真实姓名" />
              </a-form-model-item>
            </a-col>
            <a-col :span="24">
              <a-form-model-item
                label="头像"
                prop="headImgUrl"
                :label-col="{ span: 4, offset: 0 }"
              >
                <!-- 图片剪裁以及预览 -->
                <a-row>
                  <a-col :span="24" :style="{ height: '100px', width: '100px' }">
                    <vue-cropper
                      ref="cropper"
                      :img="options.headImgUrl"
                      :info="true"
                      :autoCrop="options.autoCrop"
                      :autoCropWidth="options.autoCropWidth"
                      :autoCropHeight="options.autoCropHeight"
                      :fixedBox="options.fixedBox"
                      @realTime="realTime"
                      outputType="png"
                    />
                    <div class="avatar-upload-preview" v-if="canShow">
                      <img :src="previews.url" :style="previews.img" />
                    </div>
                    <div class="avatar-upload-preview" v-else>
                      <img :src="form.headImgUrl" class="avatar" />
                    </div>
                  </a-col>
                </a-row>
                <br />
                <!-- 上传，上下左右旋转以及放大缩小 -->
                <a-row>
                  <a-col :lg="2" :md="2">
                    <a-upload
                      action="#"
                      :http-request="requestUpload"
                      :showUploadList="false"
                      :before-upload="beforeUpload"
                      accept="image/*"
                    >
                      <a-button>选择<a-icon type="upload" /></a-button>
                    </a-upload>
                  </a-col>
                  <a-col :lg="{ span: 1, offset: 7 }" :md="2">
                    <a-button icon="plus" @click="changeScale(1)"></a-button>
                  </a-col>
                  <a-col :lg="{ span: 1, offset: 3 }" :md="2">
                    <a-button icon="minus" @click="changeScale(-1)"></a-button>
                  </a-col>
                  <a-col :lg="{ span: 1, offset: 3 }" :md="2">
                    <a-button icon="undo" @click="rotateLeft()"></a-button>
                  </a-col>
                  <a-col :lg="{ span: 1, offset: 3 }" :md="2">
                    <a-button icon="redo" @click="rotateRight()"></a-button>
                  </a-col>
                </a-row>
              </a-form-model-item>
            </a-col>
          </a-row>
        </a-form-model>
      </a-modal>
    </div>
  </layout-content>
</template>

<script>
import LayoutContent from "@/views/layout/content";
import Breadcrumb from "~c/Breadcrumb";
import { getUserList, getUser, userRemove, userAddOrUpdate } from "@/api/acl/user.js";
import { getTenantALlList } from "@/api/acl/tenant.js";
import { getRoles } from "@/api/acl/role.js";
import { VueCropper } from "vue-cropper";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import { getAllRegionList } from "@/api/setting/region.js";
const columns = [
  {
    title: "头像",
    dataIndex: "headImgUrl",
    align: "center",
    scopedSlots: { customRender: "headImgUrl" },
  },
  {
    title: "用户名",
    dataIndex: "username",
    align: "center",
  },
  {
    title: "手机号",
    dataIndex: "mobile",
    align: "center",
  },
  {
    title: "所属租户",
    dataIndex: "tenantName",
    align: "center",
    scopedSlots: { customRender: "tenantName" },
  },
  {
    title: "角色",
    dataIndex: "roles",
    align: "center",
    scopedSlots: { customRender: "roles" },
  },
  {
    title: "学校编号",
    dataIndex: "schoolNum",
    align: "center",
  },
  {
    title: "状态",
    dataIndex: "enabled",
    align: "center",
    scopedSlots: { customRender: "enabled" },
  },
  {
    title: "操作",
    dataIndex: "action",
    // width: "25%",
    align: "center",
    scopedSlots: { customRender: "action" },
  },
];
export default {
  name: "User",
  components: { LayoutContent, Breadcrumb, VueCropper, Treeselect },
  filters: {
    filterEnabled(val) {
      if (val) {
        return "启用";
      } else {
        return "禁用";
      }
    },
  },
  data() {
    return {
      total: null,
      // 查询参数
      queryParams: {
        //当前页
        page: 1,
        //一页几个
        limit: 10,
      },
      //表格的表头文字
      columns,
      //表格的数据
      data: [],
      //多选的数据
      selectedRowKeys: [],
      // 非多个禁用
      multiple: true,
      //新增和编辑的表单
      form: {},
      //表单规则
      rules: {
        username: [
          {
            required: true,
            message: "请输入用户名",
            whitespace: true,
            trigger: ["change", "blur"],
          },
          {
            pattern: /^[A-Za-z][0-9A-Za-z]{2,12}$/,
            message: "用户名只能包括数字(不以数字开头)和字母且在3~12位之间",
            trigger: ["change", "blur"],
          },
        ],
        password: [
          {
            required: true,
            message: "请输入密码",
            whitespace: true,
            trigger: ["change", "blur"],
          },
          {
            pattern: /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,20}$/,
            message: "6-20位，字母和数字组合",
            trigger: ["change", "blur"],
          },
        ],
        mobile: [
          {
            required: true,
            message: "请输入手机号",
            whitespace: true,
            trigger: ["change", "blur"],
          },
          {
            pattern: /^[1][3,4,5,6,7,8,9][0-9]{9}$/,
            message: "手机号格式错误",
            trigger: "blur",
          },
        ],
        role: [
          {
            required: true,
            message: "请选择角色",
            whitespace: true,
            trigger: ["change", "blur"],
          },
        ],
        tenantId: [
          {
            required: true,
            message: "请选择所属租户",
            whitespace: true,
            trigger: ["change", "blur"],
          },
        ],
        schoolNum: [
          {
            required: true,
            message: "请输入学校编号",
            whitespace: true,
            trigger: ["change", "blur"],
          },
        ],
        regionCodes: [
          {
            type: "array",
            required: true,
            message: "请选择区域",
            whitespace: true,
            trigger: ["change", "blur"],
          },
        ],
        enabled: [
          {
            type: "boolean",
            required: true,
            message: "请选择状态",
            whitespace: true,
            trigger: ["change", "blur"],
          },
        ],
      },
      //label和wrapper的占位
      labelCol: { span: 8 },
      wrapperCol: { span: 14 },
      //新增和编辑弹出框的标题
      title: "",
      //新增和编辑弹出框的显示隐藏
      visible: false,
      //所有租户列表
      tenantList: [],
      //所有角色列表
      rolesList: [],
      //新增添加表单验证，编辑时取消
      password: "password",
      //用来判断是否是学校管理员
      isSchoolRole: false,
      //用来判断是否是区域管理员
      isRegionRole: false,
      //盒子图片裁剪
      options: {
        headImgUrl: "", //裁剪图片的地址
        autoCrop: true, // 是否默认生成截图框
        autoCropWidth: 64, // 默认生成截图框宽度
        autoCropHeight: 64, // 默认生成截图框高度
        fixedBox: true, // 固定截图框大小 不允许改变
      },
      //盒子图片预览
      previews: {},
      //盒子图片文件
      file: null,
      //是否展示预览图片
      canShow: true,
      //区域列表
      regionOptions: [],
    };
  },
  methods: {
    //获取用户列表
    async getUserList() {
      let currentRoles = [];
      this.$ls.get("userInfo").roles.forEach((r) => {
        currentRoles.push(r.roleAlias);
      });

      let roleAliasList = await this.getDictDataList(currentRoles.join(","));
      this.queryParams.roleAliases = roleAliasList.data[0].valueData;
      getUserList(this.queryParams).then((res) => {
        this.total = res.count;
        this.data = res.data;
        this.multiple = !this.selectedRowKeys.length;
      });
    },
    //获取所有的租户列表
    getTenantALlList() {
      getTenantALlList().then((res) => {
        this.tenantList = res.data;
      });
    },
    //搜索
    handleQuery() {
      this.queryParams.page = 1;
      this.getUserList();
    },
    //搜索重置
    resetQuery() {
      this.queryParams.page = 1;
      this.$refs.queryForm.resetFields();
      this.getUserList();
    },
    //复选框状态改变
    onSelectChange(selectedRowKeys, records) {
      // console.log(selectedRowKeys);
      // console.log(records);
      this.selectedRowKeys = selectedRowKeys;
      this.multiple = !selectedRowKeys.length;
    },
    /** 新增按钮操作 */
    async handleAdd() {
      let currentRoles = [];
      this.$ls.get("userInfo").roles.forEach((r) => {
        currentRoles.push(r.roleAlias);
      });

      let roleAliasList = await this.getDictDataList(currentRoles.join(","));

      if (roleAliasList.data.length > 0) {
        let result = await getRoles({ roleAliases: roleAliasList.data[0].valueData });
        this.rolesList = result.data;
      } else {
        this.rolesList = [];
      }

      let res = await getAllRegionList();
      this.regionOptions = res.data;

      this.form = {};
      this.isSchoolRole = false;
      this.isRegionRole = false;
      this.form.sex = 0;
      this.form.enabled = true;
      this.options.headImgUrl = "";
      this.canShow = true;
      this.visible = true;
      this.title = "新增用户";
      this.password = "password";
    },
    /** 修改按钮操作 */
    async handleUpdate(row) {
      let currentRoles = [];
      this.$ls.get("userInfo").roles.forEach((r) => {
        currentRoles.push(r.roleAlias);
      });
      let roleAliasList = await this.getDictDataList(currentRoles.join(","));
      if (roleAliasList.data.length > 0) {
        let result = await getRoles({ roleAliases: roleAliasList.data[0].valueData });
        this.rolesList = result.data;
      } else {
        this.rolesList = [];
      }

      let res = await getAllRegionList();
      this.regionOptions = res.data;

      let response = await getUser(row.id);
      this.form = response.data;
      this.form.role = response.data.roles[0].id;
      Object.keys(this.form).forEach((key) => {
        if (this.form[key] === null) {
          delete this.form[key];
        }
      });

      this.isSchoolRole =
        this.rolesList.find((r) => r.id == this.form.role).roleAlias === "school-admin";
      this.isRegionRole =
        this.rolesList.find((r) => r.id == this.form.role).roleAlias === "region-admin";
      this.options.headImgUrl = "";
      this.canShow = false;
      this.visible = true;
      this.title = "修改用户";
      this.password = "";
    },
    //密码框改动
    changePassword() {
      this.password = "password";
    },
    //角色选择改变
    changeRole(value) {
      this.isSchoolRole =
        this.rolesList.find((r) => r.id === value).roleAlias === "school-admin";
      this.isRegionRole =
        this.rolesList.find((r) => r.id == this.form.role).roleAlias === "region-admin";
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      this.$confirm({
        title: "您确认删除您选中的数据吗？",
        onOk: () => {
          //单个删除
          if (row.id) {
            let arr = [];
            arr.push(row.id);
            userRemove(arr).then((res) => {
              this.$message.success("删除成功");
              this.getUserList();
            });
          }
          //批量删除
          else {
            userRemove(this.selectedRowKeys).then((res) => {
              this.$message.success("删除成功");
              this.selectedRowKeys = [];
              this.getUserList();
            });
          }
        },
      });
    },
    //新增或编辑确定
    submit() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          let params = JSON.parse(JSON.stringify({ ...this.form }));
          let role = JSON.parse(JSON.stringify(this.form.role));
          let roles = [];
          roles.push(role);
          params.roleIds = roles;
          delete params.role;
          //修改
          if (this.form.id) {
            //没传图片
            if (!this.canShow) {
              let formdata = this.formdataify(params);
              userAddOrUpdate(formdata).then((res) => {
                this.$message.success("修改成功");
                this.visible = false;
                this.getUserList();
              });
            } else {
              this.$refs.cropper.getCropBlob((data) => {
                //修改命名
                const time = new Date().getTime();
                const randomName = time + "_" + this.file.name;
                // blob转file
                let file = new window.File([data], randomName, {
                  type: data.type,
                });
                params.file = file;
                let formdata = this.formdataify(params);
                userAddOrUpdate(formdata).then((res) => {
                  this.$message.success("修改成功");
                  this.visible = false;
                  this.getUserList();
                });
              });
            }
          }
          //上传
          else {
            //没传图片
            if (this.options.headImgUrl === "") {
              let formdata = this.formdataify(params);
              userAddOrUpdate(formdata).then((res) => {
                this.$message.success("新增成功");
                this.visible = false;
                this.getUserList();
              });
            } else {
              this.$refs.cropper.getCropBlob((data) => {
                const time = new Date().getTime();
                const randomName = time + "_" + this.file.name;
                // blob转file
                let file = new window.File([data], randomName, {
                  type: data.type,
                });
                params.file = file;
                let formdata = this.formdataify(params);
                userAddOrUpdate(formdata).then((res) => {
                  this.$message.success("新增成功");
                  this.visible = false;
                  this.getUserList();
                });
              });
            }
          }
        }
      });
    },
    //新增或编辑取消
    handleCancel() {
      this.form = {};
      this.visible = false;
    },
    //分页状态改变
    onChange(pageNumber) {
      this.queryParams.page = pageNumber;
      this.getUserList();
    },
    //改变分页上一步下一步的文字链接
    itemRender(current, type, originalElement) {
      if (type === "prev") {
        return <a>上一页</a>;
      } else if (type === "next") {
        return <a class="next">下一页</a>;
      }
      return originalElement;
    },
    // 覆盖默认的上传行为
    requestUpload() {},
    // 向左旋转
    rotateLeft() {
      this.$refs.cropper.rotateLeft();
    },
    // 向右旋转
    rotateRight() {
      this.$refs.cropper.rotateRight();
    },
    // 图片缩放
    changeScale(num) {
      num = num || 1;
      this.$refs.cropper.changeScale(num);
    },
    // 上传预处理
    beforeUpload(file) {
      if (file.type.indexOf("image/") == -1) {
        this.$message.error("文件格式错误，请上传图片类型,如：JPG，PNG后缀的文件。");
      } else {
        //如果编辑的时侯上传头像
        if (this.canShow === false) {
          this.canShow = true;
        }
        this.file = file;
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          this.options.headImgUrl = reader.result;
        };
      }
    },
    // 实时预览
    realTime(data) {
      this.previews = data;
    },
    //json对象转换为formData格式
    formdataify(params) {
      const formData = new FormData();
      Object.keys(params).forEach((key) => {
        formData.append(key, params[key]);
      });
      return formData;
    },
    /** 转换菜单数据结构 */
    normalizer(node) {
      if (node.children && !node.children.length) {
        delete node.children;
      }
      return {
        id: node.id,
        label: node.title,
        children: node.children,
      };
    },
  },
  created() {
    this.getUserList();
    this.getTenantALlList();
  },
};
</script>

<style scoped lang="less">
.avatar-upload-preview {
  position: absolute;
  top: 16px;
  left: 180px;
  width: 64px;
  height: 64px;
  box-shadow: 0 0 4px #ccc;
  overflow: hidden;
  border-radius: 50%;
  .avatar {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
::v-deep .vue-treeselect__control {
  border-radius: 0px;
}
::v-deep .vue-treeselect__multi-value {
  max-height: 100px;
  overflow-y: auto;
}
::v-deep .ant-form-item-control {
  line-height: 32px;
}
</style>
