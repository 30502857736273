<template>
  <layout-content :total="total">
    <!-- 面包屑的插槽 -->
    <breadcrumb slot="breadcrumb"></breadcrumb>
    <div slot="content">
      <!-- 搜索和新增删除 -->
      <div class="d-f a-c j-b">
        <a-form-model ref="queryForm" :model="queryParams" layout="inline">
          <a-form-model-item label="软件版本名称" prop="softwareVersionName">
            <a-input
              v-model="queryParams.softwareVersionName"
              placeholder="请输入软件版本名称"
              allowClear
              @keyup.enter.native="handleQuery"
            />
          </a-form-model-item>
          <a-form-model-item>
            <a-button
              type="primary"
              @click="handleQuery"
              v-hasPermi="['version:software:query']"
              >搜索</a-button
            >
            <a-button
              @click="resetQuery"
              class="ml10"
              v-hasPermi="['version:software:query']"
              >重置</a-button
            >
          </a-form-model-item>
        </a-form-model>
        <div>
          <a-button
            type="primary"
            @click="handleUpload"
            icon="plus"
            v-hasPermi="['version:software:upload']"
            >上传版本</a-button
          >
        </div>
      </div>
      <!--表格-->
      <a-table
        :columns="columns"
        rowKey="id"
        :data-source="data"
        :pagination="false"
        class="mt20"
        :scroll="{ x: 1900 }"
      >
        <span slot="motherBoardFactory" slot-scope="text">
          {{ motherBoardFactoryFormat(text) }}
        </span>
        <span slot="motherBoardModel" slot-scope="text">
          {{ motherBoardModelFormat(text) }}
        </span>
        <span slot="softwareType" slot-scope="text">
          {{ softwareTypeFormat(text) }}
        </span>
        <span slot="isNew" slot-scope="text, record">
          <a-switch :checked="text == 0 ? false : true" @change="onChangeIsNew(record)" />
        </span>
        <span slot="environment" slot-scope="text">
          {{ environmentFormat(text) }}
        </span>
        <span slot="softwareVersion" slot-scope="text">
          {{ softwareVersionFormat(text) }}
        </span>
        <span slot="action" slot-scope="text, record">
          <a @click="handleDownload(record)" v-hasPermi="['version:software:download']"
            >下载</a
          >
          <a-divider type="vertical" />
          <a @click="handleDelete(record)" v-hasPermi="['version:software:remove']"
            >删除</a
          >
        </span>
      </a-table>
      <!--分页-->
      <div class="d-f mt20">
        <a-pagination
          v-model="queryParams.page"
          :pageSize="queryParams.limit"
          :default-current="1"
          :total="total"
          :hideOnSinglePage="true"
          showLessItems
          @change="onChange"
          :item-render="itemRender"
          style="margin-left: auto"
        />
      </div>
      <!--上传的弹出框-->
      <a-modal
        v-model="visible"
        title="上传软件"
        @ok="submit"
        @cancel="handleCancel"
        width="700px"
      >
        <a-spin :spinning="confirmLoading">
          <a-form-model
            ref="ruleForm"
            :model="form"
            :rules="rules"
            :label-col="labelCol"
            :wrapper-col="wrapperCol"
          >
            <a-row>
              <a-col :span="12">
                <a-form-model-item label="主板厂家" prop="motherBoardFactory">
                  <a-select
                    v-model="form.motherBoardFactory"
                    placeholder="请选择主板厂家"
                    :getPopupContainer="(triggerNode) => triggerNode.parentNode"
                  >
                    <a-select-option
                      v-for="item in motherBoardFactoryData"
                      :key="item.value"
                      :value="item.value"
                      >{{ item.vname }}</a-select-option
                    >
                  </a-select>
                </a-form-model-item>
              </a-col>
              <a-col :span="12">
                <a-form-model-item label="主板型号" prop="motherBoardModel">
                  <a-select
                    v-model="form.motherBoardModel"
                    placeholder="请选择主板型号"
                    :getPopupContainer="(triggerNode) => triggerNode.parentNode"
                  >
                    <a-select-option
                      v-for="item in motherBoardModelData"
                      :key="item.value"
                      :value="item.value"
                      >{{ item.vname }}</a-select-option
                    >
                  </a-select>
                </a-form-model-item>
              </a-col>
              <a-col :span="12">
                <a-form-model-item label="软件类型" prop="softwareType">
                  <a-select
                    v-model="form.softwareType"
                    placeholder="请选择软件类型"
                    :getPopupContainer="(triggerNode) => triggerNode.parentNode"
                  >
                    <a-select-option
                      v-for="item in softwareTypeData"
                      :key="item.value"
                      :value="item.value"
                      >{{ item.vname }}</a-select-option
                    >
                  </a-select>
                </a-form-model-item>
              </a-col>
              <a-col :span="12">
                <a-form-model-item label="软件版本" prop="softwareVersion">
                  <a-select
                    v-model="form.softwareVersion"
                    placeholder="请选择软件版本"
                    :getPopupContainer="(triggerNode) => triggerNode.parentNode"
                  >
                    <a-select-option
                      v-for="item in softwareVersionData"
                      :key="item.value"
                      :value="item.value"
                      >{{ item.vname }}</a-select-option
                    >
                  </a-select>
                </a-form-model-item>
              </a-col>
              <a-col :span="12">
                <a-form-model-item label="最新版本" prop="isNew">
                  <a-radio-group v-model="form.isNew">
                    <a-radio :value="1">是</a-radio>
                    <a-radio :value="0">否</a-radio>
                  </a-radio-group>
                </a-form-model-item>
              </a-col>
              <a-col :span="12">
                <a-form-model-item label="环境" prop="environment">
                  <a-select
                    v-model="form.environment"
                    placeholder="请选择环境"
                    :getPopupContainer="(triggerNode) => triggerNode.parentNode"
                  >
                    <a-select-option
                      v-for="item in environmentData"
                      :key="item.value"
                      :value="item.value"
                      >{{ item.vname }}</a-select-option
                    >
                  </a-select>
                </a-form-model-item>
              </a-col>
              <a-col :span="24">
                <a-form-model-item
                  label="软件包"
                  prop="softwareDescription"
                  :label-col="{ span: 4, offset: 0 }"
                >
                  <a-upload-dragger
                    name="file"
                    :file-list="fileList"
                    :remove="handleRemove"
                    :before-upload="beforeUpload"
                  >
                    <p class="ant-upload-drag-icon">
                      <a-icon type="inbox" />
                    </p>
                    <p class="ant-upload-text">
                      点击或将文件拖到这里上传(多个文件只上传第一个)
                    </p>
                  </a-upload-dragger>
                </a-form-model-item>
              </a-col>
              <a-col :span="12">
                <a-form-model-item label="软件版本号" prop="softwareVersionCode">
                  <a-input
                    v-model="form.softwareVersionCode"
                    placeholder="请输入软件版本号"
                    disabled
                  />
                </a-form-model-item>
              </a-col>
              <a-col :span="12">
                <a-form-model-item label="软件版本名称" prop="softwareVersionName">
                  <a-input
                    v-model="form.softwareVersionName"
                    placeholder="请输入软件版本名称"
                    disabled
                  />
                </a-form-model-item>
              </a-col>
            </a-row>
          </a-form-model>
        </a-spin>
      </a-modal>
    </div>
  </layout-content>
</template>

<script>
import md5 from "js-md5";
import LayoutContent from "@/views/layout/content";
import Breadcrumb from "~c/Breadcrumb";
import {
  getSoftwareList,
  softwareUpload,
  getSoftwareDownload,
  softwareRemove,
  softwareUpdate,
} from "@/api/version/software.js";
import { getParameterDataByParamName } from "@/api/version/parameter.js";
import { getStsInfo } from "@/api/cloud-disk/document.js";
import Client from "@/utils/oss.js";
const columns = [
  {
    title: "主板厂家",
    dataIndex: "motherBoardFactory",
    width: 150,
    align: "center",
    scopedSlots: { customRender: "motherBoardFactory" },
  },
  {
    title: "主板型号",
    dataIndex: "motherBoardModel",
    width: 150,
    align: "center",
    scopedSlots: { customRender: "motherBoardModel" },
  },
  {
    title: "软件类型",
    dataIndex: "softwareType",
    width: 150,
    align: "center",
    scopedSlots: { customRender: "softwareType" },
  },
  {
    title: "软件版本",
    dataIndex: "softwareVersion",
    width: 200,
    align: "center",
    scopedSlots: { customRender: "softwareVersion" },
  },
  {
    title: "最新版本",
    dataIndex: "isNew",
    width: 200,
    align: "center",
    scopedSlots: { customRender: "isNew" },
  },
  {
    title: "环境",
    dataIndex: "environment",
    width: 200,
    align: "center",
    scopedSlots: { customRender: "environment" },
  },
  {
    title: "md5",
    dataIndex: "md5",
    width: 200,
    align: "center",
  },
  {
    title: "软件版本号",
    dataIndex: "softwareVersionCode",
    width: 200,
    align: "center",
    fixed: "right",
  },
  {
    title: "软件版本名称",
    dataIndex: "softwareVersionName",
    width: 200,
    align: "center",
    fixed: "right",
  },
  {
    title: "操作",
    dataIndex: "action",
    width: 300,
    align: "center",
    scopedSlots: { customRender: "action" },
    fixed: "right",
  },
];
export default {
  name: "Software",
  components: { LayoutContent, Breadcrumb },
  data() {
    return {
      total: null,
      // 查询参数
      queryParams: {
        //当前页
        page: 1,
        //一页几个
        limit: 10,
      },
      //表格的表头文字
      columns,
      //表格的数据
      data: [],
      //新增和编辑的表单
      form: {
        platform: "Android",
      },
      //表单规则
      rules: {
        motherBoardFactory: [
          {
            required: true,
            message: "请选择主板厂家",
            trigger: ["change", "blur"],
          },
        ],
        motherBoardModel: [
          {
            required: true,
            message: "请选择主板型号",
            trigger: ["change", "blur"],
          },
        ],
        softwareType: [
          {
            required: true,
            message: "请选择软件类型",
            trigger: ["change", "blur"],
          },
        ],
        softwareVersion: [
          {
            required: true,
            message: "请输入软件版本",
            whitespace: true,
            trigger: ["change", "blur"],
          },
        ],
        softwareVersionCode: [
          {
            required: true,
            message: "请输入软件版本号",
            type: "number",
            whitespace: true,
            trigger: ["change", "blur"],
          },
        ],
        softwareVersionName: [
          {
            required: true,
            message: "请输入软件版本名称",
            whitespace: true,
            trigger: ["change", "blur"],
          },
        ],
        isNew: [
          {
            type: "number",
            required: true,
            message: "请选择最新版本",
            trigger: ["change", "blur"],
          },
        ],
        environment: [
          {
            required: true,
            message: "请选择环境",
            trigger: ["change", "blur"],
          },
        ],
      },
      //label和wrapper的占位
      labelCol: { span: 8 },
      wrapperCol: { span: 14 },
      //软件升级弹出框的显示隐藏
      visible: false,
      //软件升级的弹框loading效果
      confirmLoading: false,
      //sts信息
      dataObj: null,
      //软件包列表
      fileList: [],
      //主板厂家列表
      motherBoardFactoryData: [],
      //主板型号列表
      motherBoardModelData: [],
      //软件类型列表
      softwareTypeData: [],
      //软件版本列表
      softwareVersionData: [],
      //环境列表
      environmentData: [],
      //是否是大文件
      isBig: false,
      //第一个5m的MD5
      fileMD51: "",
      //最后一个5m的MD5
      fileMD52: "",
    };
  },
  methods: {
    //获取软件列表
    getSoftwareList() {
      getSoftwareList(this.queryParams).then((res) => {
        this.total = res.count;
        this.data = res.data;
      });
    },
    //搜索
    handleQuery() {
      this.queryParams.page = 1;
      this.getSoftwareList();
    },
    //搜索重置
    resetQuery() {
      this.queryParams.page = 1;
      this.$refs.queryForm.resetFields();
      this.getSoftwareList();
    },
    /** 升级按钮操作 */
    handleUpload() {
      this.form = {};
      this.form.platform = "Android";
      this.fileList = [];
      this.confirmLoading = false;
      this.visible = true;
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      this.$confirm({
        title: "您确认删除您选中的数据吗？",
        onOk: () => {
          softwareRemove(row.id).then((res) => {
            this.$message.success("删除成功");
            this.getSoftwareList();
          });
        },
      });
    },
    //升级确定
    submit() {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          //上传了文件和图片
          if (this.fileList.length > 0) {
            if (this.isBig) {
              this.form.md5 = md5(this.fileMD51 + this.fileMD52);
              console.log(this.form.md5);
            }
            if (this.form.md5 != null) {
              this.confirmLoading = true;
              let res = await getStsInfo();
              const {
                region,
                bucket,
                accessKeyId,
                accessKeySecret,
                securityToken,
              } = res.data;
              this.dataObj = {
                region,
                bucket,
                accessKeyId,
                accessKeySecret,
                stsToken: securityToken,
              };
              let file = this.fileList[0];
              try {
                const client = Client(this.dataObj);
                //修改命名
                var fileExtension = file.name;
                let date = new Date().getTime();
                let fileName = date + "_" + fileExtension;
                const filePath = "OTA/android/" + fileName;
                client
                  .multipartUpload(filePath, file, {
                    partSize: 5 * 1024 * 1024,
                    progress: (p) => {},
                  })
                  .then((result) => {
                    if (result.res.statusCode === 200) {
                      let params = JSON.parse(JSON.stringify(this.form));
                      params.filePath = filePath;
                      params.fileName = fileName;
                      console.log(params);
                      softwareUpload(params).then((res) => {
                        this.$message.success("上传成功");
                        this.confirmLoading = false;
                        this.visible = false;
                        this.fileList = [];
                        this.getSoftwareList();
                      });
                    }
                  });
              } catch (error) {
                this.$message.error("上传阿里云失败");
                this.confirmLoading = false;
              }
            } else {
              this.$message.error("md5为null");
            }
          } else {
            this.$message.error("请上传软件");
          }
        }
      });
    },
    //升级取消
    handleCancel() {
      this.$refs.ruleForm.resetFields();
      this.visible = false;
    },
    //改变最新版本状态
    onChangeIsNew(record) {
      let isNew = record.isNew === 0 ? 1 : 0;
      this.$confirm({
        title: "您确认改变最新版本状态吗？",
        onOk: () => {
          softwareUpdate({ id: record.id, isNew }).then((res) => {
            this.$message.success("修改成功");
            this.getSoftwareList();
          });
        },
      });
    },
    //下载
    handleDownload(row) {
      getSoftwareDownload({ id: row.id }).then((res) => {
        let link = document.createElement("a");
        link.style.display = "none";
        link.href = res.data.url;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
    },
    //分页状态改变
    onChange(pageNumber) {
      this.queryParams.page = pageNumber;
      this.getSoftwareList();
    },
    //改变分页上一步下一步的文字链接
    itemRender(current, type, originalElement) {
      if (type === "prev") {
        return <a>上一页</a>;
      } else if (type === "next") {
        return <a class="next">下一页</a>;
      }
      return originalElement;
    },
    //移除选择的版本
    handleRemove(file) {
      const index = this.fileList.indexOf(file);
      const newFileList = this.fileList.slice();
      newFileList.splice(index, 1);
      this.fileList = newFileList;
    },
    //上传后提交前的操作
    beforeUpload(action) {
      this.isBig = false;
      let index = action.name.lastIndexOf(".");
      // 判断上传的文件是否是 以apk结尾的apk文件
      if (action.name.substr(index + 1) == "apk") {
        this.fileList = [...this.fileList, action];
        let files = this.fileList[0];
        console.log(files);
        const ApkParser = require("app-info-parser/src/apk");
        const parser = new ApkParser(files); //file:所上传的文件内容
        parser
          .parse()
          .then((res) => {
            console.log(res); //打印获取所需apk文件信息
            this.form.softwareVersionCode = res.versionCode;
            this.form.softwareVersionName = res.versionName;
            this.$forceUpdate();
          })
          .catch((err) => {
            console.log(err);
          });

        let flagSize = 10 * 1024 * 1024;
        if (files.size < flagSize) {
          let fileRederInstance = new FileReader();
          fileRederInstance.readAsArrayBuffer(files);
          fileRederInstance.addEventListener("load", (e) => {
            let fileBolb = e.target.result;
            let fileMD5 = md5(fileBolb);
            this.form.md5 = fileMD5;
          });
        } else {
          this.isBig = true;
          let chunk = this.getChunkInfo(files);
          let fileRederInstance1 = new FileReader();
          let fileRederInstance2 = new FileReader();
          fileRederInstance1.readAsArrayBuffer(chunk.startChunk);
          fileRederInstance2.readAsArrayBuffer(chunk.endChunk);
          fileRederInstance1.addEventListener("load", (e) => {
            let fileBolb1 = e.target.result;
            let fileMD51 = md5(fileBolb1);
            this.fileMD51 = fileMD51;
          });
          fileRederInstance2.addEventListener("load", (event) => {
            let fileBolb2 = event.target.result;
            let fileMD52 = md5(fileBolb2);
            this.fileMD52 = fileMD52;
          });
        }
      }
      // 判断上传的文件是否是 以zip结尾的包文件
      else if (action.name.substr(index + 1) == "zip") {
        this.fileList = [...this.fileList, action];
        let files = this.fileList[0];
        let i = files.name.lastIndexOf("-");
        let fileName = files.name.substring(i + 1, index);
        let ii = fileName.indexOf(".");
        this.form.softwareVersionCode = Number(fileName.substring(ii + 1));
        this.form.softwareVersionName = fileName.substr(0, ii);

        let flagSize = 10 * 1024 * 1024;
        if (files.size < flagSize) {
          let fileRederInstance = new FileReader();
          fileRederInstance.readAsArrayBuffer(files);
          fileRederInstance.addEventListener("load", (e) => {
            let fileBolb = e.target.result;
            let fileMD5 = md5(fileBolb);
            this.form.md5 = fileMD5;
          });
        } else {
          this.isBig = true;
          let chunk = this.getChunkInfo(files);
          let fileRederInstance1 = new FileReader();
          let fileRederInstance2 = new FileReader();
          fileRederInstance1.readAsArrayBuffer(chunk.startChunk);
          fileRederInstance2.readAsArrayBuffer(chunk.endChunk);
          fileRederInstance1.addEventListener("load", (e) => {
            let fileBolb1 = e.target.result;
            let fileMD51 = md5(fileBolb1);
            this.fileMD51 = fileMD51;
          });
          fileRederInstance2.addEventListener("load", (event) => {
            let fileBolb2 = event.target.result;
            let fileMD52 = md5(fileBolb2);
            this.fileMD52 = fileMD52;
          });
        }
      } else {
        this.$message.error("请上传正确格式的文件");
      }
      return false;
    },
    //得到文件第一个5M和最后一个5M
    getChunkInfo(file) {
      const chunkSize = 5 * 1024 * 1024; // 5MB一片
      const startChunk = file.slice(0, chunkSize);
      const endChunk = file.slice(file.size - chunkSize);
      let chunk = {
        startChunk,
        endChunk,
      };
      return chunk;
    },
    //主板厂家翻译
    motherBoardFactoryFormat(value) {
      let vname = "";
      if (this.motherBoardFactoryData.length > 0) {
        this.motherBoardFactoryData.forEach((r) => {
          if (r.value == value) {
            vname = r.vname;
          }
        });
      }
      return vname;
    },
    //主板型号翻译
    motherBoardModelFormat(value) {
      let vname = "";
      if (this.motherBoardModelData.length > 0) {
        this.motherBoardModelData.forEach((r) => {
          if (r.value == value) {
            vname = r.vname;
          }
        });
      }
      return vname;
    },
    //软件类型翻译
    softwareTypeFormat(value) {
      let vname = "";
      if (this.softwareTypeData.length > 0) {
        this.softwareTypeData.forEach((r) => {
          if (r.value == value) {
            vname = r.vname;
          }
        });
      }
      return vname;
    },
    //软件版本翻译
    softwareVersionFormat(value) {
      let vname = "";
      if (this.softwareVersionData.length > 0) {
        this.softwareVersionData.forEach((r) => {
          if (r.value == value) {
            vname = r.vname;
          }
        });
      }
      return vname;
    },
    //环境翻译
    environmentFormat(value) {
      let vname = "";
      if (this.environmentData.length > 0) {
        this.environmentData.forEach((r) => {
          if (r.value == value) {
            vname = r.vname;
          }
        });
      }
      return vname;
    },
  },
  created() {
    this.getSoftwareList();
    //主板厂家的参数
    getParameterDataByParamName({ paramName: "motherBoardFactory" }).then((res) => {
      this.motherBoardFactoryData = res.data;
    });
    //主板型号的参数
    getParameterDataByParamName({ paramName: "motherBoardModel" }).then((res) => {
      this.motherBoardModelData = res.data;
    });
    //软件类型的参数
    getParameterDataByParamName({ paramName: "softwareType" }).then((res) => {
      this.softwareTypeData = res.data;
    });
    //软件版本的参数
    getParameterDataByParamName({ paramName: "softwareVersion" }).then((res) => {
      this.softwareVersionData = res.data;
    });
    //环境的参数
    getParameterDataByParamName({ paramName: "environment" }).then((res) => {
      this.environmentData = res.data;
    });
  },
};
</script>

<style scoped lang="less"></style>
