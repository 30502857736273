<template>
  <layout-content :total="total">
    <!-- 面包屑的插槽 -->
    <breadcrumb slot="breadcrumb"></breadcrumb>
    <div slot="content">
      <!-- 搜索和新增删除 -->
      <div class="d-f a-c j-b">
        <a-form-model ref="queryForm" :model="queryParams" layout="inline">
          <a-form-model-item label="指令名称" prop="actionType">
            <a-select
              v-model="queryParams.actionType"
              :getPopupContainer="(triggerNode) => triggerNode.parentNode"
              style="width: 180px"
              allowClear
              placeholder="请选择指令名称"
            >
              <a-select-option
                :key="item.commandIdentification"
                v-for="item in commandList"
                >{{ item.commandName }}</a-select-option
              >
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="" prop="date">
            <a-range-picker style="width: 210px" @change="selectDate" v-model="time" />
          </a-form-model-item>
          <a-form-model-item>
            <a-button
              type="primary"
              @click="handleQuery"
              v-hasPermi="['log:operation:query']"
              >搜索</a-button
            >
            <a-button
              @click="resetQuery"
              class="ml10"
              v-hasPermi="['log:operation:query']"
              >重置</a-button
            >
          </a-form-model-item>
        </a-form-model>
      </div>
      <!--表格-->
      <a-table
        :columns="columns"
        rowKey="id"
        :data-source="data"
        :pagination="false"
        class="mt20"
      >
        <span slot="action" slot-scope="text, record">
          <a @click="handleOpertionLogQuery(record)" v-hasPermi="['log:operation:query']"
            >操作详情</a
          >
        </span>
      </a-table>
      <!--分页-->
      <div class="d-f mt20">
        <a-pagination
          v-model="queryParams.page"
          :pageSize="queryParams.limit"
          :default-current="1"
          :total="total"
          :hideOnSinglePage="true"
          showLessItems
          @change="onChange"
          :item-render="itemRender"
          style="margin-left: auto"
        />
      </div>

      <!--操作详情的弹出框-->
      <a-modal v-model="visible" title="操作详情" width="700px" :footer="null">
        <a-table
          size="small"
          :columns="deviceColumns"
          rowKey="id"
          :data-source="deviceData"
          :pagination="false"
        >
          <span slot="deviceName" slot-scope="text, record">
            {{ record.buildingName + "-" + record.classroomName }}
          </span>
          <span slot="operationStatus" slot-scope="text">
            {{ text == 0 ? "失败" : "成功" }}
          </span>
        </a-table>
        <!--分页-->
        <div class="d-f mt20">
          <a-pagination
            v-model="deviceQueryParams.page"
            :pageSize="deviceQueryParams.limit"
            :default-current="1"
            :total="deviceTotal"
            :hideOnSinglePage="true"
            showLessItems
            @change="deviceOnChange"
            :item-render="itemRender"
            style="margin-left: auto"
          />
        </div>
      </a-modal>
    </div>
  </layout-content>
</template>

<script>
import LayoutContent from "@/views/layout/content";
import Breadcrumb from "~c/Breadcrumb";
import { getOperationLogList, getDeviceOperationLogList } from "@/api/log/operation.js";
import { getCommandList } from "@/api/device/command.js";
const columns = [
  {
    title: "指令名称",
    dataIndex: "actionTypeName",
    align: "center",
  },
  {
    title: "发布者",
    dataIndex: "createBy",
    align: "center",
  },
  {
    title: "执行时间",
    dataIndex: "createTime",
    align: "center",
  },
  {
    title: "设备类型",
    dataIndex: "deviceTypeName",
    align: "center",
  },
  {
    title: "操作",
    dataIndex: "action",
    align: "center",
    scopedSlots: { customRender: "action" },
  },
];
const deviceColumns = [
  {
    title: "设备名称",
    dataIndex: "deviceName",
    align: "center",
    scopedSlots: { customRender: "deviceName" },
  },
  {
    title: "设备序列号",
    dataIndex: "deviceSerialNum",
    align: "center",
  },
  {
    title: "设备类型",
    dataIndex: "deviceTypeName",
    align: "center",
  },
  {
    title: "执行状态",
    dataIndex: "operationStatus",
    align: "center",
    scopedSlots: { customRender: "operationStatus" },
  },
];
export default {
  name: "Operation",
  components: { LayoutContent, Breadcrumb },
  data() {
    return {
      total: null,
      // 查询参数
      queryParams: {
        //当前页
        page: 1,
        //一页几个
        limit: 10,
        //开始时间
        startTime: "",
        //结束时间
        endTime: "",
      },
      //时间数组
      time: [],
      //表格的表头文字
      columns,
      //表格的数据
      data: [],
      //指令列表
      commandList: [],
      //操作详情
      visible: false,
      //设备数量
      deviceTotal: null,
      //查询参数
      deviceQueryParams: {
        //当前页
        page: 1,
        //一页几个
        limit: 5,
        //操作日志id
        operationLogId: "",
      },
      //表格的表头文字
      deviceColumns,
      //表格的数据
      deviceData: [],
    };
  },
  methods: {
    //查询指令列表
    getCommandList() {
      getCommandList({ isPage: false, commandType: "2" }).then((res) => {
        this.commandList = res.data;
      });
    },
    //获取通信列表
    getOperationLogList() {
      getOperationLogList(this.queryParams).then((res) => {
        this.data = res.data;
        this.total = res.count;
      });
    },
    //日期选择框改变
    selectDate(date, dateString) {
      this.queryParams.startTime = dateString[0];
      this.queryParams.endTime = dateString[1];
    },
    //搜索
    handleQuery() {
      this.queryParams.page = 1;
      this.getOperationLogList();
    },
    //搜索重置
    resetQuery() {
      this.$refs.queryForm.resetFields();
      this.queryParams = {
        page: 1,
        limit: 10,
        startTime: "",
        endTime: "",
      };
      this.time = [];
      this.getOperationLogList();
    },
    //操作详情
    handleOpertionLogQuery(record) {
      this.deviceQueryParams.operationLogId = record.id;
      getDeviceOperationLogList(this.deviceQueryParams).then((res) => {
        this.deviceData = res.data;
        this.deviceTotal = res.count;
        this.visible = true;
      });
    },
    //设备分页状态改变
    deviceOnChange(pageNumber) {
      this.deviceQueryParams.page = pageNumber;
      this.getDeviceOperationLogList();
    },
    //分页状态改变
    onChange(pageNumber) {
      this.queryParams.page = pageNumber;
      this.getOperationLogList();
    },
    //改变分页上一步下一步的文字链接
    itemRender(current, type, originalElement) {
      if (type === "prev") {
        return <a>上一页</a>;
      } else if (type === "next") {
        return <a class="next">下一页</a>;
      }
      return originalElement;
    },
  },
  created() {
    this.getOperationLogList();
    this.getCommandList();
  },
};
</script>

<style scoped lang="less">
::v-deep .ant-calendar-range-picker-input {
  cursor: default;
}
</style>
