import request from '@/utils/request'

//获取设备类型列表
export function getTypeList(params) {
    return request.get('/api-device/device/device-type', params)
}

//新增或修改设备类型
export function typeAddOrUpdate(params) {
    return request.post('/api-device/device/device-type/saveOrUpdate', params)
}

//获取设备类型详情
export function getType(id) {
    return request.get('/api-device/device/device-type/'+id)
}

//删除设备类型
export function typeRemove(id) {
    return request.remove('/api-device/device/device-type/'+ id)
}

//刷新缓存
export function refreshCache(params) {
    return request.post('/api-device/device/device-type/cache/refresh', params)
}