<template>
  <layout-content :total="total">
    <!-- 面包屑的插槽 -->
    <breadcrumb slot="breadcrumb"></breadcrumb>
    <div slot="content">
      <!-- 搜索和新增删除 -->
      <div class="d-f a-c j-b">
        <a-form-model ref="queryForm" :model="queryParams" layout="inline">
          <a-form-model-item label="公告标题" prop="name">
            <a-input
              v-model="queryParams.name"
              placeholder="请输入公告标题"
              allowClear
              @keyup.enter.native="handleQuery"
            />
          </a-form-model-item>
          <a-form-model-item>
            <a-button
              type="primary"
              @click="handleQuery"
              v-hasPermi="['setting:notice:query']"
              >搜索</a-button
            >
            <a-button
              @click="resetQuery"
              class="ml10"
              v-hasPermi="['setting:notice:query']"
              >重置</a-button
            >
          </a-form-model-item>
        </a-form-model>
        <div>
          <a-button
            type="primary"
            @click="handleAdd"
            icon="plus"
            v-hasPermi="['setting:notice:add']"
            >新增</a-button
          >
        </div>
      </div>
      <!--表格-->
      <a-table
        :columns="columns"
        rowKey="id"
        :data-source="data"
        :pagination="false"
        class="mt20"
      >
        <span slot="publishFlag" slot-scope="text, record">
          <a-switch
            :checked="text === 1 ? true : false"
            @change="onChangePublishFlag(record)"
          />
        </span>
        <span slot="action" slot-scope="text, record">
          <a @click="handleUpdate(record)" v-hasPermi="['setting:notice:edit']">编辑</a>
          <a-divider type="vertical" />
          <a @click="handleDelete(record)" v-hasPermi="['setting:notice:remove']">删除</a>
        </span>
      </a-table>
      <!--分页-->
      <div class="d-f mt20">
        <a-pagination
          v-model="queryParams.page"
          :pageSize="queryParams.limit"
          :default-current="1"
          :total="total"
          :hideOnSinglePage="true"
          showLessItems
          @change="onChange"
          :item-render="itemRender"
          style="margin-left: auto"
        />
      </div>
      <!--新增和编辑的弹出框-->
      <a-modal
        v-model="visible"
        :title="title"
        @ok="submit"
        @cancel="handleCancel"
        width="700px"
      >
        <a-form-model
          ref="ruleForm"
          :model="form"
          :rules="rules"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
        >
          <a-row>
            <a-col :span="24">
              <a-form-model-item label="公告标题" prop="name">
                <a-input v-model="form.name" placeholder="请输入公告标题" />
              </a-form-model-item>
            </a-col>
            <a-col :span="24">
              <a-form-model-item label="是否发布" prop="publishFlag">
                <a-radio-group v-model="form.publishFlag">
                  <a-radio :value="1"> 发布 </a-radio>
                  <a-radio :value="0"> 不发布 </a-radio>
                </a-radio-group>
              </a-form-model-item>
            </a-col>
            <a-col :span="24">
              <a-form-model-item label="公告内容" prop="content">
                <quill-editor
                  class="ql-editor"
                  v-model="form.content"
                  ref="quillEditor"
                  :options="option"
                  style="margin-top: -20px"
                >
                </quill-editor>
              </a-form-model-item>
            </a-col>
          </a-row>
        </a-form-model>
      </a-modal>
    </div>
  </layout-content>
</template>

<script>
import LayoutContent from "@/views/layout/content";
import Breadcrumb from "~c/Breadcrumb";
import {
  getNoticeList,
  noticeAddOrUpdate,
  noticeRemove,
  getNotice,
} from "@/api/setting/notice.js";
import { quillEditor } from "vue-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";

const columns = [
  {
    title: "公告标题",
    dataIndex: "name",
    align: "center",
  },
  {
    title: "显示状态",
    dataIndex: "publishFlag",
    align: "center",
    scopedSlots: { customRender: "publishFlag" },
  },
  {
    title: "创建时间",
    dataIndex: "createTime",
    align: "center",
  },
  {
    title: "操作",
    dataIndex: "action",
    align: "center",
    scopedSlots: { customRender: "action" },
  },
];
export default {
  name: "Notice",
  components: { LayoutContent, Breadcrumb, quillEditor },
  data() {
    return {
      total: null,
      // 查询参数
      queryParams: {
        //当前页
        page: 1,
        //一页几个
        limit: 10,
      },
      //表格的表头文字
      columns,
      //表格的数据
      data: [],
      //新增和编辑的表单
      form: {},
      //表单规则
      rules: {
        name: [
          {
            required: true,
            message: "请输入公告标题",
            whitespace: true,
            trigger: ["change", "blur"],
          },
        ],
        publishFlag: [
          {
            type: "number",
            required: true,
            message: "请选择显示状态",
            whitespace: true,
            trigger: ["change", "blur"],
          },
        ],
        sortNum: [
          {
            type: "number",
            required: true,
            message: "请输入显示排序",
            whitespace: true,
            trigger: ["change", "blur"],
          },
        ],
        content: [
          {
            required: true,
            message: "请输入公告内容",
            whitespace: true,
            trigger: ["change", "blur"],
          },
        ],
      },
      //label和wrapper的占位
      labelCol: { span: 5 },
      wrapperCol: { span: 19 },
      //新增和编辑弹出框的标题
      title: "",
      //新增和编辑弹出框的显示隐藏
      visible: false,
      // 富文本编辑器配置
      option: {
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"], // 加粗 斜体 下划线 删除线
            ["blockquote", "code-block"], // 引用  代码块
            [{ header: 1 }, { header: 2 }], // 1、2 级标题
            [{ list: "ordered" }, { list: "bullet" }], // 有序、无序列表
            [{ script: "sub" }, { script: "super" }], // 上标/下标
            [{ indent: "-1" }, { indent: "+1" }], // 缩进
            [{ direction: "rtl" }], // 文本方向
            [{ size: ["small", false, "large", "huge"] }], // 字体大小
            [{ header: [false, 1, 2, 3, 4, 5, 6] }], // 标题
            [{ color: [] }, { background: [] }], // 字体颜色、字体背景颜色
            [{ font: [] }], // 字体种类
            [{ align: [] }], // 对齐方式
            ["clean"], // 清除文本格式
            ["link", "image", "video"], // 链接、图片、视频
          ],
        },
        placeholder: "请输入正文",
      },
    };
  },
  methods: {
    //获取学段列表
    getNoticeList() {
      getNoticeList(this.queryParams).then((res) => {
        this.data = res.data;
        this.total = res.count;
      });
    },
    //搜索
    handleQuery() {
      this.queryParams.page = 1;
      this.getNoticeList();
    },
    //搜索重置
    resetQuery() {
      this.queryParams.page = 1;
      this.$refs.queryForm.resetFields();
      this.getNoticeList();
    },
    /** 新增按钮操作 */
    handleAdd() {
      this.form = {};
      this.visible = true;
      this.title = "新增公告";
    },
    /** 修改按钮操作 */
    handleUpdate(row) {
      this.form = {};
      getNotice(row.id).then((res) => {
        this.form = res.data;
        this.visible = true;
        this.title = "修改公告";
      });
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      this.$confirm({
        title: "您确认删除您选中的数据吗？",
        onOk: () => {
          let arr = [];
          arr.push(row.id);
          noticeRemove(arr).then((res) => {
            this.$message.success("删除成功");
            this.getNoticeList();
          });
        },
      });
    },
    //新增或编辑确定
    submit() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          noticeAddOrUpdate(this.form).then((res) => {
            //修改
            if (this.form.id) {
              this.$message.success("修改成功");
            }
            //新增
            else {
              this.$message.success("新增成功");
            }
            this.visible = false;
            this.getNoticeList();
          });
        }
      });
    },
    //新增或编辑取消
    handleCancel() {
      this.$refs.ruleForm.resetFields();
      this.visible = false;
    },
    //分页状态改变
    onChange(pageNumber) {
      this.queryParams.page = pageNumber;
      this.getNoticeList();
    },
    //发布状态改变
    onChangePublishFlag(row) {
      this.$confirm({
        title: "您确认更改发布状态吗？",
        onOk: () => {
          let params = JSON.parse(JSON.stringify(row));
          if (row.publishFlag == 0) {
            params.publishFlag = 1;
          } else {
            params.publishFlag = 0;
          }
          noticeAddOrUpdate(params).then((res) => {
            this.$message.success("更改成功");
            this.getNoticeList();
          });
        },
      });
    },
    //改变分页上一步下一步的文字链接
    itemRender(current, type, originalElement) {
      if (type === "prev") {
        return <a>上一页</a>;
      } else if (type === "next") {
        return <a class="next">下一页</a>;
      }
      return originalElement;
    },
  },
  created() {
    this.getNoticeList();
  },
};
</script>

<style scoped lang="less"></style>
