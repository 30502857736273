<template>
  <layout-content :total="total">
    <!-- 面包屑的插槽 -->
    <breadcrumb slot="breadcrumb"></breadcrumb>
    <div slot="content">
      <!-- 搜索和新增删除 -->
      <div class="d-f a-c">
        <a-form-model ref="queryForm" :model="queryParams" layout="inline">
          <a-form-model-item label="软件名称" prop="softwareName">
            <a-input
              v-model="queryParams.softwareName"
              placeholder="请输入软件名称"
              allowClear
              @keyup.enter.native="handleQuery"
            />
          </a-form-model-item>
          <a-form-model-item label="" prop="date">
            <a-range-picker style="width: 210px" @change="selectDate" v-model="time" />
          </a-form-model-item>
          <a-form-model-item>
            <a-button
              type="primary"
              @click="handleQuery"
              v-hasPermi="['software:uninstallRecord:query']"
              >搜索</a-button
            >
            <a-button
              @click="resetQuery"
              class="ml10"
              v-hasPermi="['software:uninstallRecord:query']"
              >重置</a-button
            >
          </a-form-model-item>
        </a-form-model>
      </div>
      <!--表格-->
      <a-table
        :columns="columns"
        rowKey="id"
        :data-source="data"
        :pagination="false"
        class="mt20"
      >
        <span
          slot="softwareName"
          slot-scope="text, record"
          :title="record.softwareDescription"
          style="display: inline-block; position: relative"
        >
          <img
            :src="record.softwareLogo"
            width="50px"
            height="50px"
            style="border-radius: 10px"
          />
          <span
            style="
              font-weight: 600;
              margin-left: 20px;
              display: inline-block;
              position: absolute;
            "
            >{{ record.softwareName }}</span
          >
          <span
            style="
              width: 240px;
              overflow: hidden;
              text-overflow: ellipsis;
              display: inline-block;
              position: absolute;
              top: 25px;
              margin-left: 20px;
            "
          >
            {{ record.softwareDescription }}
          </span>
        </span>
        <span slot="status" slot-scope="text, record">
          <a-badge status="processing" />{{ record.uninstallInProgressCount }}台进行中
          <span style="margin-left: 10px"></span>
          <a-badge status="success" />{{ record.uninstallSuccessCount }}台成功
          <span style="margin-left: 10px"></span>
          <a-badge status="error" />{{ record.uninstallFailureCount }}台失败
        </span>
        <span slot="action" slot-scope="text, record">
          <a @click="handleView(record)" v-hasPermi="['software:uninstallRecord:query']"
            >查看详情</a
          >
        </span>
      </a-table>
      <!--分页-->
      <div class="d-f mt20">
        <a-pagination
          v-model="queryParams.page"
          :pageSize="queryParams.limit"
          :default-current="1"
          :total="total"
          :hideOnSinglePage="true"
          showLessItems
          @change="onChange"
          :item-render="itemRender"
          style="margin-left: auto"
        />
      </div>

      <!--查看详情的弹出框-->
      <a-modal
        v-model="visible"
        title="查看详情"
        width="700px"
        :footer="null"
        @cancel="handleCancel"
      >
        <div class="d-f mb20">
          <img
            :src="record.softwareLogo"
            style="border-radius: 10px"
            width="65px"
            height="65px"
          />
          <div class="ml20">
            <div style="font-size: 14px; font-weight: 600; color: rgba(0, 0, 0, 0.9)">
              {{ record.softwareName }}
            </div>
            <div
              style="
                margin-top: 4px;
                font-size: 12px;
                font-weight: 600;
                color: rgba(0, 0, 0, 0.45);
              "
            >
              {{ record.softVersion }}<a-divider type="vertical" />
              {{ record.versionStatus }}<a-divider type="vertical" />
              {{ record.fileSize }}
            </div>
            <div style="margin-top: 4px; font-size: 12px; color: rgba(0, 0, 0, 0.65)">
              {{ record.softwareDescription }}
            </div>
          </div>
        </div>
        <a-radio-group
          v-model="deviceQueryParams.successFlag"
          button-style="solid"
          @change="changeDeviceList"
        >
          <a-radio-button :value="2"> 进行中 </a-radio-button>
          <a-radio-button :value="1"> 成功 </a-radio-button>
          <a-radio-button :value="0"> 失败 </a-radio-button>
        </a-radio-group>
        <div class="d-f mt20">
          <div style="margin-left: auto">
            在线设备<a-checkbox
              @change="changeOnlineStatus"
              style="margin-left: 4px"
              :checked="checked"
            ></a-checkbox>
            <a-button
              type="primary"
              @click="uninstallAgain"
              :disabled="multiple"
              class="ml24"
              >批量卸载</a-button
            >
          </div>
        </div>
        <a-table
          size="small"
          :columns="deviceColumns"
          rowKey="serialNum"
          :data-source="deviceData"
          :pagination="false"
          class="mt20"
          :rowSelection="{
            selectedRowKeys: selectedRowKeys,
            onChange: onSelectChange,
            getCheckboxProps,
          }"
        >
          <span slot="deviceName" slot-scope="text, record">
            {{ record.buildingName + "-" + record.classroomName }}
          </span>
          <span slot="deviceIsOnline" slot-scope="text">
            {{ text ? "在线" : "离线" }}
          </span>
          <span slot="action" slot-scope="text, record">
            <a
              @click="uninstallAgain(record)"
              v-hasPermi="['log:push:sendAgain']"
              v-if="deviceQueryParams.successFlag == 0 && record.deviceIsOnline"
              >重新卸载</a
            >
            <span v-else>——</span>
          </span>
        </a-table>
        <!--分页-->
        <div class="d-f mt20">
          <a-pagination
            v-model="deviceQueryParams.page"
            :pageSize="deviceQueryParams.limit"
            :default-current="1"
            :total="deviceTotal"
            :hideOnSinglePage="true"
            showLessItems
            @change="deviceOnChange"
            :item-render="itemRender"
            style="margin-left: auto"
          />
        </div>
      </a-modal>
    </div>
  </layout-content>
</template>

<script>
import LayoutContent from "@/views/layout/content";
import Breadcrumb from "~c/Breadcrumb";
import { getUninstallRecordList } from "@/api/software/uninstall-record.js";
import actionType from "@/utils/action-type";
import { websocketSend } from "@/utils/websocket-send.js";
import { v4 as uuidv4 } from "uuid";
const columns = [
  {
    title: "软件名称",
    dataIndex: "softwareName",
    align: "left",
    scopedSlots: { customRender: "softwareName" },
    ellipsis: true,
    width: "350px",
  },
  {
    title: "版本号",
    dataIndex: "softVersion",
    align: "center",
  },
  {
    title: "版本状态",
    dataIndex: "versionStatus",
    align: "center",
  },
  {
    title: "文件大小",
    dataIndex: "fileSize",
    align: "center",
  },
  {
    title: "执行状态",
    width: "20%",
    dataIndex: "status",
    align: "center",
    scopedSlots: { customRender: "status" },
    width: "25%",
  },
  {
    title: "操作",
    dataIndex: "action",
    align: "center",
    scopedSlots: { customRender: "action" },
  },
];
const deviceColumns = [
  {
    title: "设备名称",
    dataIndex: "deviceName",
    align: "center",
    scopedSlots: { customRender: "deviceName" },
  },
  {
    title: "设备状态",
    dataIndex: "deviceIsOnline",
    align: "center",
    scopedSlots: { customRender: "deviceIsOnline" },
  },
  {
    title: "执行状态",
    dataIndex: "message",
    align: "center",
  },
  {
    title: "操作",
    dataIndex: "action",
    align: "center",
    scopedSlots: { customRender: "action" },
  },
];
export default {
  name: "UninstallRecord",
  components: { LayoutContent, Breadcrumb },
  data() {
    return {
      total: null,
      // 查询参数
      queryParams: {
        //当前页
        page: 1,
        //一页几个
        limit: 10,
        //开始时间
        startTime: "",
        //结束时间
        endTime: "",
      },
      //时间数组
      time: [],
      //表格的表头文字
      columns,
      //表格的数据
      data: [],
      //查看详情
      visible: false,
      //设备数量
      deviceTotal: null,
      //查询参数
      deviceQueryParams: {
        //当前页
        page: 1,
        //一页几个
        limit: 5,
        //卸载记录id
        uninstallLogId: "",
        successFlag: 2,
      },
      //表格的表头文字
      deviceColumns,
      //表格的数据
      deviceData: [],
      uuid: null,
      //websocket连接
      websocket: null,
      //心跳定时器
      timer: null,
      //查询设备定时器
      timer1: null,
      //查看详情异常的复选框
      selectedRowKeys: [],
      // 非多个禁用
      multiple: true,
      //查看详情这一行的数据
      record: {},
      //在线状态的复选框
      checked: false,
    };
  },
  methods: {
    //卸载记录列表
    getUninstallRecordList() {
      getUninstallRecordList(this.queryParams).then((res) => {
        this.total = res.count;
        this.data = res.data;
      });
    },
    //日期选择框改变
    selectDate(date, dateString) {
      this.queryParams.startTime = dateString[0];
      this.queryParams.endTime = dateString[1];
    },
    //搜索
    handleQuery() {
      this.queryParams.page = 1;
      this.getUninstallRecordList();
    },
    //搜索重置
    resetQuery() {
      this.$refs.queryForm.resetFields();
      this.queryParams = {
        page: 1,
        limit: 10,
        startTime: "",
        endTime: "",
      };
      this.time = [];
      this.getUninstallRecordList();
    },
    //分页状态改变
    onChange(pageNumber) {
      this.queryParams.page = pageNumber;
      this.getUninstallRecordList();
    },
    //改变分页上一步下一步的文字链接
    itemRender(current, type, originalElement) {
      if (type === "prev") {
        return <a>上一页</a>;
      } else if (type === "next") {
        return <a class="next">下一页</a>;
      }
      return originalElement;
    },
    //查看详情
    handleView(record) {
      this.record = record;
      this.deviceQueryParams.page = 1;
      this.deviceQueryParams.successFlag = 2;
      this.deviceQueryParams.uninstallLogId = record.id;
      this.checked = false;
      this.deviceQueryParams.deviceIsOnline = null;
      this.selectedRowKeys = [];
      this.multiple = !this.selectedRowKeys.length;
      this.initWebSocket();
    },
    //关闭弹框
    handleCancel() {
      let close = websocketSend(this.uuid, actionType.close);
      if (this.websocket == null) {
        this.$message.error("连接已断开，请刷新页面");
        this.websocketclose();
      } else {
        this.websocket.send(JSON.stringify(close));
      }
    },
    //单选框状态改变
    changeDeviceList(e) {
      this.deviceData = [];
      this.deviceTotal = null;
      this.selectedRowKeys = [];
      this.multiple = !this.selectedRowKeys.length;
      clearInterval(this.timer1);
      this.timer1 = null;
      this.deviceQueryParams.successFlag = e.target.value;
      this.deviceQueryParams.page = 1;
      this.websocketQueryList();
    },
    //复选框状态改变
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys;
      this.multiple = !this.selectedRowKeys.length;
    },
    //推送复选框不能选择
    getCheckboxProps(record) {
      return {
        props: {
          disabled:
            record.deviceIsOnline === false || this.deviceQueryParams.successFlag != 0,
        },
      };
    },
    //在线设备复选框改变
    changeOnlineStatus(e) {
      this.checked = e.target.checked;
      if (e.target.checked) {
        this.deviceQueryParams.deviceIsOnline = true;
      } else {
        this.deviceQueryParams.deviceIsOnline = null;
      }
      this.selectedRowKeys = [];
      this.multiple = !this.selectedRowKeys.length;
      clearInterval(this.timer1);
      this.timer1 = null;
      this.websocketQueryList();
    },
    //设备分页状态改变
    deviceOnChange(pageNumber) {
      clearInterval(this.timer1);
      this.timer1 = null;
      this.deviceQueryParams.page = pageNumber;
      this.websocketQueryList();
    },
    //重新卸载
    uninstallAgain(record) {
      this.$confirm({
        title: `确定卸载${this.record.softwareName}（${this.record.softVersion}）吗？`,
        onOk: () => {
          //单个卸载
          if (record.serialNum) {
            if (record.deviceIsOnline) {
              let receiverIds = [];
              receiverIds.push(record.serialNum);
              let uninstallAgain = websocketSend(
                this.uuid,
                actionType.uninstallAgain,
                this.record,
                receiverIds
              );
              if (this.websocket == null) {
                this.$message.error("连接已断开，请刷新页面");
                this.websocketclose();
              } else {
                this.websocket.send(JSON.stringify(uninstallAgain));
              }
            } else {
              this.$message.error("设备已离线，不能重发消息");
            }
          } else {
            if (this.selectedRowKeys.length == 0) {
              this.$message.error("请选择推送的设备");
            } else {
              let uninstallAgain = websocketSend(
                this.uuid,
                actionType.uninstallAgain,
                this.record,
                this.selectedRowKeys
              );
              if (this.websocket == null) {
                this.$message.error("连接已断开，请刷新页面");
                this.websocketclose();
              } else {
                this.websocket.send(JSON.stringify(uninstallAgain));
              }
            }
          }
        },
      });
    },
    //webscoket 初始化
    initWebSocket() {
      const wsurl = process.env.VUE_APP_WSURL; //ws 相当于http 而wss 相当于https
      this.websocket = new WebSocket(wsurl); //实例对象
      console.log("websocket建立连接");
      this.websocket.onmessage = this.websocketonmessage;
      this.websocket.onopen = this.websocketonopen;
      this.websocket.onerror = this.websocketonerror;
      this.websocket.onclose = this.websocketclose;
    },
    //连接建立
    websocketonopen() {
      console.log("前端连接建立成功");
      this.uuid = uuidv4().replace(/-/g, "");
      let open = websocketSend(this.uuid, actionType.open);
      if (this.websocket == null) {
        this.$message.error("连接已断开，请刷新页面");
        this.websocketclose();
      } else {
        this.websocket.send(JSON.stringify(open));
      }
    },
    //发送心跳
    websocketHeart() {
      console.log("发送心跳");
      let heart = websocketSend(this.uuid, actionType.heart);
      if (this.websocket == null) {
        this.$message.error("连接已断开，请刷新页面");
        this.websocketclose();
      } else {
        this.websocket.send(JSON.stringify(heart));
        this.timer = setInterval(() => {
          if (this.websocket == null) {
            this.$message.error("连接已断开，请刷新页面");
            this.websocketclose();
          } else {
            this.websocket.send(JSON.stringify(heart));
          }
        }, 10 * 1000);
      }
    },
    //查询设备列表信息
    websocketQueryList() {
      console.log("请求查询设备列表信息");
      let queryUninstall = websocketSend(
        this.uuid,
        actionType.queryUninstall,
        this.deviceQueryParams
      );
      if (this.websocket == null) {
        this.$message.error("连接已断开，请刷新页面");
        this.websocketclose();
      } else {
        this.websocket.send(JSON.stringify(queryUninstall));
        this.timer1 = setInterval(() => {
          if (this.websocket == null) {
            this.$message.error("连接已断开，请刷新页面");
            this.websocketclose();
          } else {
            this.websocket.send(JSON.stringify(queryUninstall));
          }
        }, 5 * 1000);
      }
    },
    //数据接收
    websocketonmessage(e) {
      let data = JSON.parse(e.data);
      console.log("接收后端返回数据", data);
      //连接成功
      if (data.code === 8000) {
        this.websocketHeart();
        this.websocketQueryList();
        this.visible = true;
      } else if (data.code === 8022) {
        this.deviceData = data.data.data;
        this.deviceTotal = data.data.count;
      }
      //重新卸载
      else if (data.code === 8019) {
        this.$message.success(data.msg);
        clearInterval(this.timer1);
        this.timer1 = null;
        this.selectedRowKeys = [];
        this.multiple = !this.selectedRowKeys.length;
        this.deviceQueryParams.page = 1;
        this.websocketQueryList();
        this.getUninstallRecordList();
      } else if (data.code < 0) {
        this.$message.error(data.msg);
      }
    },
    //连接建立失败重连
    websocketonerror() {
      clearInterval(this.timer);
      clearInterval(this.timer1);
      this.timer = null;
      this.timer1 = null;
      this.websocket = null;
      this.initWebSocket();
    },
    //连接关闭
    websocketclose(e) {
      console.log("websocket断开连接");
      clearInterval(this.timer);
      clearInterval(this.timer1);
      this.timer = null;
      this.timer1 = null;
      this.websocket = null;
      this.visible = false;
    },
  },
  created() {
    this.getUninstallRecordList();
  },
  destroyed() {
    this.websocketclose();
  },
};
</script>

<style scoped lang="less">
.ant-radio-group {
  width: 100%;
  .ant-radio-button-wrapper {
    width: 33.3%;
    text-align: center;
  }
}
::v-deep .ant-modal-body {
  height: 500px;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 6px;
    /*高宽分别对应横竖滚动条的尺寸*/
    height: 1px;
  }

  &::-webkit-scrollbar-thumb {
    // -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    background: #e3e3e6;
    border-radius: 6px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
    // -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
  }
}
::v-deep .ant-calendar-range-picker-input {
  cursor: default;
}
</style>
