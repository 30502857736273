import request from '@/utils/request'

//获取操作日志列表
export function getOperationLogList(params) {
    return request.get('/api-device/device/operation/log-list', params)
}

//获取设备操作列表
export function getDeviceOperationLogList(params) {
    return request.get('/api-device/device/log-relation/list', params)
}
