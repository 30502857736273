import request from '@/utils/request'

//获取设备指令列表
export function getCommandList(params) {
    return request.get('/api-device/device/device-command', params)
}

//新增或修改设备指令
export function commandAddOrUpdate(params) {
    return request.post('/api-device/device/device-command/saveOrUpdate', params)
}

//获取设备指令详情
export function getCommand(id) {
    return request.get('/api-device/device/device-command/'+id)
}

//删除设备指令
export function commandRemove(id) {
    return request.remove('/api-device/device/device-command/'+ id)
}

//刷新缓存
export function refreshCache(params) {
    return request.post('/api-device/device/device-command/cache/refresh', params)
}