<template>
  <layout-content :total="total">
    <!-- 面包屑的插槽 -->
    <breadcrumb slot="breadcrumb"></breadcrumb>
    <div slot="content">
      <!-- 搜索和新增删除 -->
      <div class="d-f a-c j-b">
        <a-form-model ref="queryForm" :model="queryParams" layout="inline">
          <a-form-model-item label="分组名称" prop="groupName">
            <a-input
              v-model="queryParams.groupName"
              placeholder="请输入分组名称"
              allowClear
              @keyup.enter.native="handleQuery"
            />
          </a-form-model-item>
          <a-form-model-item>
            <a-button
              type="primary"
              @click="handleQuery"
              v-hasPermi="['device:group:query']"
              >搜索</a-button
            >
            <a-button @click="resetQuery" class="ml10" v-hasPermi="['device:group:query']"
              >重置</a-button
            >
          </a-form-model-item>
        </a-form-model>
        <div>
          <a-button
            type="primary"
            @click="handleAdd"
            icon="plus"
            v-hasPermi="['device:group:add']"
            >新增</a-button
          >
          <a-button
            @click="handleDelete"
            icon="delete"
            class="ml10"
            :disabled="multiple"
            v-hasPermi="['device:group:remove']"
          >
            批量删除
          </a-button>
        </div>
      </div>
      <!--表格-->
      <a-table
        :columns="columns"
        rowKey="id"
        :data-source="data"
        :pagination="false"
        :row-selection="{
          selectedRowKeys: selectedRowKeys,
          onChange: onSelectChange,
        }"
        class="mt20"
      >
        <span slot="action" slot-scope="text, record">
          <a @click="handleAddDevice(record)" v-hasPermi="['device:group:add']"
            >选择设备</a
          >
          <a-divider type="vertical" />
          <a @click="handleUpdate(record)" v-hasPermi="['device:group:edit']">编辑</a>
          <a-divider type="vertical" />
          <a @click="handleDelete(record)" v-hasPermi="['device:group:remove']">删除</a>
        </span>
      </a-table>
      <!--分页-->
      <div class="d-f mt20">
        <a-pagination
          v-model="queryParams.page"
          :pageSize="queryParams.limit"
          :default-current="1"
          :total="total"
          :hideOnSinglePage="true"
          showLessItems
          @change="onChange"
          :item-render="itemRender"
          style="margin-left: auto"
        />
      </div>
      <!--新增和编辑的弹出框-->
      <a-modal
        v-model="visible"
        :title="title"
        @ok="submit"
        @cancel="handleCancel"
        width="500px"
      >
        <a-form-model
          ref="ruleForm"
          :model="form"
          :rules="rules"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
        >
          <a-row>
            <a-col :span="24">
              <a-form-model-item label="分组名称" prop="groupName">
                <a-input v-model="form.groupName" placeholder="请输入分组名称" />
              </a-form-model-item>
            </a-col>
            <a-col :span="24">
              <a-form-model-item
                label="分组描述"
                prop="description"
                :label-col="{ span: 5, offset: 0 }"
              >
                <a-input
                  v-model="form.description"
                  type="textarea"
                  placeholder="请输入分组描述"
                  :rows="6"
                />
              </a-form-model-item>
            </a-col>
          </a-row>
        </a-form-model>
      </a-modal>
      <!--选择设备的弹出框-->
      <a-modal
        v-model="addDevicevisible"
        title="选择设备"
        @ok="submitAddDevice"
        @cancel="handleCancelAddDevice"
        width="500px"
      >
        <a-form-model
          ref="ruleFormAddDevice"
          :model="addDeviceForm"
          :rules="addDeviceRules"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
        >
          <a-row>
            <a-col :span="24">
              <a-form-model-item label="选择设备" prop="serialNumList">
                <a-select
                  v-model="addDeviceForm.serialNumList"
                  mode="multiple"
                  style="width: 100%"
                  placeholder="请选择设备"
                  :getPopupContainer="(triggerNode) => triggerNode.parentNode"
                  optionFilterProp="children"
                  @change="
                    () => {
                      this.$forceUpdate();
                    }
                  "
                >
                  <a-select-option
                    v-for="item in deviceList"
                    :key="item.serialNum"
                    :value="item.serialNum"
                  >
                    {{ `${item.buildingName}-${item.classroomName}(${item.serialNum})` }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
          </a-row>
        </a-form-model>
      </a-modal>
    </div>
  </layout-content>
</template>

<script>
import LayoutContent from "@/views/layout/content";
import Breadcrumb from "~c/Breadcrumb";
import {
  getGroupList,
  groupAddOrUpdate,
  groupRemove,
  getGroup,
  groupRelationDevice,
} from "@/api/device/group.js";
import { getDeviceList } from "@/api/device/terminal.js";
const columns = [
  {
    title: "分组名称",
    dataIndex: "groupName",
    align: "center",
  },
  {
    title: "分组描述",
    dataIndex: "description",
    align: "center",
    ellipsis: true,
  },
  {
    title: "操作",
    dataIndex: "action",
    align: "center",
    scopedSlots: { customRender: "action" },
  },
];
export default {
  name: "Group",
  components: { LayoutContent, Breadcrumb },
  data() {
    return {
      total: null,
      // 查询参数
      queryParams: {
        //当前页
        page: 1,
        //一页几个
        limit: 10,
      },
      //表格的表头文字
      columns,
      //表格的数据
      data: [],
      //多选的数据
      selectedRowKeys: [],
      // 非多个禁用
      multiple: true,
      //新增和编辑的表单
      form: {},
      //表单规则
      rules: {
        groupName: [
          {
            required: true,
            message: "请输入分组名称",
            whitespace: true,
            trigger: ["change", "blur"],
          },
        ],
      },
      //label和wrapper的占位
      labelCol: { span: 5 },
      wrapperCol: { span: 19 },
      //新增和编辑弹出框的标题
      title: "",
      //新增和编辑弹出框的显示隐藏
      visible: false,
      //选择设备弹出框的显示隐藏
      addDevicevisible: false,
      //选择设备的表单
      addDeviceForm: {},
      //选择设备表单规则
      addDeviceRules: {},
      //设备列表
      deviceList: [],
    };
  },
  methods: {
    //获取通信列表
    getGroupList() {
      getGroupList(this.queryParams).then((res) => {
        this.data = res.data;
        this.total = res.count;
        this.multiple = !this.selectedRowKeys.length;
      });
    },
    //搜索
    handleQuery() {
      this.queryParams.page = 1;
      this.getGroupList();
    },
    //搜索重置
    resetQuery() {
      this.queryParams.page = 1;
      this.$refs.queryForm.resetFields();
      this.getGroupList();
    },
    //复选框状态改变
    onSelectChange(selectedRowKeys, records) {
      // console.log(selectedRowKeys);
      // console.log(records);
      this.selectedRowKeys = selectedRowKeys;
      this.multiple = !selectedRowKeys.length;
    },
    /** 新增按钮操作 */
    handleAdd() {
      this.form = {};
      this.visible = true;
      this.title = "新增分组";
    },
    /** 修改按钮操作 */
    async handleUpdate(row) {
      this.form = {};
      let res = await getGroup(row.id);
      this.form = res.data;
      this.visible = true;
      this.title = "修改分组";
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      this.$confirm({
        title: "您确认删除您选中的数据吗？",
        onOk: () => {
          //单个删除
          if (row.id) {
            let arr = [];
            arr.push(row.id);
            groupRemove(arr).then((res) => {
              this.$message.success("删除成功");
              this.getGroupList();
            });
          }
          //批量删除
          else {
            groupRemove(this.selectedRowKeys).then((res) => {
              this.$message.success("删除成功");
              this.selectedRowKeys = [];
              this.getGroupList();
            });
          }
        },
      });
    },
    //新增或编辑确定
    submit() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          groupAddOrUpdate(this.form).then((res) => {
            //修改
            if (this.form.id) {
              this.$message.success("修改成功");
            }
            //新增
            else {
              this.$message.success("新增成功");
            }
            this.visible = false;
            this.getGroupList();
          });
        }
      });
    },
    //新增或编辑取消
    handleCancel() {
      this.$refs.ruleForm.resetFields();
      this.visible = false;
    },
    //选择设备
    async handleAddDevice(record) {
      this.deviceList = [];
      this.addDeviceForm.groupId = record.id;
      let res = await getDeviceList({ isPage: false });
      let result = await getGroup(record.id);
      if (result.data.deviceList == null || result.data.deviceList.length == 0) {
        this.addDeviceForm.serialNumList = [];
      } else {
        let arr = [];
        result.data.deviceList.forEach((r) => {
          arr.push(r.serialNum);
        });
        this.addDeviceForm.serialNumList = arr;
      }
      this.deviceList = res.data;
      this.addDevicevisible = true;
    },
    //选择设备确定
    submitAddDevice() {
      groupRelationDevice(this.addDeviceForm).then((res) => {
        this.$message.success("选择成功");
        this.addDevicevisible = false;
      });
    },
    //选择设备取消
    handleCancelAddDevice() {
      this.addDeviceForm = {};
      this.addDevicevisible = false;
    },
    //分页状态改变
    onChange(pageNumber) {
      this.queryParams.page = pageNumber;
      this.getGroupList();
    },
    //改变分页上一步下一步的文字链接
    itemRender(current, type, originalElement) {
      if (type === "prev") {
        return <a>上一页</a>;
      } else if (type === "next") {
        return <a class="next">下一页</a>;
      }
      return originalElement;
    },
  },
  created() {
    this.getGroupList();
  },
};
</script>

<style scoped lang="less"></style>
