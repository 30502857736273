<template>
  <layout-content :total="total">
    <!-- 面包屑的插槽 -->
    <breadcrumb slot="breadcrumb"></breadcrumb>
    <div slot="content">
      <!-- 搜索和新增删除 -->
      <div class="d-f a-c j-b">
        <a-form-model ref="queryForm" :model="queryParams" layout="inline">
          <a-form-model-item label="菜单名称" prop="menuName">
            <a-input
              v-model="queryParams.menuName"
              placeholder="请输入菜单名称"
              allowClear
              @keyup.enter.native="handleQuery"
            />
          </a-form-model-item>
          <a-form-model-item label="菜单状态" prop="status">
            <a-select
              v-model="queryParams.status"
              allowClear
              placeholder="请选择菜单状态"
              style="width: 180px"
              :getPopupContainer="(triggerNode) => triggerNode.parentNode"
            >
              <a-select-option :value="0"> 启用 </a-select-option>
              <a-select-option :value="1"> 禁用</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item>
            <a-button type="primary" @click="handleQuery" v-hasPermi="['acl:menu:query']"
              >搜索</a-button
            >
            <a-button @click="resetQuery" class="ml10" v-hasPermi="['acl:menu:query']"
              >重置</a-button
            >
          </a-form-model-item>
        </a-form-model>
        <div>
          <a-button
            type="primary"
            @click="handleAdd"
            icon="plus"
            v-hasPermi="['acl:menu:add']"
            >新增</a-button
          >
          <a-button
            @click="handleDelete"
            icon="delete"
            class="ml10"
            :disabled="multiple"
            v-hasPermi="['acl:menu:remove']"
          >
            批量删除
          </a-button>
        </div>
      </div>
      <!--表格-->
      <a-table
        :columns="columns"
        rowKey="id"
        :data-source="data"
        :pagination="false"
        :row-selection="{
          selectedRowKeys: selectedRowKeys,
          onChange: onSelectChange,
        }"
        class="mt20"
      >
        <span slot="icon" slot-scope="text">
          <img :src="text" alt="" width="64px" />
        </span>
        <span slot="menuName" slot-scope="text, record">
          <router-link
            :to="'/acl/menu-data?parentId=' + record.id + '&menuName=' + record.menuName"
          >
            <span>{{ text }}</span>
          </router-link>
        </span>
        <span slot="status" slot-scope="text">
          <a-tag :color="text === 0 ? '#009FE8' : '#f5222d'">
            {{ text | filterStatus }}
          </a-tag>
        </span>
        <span slot="hidden" slot-scope="text">
          {{ text | filterHidden }}
        </span>
        <span slot="isFrame" slot-scope="text">
          {{ text | filterIsFrame }}
        </span>
        <span slot="action" slot-scope="text, record">
          <a @click="handleUpdate(record)" v-hasPermi="['acl:menu:edit']">编辑</a>
          <a-divider type="vertical" />
          <a @click="handleDelete(record)" v-hasPermi="['acl:menu:remove']">删除</a>
        </span>
      </a-table>
      <!--新增和编辑的弹出框-->
      <a-modal
        v-model="visible"
        :title="title"
        @ok="submit"
        @cancel="handleCancel"
        width="700px"
      >
        <a-form-model
          ref="ruleForm"
          :model="form"
          :rules="rules"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
        >
          <a-row>
            <a-col :span="12">
              <a-form-model-item label="上级菜单">
                <a-select v-model="form.parentId" disabled>
                  <a-select-option :value="0"> 主类目 </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item label="菜单类型">
                <a-select v-model="form.type" disabled>
                  <a-select-option :value="0"> 盒子 </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item label="菜单名称" prop="menuName">
                <a-input v-model="form.menuName" placeholder="请输入菜单名称" />
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item label="权限标识" prop="perms">
                <a-input v-model="form.perms" placeholder="请输入权限标识" />
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item label="路由地址" prop="path">
                <a-input v-model="form.path" placeholder="请输入路由地址" />
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item label="组件路径" prop="component">
                <a-input v-model="form.component" placeholder="请输入组件路径" />
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item label="显示排序" prop="orderNum">
                <a-input-number
                  v-model="form.orderNum"
                  placeholder="请输入显示排序"
                  :min="1"
                  style="width: 100%"
                />
              </a-form-model-item>
            </a-col>
            <a-col :span="24">
              <a-form-model-item
                label="菜单图片"
                prop="icon"
                :label-col="{ span: 4, offset: 0 }"
                style="position: relative"
              >
                <p
                  style="
                    position: absolute;
                    left: -79px;
                    color: #f5222d;
                    font-size: 14px;
                    font-family: SimSun, sans-serif;
                  "
                >
                  *
                </p>
                <!-- 图片剪裁以及预览 -->
                <a-row>
                  <a-col :span="24" :style="{ height: '200px', width: '280px' }">
                    <vue-cropper
                      ref="cropper"
                      :img="options.icon"
                      :info="true"
                      :autoCrop="options.autoCrop"
                      :autoCropWidth="options.autoCropWidth"
                      :autoCropHeight="options.autoCropHeight"
                      :fixedBox="options.fixedBox"
                      @realTime="realTime"
                      outputType="png"
                    />
                    <div class="avatar-upload-preview" v-if="isBegin"></div>
                    <div class="avatar-upload-preview" v-if="canShow">
                      <img :src="previews.url" :style="previews.img" />
                    </div>
                    <div class="avatar-upload-preview" v-if="!isBegin && !canShow">
                      <img :src="form.icon" class="avatar" />
                    </div>
                  </a-col>
                </a-row>
                <br />
                <!-- 上传，上下左右旋转以及放大缩小 -->
                <a-row>
                  <a-col :lg="2" :md="2">
                    <a-upload
                      action="#"
                      :http-request="requestUpload"
                      :showUploadList="false"
                      :before-upload="beforeUpload"
                      accept="image/*"
                    >
                      <a-button>选择<a-icon type="upload" /></a-button>
                    </a-upload>
                  </a-col>
                  <a-col :lg="{ span: 1, offset: 7 }" :md="2">
                    <a-button icon="plus" @click="changeScale(1)"></a-button>
                  </a-col>
                  <a-col :lg="{ span: 1, offset: 3 }" :md="2">
                    <a-button icon="minus" @click="changeScale(-1)"></a-button>
                  </a-col>
                  <a-col :lg="{ span: 1, offset: 3 }" :md="2">
                    <a-button icon="undo" @click="rotateLeft()"></a-button>
                  </a-col>
                  <a-col :lg="{ span: 1, offset: 3 }" :md="2">
                    <a-button icon="redo" @click="rotateRight()"></a-button>
                  </a-col>
                </a-row>
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item label="是否缓存" prop="isCache">
                <a-radio-group v-model="form.isCache">
                  <a-radio :value="0"> 是 </a-radio>
                  <a-radio :value="1"> 否 </a-radio>
                </a-radio-group>
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item label="是否外链" prop="isFrame">
                <a-radio-group v-model="form.isFrame">
                  <a-radio :value="0"> 是 </a-radio>
                  <a-radio :value="1"> 否 </a-radio>
                </a-radio-group>
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item label="菜单状态" prop="status">
                <a-radio-group v-model="form.status">
                  <a-radio :value="0"> 启用 </a-radio>
                  <a-radio :value="1"> 禁用 </a-radio>
                </a-radio-group>
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item label="显示状态" prop="hidden">
                <a-radio-group v-model="form.hidden">
                  <a-radio :value="0"> 显示 </a-radio>
                  <a-radio :value="1"> 隐藏 </a-radio>
                </a-radio-group>
              </a-form-model-item>
            </a-col>
          </a-row>
        </a-form-model>
      </a-modal>
    </div>
  </layout-content>
</template>

<script>
import LayoutContent from "@/views/layout/content";
import Breadcrumb from "~c/Breadcrumb";
import {
  getPermissionBox,
  menuAddOrUpdate,
  menuRemove,
  getMenu,
} from "@/api/acl/menu.js";
import { VueCropper } from "vue-cropper";
const columns = [
  {
    title: "图片",
    dataIndex: "icon",
    align: "center",
    scopedSlots: { customRender: "icon" },
  },
  {
    title: "菜单名称",
    dataIndex: "menuName",
    align: "center",
    scopedSlots: { customRender: "menuName" },
  },
  {
    title: "显示排序",
    dataIndex: "orderNum",
    align: "center",
  },
  {
    title: "菜单状态",
    dataIndex: "status",
    align: "center",
    scopedSlots: { customRender: "status" },
  },
  {
    title: "显示状态",
    dataIndex: "hidden",
    align: "center",
    scopedSlots: { customRender: "hidden" },
  },
  {
    title: "是否外链",
    dataIndex: "isFrame",
    align: "center",
    scopedSlots: { customRender: "isFrame" },
  },
  {
    title: "操作",
    dataIndex: "action",
    // width: "25%",
    align: "center",
    scopedSlots: { customRender: "action" },
  },
];
export default {
  name: "Menu",
  components: { LayoutContent, Breadcrumb, VueCropper },
  filters: {
    filterStatus(val) {
      return ["启用", "禁用"][val];
    },
    filterHidden(val) {
      return ["显示", "隐藏"][val];
    },
    filterIsFrame(val) {
      return ["是", "否"][val];
    },
  },
  data() {
    return {
      total: null,
      // 查询参数
      queryParams: {},
      //表格的表头文字
      columns,
      //表格的数据
      data: [],
      //多选的数据
      selectedRowKeys: [],
      // 非多个禁用
      multiple: true,
      //新增和编辑的表单
      form: {
        parentId: 0,
        type: 0,
      },
      //表单规则
      rules: {
        menuName: [
          {
            required: true,
            message: "请输入菜单名称",
            whitespace: true,
            trigger: ["change", "blur"],
          },
          {
            min: 0,
            max: 12,
            message: "菜单名称不能超过12个汉字",
            trigger: ["change", "blur"],
          },
        ],
        perms: [
          {
            required: true,
            message: "请输入权限标识",
            whitespace: true,
            trigger: ["change", "blur"],
          },
        ],
        orderNum: [
          {
            type: "number",
            required: true,
            message: "请输入显示排序",
            whitespace: true,
            trigger: ["change", "blur"],
          },
        ],
        isCache: [
          {
            type: "number",
            required: true,
            message: "请选择是否缓存",
            whitespace: true,
            trigger: ["change", "blur"],
          },
        ],
        isFrame: [
          {
            type: "number",
            required: true,
            message: "请选择是否外链",
            whitespace: true,
            trigger: ["change", "blur"],
          },
        ],
        path: [
          {
            required: true,
            message: "请输入路由地址",
            whitespace: true,
            trigger: ["change", "blur"],
          },
        ],
        status: [
          {
            type: "number",
            required: true,
            message: "请选择菜单状态",
            whitespace: true,
            trigger: ["change", "blur"],
          },
        ],
        hidden: [
          {
            type: "number",
            required: true,
            message: "请选择显示状态",
            whitespace: true,
            trigger: ["change", "blur"],
          },
        ],
      },
      //label和wrapper的占位
      labelCol: { span: 8 },
      wrapperCol: { span: 16 },
      //新增和编辑弹出框的标题
      title: "",
      //新增和编辑弹出框的显示隐藏
      visible: false,
      //盒子图片裁剪
      options: {
        icon: "", //裁剪图片的地址
        autoCrop: true, // 是否默认生成截图框
        autoCropWidth: 264, // 默认生成截图框宽度
        autoCropHeight: 188, // 默认生成截图框高度
        fixedBox: true, // 固定截图框大小 不允许改变
      },
      //盒子图片预览
      previews: {},
      //盒子图片文件
      file: null,
      //新增一开始的裁剪图片显示隐藏
      isBegin: true,
      //是否展示预览图片
      canShow: false,
    };
  },
  methods: {
    //获取盒子列表
    getPermissionBox() {
      getPermissionBox(this.queryParams).then((res) => {
        this.data = res.data;
        this.total = res.count;
        this.multiple = !this.selectedRowKeys.length;
      });
    },
    //搜索
    handleQuery() {
      this.queryParams.page = 1;
      this.getPermissionBox();
    },
    //搜索重置
    resetQuery() {
      this.queryParams.page = 1;
      this.$refs.queryForm.resetFields();
      this.getPermissionBox();
    },
    //复选框状态改变
    onSelectChange(selectedRowKeys, records) {
      // console.log(selectedRowKeys);
      // console.log(records);
      this.selectedRowKeys = selectedRowKeys;
      this.multiple = !selectedRowKeys.length;
    },
    /** 新增按钮操作 */
    handleAdd() {
      this.form = {};
      this.form.parentId = 0;
      this.form.type = 0;
      this.options.icon = "";
      this.previews = {};
      this.isBegin = true;
      this.canShow = false;
      this.title = "新增菜单";
      this.visible = true;
    },
    /** 修改按钮操作 */
    handleUpdate(row) {
      this.form = {};
      getMenu(row.id).then((res) => {
        this.form = res.data;
        Object.keys(this.form).forEach((key) => {
          if (this.form[key] === null) {
            delete this.form[key];
          }
        });
        this.options.icon = "";
        this.isBegin = false;
        this.canShow = false;
        this.visible = true;
        this.title = "修改菜单";
      });
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      this.$confirm({
        title: "您确认删除您选中的数据吗？",
        onOk: () => {
          //单个删除
          if (row.id) {
            let arr = [];
            arr.push(row.id);
            menuRemove(arr).then((res) => {
              this.$message.success("删除成功");
              this.getPermissionBox();
            });
          }
          //批量删除
          else {
            menuRemove(this.selectedRowKeys).then((res) => {
              this.$message.success("删除成功");
              this.selectedRowKeys = [];
              this.getPermissionBox();
            });
          }
        },
      });
    },
    //新增或编辑确定
    submit() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          //修改
          if (this.form.id) {
            //没传图片
            if (!this.canShow) {
              let formdata = this.formdataify(this.form);
              menuAddOrUpdate(formdata).then((res) => {
                this.$message.success("修改成功");
                this.visible = false;
                this.getPermissionBox();
              });
            } else {
              this.$refs.cropper.getCropBlob((data) => {
                //修改命名
                const time = new Date().getTime();
                const randomName = time + "_" + this.file.name;
                // blob转file
                let file = new window.File([data], randomName, {
                  type: data.type,
                });
                this.form.file = file;
                let formdata = this.formdataify(this.form);
                menuAddOrUpdate(formdata).then((res) => {
                  this.$message.success("修改成功");
                  this.visible = false;
                  this.getPermissionBox();
                });
              });
            }
          }
          //上传
          else {
            //没有上传盒子图片
            if (this.isBegin && this.options.icon === "") {
              this.$message.error("请上传菜单图片");
            } else {
              this.$refs.cropper.getCropBlob((data) => {
                const time = new Date().getTime();
                const randomName = time + "_" + this.file.name;
                // blob转file
                let file = new window.File([data], randomName, {
                  type: data.type,
                });
                this.form.file = file;
                let formdata = this.formdataify(this.form);
                menuAddOrUpdate(formdata).then((res) => {
                  this.$message.success("新增成功");
                  this.visible = false;
                  this.getPermissionBox();
                });
              });
            }
          }
        }
      });
    },
    //新增或编辑取消
    handleCancel() {
      this.$refs.ruleForm.resetFields();
      this.visible = false;
    },
    // 覆盖默认的上传行为
    requestUpload() {},
    // 向左旋转
    rotateLeft() {
      this.$refs.cropper.rotateLeft();
    },
    // 向右旋转
    rotateRight() {
      this.$refs.cropper.rotateRight();
    },
    // 图片缩放
    changeScale(num) {
      num = num || 1;
      this.$refs.cropper.changeScale(num);
    },
    // 上传预处理
    beforeUpload(file) {
      if (file.type.indexOf("image/") == -1) {
        this.$message.error("文件格式错误，请上传图片类型,如：JPG，PNG后缀的文件。");
      } else {
        this.isBegin = false;
        this.canShow = true;
        this.file = file;
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          this.options.icon = reader.result;
        };
      }
    },
    // 实时预览
    realTime(data) {
      this.previews = data;
    },
    //json对象转换为formData格式
    formdataify(params) {
      const formData = new FormData();
      Object.keys(params).forEach((key) => {
        formData.append(key, params[key]);
      });
      return formData;
    },
  },
  created() {
    this.getPermissionBox();
  },
};
</script>

<style scoped lang="less">
.avatar-upload-preview {
  position: absolute;
  top: 0px;
  left: 288px;
  width: 264px;
  height: 188px;
  box-shadow: 0 0 4px #ccc;
  overflow: hidden;
  .avatar {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
  }
}
</style>
