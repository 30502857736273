var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-form-model',{ref:"accountForm",staticClass:"account-form",attrs:{"model":_vm.form,"rules":_vm.rules}},[_c('a-form-model-item',{attrs:{"prop":"username"}},[_c('a-input',{attrs:{"placeholder":"用户名/手机号"},on:{"change":function () {
          this$1.$forceUpdate();
        }},model:{value:(_vm.form.username),callback:function ($$v) {_vm.$set(_vm.form, "username", $$v)},expression:"form.username"}},[_c('img',{attrs:{"slot":"prefix","src":require("@/assets/images/login_username.png"),"width":"16px"},slot:"prefix"})])],1),_c('a-form-model-item',{attrs:{"prop":"password"}},[_c('a-input-password',{attrs:{"placeholder":"密码","type":"password"},on:{"change":function () {
          this$1.$forceUpdate();
        }},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}},[_c('img',{attrs:{"slot":"prefix","src":require("@/assets/images/login_password.png"),"width":"16px"},slot:"prefix"})])],1),_c('a-row',[_c('a-col',{attrs:{"span":15}},[_c('a-form-model-item',{attrs:{"prop":"validCode"}},[_c('a-input',{attrs:{"placeholder":"验证码"},model:{value:(_vm.form.validCode),callback:function ($$v) {_vm.$set(_vm.form, "validCode", $$v)},expression:"form.validCode"}},[_c('img',{attrs:{"slot":"prefix","src":require("@/assets/images/login_key.png"),"width":"16px"},slot:"prefix"})])],1)],1),_c('a-col',{attrs:{"span":2}}),_c('a-col',{attrs:{"span":7}},[_c('img',{staticClass:"code-img",attrs:{"src":_vm.src},on:{"click":_vm.changeCode}})])],1),_c('a-form-model-item',[_c('div',{staticClass:"d-f j-b"},[_c('a-checkbox',{on:{"change":function () {
            this$1.$forceUpdate();
          }},model:{value:(_vm.form.haschecked),callback:function ($$v) {_vm.$set(_vm.form, "haschecked", $$v)},expression:"form.haschecked"}},[_vm._v(" 记住密码 ")]),_c('router-link',{attrs:{"to":"/forget"}},[_vm._v("忘记密码")])],1)]),_c('a-form-model-item',[_c('a-button',{attrs:{"type":"primary"},on:{"click":_vm.submit}},[_vm._v(" 登录 ")])],1),_c('a-form-model-item',[_c('div',{staticClass:"d-f j-b"},[_c('span',[_vm._v(" 其他登录方式 "),_c('img',{staticClass:"wx",attrs:{"src":require("@/assets/images/login_wx.png"),"width":"24px"},on:{"click":_vm.wxLogin}})]),_c('router-link',{attrs:{"to":"/register"}},[_vm._v("注册账户")])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }