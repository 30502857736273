import request from '@/utils/request'

//获取消息推送日志列表
export function getNewsList(params) {
    return request.get('/api-device/device/push-message-log/list', params)
}

//获取消息推送详情设备列表
export function getNewsDetailDeviceList(params) {
    return request.get('/api-device/device/message-log-device-rel/serial-num-list', params)
}

//获取文件推送日志列表
export function getFileList(params) {
    return request.get('/api-device/device/push-file-log/list', params)
}

//获取文件下载地址
export function getFileDownload(params) {
    return request.get('/api-device/device/push-file-log/download', params)
}

//获取文件下发详情设备列表
export function getFileDetailDeviceList(params) {
    return request.get('/api-device/device/file-log-device-rel/serial-num-list', params)
}