import request from '@/utils/request'

//获取所有智能开关列表
export function getSwitchList(params) {
    return request.get('/api-device/device/device-list', params)
}

//根据指令名称和协议类型获取指令代码
export function getCommandCodeByCommandNameAndModel(params) {
    return request.get('/api-system/sys-4g-instruct-temp/getSysInstruct', params)
}

//调试
export function text(params) {
    return request.get('/api-device/switch-4g/transparent', params)
}

//保存
export function save(params) {
    return request.put('/api-device/switch-4g/saveInstructs', params)
}

//波特率
export function setBPS(params) {
    return request.put('/api-device/switch-4g/setBPS', params)
}