import request from '@/utils/request'

//获取学校设备数排行
export function getSchoolDeviceRank(params) {
    return request.get('/api-device/school-device/sort', params)
}

//获取学校用户数排行
export function getSchoolUsersRank(params) {
    return request.get('/api-device/school-user/sort', params)
}