<template>
  <layout-content :total="total">
    <!-- 面包屑的插槽 -->
    <breadcrumb slot="breadcrumb"></breadcrumb>
    <div slot="content">
      <!-- 搜索和新增删除 -->
      <div class="d-f a-c">
        <a-form-model ref="queryForm" :model="queryParams" layout="inline">
          <a-form-model-item label="操作类型" prop="actionType">
            <a-select
              v-model="queryParams.actionType"
              style="width: 180px"
              placeholder="请输入操作类型"
              allowClear
            >
              <a-select-option
                :value="item.commandIdentification"
                v-for="item in timeList"
                :key="item.commandIdentification"
                >{{ item.commandName }}</a-select-option
              >
            </a-select>
          </a-form-model-item>
          <a-form-model-item>
            <a-button
              type="primary"
              @click="handleQuery"
              v-hasPermi="['device:scheduleJob:query']"
              >搜索</a-button
            >
            <a-button
              @click="resetQuery"
              class="ml10"
              v-hasPermi="['device:scheduleJob:query']"
              >重置</a-button
            >
          </a-form-model-item>
        </a-form-model>
      </div>
      <!--表格-->
      <a-table
        :columns="columns"
        rowKey="id"
        :data-source="data"
        :pagination="false"
        class="mt20"
      >
        <span slot="action" slot-scope="text, record">
          <a @click="handleView(record)" v-hasPermi="['device:scheduleJob:query']"
            >查看详情</a
          >
          <a-divider type="vertical" />
          <a @click="handleDelete(record)" v-hasPermi="['device:scheduleJob:remove']"
            >删除</a
          >
          <a-divider type="vertical" />
          <a-switch
            @change="handleUpdate(record)"
            :checked="record.jobStatus === 1 ? true : false"
            v-hasPermi="['device:scheduleJob:edit']"
          />
        </span>
      </a-table>
      <!--分页-->
      <div class="d-f mt20">
        <a-pagination
          v-model="queryParams.page"
          :pageSize="queryParams.limit"
          :default-current="1"
          :total="total"
          :hideOnSinglePage="true"
          showLessItems
          @change="onChange"
          :item-render="itemRender"
          style="margin-left: auto"
        />
      </div>
      <!--查看详情的弹出框-->
      <a-modal
        v-model="visible"
        title="查看详情"
        :footer="null"
        width="700px"
        :body-style="{ height: '300px', overflowY: 'auto' }"
      >
        <div v-if="scheduleNewsSend" class="mb20">
          <p class="f-s-16">
            展示时长：{{ dictTranslation(showDurationList, form.showDuration) }}
          </p>
          <p class="f-s-16">
            是否允许关闭通知：{{ dictTranslation(closeFlagList, form.closeFlag) }}
          </p>
          <a-textarea v-model="form.content" disabled autoSize />
        </div>
        <div v-if="scheduleFileSend" class="mb20">
          <p class="f-s-16">文件名：{{ form.fileName }}</p>
          <p class="f-s-16">
            是否允许文件自动打开：{{
              dictTranslation(autoOpenFlagList, form.autoOpenFlag)
            }}
          </p>
        </div>
        <a-table
          :columns="columns1"
          rowKey="id"
          :data-source="data1"
          :pagination="false"
          size="small"
        >
          <span slot="deviceName" slot-scope="text, record">
            {{ record.buildingName + "-" + record.classroomName }}
          </span>
        </a-table>
      </a-modal>
    </div>
  </layout-content>
</template>

<script>
import LayoutContent from "@/views/layout/content";
import Breadcrumb from "~c/Breadcrumb";
import { getCommandList } from "@/api/device/command.js";
import {
  getScheduleJobList,
  scheduleJobRemove,
  scheduleJobUpdate,
  getScheduleJobDetail,
} from "@/api/device/schedule-job.js";
import actionType from "@/utils/action-type";
const columns = [
  {
    title: "操作指令",
    dataIndex: "jobName",
    align: "center",
  },
  {
    title: "执行周期",
    dataIndex: "scheduleCycle",
    align: "center",
  },
  {
    title: "执行时间",
    dataIndex: "executeTime",
    align: "center",
  },
  {
    title: "操作",
    dataIndex: "action",
    align: "center",
    scopedSlots: { customRender: "action" },
  },
];
const columns1 = [
  {
    title: "设备名称",
    dataIndex: "deviceName",
    align: "center",
    scopedSlots: { customRender: "deviceName" },
  },
  {
    title: "设备类型",
    dataIndex: "deviceTypeName",
    align: "center",
    scopedSlots: { customRender: "deviceTypeName" },
  },
];
export default {
  name: "ScheduleJob",
  components: { LayoutContent, Breadcrumb },
  data() {
    return {
      total: null,
      // 查询参数
      queryParams: {
        //当前页
        page: 1,
        //一页几个
        limit: 10,
      },
      //表格的表头文字
      columns,
      //表格的数据
      data: [],
      //定时任务操作类型列表
      timeList: [],
      //查看详情的显示隐藏
      visible: false,
      //表格的表头文字
      columns1,
      //表格的数据
      data1: [],
      //查看详情是否是定时消息推送
      scheduleNewsSend: false,
      //查看详情是否是定时文件下发
      scheduleFileSend: false,
      //查看详情的表单
      form: {},
      //展示时长列表
      showDurationList: [],
      //是否允许关闭列表
      closeFlagList: [],
      //文件自动打开列表
      autoOpenFlagList: [],
    };
  },
  methods: {
    //查询推送指令列表
    getTimeList() {
      getCommandList({ isPage: false, commandType: "4" }).then((res) => {
        let obj = {
          commandIdentification: "39",
          commandName: "定时打铃",
          commandType: "4",
        };
        let arr = JSON.parse(JSON.stringify(res.data));
        arr.push(obj);
        this.timeList = arr;
      });
    },
    //获取定时任务列表
    getScheduleJobList() {
      getScheduleJobList(this.queryParams).then((res) => {
        this.data = res.data;
        this.total = res.count;
      });
    },
    //搜索
    handleQuery() {
      this.queryParams.page = 1;
      this.getScheduleJobList();
    },
    //搜索重置
    resetQuery() {
      this.queryParams.page = 1;
      this.$refs.queryForm.resetFields();
      this.getScheduleJobList();
    },
    //获取消息展示时长列表
    getShowDurationList() {
      this.getDictDataList("device_terminal_show_duration").then((res) => {
        this.showDurationList = res.data;
      });
    },
    //获取终端关闭通知列表
    getCloseFlagList() {
      this.getDictDataList("device_terminal_close_flag").then((res) => {
        this.closeFlagList = res.data;
      });
    },
    //获取文件自动打开列表
    getAutoOpenFlagList() {
      this.getDictDataList("device_terminal_auto_open_flag").then((res) => {
        this.autoOpenFlagList = res.data;
      });
    },
    //查看详情
    handleView(row) {
      this.scheduleNewsSend = false;
      this.scheduleFileSend = false;
      this.form = {};
      getScheduleJobDetail(row.id).then(async (res) => {
        this.data1 = res.data.deviceInfoList;
        if (row.actionType == actionType.scheduleNewsSend) {
          this.scheduleNewsSend = true;
          this.form.content = res.data.content;
          this.form.showDuration = res.data.showDuration;
          this.form.closeFlag = res.data.closeFlag;
          await this.getShowDurationList();
          await this.getCloseFlagList();
        }
        if (row.actionType == actionType.scheduleFileSend) {
          this.scheduleFileSend = true;
          this.form.fileName = res.data.fileName;
          this.form.autoOpenFlag = res.data.autoOpenFlag;
          await this.getAutoOpenFlagList();
        }
        this.visible = true;
      });
    },
    /** 修改按钮操作 */
    handleUpdate(row) {
      this.$confirm({
        title: "您确认改变定时任务的状态吗？",
        onOk: () => {
          scheduleJobUpdate(row.id).then((res) => {
            this.$message.success("修改成功");
            this.getScheduleJobList();
          });
        },
      });
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      this.$confirm({
        title: "您确认删除您选中的数据吗？",
        onOk: () => {
          scheduleJobRemove(row.id).then((res) => {
            this.$message.success("删除成功");
            this.getScheduleJobList();
          });
        },
      });
    },
    //分页状态改变
    onChange(pageNumber) {
      this.queryParams.page = pageNumber;
      this.getScheduleJobList();
    },
    //改变分页上一步下一步的文字链接
    itemRender(current, type, originalElement) {
      if (type === "prev") {
        return <a>上一页</a>;
      } else if (type === "next") {
        return <a class="next">下一页</a>;
      }
      return originalElement;
    },
  },
  created() {
    this.getTimeList();
    this.getScheduleJobList();
  },
};
</script>

<style scoped lang="less"></style>
