<template>
  <layout-content :total="total">
    <!-- 面包屑的插槽 -->
    <breadcrumb slot="breadcrumb"></breadcrumb>
    <div slot="content">
      <!-- 搜索和新增删除 -->
      <div class="d-f a-c j-b">
        <a-form-model ref="queryForm" :model="queryParams" layout="inline">
          <a-form-model-item label="学科名称" prop="name">
            <a-input
              v-model="queryParams.name"
              placeholder="请输入学科名称"
              allowClear
              @keyup.enter.native="handleQuery"
            />
          </a-form-model-item>
          <a-form-model-item>
            <a-button
              type="primary"
              @click="handleQuery"
              v-hasPermi="['course:subject:query']"
              >搜索</a-button
            >
            <a-button
              @click="resetQuery"
              class="ml10"
              v-hasPermi="['course:subject:query']"
              >重置</a-button
            >
          </a-form-model-item>
        </a-form-model>
        <div>
          <a-button
            type="primary"
            @click="handleAdd"
            icon="plus"
            v-hasPermi="['course:subject:add']"
            >新增</a-button
          >
          <a-button
            @click="handleDelete"
            icon="delete"
            class="ml10"
            :disabled="multiple"
            v-hasPermi="['course:subject:remove']"
          >
            批量删除
          </a-button>
        </div>
      </div>
      <!--表格-->
      <a-table
        :columns="columns"
        rowKey="id"
        :data-source="data"
        :pagination="false"
        :row-selection="{
          selectedRowKeys: selectedRowKeys,
          onChange: onSelectChange,
        }"
        class="mt20"
      >
        <span slot="teacher" slot-scope="text, record">
          <a @click="handleTeacherQuery(record.id)" v-hasPermi="['course:subject:query']"
            >查看详情</a
          >
        </span>
        <span slot="action" slot-scope="text, record">
          <a @click="handleUpdate(record)" v-hasPermi="['course:subject:edit']">编辑</a>
          <a-divider type="vertical" />
          <a @click="handleDelete(record)" v-hasPermi="['course:subject:remove']">删除</a>
        </span>
      </a-table>
      <!--分页-->
      <div class="d-f mt20">
        <a-pagination
          v-model="queryParams.page"
          :pageSize="queryParams.limit"
          :default-current="1"
          :total="total"
          :hideOnSinglePage="true"
          showLessItems
          @change="onChange"
          :item-render="itemRender"
          style="margin-left: auto"
        />
      </div>
      <!--新增和编辑的弹出框-->
      <a-modal
        v-model="visible"
        :title="title"
        @ok="submit"
        @cancel="handleCancel"
        width="500px"
      >
        <a-form-model
          ref="ruleForm"
          :model="form"
          :rules="rules"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
        >
          <a-row>
            <a-col :span="24">
              <a-form-model-item label="学科名称" prop="name">
                <a-input v-model="form.name" placeholder="请输入学科名称" />
              </a-form-model-item>
            </a-col>
            <a-col :span="24">
              <a-form-model-item label="学科标识" prop="subjectIdentity">
                <a-input v-model="form.subjectIdentity" placeholder="请输入学科标识" />
              </a-form-model-item>
            </a-col>
            <a-col :span="24">
              <a-form-model-item label="显示排序" prop="sortNum">
                <a-input-number
                  v-model="form.sortNum"
                  placeholder="请输入显示排序"
                  :min="1"
                  style="width: 100%"
                />
              </a-form-model-item>
            </a-col>
          </a-row>
        </a-form-model>
      </a-modal>
      <!--查看授课教师的弹出框-->
      <a-modal v-model="teacherVisible" title="授课教师" width="700px">
        <div class="d-f">
          <a-button
            type="primary"
            @click="handleTeacherBind"
            icon="plus"
            v-hasPermi="['course:subject:bindTeacher']"
            style="margin-left: auto; margin-bottom: 10px"
            >授课教师</a-button
          >
        </div>
        <a-table
          size="small"
          :columns="teacherColumns"
          rowKey="id"
          :data-source="teacherData"
          :pagination="false"
        >
          <span slot="action" slot-scope="text, record">
            <a
              @click="handleTeacherUnbind(record)"
              v-hasPermi="['course:subject:unbindTeacher']"
            >
              解除绑定
            </a>
          </span>
        </a-table>
      </a-modal>
      <!--添加授课教师的弹出框-->
      <a-modal
        v-model="addTeacherVisible"
        title="添加授课教师"
        width="700px"
        @ok="addTeacher"
      >
        <a-form-model
          ref="addTeacherForm"
          :model="addTeacherForm"
          :rules="addTeacherRules"
          :label-col="{ span: 4 }"
          :wrapper-col="{ span: 20 }"
        >
          <a-form-model-item label="选择教师" prop="teacherIds">
            <a-select
              mode="multiple"
              v-model="addTeacherForm.teacherIds"
              placeholder="请选择教师"
              style="width: 100%"
              optionFilterProp="children"
              :getPopupContainer="(triggerNode) => triggerNode.parentNode"
            >
              <a-select-option v-for="item in teacherList" :key="item.id">
                {{ item.username }}
                <span v-if="item.realName">（{{ item.realName }}）</span>
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-form-model>
      </a-modal>
    </div>
  </layout-content>
</template>

<script>
import LayoutContent from "@/views/layout/content";
import Breadcrumb from "~c/Breadcrumb";
import {
  getSubjectList,
  subjectAddOrUpdate,
  subjectRemove,
  getSubject,
  getTeacherData,
  subjectBindTeacher,
  subjectUnBindTeacher,
} from "@/api/course/subject.js";
import { getTeacherList } from "@/api/teacher-student/teacher.js";
const columns = [
  {
    title: "学科名称",
    dataIndex: "name",
    align: "center",
  },
  {
    title: "学科标识",
    dataIndex: "subjectIdentity",
    align: "center",
  },
  {
    title: "显示排序",
    dataIndex: "sortNum",
    align: "center",
  },
  {
    title: "授课教师",
    dataIndex: "teacher",
    align: "center",
    scopedSlots: { customRender: "teacher" },
  },
  {
    title: "操作",
    dataIndex: "action",
    align: "center",
    scopedSlots: { customRender: "action" },
  },
];
const teacherColumns = [
  {
    title: "教师用户名",
    dataIndex: "username",
    align: "center",
  },
  {
    title: "教师姓名",
    dataIndex: "realName",
    align: "center",
  },
  {
    title: "操作",
    dataIndex: "action",
    align: "center",
    scopedSlots: { customRender: "action" },
  },
];
export default {
  name: "Subject",
  components: { LayoutContent, Breadcrumb },
  data() {
    return {
      total: null,
      // 查询参数
      queryParams: {
        //当前页
        page: 1,
        //一页几个
        limit: 10,
      },
      //表格的表头文字
      columns,
      //表格的数据
      data: [],
      //多选的数据
      selectedRowKeys: [],
      // 非多个禁用
      multiple: true,
      //新增和编辑的表单
      form: {},
      //表单规则
      rules: {
        name: [
          {
            required: true,
            message: "请输入学科名称",
            whitespace: true,
            trigger: ["change", "blur"],
          },
        ],
        subjectIdentity: [
          {
            required: true,
            message: "请输入学科标识",
            whitespace: true,
            trigger: ["change", "blur"],
          },
        ],
        sortNum: [
          {
            type: "number",
            required: true,
            message: "请输入显示排序",
            whitespace: true,
            trigger: ["change", "blur"],
          },
        ],
      },
      //label和wrapper的占位
      labelCol: { span: 5 },
      wrapperCol: { span: 19 },
      //新增和编辑弹出框的标题
      title: "",
      //新增和编辑弹出框的显示隐藏
      visible: false,
      //查看教师详情弹出框的显示隐藏
      teacherVisible: false,
      //教师表格的表头文字
      teacherColumns,
      //教师的数据列表
      teacherData: [],
      //添加教师弹出框的显示隐藏
      addTeacherVisible: false,
      //添加教师的表单
      addTeacherForm: {},
      //添加教师表单规则
      addTeacherRules: {
        teacherIds: [
          {
            type: "array",
            required: true,
            message: "请选择教师",
            whitespace: true,
            trigger: ["change", "blur"],
          },
        ],
      },
      //教师列表
      teacherList: [],
    };
  },
  methods: {
    //获取学科列表
    getSubjectList() {
      getSubjectList(this.queryParams).then((res) => {
        this.data = res.data;
        this.total = res.count;
        this.multiple = !this.selectedRowKeys.length;
      });
    },
    //搜索
    handleQuery() {
      this.queryParams.page = 1;
      this.getSubjectList();
    },
    //搜索重置
    resetQuery() {
      this.queryParams.page = 1;
      this.$refs.queryForm.resetFields();
      this.getSubjectList();
    },
    //复选框状态改变
    onSelectChange(selectedRowKeys, records) {
      // console.log(selectedRowKeys);
      // console.log(records);
      this.selectedRowKeys = selectedRowKeys;
      this.multiple = !selectedRowKeys.length;
    },
    /** 新增按钮操作 */
    handleAdd() {
      this.form = {};
      this.visible = true;
      this.title = "新增学科";
    },
    /** 修改按钮操作 */
    handleUpdate(row) {
      this.form = {};
      getSubject(row.id).then((res) => {
        this.form = res.data;
        this.visible = true;
        this.title = "修改学科";
      });
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      this.$confirm({
        title: "您确认删除您选中的数据吗？",
        onOk: () => {
          //单个删除
          if (row.id) {
            let arr = [];
            arr.push(row.id);
            subjectRemove(arr).then((res) => {
              this.$message.success("删除成功");
              this.getSubjectList();
            });
          }
          //批量删除
          else {
            subjectRemove(this.selectedRowKeys).then((res) => {
              this.$message.success("删除成功");
              this.selectedRowKeys = [];
              this.getSubjectList();
            });
          }
        },
      });
    },
    //新增或编辑确定
    submit() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          subjectAddOrUpdate(this.form).then((res) => {
            //修改
            if (this.form.id) {
              this.$message.success("修改成功");
            }
            //新增
            else {
              this.$message.success("新增成功");
            }
            this.visible = false;
            this.getSubjectList();
          });
        }
      });
    },
    //新增或编辑取消
    handleCancel() {
      this.$refs.ruleForm.resetFields();
      this.visible = false;
    },
    //分页状态改变
    onChange(pageNumber) {
      this.queryParams.page = pageNumber;
      this.getSubjectList();
    },
    //改变分页上一步下一步的文字链接
    itemRender(current, type, originalElement) {
      if (type === "prev") {
        return <a>上一页</a>;
      } else if (type === "next") {
        return <a class="next">下一页</a>;
      }
      return originalElement;
    },
    //查看授课教师详情
    handleTeacherQuery(subjectId) {
      getTeacherData(subjectId).then((res) => {
        this.teacherData = res.data;
        this.teacherVisible = true;
        this.addTeacherForm.subjectId = subjectId;
      });
    },
    //授课教师
    handleTeacherBind() {
      getTeacherList({ isPage: false }).then((res) => {
        this.teacherList = res.data;
        this.addTeacherVisible = true;
        let subjectId = JSON.parse(JSON.stringify(this.addTeacherForm.subjectId));
        this.addTeacherForm = {};
        this.addTeacherForm.subjectId = subjectId;
      });
    },
    //添加授课教师确定
    addTeacher() {
      this.$refs.addTeacherForm.validate((valid) => {
        if (valid) {
          subjectBindTeacher(this.addTeacherForm).then((res) => {
            this.$message.success("绑定成功");
            this.handleTeacherQuery(this.addTeacherForm.subjectId);
            this.addTeacherVisible = false;
          });
        }
      });
    },
    //解绑教师
    handleTeacherUnbind(row) {
      this.$confirm({
        title: "您确认解除该教师和班级的绑定吗？",
        onOk: () => {
          let arr = [];
          arr.push(row.id);
          subjectUnBindTeacher({
            subjectId: this.addTeacherForm.subjectId,
            teacherIds: arr,
          }).then((res) => {
            this.$message.success("解绑成功");
            this.handleTeacherQuery(this.addTeacherForm.subjectId);
          });
        },
      });
    },
  },
  created() {
    this.getSubjectList();
  },
};
</script>

<style scoped lang="less"></style>
