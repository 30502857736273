<template>
  <layout-content :total="total">
    <!-- 面包屑的插槽 -->
    <breadcrumb slot="breadcrumb"></breadcrumb>
    <div slot="content">
      <a-form-model ref="queryForm" :model="queryParams" layout="inline">
        <a-form-model-item label="租户标识" prop="tenantId">
          <a-input
            v-model="queryParams.tenantId"
            placeholder="请输入租户标识"
            allowClear
            @keyup.enter.native="handleQuery"
          />
        </a-form-model-item>
        <a-form-model-item label="学校编号" prop="schoolNum">
          <a-input
            v-model="queryParams.schoolNum"
            placeholder="请输入学校编号"
            allowClear
            @keyup.enter.native="handleQuery"
          />
        </a-form-model-item>
        <a-form-model-item>
          <a-button
            type="primary"
            @click="handleQuery"
            v-hasPermi="['interaction:time:query']"
            >搜索</a-button
          >
          <a-button
            @click="resetQuery"
            class="ml10"
            v-hasPermi="['interaction:time:query']"
            >重置</a-button
          >
        </a-form-model-item>
      </a-form-model>
      <!--表格-->
      <a-table
        :columns="columns"
        rowKey="schoolNum"
        :data-source="data"
        :pagination="false"
        class="mt20"
      >
        <span slot="usedDurationHourStr" slot-scope="text"> {{ text }}小时 </span>
        <span slot="totalDurationHourStr" slot-scope="text"> {{ text }}小时 </span>
        <span slot="action" slot-scope="text, record">
          <a @click="addTime(record)" v-hasPermi="['interaction:time:addTime']"
            >增加时长</a
          >
          <a-divider type="vertical" />
          <a
            @click="purchaseRecord(record)"
            v-hasPermi="['interaction:time:purchaseRecord']"
            >购买记录</a
          >
        </span>
      </a-table>
      <!--分页-->
      <div class="d-f mt20">
        <a-pagination
          v-model="queryParams.page"
          :pageSize="queryParams.limit"
          :default-current="1"
          :total="total"
          :hideOnSinglePage="true"
          showLessItems
          @change="onChange"
          :item-render="itemRender"
          style="margin-left: auto"
        />
      </div>
      <!--增加时长的弹出框-->
      <a-modal
        v-model="visible"
        title="增加时长"
        @ok="submit"
        @cancel="handleCancel"
        width="500px"
        class="add-time"
      >
        <a-form-model
          ref="ruleForm"
          :model="form"
          :rules="rules"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
        >
          <a-form-model-item label="时长" prop="interactDuration">
            <a-select
              v-model="form.interactDuration"
              placeholder="请选择时长"
              style="width: 100%"
              :getPopupContainer="(triggerNode) => triggerNode.parentNode"
            >
              <a-select-option
                :value="item.valueData"
                v-for="item in interationTimeAddList"
                :key="item.id"
              >
                {{ item.valueName }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-form-model>
      </a-modal>
      <!--购买记录的弹出框-->
      <a-modal v-model="recordVisible" title="增加时长" width="700px" :footer="null">
        <a-table
          :columns="recordColumns"
          rowKey="id"
          :data-source="recordData"
          :pagination="false"
          size="small"
        >
          <span slot="interactDurationStr" slot-scope="text"> {{ text }}小时 </span>
          <span slot="status" slot-scope="text">
            <span v-if="text == 0">已撤回</span>
            <span v-if="text == 1">正常</span>
          </span>
          <span slot="action" slot-scope="text, record">
            <a @click="recall(record)" v-if="record.status == 1">撤回</a>
            <span v-else>——</span>
          </span>
        </a-table>
        <div class="d-f mt20">
          <a-pagination
            v-model="recordQueryParams.page"
            :pageSize="recordQueryParams.limit"
            :default-current="1"
            :total="recordTotal"
            :hideOnSinglePage="true"
            showLessItems
            @change="recordOnChange"
            :item-render="itemRender"
            style="margin-left: auto"
          />
        </div>
      </a-modal>
    </div>
  </layout-content>
</template>

<script>
import LayoutContent from "@/views/layout/content";
import Breadcrumb from "~c/Breadcrumb";
import {
  getInteractionTimeList,
  addInteractionTime,
  getPurchaseRecordList,
  recall,
} from "@/api/interaction/time.js";
const columns = [
  {
    title: "租户标识",
    dataIndex: "tenantId",
    align: "center",
  },
  {
    title: "学校编号",
    dataIndex: "schoolNum",
    align: "center",
  },
  {
    title: "学校名称",
    dataIndex: "schoolName",
    align: "center",
  },
  {
    title: "已用时长",
    dataIndex: "usedDurationHourStr",
    align: "center",
    scopedSlots: { customRender: "usedDurationHourStr" },
  },
  {
    title: "总时长",
    dataIndex: "totalDurationHourStr",
    align: "center",
    scopedSlots: { customRender: "totalDurationHourStr" },
  },
  {
    title: "操作",
    dataIndex: "action",
    align: "center",
    scopedSlots: { customRender: "action" },
  },
];
const recordColumns = [
  {
    title: "购买时间",
    dataIndex: "createTime",
    align: "center",
  },
  {
    title: "购买时长",
    dataIndex: "interactDurationStr",
    align: "center",
    scopedSlots: { customRender: "interactDurationStr" },
  },
  {
    title: "状态",
    dataIndex: "status",
    align: "center",
    scopedSlots: { customRender: "status" },
  },
  {
    title: "操作",
    dataIndex: "action",
    align: "center",
    scopedSlots: { customRender: "action" },
  },
];
export default {
  name: "InteractionTime",
  components: { LayoutContent, Breadcrumb },
  data() {
    return {
      total: null,
      // 查询参数
      queryParams: {
        //当前页
        page: 1,
        //一页几个
        limit: 10,
      },
      //表格的表头文字
      columns,
      //表格的数据
      data: [],
      //新增和编辑的表单
      form: {},
      //表单规则
      rules: {
        interactDuration: [
          {
            required: true,
            message: "请选择增加时长",
            whitespace: true,
            trigger: ["change", "blur"],
          },
        ],
      },
      //label和wrapper的占位
      labelCol: { span: 5 },
      wrapperCol: { span: 19 },
      //增加时长的显示隐藏
      visible: false,
      //增加时长的选择列表
      interationTimeAddList: [],
      //购买记录的显示隐藏
      recordVisible: false,
      //表格的表头文字
      recordColumns,
      //表格的数据
      recordData: [],
      // 查询参数
      recordQueryParams: {
        //当前页
        page: 1,
        //一页几个
        limit: 5,
      },
      recordTotal: null,
    };
  },
  methods: {
    //获取互动时长列表
    getInteractionTimeList() {
      getInteractionTimeList(this.queryParams).then((res) => {
        this.total = res.count;
        this.data = res.data;
      });
    },
    //搜索
    handleQuery() {
      this.queryParams.page = 1;
      this.getInteractionTimeList();
    },
    //搜索重置
    resetQuery() {
      this.queryParams.page = 1;
      this.$refs.queryForm.resetFields();
      this.getInteractionTimeList();
    },
    //增加时长
    addTime(row) {
      this.form = {};
      this.form.schoolNum = row.schoolNum;
      this.form.tenantId = row.tenantId;
      this.getDictDataList("interaction_time_add").then((res) => {
        this.interationTimeAddList = res.data;
        this.visible = true;
      });
    },
    //增加时长确定
    submit() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          addInteractionTime(this.form).then((res) => {
            this.visible = false;
            this.$message.success("增加成功");
            this.getInteractionTimeList();
          });
        }
      });
    },
    //增加时长取消
    handleCancel() {
      this.$refs.ruleForm.resetFields();
      this.visible = false;
    },
    //购买记录
    purchaseRecord(row) {
      this.recordQueryParams.schoolNum = row.schoolNum;
      this.recordQueryParams.tenantId = row.tenantId;
      getPurchaseRecordList(this.recordQueryParams).then((res) => {
        this.recordVisible = true;
        this.recordData = res.data;
        this.recordTotal = res.count;
      });
    },
    //撤回
    recall(row) {
      this.$confirm({
        title: "您确认撤回此学校增加的时长吗？",
        onOk: () => {
          recall(row.id).then((res) => {
            this.$message.success("撤回成功");
            getPurchaseRecordList(this.recordQueryParams).then((res) => {
              this.recordData = res.data;
              this.recordTotal = res.count;
              this.getInteractionTimeList();
            });
          });
        },
      });
    },
    //分页状态改变
    recordOnChange(pageNumber) {
      this.recordQueryParams.page = pageNumber;
      getPurchaseRecordList(this.recordQueryParams).then((res) => {
        this.recordData = res.data;
        this.recordTotal = res.count;
      });
    },
    //分页状态改变
    onChange(pageNumber) {
      this.queryParams.page = pageNumber;
      this.getInteractionTimeList();
    },
    //改变分页上一步下一步的文字链接
    itemRender(current, type, originalElement) {
      if (type === "prev") {
        return <a>上一页</a>;
      } else if (type === "next") {
        return <a class="next">下一页</a>;
      }
      return originalElement;
    },
  },
  created() {
    this.getInteractionTimeList();
  },
};
</script>

<style scoped lang="less">
.add-time {
  ::v-deep .ant-modal-body {
    height: 230px;
  }
}
::v-deep .ant-modal-body {
  max-height: 500px;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 6px;
    /*高宽分别对应横竖滚动条的尺寸*/
    height: 1px;
  }

  &::-webkit-scrollbar-thumb {
    // -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    background: #e3e3e6;
    border-radius: 6px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
    // -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
  }
}
</style>
