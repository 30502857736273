import request from '@/utils/request'

//获取年级列表
export function getGradeList(params) {
    return request.get('/api-school/grade', params)
}

//新增或修改年级
export function gradeAddOrUpdate(params) {
    return request.post('/api-school/grade/saveOrUpdate', params)
}

//获取年级详情
export function getGrade(id) {
    return request.get('/api-school/grade/' + id)
}

//删除年级
export function gradeRemove(params) {
    return request.remove('/api-school/grade/deleteBatch', params)
}
