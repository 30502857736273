import request from '@/utils/request'

//获取租户列表
export function getTenantList(params) {
    return request.get('/api-uaa/tenant/list', params)
}

//新增或修改租户
export function tenantAddOrUpdate(params) {
    return request.post('/api-uaa/tenant/saveOrUpdate', params)
}

//获取租户详情
export function getTenant(id) {
    return request.get('/api-uaa/tenant/' + id)
}


//删除租户
export function tenantRemove(params) {
    return request.remove('/api-uaa/tenant/deleteBatch', params)
}

//获取所有租户列表
export function getTenantALlList(params) {
    return request.get('/api-uaa/tenant/all', params)
}