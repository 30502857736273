import request from '@/utils/request'

//获取各个时间段和设备在线台数
export function getDeviceOnlineNumList(params) {
    return request.get('/api-device/analysis/online-active-count', params)
}

//获取软件使用时长排名
export function getDeviceUseRank(params) {
    return request.get('/api-device/device/software-statistics/rank', params)
}

//获取设备在线时长排名
export function getDeviceOnlineRank(params) {
    return request.get('/api-device/analysis/online-duration-sort', params)
}

//获取设备状态评分排名
export function getDeviceStatusRank(params) {
    return request.get('/api-device/device/score/rank', params)
}