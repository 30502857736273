import request from '@/utils/request'

//获取软件升级列表
export function getSoftwareList(params) {
    return request.get('/api-device/device/android-software/list?', params)
}

//上传软件
export function softwareUpload(params) {
    return request.post('/api-device/device/android-software/add/',params)
}

//获取软件升级下载链接
export function getSoftwareDownload(params) {
    return request.get('/api-device/device/android-software/download/',params)
}

//删除软件
export function softwareRemove(id) {
    return request.remove('/api-device/device/android-software/delete/?id='+id)
}

//修改最新状态
export function softwareUpdate(params) {
    return request.put('/api-device/device/android-software/change-status/',params)
}