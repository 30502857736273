import request from '@/utils/request'

//获取互动时长学校列表
export function getInteractionTimeList(params) {
    return request.get('/api-school/interact-duration/list', params)
}

//增加学校互动时长
export function addInteractionTime(params) {
    return request.post('/api-device/interact-duration-buy/save', params)
}

//获取学校购买记录列表
export function getPurchaseRecordList(params) {
    return request.get('/api-device/interact-duration-buy/list', params)
}

//撤回增加时长的记录
export function recall(id) {
    return request.put('/api-device/interact-duration-buy/cancel/' + id)
}