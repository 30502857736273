import request from '@/utils/request'

//获取设备分组列表
export function getGroupList(params) {
    return request.get('/api-device/device/custom-group', params)
}

//新增或修改设备分组
export function groupAddOrUpdate(params) {
    return request.post('/api-device/device/custom-group/saveOrUpdate', params)
}

//获取设备分组详情
export function getGroup(id) {
    return request.get('/api-device/device/custom-group/'+id)
}

//删除设备分组
export function groupRemove(params) {
    return request.remove('/api-device/device/custom-group/deleteBatch', params)
}


//分组选择设备
export function groupRelationDevice(params) {
    return request.post('/api-device/device/group-device-relation', params)
}