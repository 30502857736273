import request from '@/utils/request'

//获取学段列表
export function getRankList(params) {
    return request.get('/api-school/rank', params)
}

//新增或修改学段
export function rankAddOrUpdate(params) {
    return request.post('/api-school/rank/saveOrUpdate', params)
}

//获取学段详情
export function getRank(id) {
    return request.get('/api-school/rank/' + id)
}

//删除学段
export function rankRemove(params) {
    return request.remove('/api-school/rank/deleteBatch', params)
}
