<template>
  <layout-content :total="total">
    <!-- 面包屑的插槽 -->
    <breadcrumb slot="breadcrumb"></breadcrumb>
    <div slot="content">
      <!-- 搜索和新增删除 -->
      <div class="d-f a-c j-b">
        <a-form-model ref="queryForm" :model="queryParams" layout="inline">
          <a-form-model-item label="字典名称" prop="keyName">
            <a-input
              v-model="queryParams.keyName"
              placeholder="请输入字典名称"
              allowClear
              @keyup.enter.native="handleQuery"
            />
          </a-form-model-item>
          <a-form-model-item label="字典类型" prop="keyType">
            <a-input
              v-model="queryParams.keyType"
              placeholder="请输入字典类型"
              allowClear
              @keyup.enter.native="handleQuery"
              @change="
                () => {
                  this.$forceUpdate();
                }
              "
            />
          </a-form-model-item>
          <a-form-model-item>
            <a-button
              type="primary"
              @click="handleQuery"
              v-hasPermi="['setting:dict:query']"
              >搜索</a-button
            >
            <a-button @click="resetQuery" class="ml10" v-hasPermi="['setting:dict:query']"
              >重置</a-button
            >
          </a-form-model-item>
        </a-form-model>
        <div>
          <a-button
            type="primary"
            @click="handleAdd"
            icon="plus"
            v-hasPermi="['setting:dict:add']"
            >新增</a-button
          >
          <a-button
            @click="handleDelete"
            icon="delete"
            class="ml10"
            :disabled="multiple"
            v-hasPermi="['setting:dict:remove']"
          >
            批量删除
          </a-button>
        </div>
      </div>
      <!--表格-->
      <a-table
        :columns="columns"
        rowKey="id"
        :data-source="data"
        :pagination="false"
        :row-selection="{
          selectedRowKeys: selectedRowKeys,
          onChange: onSelectChange,
        }"
        class="mt20"
      >
        <span slot="keyType" slot-scope="text, record">
          <router-link :to="'/setting/dict-data?keyType=' + record.keyType">
            <span>{{ text }}</span>
          </router-link>
        </span>
        <span slot="status" slot-scope="text">
          <a-tag :color="text === 0 ? '#009FE8' : '#f5222d'">
            {{ text | filterStatus }}
          </a-tag>
        </span>
        <span slot="remark" slot-scope="text" :title="text">
          {{ text }}
        </span>
        <span slot="action" slot-scope="text, record">
          <a @click="handleUpdate(record)" v-hasPermi="['setting:dict:edit']">编辑</a>
          <a-divider type="vertical" />
          <a @click="handleDelete(record)" v-hasPermi="['setting:dict:remove']">删除</a>
        </span>
      </a-table>
      <!--分页-->
      <div class="d-f mt20">
        <a-pagination
          v-model="queryParams.page"
          :pageSize="queryParams.limit"
          :default-current="1"
          :total="total"
          :hideOnSinglePage="true"
          showLessItems
          @change="onChange"
          :item-render="itemRender"
          style="margin-left: auto"
        />
      </div>
      <!--新增和编辑的弹出框-->
      <a-modal
        v-model="visible"
        :title="title"
        @ok="submit"
        @cancel="handleCancel"
        width="500px"
      >
        <a-form-model
          ref="ruleForm"
          :model="form"
          :rules="rules"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
        >
          <a-row>
            <a-col :span="24">
              <a-form-model-item label="字典名称" prop="keyName">
                <a-input v-model="form.keyName" placeholder="请输入字典名称" />
              </a-form-model-item>
            </a-col>
            <a-col :span="24">
              <a-form-model-item label="字典类型" prop="keyType">
                <a-input v-model="form.keyType" placeholder="请输入字典类型" />
              </a-form-model-item>
            </a-col>
            <a-col :span="24">
              <a-form-model-item label="状态" prop="status">
                <a-radio-group v-model="form.status">
                  <a-radio :value="0"> 启用 </a-radio>
                  <a-radio :value="1"> 禁用 </a-radio>
                </a-radio-group>
              </a-form-model-item>
            </a-col>
            <a-col :span="24">
              <a-form-model-item label="备注" prop="remark">
                <a-input
                  v-model="form.remark"
                  type="textarea"
                  :rows="6"
                  :maxLength="200"
                />
              </a-form-model-item>
            </a-col>
          </a-row>
        </a-form-model>
      </a-modal>
    </div>
  </layout-content>
</template>

<script>
import LayoutContent from "@/views/layout/content";
import Breadcrumb from "~c/Breadcrumb";
import { getDictList, dictAddOrUpdate, dictRemove, getDict } from "@/api/setting/dict.js";
const columns = [
  {
    title: "字典名称",
    dataIndex: "keyName",
    align: "center",
  },
  {
    title: "字典类型",
    dataIndex: "keyType",
    align: "center",
    scopedSlots: { customRender: "keyType" },
  },
  {
    title: "状态",
    dataIndex: "status",
    align: "center",
    scopedSlots: { customRender: "status" },
  },
  {
    title: "备注",
    dataIndex: "remark",
    align: "center",
    scopedSlots: { customRender: "remark" },
    ellipsis: true,
  },
  {
    title: "操作",
    dataIndex: "action",
    align: "center",
    scopedSlots: { customRender: "action" },
  },
];
export default {
  name: "Dict",
  components: { LayoutContent, Breadcrumb },
  filters: {
    filterStatus(val) {
      return ["启用", "禁用"][val];
    },
  },
  data() {
    return {
      total: null,
      // 查询参数
      queryParams: {
        //当前页
        page: 1,
        //一页几个
        limit: 10,
      },
      //表格的表头文字
      columns,
      //表格的数据
      data: [],
      //多选的数据
      selectedRowKeys: [],
      // 非多个禁用
      multiple: true,
      //新增和编辑的表单
      form: {},
      //表单规则
      rules: {
        keyName: [
          {
            required: true,
            message: "请输入字典名称",
            whitespace: true,
            trigger: ["change", "blur"],
          },
        ],
        keyType: [
          {
            required: true,
            message: "请输入字典类型",
            whitespace: true,
            trigger: ["change", "blur"],
          },
        ],
        status: [
          {
            type: "number",
            required: true,
            message: "请选择状态",
            whitespace: true,
            trigger: ["change", "blur"],
          },
        ],
      },
      //label和wrapper的占位
      labelCol: { span: 5 },
      wrapperCol: { span: 19 },
      //新增和编辑弹出框的标题
      title: "",
      //新增和编辑弹出框的显示隐藏
      visible: false,
    };
  },
  methods: {
    //获取字典列表
    getDictList() {
      getDictList(this.queryParams).then((res) => {
        this.data = res.data;
        this.total = res.count;
        this.multiple = !this.selectedRowKeys.length;
      });
    },
    //搜索
    handleQuery() {
      this.queryParams.page = 1;
      this.getDictList();
    },
    //搜索重置
    resetQuery() {
      this.queryParams.page = 1;
      this.$refs.queryForm.resetFields();
      this.getDictList();
    },
    //复选框状态改变
    onSelectChange(selectedRowKeys, records) {
      // console.log(selectedRowKeys);
      // console.log(records);
      this.selectedRowKeys = selectedRowKeys;
      this.multiple = !selectedRowKeys.length;
    },
    /** 新增按钮操作 */
    handleAdd() {
      this.form = {};
      this.visible = true;
      this.title = "新增字典";
    },
    /** 修改按钮操作 */
    handleUpdate(row) {
      this.form = {};
      getDict(row.id).then((res) => {
        this.form = res.data;
        this.visible = true;
        this.title = "修改字典";
      });
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      this.$confirm({
        title: "您确认删除您选中的数据吗？",
        onOk: () => {
          //单个删除
          if (row.id) {
            let arr = [];
            arr.push(row.id);
            dictRemove(arr).then((res) => {
              this.$message.success("删除成功");
              this.getDictList();
            });
          }
          //批量删除
          else {
            dictRemove(this.selectedRowKeys).then((res) => {
              this.$message.success("删除成功");
              this.selectedRowKeys = [];
              this.getDictList();
            });
          }
        },
      });
    },
    //新增或编辑确定
    submit() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          dictAddOrUpdate(this.form).then((res) => {
            //修改
            if (this.form.id) {
              this.$message.success("修改成功");
            }
            //新增
            else {
              this.$message.success("新增成功");
            }
            this.visible = false;
            this.getDictList();
          });
        }
      });
    },
    //新增或编辑取消
    handleCancel() {
      this.$refs.ruleForm.resetFields();
      this.visible = false;
    },
    //分页状态改变
    onChange(pageNumber) {
      this.queryParams.page = pageNumber;
      this.getDictList();
    },
    //改变分页上一步下一步的文字链接
    itemRender(current, type, originalElement) {
      if (type === "prev") {
        return <a>上一页</a>;
      } else if (type === "next") {
        return <a class="next">下一页</a>;
      }
      return originalElement;
    },
  },
  created() {
    this.getDictList();
  },
};
</script>

<style scoped lang="less"></style>
