import request from '@/utils/request'

//获取区域列表（懒加载）
export function getRegionList(params) {
    return request.get('/api-system/region/lazy-tree', params)
}

//新增或修改区域
export function regionAddOrUpdate(params) {
    return request.post('/api-system/region/submit', params)
}

//获取区域详情
export function getRegion(params) {
    return request.get('/api-system/region/detail',params)
}

//删除区域
export function regionRemove(id) {
    return request.remove('/api-system/region/remove?id='+id)
}

//获取所有的区域列表
export function getAllRegionList(params) {
    return request.get('/api-system/region/tree-list', params)
}

//获取区域管理员的区域信息
export function getBindRegionList(params) {
    return request.get('/api-system/region/user-bind-region', params)
}