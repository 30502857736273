import request from '@/utils/request'

//获取定时任务列表
export function getScheduleJobList(params) {
    return request.get('/api-device/device/schedule-job/list', params)
}

//删除定时任务
export function scheduleJobRemove(id) {
    return request.remove('/api-device/device/schedule-job/delete/'+id)
}

//修改定时任务
export function scheduleJobUpdate(id) {
    return request.post('/api-device/device/schedule-job/pause-or-resume/'+id)
}

//获取定时任务详情
export function getScheduleJobDetail(id) {
    return request.get('/api-device/device/schedule-job/detail/'+id)
}