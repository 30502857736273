<template>
  <layout-content :total="total">
    <!-- 面包屑的插槽 -->
    <breadcrumb slot="breadcrumb"></breadcrumb>
    <div slot="content">
      <!-- 搜索和新增删除 -->
      <div class="d-f a-c j-b">
        <a-form-model ref="queryForm" :model="queryParams" layout="inline">
          <a-form-model-item label="用户名" prop="username">
            <a-input
              v-model="queryParams.username"
              placeholder="请输入用户名"
              allowClear
              @keyup.enter.native="handleQuery"
            />
          </a-form-model-item>
          <a-form-model-item label="角色">
            <a-select
              mode="multiple"
              v-model="roles"
              placeholder="请选择角色"
              style="width: 280px"
              :allowClear="true"
              :getPopupContainer="(triggerNode) => triggerNode.parentNode"
            >
              <a-select-option
                :value="item.roleAlias"
                v-for="item in rolesList"
                :key="item.id"
              >
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item>
            <a-button
              type="primary"
              @click="handleQuery"
              v-hasPermi="['teacherStudent:teacher:query']"
              >搜索</a-button
            >
            <a-button
              @click="resetQuery"
              class="ml10"
              v-hasPermi="['teacherStudent:teacher:query']"
              >重置</a-button
            >
          </a-form-model-item>
        </a-form-model>
        <div>
          <a-button
            type="primary"
            @click="handleAdd"
            icon="plus"
            v-hasPermi="['teacherStudent:teacher:add']"
            >新增</a-button
          >
          <a-dropdown>
            <a-menu slot="overlay" @click="handleMenuClick">
              <a-menu-item key="add" v-hasPermi="['teacherStudent:teacher:add']">
                <a-icon type="file-add" /> 批量导入
              </a-menu-item>
              <a-menu-item key="export" v-hasPermi="['teacherStudent:teacher:export']">
                <a-icon type="export" /> 批量导出
              </a-menu-item>
            </a-menu>
            <a-button class="ml10"><a-icon type="snippets" /> 导入导出 </a-button>
          </a-dropdown>
        </div>
      </div>
      <!--表格-->
      <a-table
        :columns="columns"
        rowKey="id"
        :data-source="data"
        :pagination="false"
        class="mt20"
      >
        <span slot="headImgUrl" slot-scope="text">
          <img
            :src="text"
            v-if="text"
            width="32px"
            height="32px"
            style="border-radius: 50%"
          />
          <img
            src="@/assets/images/enter_avatar.png"
            width="32px"
            v-else
            style="border-radius: 50%"
          />
        </span>
        <span slot="roles" slot-scope="text">
          <a-tag color="blue" v-for="item in text" :key="item.id">
            {{ item.name }}
          </a-tag>
        </span>
        <span slot="enabled" slot-scope="text">
          <a-tag :color="text ? '#009FE8' : '#f5222d'">
            {{ text | filterEnabled }}
          </a-tag>
        </span>
        <span slot="action" slot-scope="text, record">
          <a @click="handleUpdate(record)" v-hasPermi="['teacherStudent:teacher:edit']"
            >编辑</a
          >
          <a-divider type="vertical" />
          <a @click="handleDelete(record)" v-hasPermi="['teacherStudent:teacher:remove']"
            >删除</a
          >
        </span>
      </a-table>
      <!--分页-->
      <div class="d-f mt20">
        <a-pagination
          v-model="queryParams.page"
          :pageSize="queryParams.limit"
          :default-current="1"
          :total="total"
          :hideOnSinglePage="true"
          showLessItems
          @change="onChange"
          :item-render="itemRender"
          style="margin-left: auto"
        />
      </div>
      <!--新增和编辑的弹出框-->
      <a-modal
        v-model="visible"
        :title="title"
        @ok="submit"
        @cancel="handleCancel"
        width="700px"
      >
        <a-form-model
          ref="ruleForm"
          :model="form"
          :rules="rules"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
        >
          <a-row>
            <a-col :span="12">
              <a-form-model-item label="用户名" prop="username">
                <a-input v-model="form.username" placeholder="请输入用户名" />
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item label="密码" prop="password">
                <a-input
                  v-model="form.password"
                  placeholder="请输入密码"
                  type="password"
                />
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item label="手机号" prop="mobile">
                <a-input v-model="form.mobile" placeholder="请输入手机号" />
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item label="状态" prop="enabled">
                <a-radio-group v-model="form.enabled">
                  <a-radio :value="true"> 启用 </a-radio>
                  <a-radio :value="false"> 禁用 </a-radio>
                </a-radio-group>
              </a-form-model-item>
            </a-col>
            <a-col :span="24">
              <a-form-model-item
                label="角色"
                prop="roleIds"
                :label-col="{ span: 4, offset: 0 }"
              >
                <a-select
                  mode="multiple"
                  v-model="form.roleIds"
                  placeholder="请选择角色"
                  style="width: 100%"
                  :getPopupContainer="(triggerNode) => triggerNode.parentNode"
                >
                  <a-select-option
                    :value="item.id"
                    v-for="item in rolesList"
                    :key="item.id"
                  >
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item label="性别" prop="sex">
                <a-select
                  v-model="form.sex"
                  placeholder="请选择性别"
                  style="width: 100%"
                  :getPopupContainer="(triggerNode) => triggerNode.parentNode"
                  @change="
                    () => {
                      this.$forceUpdate();
                    }
                  "
                >
                  <a-select-option :value="0"> 未知 </a-select-option>
                  <a-select-option :value="1"> 男</a-select-option>
                  <a-select-option :value="2"> 女</a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item label="真实姓名" prop="realName">
                <a-input v-model="form.realName" placeholder="请输入真实姓名" />
              </a-form-model-item>
            </a-col>
            <a-col :span="24">
              <a-form-model-item
                label="头像"
                prop="headImgUrl"
                :label-col="{ span: 4, offset: 0 }"
              >
                <!-- 图片剪裁以及预览 -->
                <a-row>
                  <a-col :span="24" :style="{ height: '100px', width: '100px' }">
                    <vue-cropper
                      ref="cropper"
                      :img="options.headImgUrl"
                      :info="true"
                      :autoCrop="options.autoCrop"
                      :autoCropWidth="options.autoCropWidth"
                      :autoCropHeight="options.autoCropHeight"
                      :fixedBox="options.fixedBox"
                      @realTime="realTime"
                      outputType="png"
                    />
                    <div class="avatar-upload-preview" v-if="canShow">
                      <img :src="previews.url" :style="previews.img" />
                    </div>
                    <div class="avatar-upload-preview" v-else>
                      <img :src="form.headImgUrl" class="avatar" />
                    </div>
                  </a-col>
                </a-row>
                <br />
                <!-- 上传，上下左右旋转以及放大缩小 -->
                <a-row>
                  <a-col :lg="2" :md="2">
                    <a-upload
                      action="#"
                      :http-request="requestUpload"
                      :showUploadList="false"
                      :before-upload="beforeUpload"
                      accept="image/*"
                    >
                      <a-button>选择<a-icon type="upload" /></a-button>
                    </a-upload>
                  </a-col>
                  <a-col :lg="{ span: 1, offset: 7 }" :md="2">
                    <a-button icon="plus" @click="changeScale(1)"></a-button>
                  </a-col>
                  <a-col :lg="{ span: 1, offset: 3 }" :md="2">
                    <a-button icon="minus" @click="changeScale(-1)"></a-button>
                  </a-col>
                  <a-col :lg="{ span: 1, offset: 3 }" :md="2">
                    <a-button icon="undo" @click="rotateLeft()"></a-button>
                  </a-col>
                  <a-col :lg="{ span: 1, offset: 3 }" :md="2">
                    <a-button icon="redo" @click="rotateRight()"></a-button>
                  </a-col>
                </a-row>
              </a-form-model-item>
            </a-col>
          </a-row>
        </a-form-model>
      </a-modal>
      <!--批量导入的弹出框-->
      <a-modal
        v-model="largeAddVisible"
        title="批量导入"
        @ok="handleUpload"
        width="500px"
      >
        <a-spin :spinning="confirmLoading">
          <a-upload-dragger
            :file-list="fileList"
            :remove="handleRemove"
            @change="handleChange"
            :before-upload="beforeUploadFile"
            accept=".xlsx,.xls"
            :multiple="false"
          >
            <p class="ant-upload-drag-icon"><a-icon type="inbox" /></p>
            <p class="ant-upload-text">点击或将文件拖到这里上传</p>
            <p class="ant-upload-hint">支持扩展名： .xlsx .xls</p>
          </a-upload-dragger>
        </a-spin>

        请先下载模板后上传，点击此处<a @click="download">下载模板</a>
      </a-modal>
    </div>
  </layout-content>
</template>

<script>
import LayoutContent from "@/views/layout/content";
import Breadcrumb from "~c/Breadcrumb";
import {
  getTeacherList,
  getTeacher,
  teacherRemove,
  teacherAddOrUpdate,
  teacherDownloadTemplate,
  teacherExport,
  teacherImport,
} from "@/api/teacher-student/teacher.js";
import { getRoles } from "@/api/acl/role.js";
import { VueCropper } from "vue-cropper";
const columns = [
  {
    title: "头像",
    dataIndex: "headImgUrl",
    align: "center",
    scopedSlots: { customRender: "headImgUrl" },
  },
  {
    title: "用户名",
    dataIndex: "username",
    align: "center",
  },
  {
    title: "姓名",
    dataIndex: "realName",
    align: "center",
  },
  {
    title: "手机号",
    dataIndex: "mobile",
    align: "center",
  },
  {
    title: "角色",
    dataIndex: "roles",
    align: "center",
    scopedSlots: { customRender: "roles" },
  },
  {
    title: "状态",
    dataIndex: "enabled",
    align: "center",
    scopedSlots: { customRender: "enabled" },
  },
  {
    title: "操作",
    dataIndex: "action",
    // width: "25%",
    align: "center",
    scopedSlots: { customRender: "action" },
  },
];
export default {
  name: "Teacher",
  components: { LayoutContent, Breadcrumb, VueCropper },
  filters: {
    filterEnabled(val) {
      if (val) {
        return "启用";
      } else {
        return "禁用";
      }
    },
  },
  data() {
    return {
      total: null,
      //查询角色数组
      roles: [],
      // 查询参数
      queryParams: {
        //当前页
        page: 1,
        //一页几个
        limit: 10,
      },
      //表格的表头文字
      columns,
      //表格的数据
      data: [],
      //新增和编辑的表单
      form: {},
      //表单规则
      rules: {
        username: [
          {
            required: true,
            message: "请输入用户名",
            whitespace: true,
            trigger: ["change", "blur"],
          },
          {
            pattern: /^[A-Za-z][0-9A-Za-z]{2,12}$/,
            message: "用户名只能包括数字(不以数字开头)和字母且在3~12位之间",
            trigger: ["change", "blur"],
          },
        ],
        password: [
          {
            required: true,
            message: "请输入密码",
            whitespace: true,
            trigger: ["change", "blur"],
          },
          {
            pattern: /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,20}$/,
            message: "6-20位，字母和数字组合",
            trigger: ["change", "blur"],
          },
        ],
        mobile: [
          {
            required: true,
            message: "请输入手机号",
            whitespace: true,
            trigger: ["change", "blur"],
          },
          {
            pattern: /^[1][3,4,5,6,7,8,9][0-9]{9}$/,
            message: "手机号格式错误",
            trigger: ["change", "blur"],
          },
        ],
        enabled: [
          {
            type: "boolean",
            required: true,
            message: "请选择状态",
            whitespace: true,
            trigger: ["change", "blur"],
          },
        ],
        roleIds: [
          {
            type: "array",
            required: true,
            message: "请选择角色",
            whitespace: true,
            trigger: ["change", "blur"],
          },
        ],
      },
      //label和wrapper的占位
      labelCol: { span: 8 },
      wrapperCol: { span: 14 },
      //新增和编辑弹出框的标题
      title: "",
      //新增和编辑弹出框的显示隐藏
      visible: false,
      //角色列表
      rolesList: [],
      //图片裁剪
      options: {
        headImgUrl: "", //裁剪图片的地址
        autoCrop: true, // 是否默认生成截图框
        autoCropWidth: 64, // 默认生成截图框宽度
        autoCropHeight: 64, // 默认生成截图框高度
        fixedBox: true, // 固定截图框大小 不允许改变
      },
      //盒子图片预览
      previews: {},
      //盒子图片文件
      file: null,
      //是否展示预览图片
      canShow: true,
      //批量导入的显示隐藏
      largeAddVisible: false,
      //批量导入的loading
      confirmLoading: false,
      //文件列表
      fileList: [],
      //导入的文件
      excelFile: null,
    };
  },
  methods: {
    //获取角色列表
    async getRoles() {
      let currentRoles = [];
      this.$ls.get("userInfo").roles.forEach((r) => {
        currentRoles.push(r.roleAlias);
      });

      let roleAliasList = await this.getDictDataList(currentRoles.join(","));
      if (roleAliasList.data.length > 0) {
        let result = await getRoles({ roleAliases: roleAliasList.data[0].valueData });
        this.rolesList = result.data;
      } else {
        this.rolesList = [];
      }
    },
    //获取教师列表
    getTeacherList() {
      getTeacherList(this.queryParams).then((res) => {
        this.total = res.count;
        this.data = res.data;
      });
    },
    //搜索
    handleQuery() {
      this.queryParams.page = 1;
      if (this.roles.length != 0) {
        this.queryParams.roleAliases = this.roles.join(",");
      } else {
        this.queryParams.roleAliases = null;
      }
      this.getTeacherList();
    },
    //搜索重置
    resetQuery() {
      this.queryParams.page = 1;
      this.roles = [];
      this.queryParams.roleAliases = null;
      this.$refs.queryForm.resetFields();
      this.getTeacherList();
    },
    /** 新增按钮操作 */
    handleAdd() {
      this.form = {};
      this.form.sex = 0;
      this.form.enabled = true;
      this.options.headImgUrl = "";
      this.canShow = true;
      this.visible = true;
      this.title = "新增教师";
    },
    /** 修改按钮操作 */
    async handleUpdate(row) {
      let response = await getTeacher(row.id);
      this.form = response.data;
      Object.keys(this.form).forEach((key) => {
        if (this.form[key] === null) {
          delete this.form[key];
        }
      });
      this.options.headImgUrl = "";
      this.canShow = false;
      this.visible = true;
      this.title = "修改教师";
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      this.$confirm({
        title: "您确认删除您选中的数据吗？",
        onOk: () => {
          teacherRemove(row.id).then((res) => {
            this.$message.success("删除成功");
            this.getTeacherList();
          });
        },
      });
    },
    //新增或编辑确定
    submit() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          //修改
          if (this.form.id) {
            //没传图片
            if (!this.canShow) {
              let formdata = this.formdataify(this.form);
              teacherAddOrUpdate(formdata).then((res) => {
                this.$message.success("修改成功");
                this.visible = false;
                this.getTeacherList();
              });
            } else {
              this.$refs.cropper.getCropBlob((data) => {
                //修改命名
                const time = new Date().getTime();
                const randomName = time + "_" + this.file.name;
                // blob转file
                let file = new window.File([data], randomName, {
                  type: data.type,
                });
                this.form.file = file;
                let formdata = this.formdataify(this.form);
                teacherAddOrUpdate(formdata).then((res) => {
                  this.$message.success("修改成功");
                  this.visible = false;
                  this.getTeacherList();
                });
              });
            }
          }
          //上传
          else {
            //没传图片
            if (this.options.headImgUrl === "") {
              let formdata = this.formdataify(this.form);
              teacherAddOrUpdate(formdata).then((res) => {
                this.$message.success("新增成功");
                this.visible = false;
                this.getTeacherList();
              });
            } else {
              this.$refs.cropper.getCropBlob((data) => {
                const time = new Date().getTime();
                const randomName = time + "_" + this.file.name;
                // blob转file
                let file = new window.File([data], randomName, {
                  type: data.type,
                });
                this.form.file = file;
                let formdata = this.formdataify(this.form);
                teacherAddOrUpdate(formdata).then((res) => {
                  this.$message.success("新增成功");
                  this.visible = false;
                  this.getTeacherList();
                });
              });
            }
          }
        }
      });
    },
    //新增或编辑取消
    handleCancel() {
      //   this.$refs.ruleForm.resetFields();
      this.visible = false;
    },
    //分页状态改变
    onChange(pageNumber) {
      this.queryParams.page = pageNumber;
      this.getTeacherList();
    },
    //改变分页上一步下一步的文字链接
    itemRender(current, type, originalElement) {
      if (type === "prev") {
        return <a>上一页</a>;
      } else if (type === "next") {
        return <a class="next">下一页</a>;
      }
      return originalElement;
    },
    // 覆盖默认的上传行为
    requestUpload() {},
    // 向左旋转
    rotateLeft() {
      this.$refs.cropper.rotateLeft();
    },
    // 向右旋转
    rotateRight() {
      this.$refs.cropper.rotateRight();
    },
    // 图片缩放
    changeScale(num) {
      num = num || 1;
      this.$refs.cropper.changeScale(num);
    },
    // 上传预处理
    beforeUpload(file) {
      if (file.type.indexOf("image/") == -1) {
        this.$message.error("文件格式错误，请上传图片类型,如：JPG，PNG后缀的文件。");
      } else {
        //如果编辑的时侯上传头像
        if (this.canShow === false) {
          this.canShow = true;
        }
        this.file = file;
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          this.options.headImgUrl = reader.result;
        };
      }
    },
    // 实时预览
    realTime(data) {
      this.previews = data;
    },
    //下拉菜单
    handleMenuClick(e) {
      //批量导入
      if (e.key == "add") {
        this.largeAddVisible = true;
        this.confirmLoading = false;
        this.fileList = [];
      }
      //批量导出
      else {
        this.$confirm({
          title: "您确认导出教师数据吗？",
          onOk: () => {
            teacherExport({}, `teacher_export_${new Date().getTime()}.xlsx`);
          },
        });
      }
    },
    //下载模板
    download() {
      teacherDownloadTemplate({}, `teacher_${new Date().getTime()}.xlsx`);
    },
    //移除选择的文件
    handleRemove(file) {
      const index = this.fileList.indexOf(file);
      const newFileList = this.fileList.slice();
      newFileList.splice(index, 1);
      this.fileList = newFileList;
    },
    //文件状态改变
    handleChange(info) {
      this.$nextTick(() => {
        if (this.fileList.length > 0) {
          this.excelFile = info.file;
        } else {
          this.excelFile = null;
        }
      });
    },
    //上传后提交前的操作
    beforeUploadFile(file) {
      if (this.fileList.length > 0) {
        this.$message.error("您只能选择一个文件上传，请删除后继续上传");
        return false;
      } else {
        let fileExtension = file.name.substring(file.name.lastIndexOf(".") + 1);
        const isExcel = fileExtension === "xlsx" || fileExtension === "xls";
        if (!isExcel) {
          this.$message.error("文件格式错误");
        }
        const isLt50M = file.size / 1024 / 1024 < 50;
        if (!isLt50M) {
          this.$message.error("文件不能大于50M");
        }
        if (isLt50M && isExcel) {
          this.fileList = [...this.fileList, file];
        } else {
          this.fileList = [];
        }
        return false;
      }
    },
    //上传
    handleUpload() {
      if (this.fileList.length > 0) {
        this.confirmLoading = true;
        let params = {};
        params.file = this.excelFile;
        let formData = this.formdataify(params);
        teacherImport(formData).then((res) => {
          this.$message.success("上传成功");
          this.largeAddVisible = false;
          this.confirmLoading = false;
          this.getTeacherList();
        });
      } else {
        this.$message.error("请上传文件");
      }
    },
    //json对象转换为formData格式
    formdataify(params) {
      const formData = new FormData();
      Object.keys(params).forEach((key) => {
        formData.append(key, params[key]);
      });
      return formData;
    },
  },
  created() {
    this.getRoles();
    this.getTeacherList();
  },
};
</script>

<style scoped lang="less">
.avatar-upload-preview {
  position: absolute;
  top: 16px;
  left: 180px;
  width: 64px;
  height: 64px;
  box-shadow: 0 0 4px #ccc;
  overflow: hidden;
  border-radius: 50%;
  .avatar {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
::v-deep .ant-descriptions-item-content {
  text-align: center;
  width: 50%;
}
::v-deep .ant-descriptions-item-label {
  text-align: center;
}
</style>
