<template>
  <a-form-model ref="accountForm" :model="form" :rules="rules" class="account-form">
    <a-form-model-item prop="username">
      <a-input
        v-model="form.username"
        placeholder="用户名/手机号"
        @change="
          () => {
            this.$forceUpdate();
          }
        "
      >
        <img src="@/assets/images/login_username.png" width="16px" slot="prefix" />
      </a-input>
    </a-form-model-item>
    <a-form-model-item prop="password">
      <a-input-password
        v-model="form.password"
        placeholder="密码"
        type="password"
        @change="
          () => {
            this.$forceUpdate();
          }
        "
      >
        <img src="@/assets/images/login_password.png" width="16px" slot="prefix" />
      </a-input-password>
    </a-form-model-item>
    <a-row>
      <a-col :span="15">
        <a-form-model-item prop="validCode">
          <a-input v-model="form.validCode" placeholder="验证码">
            <img src="@/assets/images/login_key.png" width="16px" slot="prefix" />
          </a-input>
        </a-form-model-item>
      </a-col>
      <a-col :span="2"></a-col>
      <a-col :span="7">
        <img :src="src" class="code-img" @click="changeCode" />
      </a-col>
    </a-row>
    <a-form-model-item>
      <div class="d-f j-b">
        <a-checkbox
          v-model="form.haschecked"
          @change="
            () => {
              this.$forceUpdate();
            }
          "
        >
          记住密码
        </a-checkbox>
        <router-link to="/forget">忘记密码</router-link>
      </div>
    </a-form-model-item>
    <a-form-model-item>
      <a-button type="primary" @click="submit"> 登录 </a-button>
    </a-form-model-item>
    <a-form-model-item>
      <div class="d-f j-b">
        <span>
          其他登录方式
          <img
            src="@/assets/images/login_wx.png"
            width="24px"
            class="wx"
            @click="wxLogin"
          />
          <!-- <img
            src="@/assets/images/login_dd.png"
            width="24px"
            class="wx"
            @click="ddLogin"
          /> -->
        </span>
        <router-link to="/register">注册账户</router-link>
      </div>
    </a-form-model-item>
  </a-form-model>
</template>

<script>
import { login } from "@/api/login.js";
import { getUserInfo } from "@/api/acl/user.js";
import { getCookie, setCookie } from "@/utils/cookie.js";
export default {
  name: "Account",
  data() {
    return {
      src: "",
      form: {},
      rules: {
        username: [
          {
            required: true,
            message: "请输入用户名",
            whitespace: true,
            trigger: ["change", "blur"],
          },
        ],
        password: [
          {
            required: true,
            message: "请输入密码",
            whitespace: true,
            trigger: ["change", "blur"],
          },
        ],
        validCode: [
          {
            required: true,
            message: "请输入验证码",
            whitespace: true,
            trigger: ["change", "blur"],
          },
          {
            pattern: /^[A-Za-z0-9]{4}$/,
            message: "验证码格式错误",
            trigger: "blur",
          },
        ],
      },
    };
  },
  computed: {
    codeUrl() {
      return this.$store.state.codeUrl;
    },
  },
  watch: {
    codeUrl(n, o) {
      this.src = n;
    },
  },
  methods: {
    //登录
    submit() {
      this.$refs.accountForm.validate((valid) => {
        if (valid) {
          this.form.grant_type = "password_code";
          this.form.deviceId = this.$store.state.uuid;
          login(this.form).then((res) => {
            let { access_token, token_type, refresh_token, scope } = res.data;
            setCookie("access_token", access_token, 7);
            setCookie("token_type", token_type, 7);
            setCookie("refresh_token", refresh_token, 7);
            setCookie("scope", scope, 7);
            this.$ls.set("access_token", access_token, 7 * 24 * 60 * 60 * 1000); //有效7*24小时
            this.$ls.set("token_type", token_type, 7 * 24 * 60 * 60 * 1000); //有效7*24小时
            this.$ls.set("refresh_token", refresh_token, 7 * 24 * 60 * 60 * 1000); //有效7*24小时
            this.$ls.set("scope", scope, 7 * 24 * 60 * 60 * 1000); //有效7*24小时
            getUserInfo().then((result) => {
              this.$message.success("登录成功");
              this.$ls.set("userInfo", result.data, 7 * 24 * 60 * 60 * 1000); //有效7*24小时
              //登录请求
              if (this.form.haschecked) {
                setCookie("username", this.form.username, 7);
                setCookie("password", this.form.password, 7);
              } else {
                setCookie("username", this.form.username, 7);
                setCookie("password", "");
              }
              this.$router.push("/enter");
            });
          });
        }
      });
    },
    //微信扫码登录
    wxLogin() {
      this.$router.push("/wx-login");
    },
    //钉钉扫码登录
    ddLogin() {
      this.$router.push("/dd-login");
    },
    //点击图片更换验证码
    changeCode() {
      this.$store.commit("setCodeUrl");
      this.src = this.$store.state.codeUrl;
    },
  },
  created() {
    // 在页面加载时从cookie获取登录信息
    let username = getCookie("username");
    let password = getCookie("password");
    // 如果存在赋值给表单，并且将记住密码勾选
    if (username) {
      this.form.username = username;
      if (password) {
        this.form.password = password;
        this.form.haschecked = true;
      }
    }
    this.$store.commit("setCodeUrl");
    this.src = this.$store.state.codeUrl;
  },
};
</script>

<style scoped lang="less">
.account-form {
  width: 100%;
  margin-top: 45px;
  ::v-deep .ant-input {
    border: 1px solid transparent;
    border-bottom: 1px solid #e8eaee;
  }
  ::v-deep .ant-input:hover {
    border-color: transparent;
    border-bottom: 1px solid #e8eaee;
    box-shadow: none;
  }
  ::v-deep .ant-input:focus {
    border-color: transparent;
    border-bottom: 1px solid #e8eaee;
    box-shadow: none;
  }
  ::v-deep .ant-input-password:hover {
    border-color: transparent;
    box-shadow: none;
  }
  ::v-deep .ant-input-affix-wrapper:hover {
    .ant-input:not(.ant-input-disabled) {
      border-color: transparent;
      border-bottom: 1px solid #e8eaee;
      box-shadow: none;
    }
  }
  ::v-deep .has-error {
    .ant-input {
      border-color: transparent;
      border-bottom: 1px solid #f5222d;
      box-shadow: none;
    }
    .ant-input:focus {
      border-color: transparent;
      border-bottom: 1px solid #f5222d;
      box-shadow: none;
    }
    .ant-input:hover {
      border-color: transparent;
      border-bottom: 1px solid #f5222d;
      box-shadow: none;
    }
    .ant-input-password:hover {
      border-color: transparent;
      box-shadow: none;
    }
  }
  ::v-deep .ant-input-affix-wrapper {
    .ant-input:not(:first-child) {
      padding-left: 40px;
    }
  }
  .code-img {
    border: 1px solid #e8eaee;
  }
  button {
    width: 100%;
    height: 36px;
    border-radius: 18px;
  }
  .wx {
    margin-left: 18px;
  }
}
</style>
