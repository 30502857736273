<template>
  <layout-content :total="total">
    <!-- 面包屑的插槽 -->
    <breadcrumb slot="breadcrumb"></breadcrumb>
    <div slot="content">
      <!-- 搜索和新增删除 -->
      <div class="d-f a-c j-b">
        <a-form-model ref="queryForm" :model="queryParams" layout="inline">
          <a-form-model-item label="角色名称" prop="searchValue">
            <a-input
              v-model="queryParams.searchValue"
              placeholder="请输入角色名称"
              allowClear
              @keyup.enter.native="handleQuery"
            />
          </a-form-model-item>
          <a-form-model-item>
            <a-button type="primary" @click="handleQuery" v-hasPermi="['acl:role:query']"
              >搜索</a-button
            >
            <a-button @click="resetQuery" class="ml10" v-hasPermi="['acl:role:query']"
              >重置</a-button
            >
          </a-form-model-item>
        </a-form-model>
        <div>
          <a-button
            type="primary"
            @click="handleAdd"
            icon="plus"
            v-hasPermi="['acl:role:add']"
            >新增</a-button
          >
          <a-button
            @click="handleDelete"
            icon="delete"
            class="ml10"
            :disabled="multiple"
            v-hasPermi="['acl:role:remove']"
          >
            批量删除
          </a-button>
        </div>
      </div>
      <!--表格-->
      <a-table
        :columns="columns"
        rowKey="id"
        :data-source="data"
        :pagination="false"
        :row-selection="{
          selectedRowKeys: selectedRowKeys,
          onChange: onSelectChange,
        }"
        class="mt20"
      >
        <span slot="action" slot-scope="text, record">
          <a @click="handleUpdate(record)" v-hasPermi="['acl:role:edit']">编辑</a>
          <a-divider type="vertical" />
          <a @click="handleDelete(record)" v-hasPermi="['acl:role:remove']">删除</a>
          <a-divider type="vertical" />
          <a @click="handleAllot(record)" v-hasPermi="['acl:role:edit']">分配权限</a>
        </span>
      </a-table>
      <!--分页-->
      <div class="d-f mt20">
        <a-pagination
          v-model="queryParams.page"
          :pageSize="queryParams.limit"
          :default-current="1"
          :total="total"
          :hideOnSinglePage="true"
          showLessItems
          @change="onChange"
          :item-render="itemRender"
          style="margin-left: auto"
        />
      </div>
      <!--新增和编辑的弹出框-->
      <a-modal
        v-model="visible"
        :title="title"
        @ok="submit"
        @cancel="handleCancel"
        width="700px"
      >
        <a-form-model
          ref="ruleForm"
          :model="form"
          :rules="rules"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
        >
          <a-row>
            <a-col :span="12">
              <a-form-model-item label="角色名称" prop="name">
                <a-input v-model="form.name" placeholder="请输入角色名称" />
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item label="显示排序" prop="orderNum">
                <a-input-number
                  v-model="form.orderNum"
                  placeholder="请输入显示排序"
                  :min="1"
                  style="width: 100%"
                />
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item label="权限值" prop="code">
                <a-input-number
                  v-model="form.code"
                  placeholder="请输入权限值"
                  :min="1"
                  style="width: 100%"
                />
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item label="权限字符" prop="roleAlias">
                <a-input v-model="form.roleAlias" placeholder="请输入权限字符" />
              </a-form-model-item>
            </a-col>
          </a-row>
        </a-form-model>
      </a-modal>
      <!--分配权限的弹出框-->
      <a-modal v-model="allotVisible" title="分配权限" @ok="allotOk">
        <a-tree
          v-model="checkedKeys"
          checkable
          :replace-fields="{
            children: 'children',
            key: 'id',
            title: 'menuName',
          }"
          :tree-data="treeData"
          @check="onCheck"
        >
        </a-tree>
      </a-modal>
    </div>
  </layout-content>
</template>

<script>
import LayoutContent from "@/views/layout/content";
import Breadcrumb from "~c/Breadcrumb";
import {
  getRoleList,
  roleAddOrUpdate,
  roleRemove,
  roleRemoveBatch,
  getRolePermission,
  allotRolePermission,
} from "@/api/acl/role.js";
import { getChildrenMenu } from "@/api/acl/menu.js";
const columns = [
  {
    title: "角色名称",
    dataIndex: "name",
    align: "center",
  },
  {
    title: "显示排序",
    dataIndex: "orderNum",
    align: "center",
  },
  {
    title: "权限值",
    dataIndex: "code",
    align: "center",
  },
  {
    title: "权限字符",
    dataIndex: "roleAlias",
    align: "center",
  },
  {
    title: "操作",
    dataIndex: "action",
    // width: "25%",
    align: "center",
    scopedSlots: { customRender: "action" },
  },
];
export default {
  name: "Role",
  components: { LayoutContent, Breadcrumb },
  data() {
    return {
      total: null,
      // 查询参数
      queryParams: {
        //当前页
        page: 1,
        //一页几个
        limit: 10,
        searchKey: "name",
        searchValue: "",
      },
      //表格的表头文字
      columns,
      //表格的数据
      data: [],
      //多选的数据
      selectedRowKeys: [],
      // 非多个禁用
      multiple: true,
      //新增和编辑的表单
      form: {},
      //表单规则
      rules: {
        name: [
          {
            required: true,
            message: "请输入角色名称",
            whitespace: true,
            trigger: ["change", "blur"],
          },
          {
            min: 0,
            max: 12,
            message: "角色名称不能超过12个汉字",
            trigger: ["change", "blur"],
          },
        ],
        orderNum: [
          {
            type: "number",
            required: true,
            message: "请输入显示排序",
            whitespace: true,
            trigger: ["change", "blur"],
          },
        ],
        code: [
          {
            type: "number",
            required: true,
            message: "请输入权限值",
            whitespace: true,
            trigger: ["change", "blur"],
          },
        ],
        roleAlias: [
          {
            required: true,
            message: "请输入权限字符",
            whitespace: true,
            trigger: ["change", "blur"],
          },
        ],
      },
      //label和wrapper的占位
      labelCol: { span: 8 },
      wrapperCol: { span: 14 },
      //新增和编辑弹出框的标题
      title: "",
      //新增和编辑弹出框的显示隐藏
      visible: false,
      //分配权限弹出框的显示隐藏
      allotVisible: false,
      //分配权限所有的菜单数组
      treeData: [],
      //分配权限的选中的菜单数组(只有子节点)
      checkedKeys: [],
      //分配权限的选中的父Id菜单数组
      parentIdList: [],
      //分配权限的角色id
      roleId: null,
      //分配权限的权限字符
      roleAlias: "",
      //判断分配权限有没有勾选过复选框的标识
      flag: false,
      //分配权限选中的原始数据
      originalList: [],
      //分配权限所有的菜单数组的子节点
      childrenList: [],
    };
  },
  methods: {
    //获取角色列表
    getRoleList() {
      getRoleList(this.queryParams).then((res) => {
        this.data = res.data;
        this.total = res.count;
        this.multiple = !this.selectedRowKeys.length;
      });
    },
    //搜索
    handleQuery() {
      this.queryParams.page = 1;
      this.getRoleList();
    },
    //搜索重置
    resetQuery() {
      this.queryParams.page = 1;
      this.$refs.queryForm.resetFields();
      this.getRoleList();
    },
    //复选框状态改变
    onSelectChange(selectedRowKeys, records) {
      // console.log(selectedRowKeys);
      // console.log(records);
      this.selectedRowKeys = selectedRowKeys;
      this.multiple = !selectedRowKeys.length;
    },
    /** 新增按钮操作 */
    handleAdd() {
      this.form = {};
      this.visible = true;
      this.title = "新增角色";
    },
    /** 修改按钮操作 */
    handleUpdate(row) {
      this.form = JSON.parse(JSON.stringify(row));
      this.visible = true;
      this.title = "修改角色";
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      this.$confirm({
        title: "您确认删除您选中的数据吗？",
        onOk: () => {
          //单个删除
          if (row.id) {
            roleRemove(row.id).then((res) => {
              this.$message.success("删除成功");
              this.getRoleList();
            });
          }
          //批量删除
          else {
            roleRemoveBatch(this.selectedRowKeys).then((res) => {
              this.$message.success("删除成功");
              this.selectedRowKeys = [];
              this.getRoleList();
            });
          }
        },
      });
    },
    /** 分配权限按钮操作 */
    handleAllot(row) {
      this.flag = false;
      this.parentIdList = [];
      this.childrenList = [];
      this.roleId = row.id;
      this.roleAlias = row.roleAlias;
      getChildrenMenu({ parentId: "0" }).then((res) => {
        getRolePermission(row.id).then((result) => {
          //选中的数组（包括子节点和父节点,也是需要传给后端的格式）
          this.originalList = result.data;
          let arr = JSON.parse(JSON.stringify(result.data));
          this.treeData = JSON.parse(JSON.stringify(res.data));
          //过滤出只有子节点的数组
          let arr1 = this.getTreeChildren(res.data);
          let checkedKeys = [];
          arr.forEach((r) => {
            arr1.forEach((rr) => {
              if (r == rr) {
                checkedKeys.push(r);
              }
            });
          });
          this.checkedKeys = checkedKeys;
          this.allotVisible = true;
        });
      });
    },
    //新增或编辑确定
    submit() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          roleAddOrUpdate(this.form).then((res) => {
            //修改
            if (this.form.id) {
              this.$message.success("修改成功");
            }
            //新增
            else {
              this.$message.success("新增成功");
            }
            this.visible = false;
            this.getRoleList();
          });
        }
      });
    },
    //新增或编辑取消
    handleCancel() {
      this.$refs.ruleForm.resetFields();
      this.visible = false;
    },
    //分配权限确定
    allotOk() {
      let arr = [];
      if (this.flag) {
        arr = this.checkedKeys.concat(this.parentIdList);
      } else {
        arr = this.originalList;
      }
      allotRolePermission({
        roleId: this.roleId,
        menuIds: arr,
        roleAlias: this.roleAlias,
      }).then((res) => {
        this.$message.success("分配成功");
        this.allotVisible = false;
        this.getRoleList();
      });
    },
    //分页状态改变
    onChange(pageNumber) {
      this.queryParams.page = pageNumber;
      this.getRoleList();
    },
    //改变分页上一步下一步的文字链接
    itemRender(current, type, originalElement) {
      if (type === "prev") {
        return <a>上一页</a>;
      } else if (type === "next") {
        return <a class="next">下一页</a>;
      }
      return originalElement;
    },
    //选择树形控件复选框时触发
    onCheck(checkedKeys, e) {
      this.flag = true;
      this.parentIdList = e.halfCheckedKeys;
    },
    //递归数组，得到所有菜单的子节点
    getTreeChildren(data) {
      data.map((item) => {
        if (item.children && item.children.length > 0) {
          this.getTreeChildren(item.children);
        } else {
          this.childrenList.push(item.id);
        }
      });
      return this.childrenList;
    },
  },
  created() {
    this.getRoleList();
  },
};
</script>

<style scoped lang="less"></style>
