<template>
  <div class="layout-content d-f f-d">
    <breadcrumb></breadcrumb>
    <div class="list-container">
      <a-layout>
        <a-layout-header>
          <a-menu
            theme="light"
            mode="horizontal"
            :defaultSelectedKeys="defaultSelectedKeys"
          >
            <a-menu-item
              :key="item.absPath"
              v-for="item in menuList"
              @click="selectMenu(item)"
              >{{ item.name }}</a-menu-item
            >
          </a-menu>
          <div class="size-container d-f a-c">
            <div>{{ usedSizeList[0] }}/{{ diskSizeList[0] }}GB</div>
            <div ref="size" class="size"></div>
          </div>
        </a-layout-header>
        <a-layout-content>
          <!-- 上传新增批量删除 -->
          <div class="back d-f a-c j-b">
            <div>
              <a @click="back" v-if="!isRoot">返回上一级</a>
            </div>
            <div class="mt20">
              <a-button type="primary" icon="upload" @click="uploadVisible = true"
                >上传</a-button
              >
              <a-button icon="plus" class="ml10" @click="addVisible = true">
                新建
              </a-button>
              <a-button icon="delete" class="ml10" :disabled="multiple" @click="delBatch">
                批量删除
              </a-button>
              <a-button icon="reload" class="ml10" @click="refresh"> 刷新 </a-button>
            </div>
          </div>
          <!-- 目录 -->
          <div class="catalogue mt10">{{ catalogue }}</div>
          <!-- 表格 -->
          <div class="table mt10">
            <a-table
              :columns="columns"
              rowKey="absPath"
              :data-source="diskData"
              :pagination="false"
              :row-selection="{
                selectedRowKeys: selectedRowKeys,
                onChange: onSelectChange,
              }"
            >
              <span slot="name" slot-scope="text, record">
                <img
                  width="24px"
                  src="@/assets/images/document_dir.png"
                  v-if="record.type === 'dir'"
                />
                <img
                  width="24px"
                  src="@/assets/images/document_image.png"
                  v-else-if="record.type === 'image'"
                />
                <img
                  width="24px"
                  src="@/assets/images/document_audio.png"
                  v-else-if="record.type === 'audio'"
                />
                <img
                  width="24px"
                  src="@/assets/images/document_video.png"
                  v-else-if="record.type === 'video'"
                />
                <img
                  width="24px"
                  src="@/assets/images/document_courseware.png"
                  v-else-if="record.type === 'courseware'"
                />
                <img
                  width="24px"
                  src="@/assets/images/document_file.png"
                  v-else-if="
                    record.type === 'excel' ||
                    record.type === 'doc' ||
                    record.type === 'ppt' ||
                    record.type === 'pdf'
                  "
                />
                <img width="24px" src="@/assets/images/document_other.png" v-else />
                <span :title="text" class="ml10 c-p" @click="toNext(record)">{{
                  text
                }}</span>
              </span>
              <span slot="size" slot-scope="text">
                <span>{{ text | handleSize }}</span>
              </span>
              <span slot="action" slot-scope="text, record">
                <a-dropdown
                  placement="bottomLeft"
                  :getPopupContainer="(triggerNode) => triggerNode.parentNode"
                >
                  <a class="ant-dropdown-link" @click="(e) => e.preventDefault()">
                    <a-icon type="ellipsis" style="font-size: 24px" />
                  </a>
                  <a-menu slot="overlay">
                    <a-menu-item v-if="record.type != 'dir'">
                      <a href="javascript:;" @click="download(record)">下载</a>
                    </a-menu-item>
                    <a-menu-item v-if="record.type != 'dir'">
                      <a href="javascript:;" @click="push(record)">推送</a>
                    </a-menu-item>
                    <a-menu-item v-if="record.type != 'dir'">
                      <a href="javascript:;" @click="move(record)">移动到</a>
                    </a-menu-item>
                    <a-menu-item v-if="record.type != 'dir'">
                      <a href="javascript:;" @click="rename(record)">重命名</a>
                    </a-menu-item>
                    <a-menu-item>
                      <a href="javascript:;" class="file-del" @click="del(record)"
                        >删除</a
                      >
                    </a-menu-item>
                  </a-menu>
                </a-dropdown>
              </span>
            </a-table>
          </div>
          <div class="mb20 d-f j-c">共{{ diskTotal }}项</div>

          <!--上传文件的弹出框-->
          <a-modal
            v-model="uploadVisible"
            title="上传文件"
            @ok="uploadOk"
            @cancel="uploadCancel"
            width="500px"
          >
            <a-spin :spinning="confirmLoading">
              <a-upload-dragger
                :file-list="fileList"
                :remove="handleRemove"
                :before-upload="beforeUpload"
              >
                <p class="ant-upload-drag-icon">
                  <a-icon type="inbox" />
                </p>
                <p class="ant-upload-text">
                  点击或将文件拖到这里上传(多个文件只上传第一个)
                </p>
              </a-upload-dragger>
            </a-spin>
          </a-modal>

          <!--新建的弹出框-->
          <a-modal
            v-model="addVisible"
            title="新建文件夹"
            @ok="addOk"
            @cancel="addCancel"
            width="500px"
          >
            <a-form-model
              ref="addForm"
              :model="addForm"
              :rules="addRules"
              :label-col="labelCol"
              :wrapper-col="wrapperCol"
            >
              <a-form-model-item label="文件夹名称" prop="filePath">
                <a-input v-model="addForm.filePath" />
              </a-form-model-item>
            </a-form-model>
          </a-modal>

          <!--重命名的弹出框-->
          <a-modal v-model="renameVisible" title="重命名" width="500px" @ok="renameOk">
            <a-form-model
              ref="renameForm"
              :model="renameForm"
              :rules="renameRules"
              :label-col="labelCol"
              :wrapper-col="wrapperCol"
            >
              <a-form-model-item prop="newPath" label="重命名名称">
                <a-input v-model="renameForm.newPath" />
              </a-form-model-item>
            </a-form-model>
            <!-- <template slot="footer">
              <a-button type="primary" @click="renameOk"> 确定 </a-button>
            </template> -->
          </a-modal>

          <!--移动到的弹出框-->
          <a-modal v-model="moveVisible" title="移动文件" @ok="moveOk" width="500px">
            <a-tree
              show-icon
              :replace-fields="{
                children: 'child',
                key: 'path',
                title: 'name',
              }"
              :tree-data="treeData"
              @select="onSelect"
              :selectedKeys="moveForm.newPath"
            >
              <a-icon slot="switcherIcon" type="down" />
              <img
                src="@/assets/images/document_dir.png"
                slot="folder"
                style="margin-right: 5px"
                width="16px"
              />
            </a-tree>
          </a-modal>

          <!--推送的弹出框-->
          <a-modal
            v-model="pushVisible"
            title="推送文件"
            :footer="null"
            @cancel="pushCancel"
            width="700px"
          >
            <div class="d-f mt20">
              <a-button
                type="primary"
                @click="pushFile"
                style="margin-left: auto"
                :disabled="pushMultiple"
                >批量推送</a-button
              >
            </div>
            <a-table
              size="small"
              :columns="pushColumns"
              rowKey="serialNum"
              :data-source="pushData"
              :pagination="false"
              class="mt20"
              :rowSelection="{
                selectedRowKeys: pushSelectedRowKeys,
                onChange: pushOnSelectChange,
                getCheckboxProps,
              }"
            >
              <span slot="device" slot-scope="text, record">
                {{ record.buildingName + "-" + record.classroomName }}
              </span>
              <span slot="onlineFlag" slot-scope="text">
                {{ text ? "在线" : "离线" }}
              </span>
              <span slot="action" slot-scope="text, record">
                <a @click="pushFile(record)" v-if="record.onlineFlag">推送文件</a>
                <span v-else>——</span>
              </span>
            </a-table>
            <!--分页-->
            <div class="d-f mt20">
              <a-pagination
                v-model="pushQueryParams.page"
                :pageSize="pushQueryParams.limit"
                :default-current="1"
                :total="pushTotal"
                :hideOnSinglePage="true"
                showLessItems
                @change="pushOnChange"
                :item-render="itemRender"
                style="margin-left: auto"
              />
            </div>
          </a-modal>
        </a-layout-content>
      </a-layout>
    </div>
  </div>
</template>

<script>
let echarts = require("echarts");
import Client from "@/utils/oss.js";
import Breadcrumb from "~c/Breadcrumb";
import {
  isOwnDisk,
  addOwnDisk,
  getCloudDiskList,
  getCloudDiskUsed,
  getStsInfo,
  addDir,
  deleteFile,
  getDownloadUrl,
  renameOrMoveFile,
  getDirStruct,
  deleteBatchFile,
} from "@/api/cloud-disk/document.js";
import { getTerminalList } from "@/api/device/terminal.js";
import { v4 as uuidv4 } from "uuid";
import actionType from "@/utils/action-type";
import { websocketSend } from "@/utils/websocket-send.js";
const columns = [
  {
    title: "名称",
    dataIndex: "name",
    width: "40%",
    align: "left",
    scopedSlots: { customRender: "name" },
    ellipsis: true,
  },
  {
    title: "文件大小",
    dataIndex: "size",
    width: "20%",
    align: "left",
    scopedSlots: { customRender: "size" },
  },
  {
    title: "修改日期",
    dataIndex: "modifiedTime",
    width: "20%",
    align: "left",
  },
  {
    title: "操作",
    dataIndex: "action",
    width: "20%",
    align: "left",
    scopedSlots: { customRender: "action" },
  },
];
const pushColumns = [
  {
    title: "设备",
    dataIndex: "device",
    align: "center",
    scopedSlots: { customRender: "device" },
  },
  {
    title: "设备状态",
    dataIndex: "onlineFlag",
    align: "center",
    scopedSlots: { customRender: "onlineFlag" },
  },
  {
    title: "操作",
    dataIndex: "action",
    align: "center",
    scopedSlots: { customRender: "action" },
  },
];
export default {
  name: "Document",
  components: { Breadcrumb },
  filters: {
    handleSize(val) {
      if (val != null) {
        let KB = val / 1024;
        let KBInt = Math.floor(KB);
        if (KBInt > 1024) {
          let MB = KB / 1024;
          let MBInt = Math.floor(MB);
          if (MBInt > 1024) {
            let GB = MB / 1024;
            return GB.toFixed(2) + "G";
          } else {
            return MB.toFixed(2) + "M";
          }
        } else {
          return KB.toFixed(2) + "KB";
        }
      }
    },
  },
  watch: {
    usedSizeList: {
      handler(val, oldVal) {
        this.usedSizeList = val;
        setTimeout(() => {
          this.sizeCharts();
        });
      },
      immediate: false,
    },
  },
  data() {
    return {
      //echarts实例
      chartsDom: null,
      //默认选中菜单
      defaultSelectedKeys: [],
      //菜单数组
      menuList: [],
      //使用网盘数据数组
      usedSizeList: [],
      //网盘大学数据数组
      diskSizeList: [],
      //是否是根目录
      isRoot: true,
      //根路径字符串切割数组的长度
      rootLength: 0,
      // 非多个禁用
      multiple: true,
      //目录
      catalogue: "",
      //表格数据总数
      diskTotal: 0,
      //表头
      columns,
      //表格数据
      diskData: [],
      //复选框数据
      selectedRowKeys: [],
      //文件路径
      absPath: "",
      //上传文件弹出框的显示隐藏
      uploadVisible: false,
      //oss的参数
      dataObj: null,
      //上传文件等待中
      confirmLoading: false,
      //待上传的文件列表
      fileList: [],
      labelCol: { span: 5 },
      wrapperCol: { span: 19 },
      //新建文件夹弹出框的显示隐藏
      addVisible: false,
      //新建文件夹表单
      addForm: {},
      //新建文件夹表单规则
      addRules: {
        filePath: [
          {
            required: true,
            message: "请输入文件夹名称",
            whitespace: true,
            trigger: ["change", "blur"],
          },
          {
            min: 0,
            max: 50,
            message: "文件夹名称不能超过50个汉字",
            trigger: ["change", "blur"],
          },
          {
            validator: (rule, value, callbackFn) => {
              if (
                value.includes("\\") ||
                value.includes("/") ||
                value.includes(":") ||
                value.includes("*") ||
                value.includes("?") ||
                value.includes('"') ||
                value.includes("<") ||
                value.includes(">") ||
                value.includes("|")
              ) {
                callbackFn("文件夹名称不能包括下列任何字符:\\/:*?<>|");
                return;
              }
              callbackFn();
            },
            trigger: ["change", "blur"],
          },
        ],
      },
      //重命名弹出框显示隐藏
      renameVisible: false,
      //重命名表单
      renameForm: {
        oldPath: "",
        newPath: "",
      },
      //重命名表单规则
      renameRules: {
        newPath: [
          {
            required: true,
            message: "请输入文件名称",
            whitespace: true,
            trigger: ["change", "blur"],
          },
          {
            min: 0,
            max: 50,
            message: "文件名称不能超过50个汉字",
            trigger: ["change", "blur"],
          },
          {
            validator: (rule, value, callbackFn) => {
              if (
                value.includes("\\") ||
                value.includes("/") ||
                value.includes(":") ||
                value.includes("*") ||
                value.includes("?") ||
                value.includes('"') ||
                value.includes("<") ||
                value.includes(">") ||
                value.includes("|")
              ) {
                callbackFn("文件夹名称不能包括下列任何字符:\\/:*?<>|");
                return;
              }
              callbackFn();
            },
            trigger: ["change", "blur"],
          },
        ],
      },
      //移动到弹出框的显示隐藏
      moveVisible: false,
      //移动到的表单
      moveForm: {
        oldPath: "",
        newPath: [],
      },
      //移动到的树状文件夹数据
      treeData: [],
      //推送弹出框的显示隐藏
      pushVisible: false,
      //推送表头
      pushColumns,
      //设备列表
      deviceData: [],
      //推送列表
      pushData: [],
      //推送查询数据
      pushQueryParams: {
        page: 1,
        limit: 5,
      },
      //推送列表的总数
      pushTotal: 0,
      websocket: null,
      //心跳定时器
      timer: null,
      //查询状态定时器
      timer1: null,
      uuid: null,
      //非多个禁用
      pushMultiple: true,
      //复选框数据
      pushSelectedRowKeys: [],
      //推送文件信息
      fileQueryParams: {},
    };
  },
  methods: {
    //判断用户是否开通网盘
    isOwnDisk() {
      let userId = this.$ls.get("userInfo").id;
      isOwnDisk({ userId }).then((res) => {
        if (res.code === -4321) {
          this.$info({
            title: res.msg,
            okText: "确认",
            onOk: () => {
              this.addOwnDisk();
            },
          });
        } else {
          this.getCloudDiskList();
          this.getCloudDiskUsed();
        }
      });
    },
    //新增网盘
    addOwnDisk() {
      addOwnDisk().then((res) => {
        this.$message.success("创建成功");
        this.getCloudDiskUsed();
        this.getCloudDiskList();
      });
    },
    //获取网盘使用情况
    getCloudDiskUsed() {
      getCloudDiskUsed().then((res) => {
        let usedSize = res.data.totalUsedSize;
        let diskSizeList = res.data.diskSize;
        this.usedSizeList = [];
        this.diskSizeList = [];
        this.usedSizeList.push(usedSize);
        this.diskSizeList.push(diskSizeList);
        setTimeout(() => {
          this.sizeCharts();
        });
      });
    },
    //获取菜单列表
    getCloudDiskList() {
      getCloudDiskList().then((res) => {
        this.rootLength = res.data[0].absPath.split("/").length;
        this.menuList = res.data;
        this.defaultSelectedKeys.push(res.data[0].absPath);
        this.catalogue = res.data[0].name;
        this.absPath = res.data[0].absPath;
        this.getFileList(res.data[0].absPath);
      });
    },
    //选择菜单
    selectMenu(item) {
      this.absPath = item.absPath;
      this.catalogue = item.name;
      this.getFileList(item.absPath);
      this.isRoot = true;
    },
    //获取文件下级目录
    getFileList(absPath) {
      getCloudDiskList({ path: absPath }).then((res) => {
        this.diskData = res.data;
        this.diskTotal = res.data.length;
        this.multiple = !this.selectedRowKeys.length;
      });
    },
    //点击文件进入下一级
    toNext(record) {
      if (record.type == "dir") {
        this.isRoot = false;
        this.absPath = record.absPath;
        this.catalogue += ">" + record.name;
        this.getFileList(record.absPath);
      }
    },
    //文件大小的图表格
    sizeCharts() {
      if (
        //判断是否存在echarts实例化对象,如果存在则销毁
        this.chartsDom != null &&
        this.chartsDom != "" &&
        this.chartsDom != undefined
      ) {
        this.chartsDom.dispose();
      }
      let chartDom = this.$refs.size;
      this.chartsDom = echarts.init(chartDom); //创建echarts实例化对象
      this.chartsDom.clear(); //清空画布数据

      let option = {
        grid: {
          top: "center",
        },
        xAxis: {
          show: false,
          type: "value",
        },
        yAxis: {
          show: false,
          type: "category",
        },
        series: [
          {
            type: "bar",
            data: this.usedSizeList,
            barWidth: 5,
            tooltip: { show: false },
            emphasis: {
              itemStyle: {
                color: "rgba(165, 192, 204, 1)",
              },
            },
            z: 10,
            itemStyle: {
              color: "rgba(165, 192, 204, 1)", // 柱状图颜色
              borderRadius: [20],
              legendHoverLink: false,
              label: {
                show: false, // 显示文本
              },
            },
          },
          {
            type: "bar",
            data: this.diskSizeList,
            tooltip: { show: false },
            emphasis: {
              itemStyle: {
                color: "rgba(222, 225, 228, 1)",
              },
            },
            barWidth: 5,
            barGap: "-100%", // 两个柱子之间的距离，如果要重叠设置为-100%
            itemStyle: {
              borderRadius: [20],
              color: "rgba(222, 225, 228, 1)", // 柱子颜色，作为底层背景
              label: {
                show: false,
              },
            },
          },
        ],
      };

      this.chartsDom.setOption(option);
    },
    //返回上一级
    back() {
      let absPath = this.absPath;
      let str = absPath.substr(0, absPath.length - 1);
      let index = absPath.substr(0, absPath.length - 1).lastIndexOf("/");
      this.absPath = str.substr(0, index + 1);
      let catalogue = this.catalogue;
      let i = catalogue.lastIndexOf(">");
      this.catalogue = catalogue.substr(0, i);
      let length = this.absPath.split("/").length;
      if (length == this.rootLength || length < this.rootLength) {
        this.isRoot = true;
      }
      this.getFileList(this.absPath);
    },
    //刷新
    refresh() {
      getCloudDiskList({ path: this.absPath }).then((res) => {
        this.diskData = res.data;
        this.diskTotal = res.data.length;
        this.multiple = !this.selectedRowKeys.length;
        this.$message.success("刷新成功");
      });
    },
    //复选框状态改变
    onSelectChange(selectedRowKeys, records) {
      this.selectedRowKeys = selectedRowKeys;
      this.multiple = !selectedRowKeys.length;
    },
    //批量删除
    delBatch() {
      deleteBatchFile(this.selectedRowKeys).then((res) => {
        this.$message.success("删除成功");
        this.getFileList(this.absPath);
        this.getCloudDiskUsed();
        this.selectedRowKeys = [];
      });
    },
    //移除选择的文件
    handleRemove(file) {
      const index = this.fileList.indexOf(file);
      const newFileList = this.fileList.slice();
      newFileList.splice(index, 1);
      this.fileList = newFileList;
    },
    //上传后提交前的操作
    beforeUpload(action) {
      this.fileList = [...this.fileList, action];
      return false;
    },
    //上传文件确定
    async uploadOk() {
      let file = this.fileList[0];
      if (this.fileList.length === 0) {
        this.$message.error("您上传的文件列表为空，请选择文件上传");
      } else {
        if (file.name.includes("/")) {
          this.$message.error('文件名包含特殊字符"/"');
        } else {
          let size = file.size;
          let usedSize = this.usedSizeList[0];
          let diskSize = this.diskSizeList[0];
          let a = size + usedSize * 1024 * 1024 * 1024;
          let b = diskSize * 1024 * 1024 * 1024;
          let flag = a < b;
          if (!flag) {
            this.$message.warning("网盘内存过满，请删除一些文件后上传");
            this.uploadVisible = false;
            this.fileList = [];
          } else {
            this.confirmLoading = true;
            //获取Token
            let res = await getStsInfo();
            const {
              region,
              bucket,
              accessKeyId,
              accessKeySecret,
              securityToken,
            } = res.data;
            this.dataObj = {
              region,
              bucket,
              accessKeyId,
              accessKeySecret,
              stsToken: securityToken,
            };
            //修改命名
            var fileExtension = file.name;
            const randomName = this.absPath + fileExtension;
            Client(this.dataObj)
              .multipartUpload(randomName, file, {
                progress: (p) => {
                  //获取进度条的值
                  // this.percent = Math.floor(p * 100);
                },
              })
              .then(async (result) => {
                if (result.res.statusCode === 200) {
                  this.$message.success("上传成功");
                  this.fileList = [];
                  this.confirmLoading = false;
                  this.uploadVisible = false;
                  this.getFileList(this.absPath);
                  this.getCloudDiskUsed();
                }
              })
              .catch((error) => {
                console.log(error);
                this.$message.error(error);
              });
          }
        }
      }
    },
    //上传文件取消
    uploadCancel() {
      this.fileList = [];
      this.confirmLoading = false;
    },
    //新建文件夹确定
    addOk() {
      this.$refs.addForm.validate((valid) => {
        if (valid) {
          let filePath = this.absPath + this.addForm.filePath + "/";
          addDir({ filePath }).then((res) => {
            this.$message.success("新建成功");
            this.addVisible = false;
            this.addForm.filePath = "";
            this.getFileList(this.absPath);
          });
        }
      });
    },
    //新建文件夹取消
    addCancel() {
      this.addForm.filePath = "";
    },
    //下载
    download(record) {
      getDownloadUrl({ path: record.absPath }).then((res) => {
        const x = new XMLHttpRequest();
        x.open("GET", res.data.downloadUrl, true);
        x.responseType = "blob";
        x.onload = function (e) {
          const url = window.URL.createObjectURL(x.response);
          const a = document.createElement("a");
          a.href = url;
          a.target = "_blank";
          a.download = res.data.filename;
          a.click();
          a.remove();
        };
        x.send();
        // let link = document.createElement("a");
        // link.style.display = "none";
        // link.href = res.data.downloadUrl;
        // link.setAttribute("download", res.data.filename); // 动态设置原文件名
        // document.body.appendChild(link);
        // link.click();
        // document.body.removeChild(link);
      });
    },
    //重命名
    rename(record) {
      this.renameVisible = true;
      this.renameForm.newPath = record.name;
      this.renameForm.oldPath = record.absPath;
    },
    //重命名确定
    renameOk() {
      this.$refs.renameForm.validate((valid) => {
        if (valid) {
          let oldPath = this.renameForm.oldPath;
          let index = oldPath.lastIndexOf("/");
          let newPath = oldPath.substr(0, index + 1) + this.renameForm.newPath;
          renameOrMoveFile({
            oldPath: oldPath,
            newPath: newPath,
          }).then((res) => {
            this.$message.success("重命名成功");
            this.renameVisible = false;
            this.getFileList(this.absPath);
          });
        }
      });
    },
    //移动到
    move(record) {
      this.getDirStruct();
      this.moveForm.oldPath = record.absPath;
    },
    //得到所有的文件夹列表
    getDirStruct() {
      getDirStruct().then((res) => {
        this.moveVisible = true;
        this.treeData = res.data;
        this.setIcon(this.treeData);
      });
    },
    //前端给treedata赋slots值
    setIcon(datas) {
      let that = this;
      for (let i = 0; i < datas.length; i++) {
        datas[i].slots = { icon: "folder" };
        if (datas[i].child != null && datas[i].child.length > 0) {
          that.setIcon(datas[i].child);
        }
      }
    },
    //选择树形控件name时触发
    onSelect(selectedKeys, info) {
      this.moveForm.newPath = selectedKeys;
    },
    //移动到确定
    moveOk() {
      if (this.moveForm.newPath.length === 0) {
        this.$message.error("请选择移动到的目标文件夹");
      } else {
        let oldPath = this.moveForm.oldPath;
        let index = oldPath.lastIndexOf("/");
        let newPath = this.moveForm.newPath.join("") + oldPath.substr(index + 1);
        if (this.moveForm.oldPath === newPath) {
          this.$message.error("您选择的是当前文件夹");
        } else {
          renameOrMoveFile({
            oldPath: this.moveForm.oldPath,
            newPath: newPath,
          }).then((res) => {
            this.$message.success("移动成功");
            this.moveVisible = false;
            this.getFileList(this.absPath);
            this.moveForm.newPath = [];
          });
        }
      }
    },
    //删除
    del(record) {
      this.$confirm({
        title: "您确认删除吗？",
        onOk: () => {
          deleteFile({ path: record.absPath }).then((res) => {
            this.$message.success("删除成功");
            this.getFileList(this.absPath);
            this.getCloudDiskUsed();
          });
        },
      });
    },
    //推送
    push(record) {
      this.fileQueryParams.filePath = record.absPath;
      this.fileQueryParams.fileName = record.name;
      this.fileQueryParams.autoOpenFlag = "0";
      this.pushSelectedRowKeys = [];
      this.pushMultiple = !this.pushSelectedRowKeys.length;
      this.deviceData = [];
      this.pushTotal = null;
      this.pushData = [];
      this.pushQueryParams.page = 1;
      getTerminalList(this.pushQueryParams).then((res) => {
        this.deviceData = res.data;
        this.pushTotal = res.count;
        this.initWebSocket();
        this.pushVisible = true;
      });
    },
    //推送复选框改变
    pushOnSelectChange(selectedRowKeys) {
      this.pushSelectedRowKeys = selectedRowKeys;
      this.pushMultiple = !this.pushSelectedRowKeys.length;
    },
    //推送复选框不能选择
    getCheckboxProps(record) {
      return { props: { disabled: record.onlineFlag === false } };
    },
    //推送取消
    pushCancel() {
      let close = websocketSend(this.uuid, actionType.close);
      if (this.websocket == null) {
        this.$message.error("连接已断开，请刷新页面");
        this.websocketclose();
      } else {
        this.websocket.send(JSON.stringify(close));
      }
    },
    //推送文件
    pushFile(record) {
      this.$confirm({
        title: "您确认推送文件吗？",
        onOk: () => {
          //单个推送
          if (record.serialNum) {
            if (record.onlineFlag) {
              let receiverIds = [];
              receiverIds.push(record.serialNum);
              let fileSend = websocketSend(
                this.uuid,
                actionType.fileSend,
                this.fileQueryParams,
                receiverIds
              );
              if (this.websocket == null) {
                this.$message.error("连接已断开，请刷新页面");
                this.websocketclose();
              } else {
                this.websocket.send(JSON.stringify(fileSend));
              }
            } else {
              this.$message.error("设备已离线，不能重发消息");
            }
          } else {
            if (this.pushSelectedRowKeys.length == 0) {
              this.$message.error("请选择推送的设备");
            } else {
              let fileSend = websocketSend(
                this.uuid,
                actionType.fileSend,
                this.fileQueryParams,
                this.pushSelectedRowKeys
              );
              if (this.websocket == null) {
                this.$message.error("连接已断开，请刷新页面");
                this.websocketclose();
              } else {
                this.websocket.send(JSON.stringify(fileSend));
              }
            }
          }
        },
      });
    },
    //推送列表分页数据改变
    pushOnChange(pageNumber) {
      this.pushQueryParams.page = pageNumber;
      clearInterval(this.timer1);
      this.timer1 = null;
      getTerminalList(this.pushQueryParams).then((res) => {
        this.deviceData = res.data;
        this.pushTotal = res.count;
        this.pushMultiple = !this.pushSelectedRowKeys.length;
        this.websocketQueryList();
      });
    },
    //改变分页上一步下一步的文字链接
    itemRender(current, type, originalElement) {
      if (type === "prev") {
        return <a>上一页</a>;
      } else if (type === "next") {
        return <a class="next">下一页</a>;
      }
      return originalElement;
    },
    //webscoket 初始化
    initWebSocket() {
      const wsurl = process.env.VUE_APP_WSURL; //ws 相当于http 而wss 相当于https
      this.websocket = new WebSocket(wsurl); //实例对象
      console.log("websocket建立连接");
      this.websocket.onmessage = this.websocketonmessage;
      this.websocket.onopen = this.websocketonopen;
      this.websocket.onerror = this.websocketonerror;
      this.websocket.onclose = this.websocketclose;
    },
    //连接建立
    websocketonopen() {
      console.log("前端连接建立成功");
      this.uuid = uuidv4().replace(/-/g, "");
      let open = websocketSend(this.uuid, actionType.open);
      if (this.websocket == null) {
        this.$message.error("连接已断开，请刷新页面");
        this.websocketclose();
      } else {
        this.websocket.send(JSON.stringify(open));
      }
    },
    //发送心跳
    websocketHeart() {
      console.log("发送心跳");
      let heart = websocketSend(this.uuid, actionType.heart);
      if (this.websocket == null) {
        this.$message.error("连接已断开，请刷新页面");
        this.websocketclose();
      } else {
        this.websocket.send(JSON.stringify(heart));
        this.timer = setInterval(() => {
          if (this.websocket == null) {
            this.$message.error("连接已断开，请刷新页面");
            this.websocketclose();
          } else {
            this.websocket.send(JSON.stringify(heart));
          }
        }, 10 * 1000);
      }
    },
    //查询设备列表信息
    websocketQueryList() {
      console.log("请求查询设备列表信息");
      let queryTerminal = websocketSend(
        this.uuid,
        actionType.queryTerminal,
        this.deviceData
      );
      if (this.websocket == null) {
        this.$message.error("连接已断开，请刷新页面");
        this.websocketclose();
      } else {
        this.websocket.send(JSON.stringify(queryTerminal));
        this.timer1 = setInterval(() => {
          if (this.websocket == null) {
            this.$message.error("连接已断开，请刷新页面");
            this.websocketclose();
          } else {
            this.websocket.send(JSON.stringify(queryTerminal));
          }
        }, 5 * 1000);
      }
    },
    //数据接收
    websocketonmessage(e) {
      let data = JSON.parse(e.data);
      console.log("接收后端返回数据", data);
      //连接成功
      if (data.code === 8000) {
        this.websocketHeart();
        this.websocketQueryList();
      } else if (data.code === 8001) {
        this.pushData = data.data;
      }
      //网盘推送消息
      else if (data.code === 8014) {
        this.$message.success(data.msg);
      } else if (data.code < 0) {
        this.$message.error(data.msg);
      }
    },
    //连接建立失败重连
    websocketonerror() {
      clearInterval(this.timer);
      clearInterval(this.timer1);
      this.timer = null;
      this.timer1 = null;
      this.websocket = null;
      this.initWebSocket();
    },
    //连接关闭
    websocketclose(e) {
      console.log("websocket断开连接");
      clearInterval(this.timer);
      clearInterval(this.timer1);
      this.timer = null;
      this.timer1 = null;
      this.websocket = null;
      this.pushVisible = false;
    },
  },
  mounted() {
    this.isOwnDisk();
  },
  destroyed() {
    this.websocketclose();
  },
};
</script>

<style scoped lang="less">
.layout-content {
  width: 100%;
  height: 100%;
  .list-container {
    flex: 1;
    padding: 24px;
    .ant-layout {
      width: 100%;
      height: 100%;
      background: #ffffff;
      .ant-layout-header {
        height: 70px;
        line-height: 70px;
        padding: 0;
        background-color: #ffffff;
        position: relative;
        .ant-menu {
          height: 100%;
          display: flex;
          align-items: center;
          font-size: 16px;
          font-weight: bold;
        }
        .ant-menu-horizontal {
          line-height: 69px;
          padding: 0 24px;
        }
        .size-container {
          position: absolute;
          right: 24px;
          top: 0;
          font-family: "PingFang SC";
          font-weight: bold;
          color: #999999;
          font-size: 12px;
          .size {
            width: 200px;
            height: 20px;
          }
        }
      }
      .back {
        padding: 0 24px;
      }
      .catalogue {
        width: 100%;
        padding: 0 24px;
        color: #999999;
        white-space: normal;
        word-wrap: break-word;
      }
      .table {
        width: 100%;
        padding: 0 24px;
      }
    }
  }
}
</style>
