<template>
  <layout-content :total="total">
    <!-- 面包屑的插槽 -->
    <breadcrumb slot="breadcrumb"></breadcrumb>
    <div slot="content">
      <!-- 搜索和新增删除 -->
      <div class="d-f a-c j-b">
        <a-form-model ref="queryForm" :model="queryParams" layout="inline">
          <a-form-model-item label="租户名称" prop="tenantName">
            <a-input
              v-model="queryParams.tenantName"
              placeholder="请输入租户名称"
              allowClear
              @keyup.enter.native="handleQuery"
            />
          </a-form-model-item>
          <a-form-model-item label="联系人" prop="linkman">
            <a-input
              v-model="queryParams.linkman"
              placeholder="请输入联系人"
              allowClear
              @keyup.enter.native="handleQuery"
              @change="
                () => {
                  this.$forceUpdate();
                }
              "
            />
          </a-form-model-item>
          <a-form-model-item>
            <a-button
              type="primary"
              @click="handleQuery"
              v-hasPermi="['acl:tenant:query']"
              >搜索</a-button
            >
            <a-button @click="resetQuery" class="ml10" v-hasPermi="['acl:tenant:query']"
              >重置</a-button
            >
          </a-form-model-item>
        </a-form-model>
        <div>
          <a-button
            type="primary"
            @click="handleAdd"
            icon="plus"
            v-hasPermi="['acl:tenant:add']"
            >新增</a-button
          >
          <a-button
            @click="handleDelete"
            icon="delete"
            class="ml10"
            :disabled="multiple"
            v-hasPermi="['acl:tenant:remove']"
          >
            批量删除
          </a-button>
        </div>
      </div>
      <!--表格-->
      <a-table
        :columns="columns"
        rowKey="id"
        :data-source="data"
        :pagination="false"
        :row-selection="{
          selectedRowKeys: selectedRowKeys,
          onChange: onSelectChange,
        }"
        class="mt20"
      >
        <span slot="logo" slot-scope="text">
          <img :src="text" v-if="text" width="32px" style="border-radius: 8px" />
          <img
            src="@/assets/images/layout_logo.png"
            v-else
            width="32px"
            style="border-radius: 8px"
          />
        </span>
        <span slot="status" slot-scope="text">
          <a-tag :color="text === 0 ? '#009FE8' : '#f5222d'">
            {{ text | filterStatus }}
          </a-tag>
        </span>
        <span slot="action" slot-scope="text, record">
          <a @click="handleUpdate(record)" v-hasPermi="['acl:tenant:edit']">编辑</a>
          <a-divider type="vertical" />
          <a @click="handleDelete(record)" v-hasPermi="['acl:tenant:remove']">删除</a>
        </span>
      </a-table>
      <!--分页-->
      <div class="d-f mt20">
        <a-pagination
          v-model="queryParams.page"
          :pageSize="queryParams.limit"
          :default-current="1"
          :total="total"
          :hideOnSinglePage="true"
          showLessItems
          @change="onChange"
          :item-render="itemRender"
          style="margin-left: auto"
        />
      </div>
      <!--新增和编辑的弹出框-->
      <a-modal
        v-model="visible"
        :title="title"
        @ok="submit"
        @cancel="handleCancel"
        width="700px"
      >
        <a-form-model
          ref="ruleForm"
          :model="form"
          :rules="rules"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
        >
          <a-row>
            <a-col :span="12">
              <a-form-model-item label="租户标识" prop="tenantId">
                <a-input v-model="form.tenantId" placeholder="请输入租户标识" />
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item label="租户名称" prop="tenantName">
                <a-input v-model="form.tenantName" placeholder="请输入租户名称" />
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item label="联系人" prop="linkman">
                <a-input v-model="form.linkman" placeholder="请输入联系人" />
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item label="联系电话" prop="contactNumber">
                <a-input v-model="form.contactNumber" placeholder="请输入联系电话" />
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item label="联系地址" prop="address">
                <a-input v-model="form.address" placeholder="请输入联系地址" />
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item label="状态" prop="status">
                <a-radio-group v-model="form.status">
                  <a-radio :value="0"> 启用 </a-radio>
                  <a-radio :value="1"> 禁用 </a-radio>
                </a-radio-group>
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item label="到期时间" prop="time">
                <a-date-picker
                  v-model="form.time"
                  @change="dataPicker"
                  show-time
                  placeholder="请选择到期时间"
                  style="width: 100%"
                />
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item label="域名" prop="domain">
                <a-input v-model="form.domain" placeholder="请输入域名地址" />
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item label="小程序id" prop="miniAppId">
                <a-input v-model="form.miniAppId" placeholder="请输入微信小程序id" />
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item label="小程序secret" prop="miniAppSecret">
                <a-input
                  v-model="form.miniAppSecret"
                  placeholder="请输入微信小程序secret"
                />
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item label="微信扫码地址" prop="wxScanUrl">
                <a-input v-model="form.wxScanUrl" placeholder="请输入微信扫码地址" />
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item label="短信签名" prop="smsSignName">
                <a-input v-model="form.smsSignName" placeholder="请输入短信签名" />
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item label="短信签名code" prop="smsTemplateCode">
                <a-input
                  v-model="form.smsTemplateCode"
                  placeholder="请输入短信签名code"
                />
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item label="短信AKId" prop="aliAccessKeyId">
                <a-input
                  v-model="form.aliAccessKeyId"
                  placeholder="请输入aliAccessKeyId"
                />
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item label="短信AKSecret" prop="aliAccessKeySecret">
                <a-input
                  v-model="form.aliAccessKeySecret"
                  placeholder="请输入aliAccessKeySecret"
                />
              </a-form-model-item>
            </a-col>
            <a-col :span="24">
              <a-form-model-item
                label="logo"
                prop="logo"
                :label-col="{ span: 4, offset: 0 }"
              >
                <!-- 图片剪裁以及预览 -->
                <a-row>
                  <a-col :span="24" :style="{ height: '100px', width: '100px' }">
                    <vue-cropper
                      ref="cropper"
                      :img="options.logo"
                      :info="true"
                      :autoCrop="options.autoCrop"
                      :autoCropWidth="options.autoCropWidth"
                      :autoCropHeight="options.autoCropHeight"
                      :fixedBox="options.fixedBox"
                      @realTime="realTime"
                      outputType="png"
                    />
                    <div class="avatar-upload-preview" v-if="canShow">
                      <img :src="previews.url" :style="previews.img" />
                    </div>
                    <div class="avatar-upload-preview" v-else>
                      <img :src="form.logo" class="avatar" />
                    </div>
                  </a-col>
                </a-row>
                <br />
                <!-- 上传，上下左右旋转以及放大缩小 -->
                <a-row>
                  <a-col :lg="2" :md="2">
                    <a-upload
                      action="#"
                      :http-request="requestUpload"
                      :showUploadList="false"
                      :before-upload="beforeUpload"
                      accept="image/*"
                    >
                      <a-button>选择<a-icon type="upload" /></a-button>
                    </a-upload>
                  </a-col>
                  <a-col :lg="{ span: 1, offset: 7 }" :md="2">
                    <a-button icon="plus" @click="changeScale(1)"></a-button>
                  </a-col>
                  <a-col :lg="{ span: 1, offset: 3 }" :md="2">
                    <a-button icon="minus" @click="changeScale(-1)"></a-button>
                  </a-col>
                  <a-col :lg="{ span: 1, offset: 3 }" :md="2">
                    <a-button icon="undo" @click="rotateLeft()"></a-button>
                  </a-col>
                  <a-col :lg="{ span: 1, offset: 3 }" :md="2">
                    <a-button icon="redo" @click="rotateRight()"></a-button>
                  </a-col>
                </a-row>
              </a-form-model-item>
            </a-col>
          </a-row>
        </a-form-model>
      </a-modal>
    </div>
  </layout-content>
</template>

<script>
import LayoutContent from "@/views/layout/content";
import Breadcrumb from "~c/Breadcrumb";
import {
  getTenantList,
  tenantAddOrUpdate,
  getTenant,
  tenantRemove,
} from "@/api/acl/tenant.js";
import moment from "moment";
import { VueCropper } from "vue-cropper";
const columns = [
  {
    title: "logo",
    dataIndex: "logo",
    align: "center",
    scopedSlots: { customRender: "logo" },
  },
  {
    title: "租户标识",
    dataIndex: "tenantId",
    align: "center",
  },
  {
    title: "租户名称",
    dataIndex: "tenantName",
    align: "center",
  },
  {
    title: "联系人",
    dataIndex: "linkman",
    align: "center",
  },
  {
    title: "联系电话",
    dataIndex: "contactNumber",
    align: "center",
  },
  {
    title: "状态",
    dataIndex: "status",
    align: "center",
    scopedSlots: { customRender: "status" },
  },
  {
    title: "到期时间",
    dataIndex: "expireTime",
    align: "center",
  },
  {
    title: "操作",
    dataIndex: "action",
    // width: "25%",
    align: "center",
    scopedSlots: { customRender: "action" },
  },
];
export default {
  name: "Tenant",
  components: { LayoutContent, Breadcrumb, VueCropper },
  filters: {
    filterStatus(val) {
      return ["启用", "禁用"][val];
    },
  },
  data() {
    return {
      total: null,
      // 查询参数
      queryParams: {
        //当前页
        page: 1,
        //一页几个
        limit: 10,
      },
      //表格的表头文字
      columns,
      //表格的数据
      data: [],
      //多选的数据
      selectedRowKeys: [],
      // 非多个禁用
      multiple: true,
      //新增和编辑的表单
      form: {},
      //表单规则
      rules: {
        tenantId: [
          {
            required: true,
            message: "请输入租户标识",
            whitespace: true,
            trigger: ["change", "blur"],
          },
          {
            min: 0,
            max: 20,
            message: "租户标识不能超过20个汉字",
            trigger: ["change", "blur"],
          },
        ],
        tenantName: [
          {
            required: true,
            message: "请输入租户名称",
            whitespace: true,
            trigger: ["change", "blur"],
          },
        ],
        linkman: [
          {
            required: true,
            message: "请输入联系人",
            whitespace: true,
            trigger: ["change", "blur"],
          },
        ],
        contactNumber: [
          {
            required: true,
            message: "请输入联系电话",
            whitespace: true,
            trigger: ["change", "blur"],
          },
        ],
        status: [
          {
            type: "number",
            required: true,
            message: "请选择状态",
            whitespace: true,
            trigger: ["change", "blur"],
          },
        ],
        time: [
          {
            required: true,
            message: "请选择到期时间",
            trigger: ["change", "blur"],
          },
        ],
        domain: [
          {
            required: true,
            message: "请输入域名",
            whitespace: true,
            trigger: ["change", "blur"],
          },
        ],
        miniAppId: [
          {
            required: true,
            message: "请输入微信小程序id",
            whitespace: true,
            trigger: ["change", "blur"],
          },
        ],
        miniAppSecret: [
          {
            required: true,
            message: "请输入微信小程序secret",
            whitespace: true,
            trigger: ["change", "blur"],
          },
        ],
        wxScanUrl: [
          {
            required: true,
            message: "请输入微信扫码地址",
            whitespace: true,
            trigger: ["change", "blur"],
          },
        ],
        smsSignName: [
          {
            required: true,
            message: "请输入短信签名",
            whitespace: true,
            trigger: ["change", "blur"],
          },
        ],
        smsTemplateCode: [
          {
            required: true,
            message: "请输入短信签名code",
            whitespace: true,
            trigger: ["change", "blur"],
          },
        ],
        aliAccessKeyId: [
          {
            required: true,
            message: "请输入aliAccessKeyId",
            whitespace: true,
            trigger: ["change", "blur"],
          },
        ],
        aliAccessKeySecret: [
          {
            required: true,
            message: "请输入aliAccessKeySecret",
            whitespace: true,
            trigger: ["change", "blur"],
          },
        ],
      },
      //label和wrapper的占位
      labelCol: { span: 8 },
      wrapperCol: { span: 14 },
      //新增和编辑弹出框的标题
      title: "",
      //新增和编辑弹出框的显示隐藏
      visible: false,
      //盒子图片裁剪
      options: {
        logo: "", //裁剪图片的地址
        autoCrop: true, // 是否默认生成截图框
        autoCropWidth: 32, // 默认生成截图框宽度
        autoCropHeight: 32, // 默认生成截图框高度
        fixedBox: true, // 固定截图框大小 不允许改变
      },
      //盒子图片预览
      previews: {},
      //盒子图片文件
      file: null,
      //是否展示预览图片
      canShow: true,
    };
  },
  methods: {
    //获取租户列表
    getTenantList() {
      getTenantList(this.queryParams).then((res) => {
        this.total = res.count;
        this.data = res.data;
        this.multiple = !this.selectedRowKeys.length;
      });
    },
    //搜索
    handleQuery() {
      this.getTenantList();
    },
    //搜索重置
    resetQuery() {
      this.$refs.queryForm.resetFields();
      this.getTenantList();
    },
    //复选框状态改变
    onSelectChange(selectedRowKeys, records) {
      // console.log(selectedRowKeys);
      // console.log(records);
      this.selectedRowKeys = selectedRowKeys;
      this.multiple = !selectedRowKeys.length;
    },
    //日期选择框变化
    dataPicker(date, dateString) {
      // console.log(date, dateString);
      this.form.expireTime = dateString;
    },
    /** 新增按钮操作 */
    handleAdd() {
      this.form = {};
      this.options.logo = "";
      this.canShow = true;
      this.visible = true;
      this.title = "新增租户";
    },
    /** 修改按钮操作 */
    handleUpdate(row) {
      getTenant(row.id).then((res) => {
        this.form = res.data;
        Object.keys(this.form).forEach((key) => {
          if (this.form[key] === null) {
            delete this.form[key];
          }
        });
        this.options.logo = "";
        this.canShow = false;
        this.visible = true;
        this.title = "修改租户";
        let time = new Date(JSON.parse(JSON.stringify(this.form.expireTime)));
        // this.form.time = moment(moment(time).format("YYYY-MM-DD HH:mm:ss"));
        this.$set(this.form, "time", moment(time));
      });
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      this.$confirm({
        title: "您确认删除您选中的数据吗？",
        onOk: () => {
          //单个删除
          if (row.id) {
            let arr = [];
            arr.push(row.id);
            tenantRemove(arr).then((res) => {
              this.$message.success("删除成功");
              this.getTenantList();
            });
          }
          //批量删除
          else {
            tenantRemove(this.selectedRowKeys).then((res) => {
              this.$message.success("删除成功");
              this.selectedRowKeys = [];
              this.getTenantList();
            });
          }
        },
      });
    },
    //新增或编辑确定
    submit() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          let params = JSON.parse(JSON.stringify({ ...this.form }));
          delete params.time;
          //修改
          if (this.form.id) {
            //没传图片
            if (!this.canShow) {
              let formdata = this.formdataify(params);
              tenantAddOrUpdate(formdata).then((res) => {
                this.$message.success("修改成功");
                this.visible = false;
                this.getTenantList();
              });
            } else {
              this.$refs.cropper.getCropBlob((data) => {
                //修改命名
                const time = new Date().getTime();
                const randomName = time + "_" + this.file.name;
                // blob转file
                let file = new window.File([data], randomName, {
                  type: data.type,
                });
                params.file = file;
                let formdata = this.formdataify(params);
                tenantAddOrUpdate(formdata).then((res) => {
                  this.$message.success("修改成功");
                  this.visible = false;
                  this.getTenantList();
                });
              });
            }
          }
          //上传
          else {
            //没有上传盒子图片
            if (this.options.logo === "") {
              let formdata = this.formdataify(params);
              tenantAddOrUpdate(formdata).then((res) => {
                this.$message.success("新增成功");
                this.visible = false;
                this.getTenantList();
              });
            } else {
              this.$refs.cropper.getCropBlob((data) => {
                const time = new Date().getTime();
                const randomName = time + "_" + this.file.name;
                // blob转file
                let file = new window.File([data], randomName, {
                  type: data.type,
                });
                params.file = file;
                let formdata = this.formdataify(params);
                tenantAddOrUpdate(formdata).then((res) => {
                  this.$message.success("新增成功");
                  this.visible = false;
                  this.getTenantList();
                });
              });
            }
          }
        }
      });
    },
    //新增或编辑取消
    handleCancel() {
      this.$refs.ruleForm.resetFields();
      this.visible = false;
    },
    //分页状态改变
    onChange(pageNumber) {
      this.queryParams.page = pageNumber;
      this.getTenantList();
    },
    //改变分页上一步下一步的文字链接
    itemRender(current, type, originalElement) {
      if (type === "prev") {
        return <a>上一页</a>;
      } else if (type === "next") {
        return <a class="next">下一页</a>;
      }
      return originalElement;
    },
    // 覆盖默认的上传行为
    requestUpload() {},
    // 向左旋转
    rotateLeft() {
      this.$refs.cropper.rotateLeft();
    },
    // 向右旋转
    rotateRight() {
      this.$refs.cropper.rotateRight();
    },
    // 图片缩放
    changeScale(num) {
      num = num || 1;
      this.$refs.cropper.changeScale(num);
    },
    // 上传预处理
    beforeUpload(file) {
      if (file.type.indexOf("image/") == -1) {
        this.$message.error("文件格式错误，请上传图片类型,如：JPG，PNG后缀的文件。");
      } else {
        //如果编辑的时侯上传头像
        if (this.canShow === false) {
          this.canShow = true;
        }
        this.file = file;
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          this.options.logo = reader.result;
        };
      }
    },
    // 实时预览
    realTime(data) {
      this.previews = data;
    },
    //json对象转换为formData格式
    formdataify(params) {
      const formData = new FormData();
      Object.keys(params).forEach((key) => {
        formData.append(key, params[key]);
      });
      return formData;
    },
  },
  created() {
    this.getTenantList();
  },
};
</script>

<style scoped lang="less">
.avatar-upload-preview {
  position: absolute;
  top: 0px;
  left: 110px;
  width: 32px;
  height: 32px;
  box-shadow: 0 0 4px #ccc;
  overflow: hidden;
  .avatar {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
::v-deep .ant-calendar-picker-input {
  cursor: default;
}
</style>
