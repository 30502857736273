import { render, staticRenderFns } from "./uninstallRecord.vue?vue&type=template&id=3c219785&scoped=true&"
import script from "./uninstallRecord.vue?vue&type=script&lang=js&"
export * from "./uninstallRecord.vue?vue&type=script&lang=js&"
import style0 from "./uninstallRecord.vue?vue&type=style&index=0&id=3c219785&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3c219785",
  null
  
)

export default component.exports