import request from '@/utils/request'

//获取楼栋列表
export function getBuildingList(params) {
    return request.get('/api-school/building', params)
}

//新增或修改楼栋
export function buildingAddOrUpdate(params) {
    return request.post('/api-school/building/saveOrUpdate', params)
}

//获取楼栋详情
export function getBuilding(id) {
    return request.get('/api-school/building/'+id)
}

//删除楼栋
export function buildingRemove(params) {
    return request.remove('/api-school/building/deleteBatch', params)
}

//根据学校获取楼栋列表
export function getBuildingListBySchool(params) {
    return request.get('/api-school/building/getBySchoolNum', params)
}