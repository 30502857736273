<template>
  <div class="right d-f f-d a-c">
    <a-menu v-model="current" mode="horizontal" @click="changeCurrent">
      <a-menu-item key="account">账号密码登录</a-menu-item>
      <a-menu-item key="phone">手机号登录</a-menu-item>
    </a-menu>
    <account v-show="showAccount"></account>
    <phone v-show="showPhone"></phone>
  </div>
</template>

<script>
import Account from "./account.vue";
import Phone from "./phone.vue";
export default {
  name: "Login",
  components: {
    Account,
    Phone,
  },
  data() {
    return {
      current: ["account"],
      showAccount: true,
      showPhone: false,
    };
  },
  methods: {
    changeCurrent(e) {
      this.current = [e.key];
      if (e.key === "account") {
        this.showAccount = true;
        this.showPhone = false;
      } else {
        this.showPhone = true;
        this.showAccount = false;
      }
    },
  },
};
</script>

<style scoped lang="less">
.right {
  width: 445px;
  height: 613px;
  background-image: url("~@/assets/images/login_right_bac.png");
  background-size: 100% 100%;
  padding: 110px 48px 0;
  box-sizing: border-box;
  .ant-menu-horizontal {
    border-bottom: none;
  }
  .ant-menu-item {
    font-size: 16px;
  }
}
</style>
