import request from '@/utils/request'

//获取班级列表
export function getClassList(params) {
    return request.get('/api-school/class', params)
}

//新增或修改班级
export function classAddOrUpdate(params) {
    return request.post('/api-school/class/saveOrUpdate', params)
}

//获取班级详情
export function getClass(id) {
    return request.get('/api-school/class/' + id)
}

//删除班级
export function classRemove(params) {
    return request.remove('/api-school/class/deleteBatch', params)
}

//班级绑定/解绑教室
export function classBindClassroom(params) {
    return request.put('/api-school/class/unbindOrBindRoom', params)
}

//根据教室id获取绑定教师列表
export function getTeacherData(id) {
    return request.get('/api-school/class/teacherList/' + id)
}

//班级绑定教师
export function classBindTeacher(params) {
    return request.post('/api-school/class/bindTeacher', params)
}

//班级解绑教师
export function classUnBindTeacher(params) {
    return request.remove('/api-school/class/unBindTeacher', params)
}