import request from '@/utils/request'

//获取角色列表
export function getRoleList(params) {
    return request.get('/api-user/roles', params)
}

//新增或修改角色
export function roleAddOrUpdate(params) {
    return request.post('/api-user/roles/saveOrUpdate', params)
}

//删除角色
export function roleRemove(id) {
    return request.remove('/api-user/roles/' + id)
}

//批量删除角色
export function roleRemoveBatch(params) {
    return request.remove('/api-user/roles/deleteBatch', params)
}

//获取角色id获取分配权限数组
export function getRolePermission(id) {
    return request.get('/api-user/menus/getByRoleId/' + id)
}

//给角色分配菜单权限 
export function allotRolePermission(params) {
    return request.post('/api-user/menus/granted', params)
}

//获取所有角色列表
export function getRoles(params) {
    return request.get('/api-user/getRoles', params)
}