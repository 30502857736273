<template>
  <div>
    <div v-if="isNew">
      <div class="d-f j-b a-c">
        <h1>消息通知（{{ newCount }}个）</h1>
        <a @click="toHistory">历史消息</a>
      </div>
      <div v-if="newList.length > 0">
        <a-list item-layout="vertical" size="large" :data-source="newList">
          <a-list-item slot="renderItem" key="item.title" slot-scope="item">
            <span slot="extra">
              <a-button type="primary" @click="pass(item)">通过</a-button>
              <a-button class="ml10" @click="noPass(item)">不通过</a-button>
            </span>
            <a-list-item-meta :description="item.content">
              <a slot="title">{{ item.name }}</a>
            </a-list-item-meta>
            {{ item.createTime }}
            <a class="ml20" @click="delMessage(item)"
              ><a-icon type="delete" />删除该通知</a
            >
          </a-list-item>
        </a-list>
        <!--分页-->
        <div class="d-f mt20">
          <a-pagination
            v-model="newQueryParams.page"
            :pageSize="newQueryParams.limit"
            :default-current="1"
            :total="newCount"
            :hideOnSinglePage="true"
            showLessItems
            @change="newOnChange"
            :item-render="itemRender"
            style="margin-left: auto"
          />
        </div>
      </div>
      <div v-else class="d-f f-d a-c">
        <img src="@/assets/images/user_center_nodata.png" width="140px" class="mt100" />
        <p>暂无消息</p>
      </div>
    </div>
    <div v-else>
      <div class="d-f j-b a-c">
        <h1>历史消息（{{ historyCount }}个）</h1>
        <a @click="toNew">消息通知</a>
      </div>
      <div v-if="historyList.length > 0">
        <a-list item-layout="vertical" size="large" :data-source="historyList">
          <a-list-item slot="renderItem" key="item.title" slot-scope="item">
            <span slot="extra" v-if="item.isReviewed == '1'"> 已通过审核 </span>
            <span slot="extra" v-else> 未通过审核 </span>
            <a-list-item-meta :description="item.content">
              <a slot="title">{{ item.name }}</a>
            </a-list-item-meta>
            {{ item.createTime }}
            <a class="ml20" @click="delMessage(item)"
              ><a-icon type="delete" />删除该通知</a
            >
          </a-list-item>
        </a-list>
        <!--分页-->
        <div class="d-f mt20">
          <a-pagination
            v-model="historyQueryParams.page"
            :pageSize="historyQueryParams.limit"
            :default-current="1"
            :total="historyCount"
            :hideOnSinglePage="true"
            showLessItems
            @change="historyOnChange"
            :item-render="itemRender"
            style="margin-left: auto"
          />
        </div>
      </div>
      <div v-else class="d-f f-d a-c">
        <img src="@/assets/images/user_center_nodata.png" width="140px" class="mt100" />
        <p>暂无消息</p>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getMessageNotificationList,
  delMessageNotification,
  getHistoryMessageNotificationList,
  reviewMessageNotification,
} from "@/api/user-center/message-notification.js";
export default {
  name: "MessageNotification",
  data() {
    return {
      isNew: true,
      newCount: null,
      historyCount: 5,
      newList: [],
      historyList: [],
      // 查询参数
      newQueryParams: {
        //当前页
        page: 1,
        //一页几个
        limit: 4,
      },
      // 查询参数
      historyQueryParams: {
        //当前页
        page: 1,
        //一页几个
        limit: 4,
      },
    };
  },
  methods: {
    //获取消息通知列表
    getMessageNotificationList() {
      getMessageNotificationList(this.newQueryParams).then((res) => {
        this.newCount = res.count;
        this.newList = res.data;
      });
    },
    //获取历史消息通知列表
    getHistoryMessageNotificationList() {
      getHistoryMessageNotificationList(this.historyQueryParams).then((res) => {
        this.historyCount = res.count;
        this.historyList = res.data;
      });
    },
    //历史消息
    toHistory() {
      this.isNew = false;
      this.getHistoryMessageNotificationList();
    },
    //消息通知
    toNew() {
      this.isNew = true;
      this.getMessageNotificationList();
    },
    //通过审核
    pass(item) {
      reviewMessageNotification({
        id: item.id,
        isReviewed: "1",
      }).then((res) => {
        this.$message.success("操作成功");
        this.getMessageNotificationList();
      });
    },
    //不通过审核
    noPass(item) {
      reviewMessageNotification({
        id: item.id,
        isReviewed: "0",
      }).then((res) => {
        this.$message.success("操作成功");
        this.getMessageNotificationList();
      });
    },
    //删除消息
    delMessage(item) {
      this.$confirm({
        title: "您确认删除该消息吗？",
        onOk: () => {
          delMessageNotification(item.id).then((res) => {
            this.$message.success("删除成功");
            if (this.isNew) {
              this.getMessageNotificationList();
            } else {
              this.getHistoryMessageNotificationList();
            }
          });
        },
      });
    },
    //分页状态改变
    newOnChange(pageNumber) {
      this.newQueryParams.page = pageNumber;
      this.getMessageNotificationList();
    },
    //分页状态改变
    historyOnChange(pageNumber) {
      this.historyQueryParams.page = pageNumber;
      this.getHistoryMessageNotificationList();
    },
    //改变分页上一步下一步的文字链接
    itemRender(current, type, originalElement) {
      if (type === "prev") {
        return <a>上一页</a>;
      } else if (type === "next") {
        return <a class="next">下一页</a>;
      }
      return originalElement;
    },
  },
  created() {
    this.getMessageNotificationList();
  },
};
</script>

<style scoped lang="less">
h1 {
  font-size: 20px;
  font-family: "PingFang SC";
  font-weight: bold;
  color: rgba(0, 0, 0, 0.85);
  margin-bottom: 16px;
}
.ant-list-vertical {
  ::v-deep .ant-list-item-extra {
    margin-left: 40px;
    display: flex;
    align-items: center;
  }
}
</style>
