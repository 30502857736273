<template>
  <div class="layout-content d-f f-d">
    <breadcrumb></breadcrumb>
    <div class="list-container">
      <a-layout>
        <a-layout-header>
          <a-menu theme="light" mode="horizontal" v-model="current">
            <a-menu-item
              :key="item.commandIdentification"
              v-for="item in pushList"
              @click="selectMenu(item)"
              >{{ item.commandName }}</a-menu-item
            >
          </a-menu>
        </a-layout-header>
        <a-layout-content>
          <div v-if="isNews">
            <!-- 搜索和新增删除 -->
            <div class="d-f a-c">
              <a-form-model ref="queryForm" :model="queryParams" layout="inline">
                <a-form-model-item label="消息内容" prop="commandName">
                  <a-input
                    v-model="queryParams.content"
                    placeholder="消息内容关键词"
                    allowClear
                    @keyup.enter.native="handleQuery"
                  />
                </a-form-model-item>
                <a-form-model-item label="" prop="date">
                  <a-range-picker
                    style="width: 210px"
                    @change="selectDate"
                    v-model="time"
                  />
                </a-form-model-item>
                <a-form-model-item>
                  <a-button
                    type="primary"
                    @click="handleQuery"
                    v-hasPermi="['log:push:query']"
                    >搜索</a-button
                  >
                  <a-button
                    @click="resetQuery"
                    class="ml10"
                    v-hasPermi="['log:push:query']"
                    >重置</a-button
                  >
                </a-form-model-item>
              </a-form-model>
            </div>
            <!--表格-->
            <a-table
              :columns="columns"
              rowKey="id"
              :data-source="data"
              :pagination="false"
              class="mt20"
            >
              <span slot="content" slot-scope="text">
                <span :title="text">{{ text }}</span>
              </span>
              <span slot="showDuration" slot-scope="text">
                {{ dictTranslation(showDurationList, text) }}
              </span>
              <span slot="closeFlag" slot-scope="text">
                {{ dictTranslation(closeFlagList, text) }}
              </span>
              <span slot="status" slot-scope="text, record">
                <a-badge status="success" />{{ record.pushSuccessCount }}台成功
                <span style="margin-left: 10px"></span>
                <a-badge status="error" />{{ record.pushFailureCount }}台失败
              </span>
              <span slot="action" slot-scope="text, record">
                <a @click="handlePushLogQuery(record)" v-hasPermi="['log:push:query']"
                  >查看详情</a
                >
              </span>
            </a-table>
            <!--分页-->
            <div class="d-f mt20">
              <a-pagination
                v-model="queryParams.page"
                :pageSize="queryParams.limit"
                :default-current="1"
                :total="total"
                :hideOnSinglePage="true"
                showLessItems
                @change="onChange"
                :item-render="itemRender"
                style="margin-left: auto"
              />
            </div>
          </div>
          <div v-else>
            <!-- 搜索和新增删除 -->
            <div class="d-f a-c">
              <a-form-model ref="fileQueryForm" :model="fileQueryParams" layout="inline">
                <a-form-model-item label="文件名称" prop="fileName">
                  <a-input
                    v-model="fileQueryParams.fileName"
                    placeholder="文件名称关键词"
                    allowClear
                    @keyup.enter.native="handleFileQuery"
                  />
                </a-form-model-item>
                <a-form-model-item label="" prop="date">
                  <a-range-picker
                    style="width: 210px"
                    @change="selectFileDate"
                    v-model="fileTime"
                  />
                </a-form-model-item>
                <a-form-model-item>
                  <a-button
                    type="primary"
                    @click="handleFileQuery"
                    v-hasPermi="['log:push:query']"
                    >搜索</a-button
                  >
                  <a-button
                    @click="resetFileQuery"
                    class="ml10"
                    v-hasPermi="['log:push:query']"
                    >重置</a-button
                  >
                </a-form-model-item>
              </a-form-model>
            </div>
            <!--表格-->
            <a-table
              :columns="fileColumns"
              rowKey="id"
              :data-source="fileData"
              :pagination="false"
              class="mt20"
            >
              <span slot="autoOpenFlag" slot-scope="text">
                {{ dictTranslation(autoOpenFlagList, text) }}
              </span>
              <span slot="status" slot-scope="text, record">
                <a-badge status="success" />{{ record.pushSuccessCount }}台成功
                <span style="margin-left: 10px"></span>
                <a-badge status="error" />{{ record.pushFailureCount }}台失败
              </span>
              <span slot="action" slot-scope="text, record">
                <a @click="handlePushLogFileQuery(record)" v-hasPermi="['log:push:query']"
                  >查看详情</a
                >
                <a-divider type="vertical" />
                <a @click="handleDownload(record)" v-hasPermi="['log:push:query']"
                  >下载</a
                >
              </span>
            </a-table>
            <!--分页-->
            <div class="d-f mt20">
              <a-pagination
                v-model="fileQueryParams.page"
                :pageSize="fileQueryParams.limit"
                :default-current="1"
                :total="fileTotal"
                :hideOnSinglePage="true"
                showLessItems
                @change="onFileChange"
                :item-render="itemRender"
                style="margin-left: auto"
              />
            </div>
          </div>

          <!--消息推送查看详情的弹出框-->
          <a-modal
            v-model="newsVisible"
            title="消息推送详情"
            width="700px"
            :footer="null"
            @cancel="newsSendCancel"
          >
            <a-radio-group
              v-model="newsQueryParams.successFlag"
              button-style="solid"
              @change="changeNewsData"
            >
              <a-radio-button :value="1"> 成功 </a-radio-button>
              <a-radio-button :value="0"> 失败 </a-radio-button>
            </a-radio-group>
            <div class="d-f mt20">
              <a-button
                type="primary"
                @click="sendMessageAgain"
                style="margin-left: auto"
                v-hasPermi="['log:push:sendAgain']"
                v-if="newsQueryParams.successFlag == 0"
                :disabled="multiple"
                >批量重发</a-button
              >
            </div>
            <a-table
              size="small"
              :columns="newsColumns"
              rowKey="serialNum"
              :data-source="newsData"
              :pagination="false"
              class="mt20"
              :row-selection="rowSelection"
            >
              <span slot="device" slot-scope="text, record">
                {{ record.buildingName + "-" + record.classroomName }}
              </span>
              <span slot="onlineFlag" slot-scope="text">
                {{ text ? "在线" : "离线" }}
              </span>
              <span slot="action" slot-scope="text, record">
                <a
                  @click="sendMessageAgain(record)"
                  v-hasPermi="['log:push:sendAgain']"
                  v-if="newsQueryParams.successFlag == 0 && record.onlineFlag"
                  >重发消息</a
                >
                <span v-else>——</span>
              </span>
            </a-table>
            <!--分页-->
            <div class="d-f mt20">
              <a-pagination
                v-model="newsQueryParams.page"
                :pageSize="newsQueryParams.limit"
                :default-current="1"
                :total="newsTotal"
                :hideOnSinglePage="true"
                showLessItems
                @change="newsOnChange"
                :item-render="itemRender"
                style="margin-left: auto"
              />
            </div>
          </a-modal>

          <!--文件推送查看详情的弹出框-->
          <a-modal
            v-model="fileVisible"
            title="文件下发详情"
            width="700px"
            :footer="null"
            @cancel="fileSendCancel"
          >
            <a-radio-group
              v-model="fileSendQueryParams.successFlag"
              button-style="solid"
              @change="changeFileData"
            >
              <a-radio-button :value="1"> 成功 </a-radio-button>
              <a-radio-button :value="0"> 失败 </a-radio-button>
            </a-radio-group>
            <div class="d-f mt20">
              <a-button
                type="primary"
                @click="sendFileAgain"
                style="margin-left: auto"
                v-hasPermi="['log:push:sendAgain']"
                v-if="fileSendQueryParams.successFlag == 0"
                :disabled="multiple1"
                >批量重发</a-button
              >
            </div>
            <a-table
              size="small"
              :columns="fileSendColumns"
              rowKey="serialNum"
              :data-source="fileSendData"
              :pagination="false"
              class="mt20"
              :row-selection="rowSelection1"
            >
              <span slot="device" slot-scope="text, record">
                {{ record.buildingName + "-" + record.classroomName }}
              </span>
              <span slot="onlineFlag" slot-scope="text">
                {{ text ? "在线" : "离线" }}
              </span>
              <span slot="action" slot-scope="text, record">
                <a
                  @click="sendFileAgain(record)"
                  v-hasPermi="['log:push:sendAgain']"
                  v-if="fileSendQueryParams.successFlag == 0 && record.onlineFlag"
                  >重发文件</a
                >
                <span v-else>——</span>
              </span>
            </a-table>
            <!--分页-->
            <div class="d-f mt20">
              <a-pagination
                v-model="fileSendQueryParams.page"
                :pageSize="fileSendQueryParams.limit"
                :default-current="1"
                :total="fileSendTotal"
                :hideOnSinglePage="true"
                showLessItems
                @change="fileSendOnChange"
                :item-render="itemRender"
                style="margin-left: auto"
              />
            </div>
          </a-modal>
        </a-layout-content>
      </a-layout>
    </div>
  </div>
</template>

<script>
import Breadcrumb from "~c/Breadcrumb";
import { getCommandList } from "@/api/device/command.js";
import {
  getNewsList,
  getNewsDetailDeviceList,
  getFileList,
  getFileDownload,
  getFileDetailDeviceList,
} from "@/api/log/push.js";
import actionType from "@/utils/action-type";
import { websocketSend } from "@/utils/websocket-send.js";
import { v4 as uuidv4 } from "uuid";
const columns = [
  {
    title: "消息内容",
    dataIndex: "content",
    align: "center",
    scopedSlots: { customRender: "content" },
    ellipsis: true,
  },
  {
    title: "发布者",
    dataIndex: "createBy",
    align: "center",
  },
  {
    title: "展示时长",
    dataIndex: "showDuration",
    align: "center",
    scopedSlots: { customRender: "showDuration" },
  },
  {
    title: "关闭通知",
    dataIndex: "closeFlag",
    align: "center",
    scopedSlots: { customRender: "closeFlag" },
  },
  {
    title: "执行状态",
    width: "20%",
    dataIndex: "status",
    align: "center",
    scopedSlots: { customRender: "status" },
  },
  {
    title: "执行时间",
    width: "15%",
    dataIndex: "createTime",
    align: "center",
  },
  {
    title: "操作",
    dataIndex: "action",
    align: "center",
    scopedSlots: { customRender: "action" },
  },
];
const fileColumns = [
  {
    title: "文件名称",
    dataIndex: "fileName",
    align: "center",
    ellipsis: true,
  },
  {
    title: "发布者",
    dataIndex: "createBy",
    align: "center",
  },
  {
    title: "自动打开",
    dataIndex: "autoOpenFlag",
    align: "center",
    scopedSlots: { customRender: "autoOpenFlag" },
  },
  {
    title: "执行状态",
    width: "20%",
    dataIndex: "status",
    align: "center",
    scopedSlots: { customRender: "status" },
  },
  {
    title: "执行时间",
    width: "15%",
    dataIndex: "createTime",
    align: "center",
  },
  {
    title: "操作",
    dataIndex: "action",
    align: "center",
    scopedSlots: { customRender: "action" },
  },
];
const newsColumns = [
  {
    title: "设备",
    dataIndex: "device",
    align: "center",
    scopedSlots: { customRender: "device" },
  },
  {
    title: "设备状态",
    dataIndex: "onlineFlag",
    align: "center",
    scopedSlots: { customRender: "onlineFlag" },
  },
  {
    title: "解决方案",
    dataIndex: "action",
    align: "center",
    scopedSlots: { customRender: "action" },
  },
];
const fileSendColumns = [
  {
    title: "设备",
    dataIndex: "device",
    align: "center",
    scopedSlots: { customRender: "device" },
  },
  {
    title: "设备状态",
    dataIndex: "onlineFlag",
    align: "center",
    scopedSlots: { customRender: "onlineFlag" },
  },
  {
    title: "解决方案",
    dataIndex: "action",
    align: "center",
    scopedSlots: { customRender: "action" },
  },
];
export default {
  name: "Push",
  components: { Breadcrumb },
  computed: {
    rowSelection() {
      const { selectedRowKeys, newsQueryParams } = this;
      return {
        selectedRowKeys,
        onChange: (selectedRowKeys) => {
          this.selectedRowKeys = selectedRowKeys;
          this.multiple = !this.selectedRowKeys.length;
        },
        getCheckboxProps: (record) => ({
          props: {
            // 全部默认禁止选中
            // disabled: true,
            // 某几项默认禁止选中(R: 当state等于1时)
            disabled: newsQueryParams.successFlag === 1 || record.onlineFlag === false,
            // 某几项默认选中(R: 当state等于1时) // defaultChecked: record.state == 1,
          },
        }),
      };
    },
    rowSelection1() {
      const { selectedRowKeys1, fileSendQueryParams } = this;
      return {
        selectedRowKeys1,
        onChange: (selectedRowKeys1) => {
          this.selectedRowKeys1 = selectedRowKeys1;
          this.multiple1 = !this.selectedRowKeys1.length;
        },
        getCheckboxProps: (record) => ({
          props: {
            // 全部默认禁止选中
            // disabled: true,
            // 某几项默认禁止选中(R: 当state等于1时)
            disabled:
              fileSendQueryParams.successFlag === 1 || record.onlineFlag === false,
            // 某几项默认选中(R: 当state等于1时) // defaultChecked: record.state == 1,
          },
        }),
      };
    },
  },
  data() {
    return {
      total: null,
      //当前选择的菜单
      current: [],
      //判断是否是消息推送
      isNews: true,
      //推送指令列表
      pushList: [],
      // 查询参数
      queryParams: {
        //当前页
        page: 1,
        //一页几个
        limit: 10,
        //开始时间
        startTime: "",
        //结束时间
        endTime: "",
      },
      //时间数组
      time: [],
      //表格的表头文字
      columns,
      //表格的数据
      data: [],
      //展示时长列表
      showDurationList: [],
      //关闭通知列表
      closeFlagList: [],
      //消息推送查看详情
      newsVisible: false,
      //消息推送查看详情表头
      newsColumns,
      //消息推送查看详情数据
      newsData: [],
      //设备列表
      deviceData: [],
      //消息推送查询参数
      newsQueryParams: {
        //当前页
        page: 1,
        //一页几个
        limit: 5,
        //成功1或失败0
        successFlag: 1,
        pushMessageLogId: null,
      },
      //消息推送数量
      newsTotal: null,
      uuid: null,
      //websocket连接
      websocket: null,
      //心跳定时器
      timer: null,
      //查询设备定时器
      timer1: null,
      //查看详情异常的复选框
      selectedRowKeys: [],
      // 非多个禁用
      multiple: true,
      //文件列表的总数
      fileTotal: null,
      //推送指令列表
      fileList: [],
      // 查询参数
      fileQueryParams: {
        //当前页
        page: 1,
        //一页几个
        limit: 10,
        //开始时间
        startTime: "",
        //结束时间
        endTime: "",
      },
      //时间数组
      fileTime: [],
      //表格的表头文字
      fileColumns,
      //表格的数据
      fileData: [],
      //展示时长列表
      autoOpenFlagList: [],
      //文件下发查看详情
      fileVisible: false,
      //文件下发查看详情表头
      fileSendColumns,
      //文件下发查看详情数据
      fileSendData: [],
      //设备列表
      deviceFileData: [],
      //文件下发查询参数
      fileSendQueryParams: {
        //当前页
        page: 1,
        //一页几个
        limit: 5,
        //成功1或失败0
        successFlag: 1,
        pushFileLogId: null,
      },
      //文件下发数量
      fileSendTotal: null,
      uuid1: null,
      //websocket连接
      websocket1: null,
      //心跳定时器
      timer2: null,
      //查询设备定时器
      timer3: null,
      //查看详情异常的复选框
      selectedRowKeys1: [],
      // 非多个禁用
      multiple1: true,
    };
  },
  methods: {
    //查询推送指令列表
    getPushList() {
      getCommandList({ isPage: false, commandType: "3" }).then((res) => {
        this.pushList = res.data;
        this.current = [res.data[0].commandIdentification];
        this.getNewsList();
        this.getShowDurationList();
        this.getCloseFlagList();
      });
    },
    //选择菜单改变
    selectMenu(e) {
      if (e.commandIdentification == 8) {
        this.queryParams = {
          page: 1,
          limit: 10,
          startTime: "",
          endTime: "",
        };
        this.time = [];
        this.isNews = true;
        this.getNewsList();
        this.getShowDurationList();
        this.getCloseFlagList();
      } else {
        this.fileQueryParams = {
          page: 1,
          limit: 10,
          startTime: "",
          endTime: "",
        };
        this.fileTime = [];
        this.isNews = false;
        this.getFileList();
        this.getAutoOpenFlagList();
      }
    },
    //获取展示时长列表
    getShowDurationList() {
      this.getDictDataList("device_terminal_show_duration").then((res) => {
        this.showDurationList = res.data;
      });
    },
    //获取关闭通知列表
    getCloseFlagList() {
      this.getDictDataList("device_terminal_close_flag").then((res) => {
        this.closeFlagList = res.data;
      });
    },
    //日期选择框改变
    selectDate(date, dateString) {
      this.queryParams.startTime = dateString[0];
      this.queryParams.endTime = dateString[1];
    },
    //搜索
    handleQuery() {
      this.queryParams.page = 1;
      this.getNewsList();
    },
    //搜索重置
    resetQuery() {
      this.$refs.queryForm.resetFields();
      this.queryParams = {
        page: 1,
        limit: 10,
        startTime: "",
        endTime: "",
      };
      this.time = [];
      this.getNewsList();
    },
    //分页状态改变
    onChange(pageNumber) {
      this.queryParams.page = pageNumber;
      this.getNewsList();
    },
    //改变分页上一步下一步的文字链接
    itemRender(current, type, originalElement) {
      if (type === "prev") {
        return <a>上一页</a>;
      } else if (type === "next") {
        return <a class="next">下一页</a>;
      }
      return originalElement;
    },
    //消息推送列表
    getNewsList() {
      getNewsList(this.queryParams).then((res) => {
        this.total = res.count;
        this.data = res.data;
      });
    },
    //webscoket 初始化
    initWebSocket() {
      const wsurl = process.env.VUE_APP_WSURL; //ws 相当于http 而wss 相当于https
      this.websocket = new WebSocket(wsurl); //实例对象
      console.log("websocket建立连接");
      this.websocket.onmessage = this.websocketonmessage;
      this.websocket.onopen = this.websocketonopen;
      this.websocket.onerror = this.websocketonerror;
      this.websocket.onclose = this.websocketclose;
    },
    //连接建立
    websocketonopen() {
      console.log("前端连接建立成功");
      this.uuid = uuidv4().replace(/-/g, "");
      let open = websocketSend(this.uuid, actionType.open);
      if (this.websocket == null) {
        this.$message.error("连接已断开，请刷新页面");
        this.websocketclose();
      } else {
        this.websocket.send(JSON.stringify(open));
      }
    },
    //发送心跳
    websocketHeart() {
      console.log("发送心跳");
      let heart = websocketSend(this.uuid, actionType.heart);
      if (this.websocket == null) {
        this.$message.error("连接已断开，请刷新页面");
        this.websocketclose();
      } else {
        this.websocket.send(JSON.stringify(heart));
        this.timer = setInterval(() => {
          if (this.websocket == null) {
            this.$message.error("连接已断开，请刷新页面");
            this.websocketclose();
          } else {
            this.websocket.send(JSON.stringify(heart));
          }
        }, 10 * 1000);
      }
    },
    //查询设备列表信息
    websocketQueryList() {
      console.log("请求查询设备列表信息");
      let queryTerminal = websocketSend(
        this.uuid,
        actionType.queryTerminal,
        this.deviceData
      );
      if (this.websocket == null) {
        this.$message.error("连接已断开，请刷新页面");
        this.websocketclose();
      } else {
        this.websocket.send(JSON.stringify(queryTerminal));
        this.timer1 = setInterval(() => {
          if (this.websocket == null) {
            this.$message.error("连接已断开，请刷新页面");
            this.websocketclose();
          } else {
            this.websocket.send(JSON.stringify(queryTerminal));
          }
        }, 5 * 1000);
      }
    },
    //数据接收
    websocketonmessage(e) {
      let data = JSON.parse(e.data);
      console.log("接收后端返回数据", data);
      //连接成功
      if (data.code === 8000) {
        this.websocketHeart();
        this.websocketQueryList();
      } else if (data.code === 8001) {
        this.newsData = data.data;
      }
      //重发消息
      else if (data.code === 8013) {
        this.$message.success(data.msg);
        clearInterval(this.timer1);
        this.timer1 = null;
        getNewsDetailDeviceList(this.newsQueryParams).then((res) => {
          this.deviceData = res.data;
          this.newsTotal = res.count;
          this.websocketQueryList();
        });
        this.getNewsList();
      } else if (data.code < 0) {
        this.$message.error(data.msg);
      }
    },
    //连接建立失败重连
    websocketonerror() {
      clearInterval(this.timer);
      clearInterval(this.timer1);
      this.timer = null;
      this.timer1 = null;
      this.websocket = null;
      this.initWebSocket();
    },
    //连接关闭
    websocketclose(e) {
      console.log("websocket断开连接");
      clearInterval(this.timer);
      clearInterval(this.timer1);
      this.timer = null;
      this.timer1 = null;
      this.websocket = null;
      this.newsVisible = false;
    },
    //查看详情
    handlePushLogQuery(record) {
      this.deviceData = [];
      this.newsData = [];
      this.newsTotal = null;
      this.selectedRowKeys = [];
      this.multiple = !this.selectedRowKeys.length;
      this.newsQueryParams.page = 1;
      this.newsQueryParams.successFlag = 1;
      this.newsQueryParams.pushMessageLogId = record.id;
      getNewsDetailDeviceList(this.newsQueryParams).then((res) => {
        this.deviceData = res.data;
        this.newsTotal = res.count;
        this.initWebSocket();
        this.newsVisible = true;
      });
    },
    //单选框状态改变
    changeNewsData(e) {
      this.deviceData = [];
      this.newsData = [];
      this.newsTotal = null;
      this.selectedRowKeys = [];
      this.multiple = !this.selectedRowKeys.length;
      clearInterval(this.timer1);
      this.timer1 = null;
      this.newsQueryParams.successFlag = e.target.value;
      this.newsQueryParams.page = 1;
      getNewsDetailDeviceList(this.newsQueryParams).then((res) => {
        this.deviceData = res.data;
        this.newsTotal = res.count;
        this.websocketQueryList();
      });
    },
    //消息推送分页状态改变
    newsOnChange(pageNumber) {
      clearInterval(this.timer1);
      this.timer1 = null;
      this.newsQueryParams.page = pageNumber;
      getNewsDetailDeviceList(this.newsQueryParams).then((res) => {
        this.deviceData = res.data;
        this.newsTotal = res.count;
        this.websocketQueryList();
      });
    },
    //重发指令
    sendMessageAgain(record) {
      this.$confirm({
        title: "您确认重发消息吗？",
        onOk: () => {
          //单个重发
          if (record.serialNum) {
            if (record.onlineFlag) {
              let receiverIds = [];
              receiverIds.push(record.serialNum);
              let newsSendAgain = websocketSend(
                this.uuid,
                actionType.newsSendAgain,
                {
                  id: this.newsQueryParams.pushMessageLogId,
                },
                receiverIds
              );
              if (this.websocket == null) {
                this.$message.error("连接已断开，请刷新页面");
                this.websocketclose();
              } else {
                this.websocket.send(JSON.stringify(newsSendAgain));
              }
            } else {
              this.$message.error("设备已离线，不能重发消息");
            }
          } else {
            if (this.selectedRowKeys.length == 0) {
              this.$message.error("请选择消息重发的设备");
            } else {
              let newsSendAgain = websocketSend(
                this.uuid,
                actionType.newsSendAgain,
                {
                  id: this.newsQueryParams.pushMessageLogId,
                },
                this.selectedRowKeys
              );
              if (this.websocket == null) {
                this.$message.error("连接已断开，请刷新页面");
                this.websocketclose();
              } else {
                this.websocket.send(JSON.stringify(newsSendAgain));
              }
            }
          }
        },
      });
    },
    //查看详情关闭复选框
    newsSendCancel() {
      let close = websocketSend(this.uuid, actionType.close);
      if (this.websocket == null) {
        this.$message.error("连接已断开，请刷新页面");
        this.websocketclose();
      } else {
        this.websocket.send(JSON.stringify(close));
      }
    },
    //获取展示时长列表
    getAutoOpenFlagList() {
      this.getDictDataList("device_terminal_auto_open_flag").then((res) => {
        this.autoOpenFlagList = res.data;
      });
    },
    //日期选择框改变
    selectFileDate(date, dateString) {
      this.fileQueryParams.startTime = dateString[0];
      this.fileQueryParams.endTime = dateString[1];
    },
    //搜索
    handleFileQuery() {
      this.fileQueryParams.page = 1;
      this.getFileList();
    },
    //搜索重置
    resetFileQuery() {
      this.$refs.fileQueryForm.resetFields();
      this.fileQueryParams = {
        page: 1,
        limit: 10,
        startTime: "",
        endTime: "",
      };
      this.fileTime = [];
      this.getFileList();
    },
    //分页状态改变
    onFileChange(pageNumber) {
      this.fileQueryParams.page = pageNumber;
      this.getFileList();
    },
    //文件推送列表
    getFileList() {
      getFileList(this.fileQueryParams).then((res) => {
        this.fileTotal = res.count;
        this.fileData = res.data;
      });
    },
    //查看详情
    handlePushLogFileQuery(record) {
      this.deviceFileData = [];
      this.fileSendData = [];
      this.fileSendTotal = null;
      this.selectedRowKeys1 = [];
      this.multiple1 = !this.selectedRowKeys1.length;
      this.fileSendQueryParams.page = 1;
      this.fileSendQueryParams.successFlag = 1;
      this.fileSendQueryParams.pushFileLogId = record.id;
      getFileDetailDeviceList(this.fileSendQueryParams).then((res) => {
        this.deviceFileData = res.data;
        this.fileSendTotal = res.count;
        this.initWebSocket1();
        this.fileVisible = true;
      });
    },
    //下载
    handleDownload(record) {
      getFileDownload({ filePath: record.filePath }).then((res) => {
        let link = document.createElement("a");
        link.style.display = "none";
        link.href = res.data.downloadUrl;
        link.setAttribute("download", record.fileName); // 动态设置原文件名
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
    },
    //webscoket 初始化
    initWebSocket1() {
      const wsurl = process.env.VUE_APP_WSURL; //ws 相当于http 而wss 相当于https
      this.websocket1 = new WebSocket(wsurl); //实例对象
      console.log("websocket建立连接");
      this.websocket1.onmessage = this.websocketonmessage1;
      this.websocket1.onopen = this.websocketonopen1;
      this.websocket1.onerror = this.websocketonerror1;
      this.websocket1.onclose = this.websocketclose1;
    },
    //连接建立
    websocketonopen1() {
      console.log("前端连接建立成功");
      this.uuid1 = uuidv4().replace(/-/g, "");
      let open = websocketSend(this.uuid1, actionType.open);
      if (this.websocket1 == null) {
        this.$message.error("连接已断开，请刷新页面");
        this.websocketclose1();
      } else {
        this.websocket1.send(JSON.stringify(open));
      }
    },
    //发送心跳
    websocketHeart1() {
      console.log("发送心跳");
      let heart = websocketSend(this.uuid1, actionType.heart);
      if (this.websocket1 == null) {
        this.$message.error("连接已断开，请刷新页面");
        this.websocketclose1();
      } else {
        this.websocket1.send(JSON.stringify(heart));
        this.timer2 = setInterval(() => {
          if (this.websocket1 == null) {
            this.$message.error("连接已断开，请刷新页面");
            this.websocketclose1();
          } else {
            this.websocket1.send(JSON.stringify(heart));
          }
        }, 10 * 1000);
      }
    },
    //查询设备列表信息
    websocketQueryList1() {
      console.log("请求查询设备列表信息");
      let queryTerminal = websocketSend(
        this.uuid1,
        actionType.queryTerminal,
        this.deviceFileData
      );
      if (this.websocket1 == null) {
        this.$message.error("连接已断开，请刷新页面");
        this.websocketclose1();
      } else {
        this.websocket1.send(JSON.stringify(queryTerminal));
        this.timer3 = setInterval(() => {
          if (this.websocket1 == null) {
            this.$message.error("连接已断开，请刷新页面");
            this.websocketclose1();
          } else {
            this.websocket1.send(JSON.stringify(queryTerminal));
          }
        }, 5 * 1000);
      }
    },
    //数据接收
    websocketonmessage1(e) {
      let data = JSON.parse(e.data);
      console.log("接收后端返回数据", data);
      //连接成功
      if (data.code === 8000) {
        this.websocketHeart1();
        this.websocketQueryList1();
      } else if (data.code === 8001) {
        this.fileSendData = data.data;
      }
      //文件下发
      else if (data.code === 8015) {
        this.$message.success(data.msg);
        clearInterval(this.timer3);
        this.timer3 = null;
        getFileDetailDeviceList(this.fileSendQueryParams).then((res) => {
          this.deviceFileData = res.data;
          this.fileSendTotal = res.count;
          this.websocketQueryList1();
        });
        this.getFileList();
      } else if (data.code < 0) {
        this.$message.error(data.msg);
      }
    },
    //连接建立失败重连
    websocketonerror1() {
      clearInterval(this.timer2);
      clearInterval(this.timer3);
      this.timer2 = null;
      this.timer3 = null;
      this.websocket1 = null;
      this.initWebSocket1();
    },
    //连接关闭
    websocketclose1(e) {
      console.log("websocket断开连接");
      clearInterval(this.timer2);
      clearInterval(this.timer3);
      this.timer2 = null;
      this.timer3 = null;
      this.websocket1 = null;
      this.fileVisible = false;
    },
    //单选框状态改变
    changeFileData(e) {
      this.deviceFileData = [];
      this.fileSendData = [];
      this.fileSendTotal = null;
      this.selectedRowKeys1 = [];
      this.multiple1 = !this.selectedRowKeys1.length;
      clearInterval(this.timer3);
      this.timer3 = null;
      this.fileSendQueryParams.successFlag = e.target.value;
      this.fileSendQueryParams.page = 1;
      getFileDetailDeviceList(this.fileSendQueryParams).then((res) => {
        this.deviceFileData = res.data;
        this.fileSendTotal = res.count;
        this.websocketQueryList1();
      });
    },
    //文件下发分页状态改变
    fileSendOnChange(pageNumber) {
      clearInterval(this.timer3);
      this.timer3 = null;
      this.fileSendQueryParams.page = pageNumber;
      getFileDetailDeviceList(this.fileSendQueryParams).then((res) => {
        this.deviceFileData = res.data;
        this.fileSendTotal = res.count;
        this.websocketQueryList1();
      });
    },
    //重发文件
    sendFileAgain(record) {
      this.$confirm({
        title: "您确认重发文件吗？",
        onOk: () => {
          //单个重发
          if (record.serialNum) {
            if (record.onlineFlag) {
              let receiverIds = [];
              receiverIds.push(record.serialNum);
              let fileSendAgain = websocketSend(
                this.uuid1,
                actionType.fileSendAgain,
                {
                  id: this.fileSendQueryParams.pushFileLogId,
                },
                receiverIds
              );
              if (this.websocket1 == null) {
                this.$message.error("连接已断开，请刷新页面");
                this.websocketclose1();
              } else {
                this.websocket1.send(JSON.stringify(fileSendAgain));
              }
            } else {
              this.$message.error("设备已离线，不能重发消息");
            }
          } else {
            if (this.selectedRowKeys1.length == 0) {
              this.$message.error("请选择消息重发的设备");
            } else {
              let fileSendAgain = websocketSend(
                this.uuid1,
                actionType.fileSendAgain,
                {
                  id: this.fileSendQueryParams.pushFileLogId,
                },
                this.selectedRowKeys1
              );
              if (this.websocket1 == null) {
                this.$message.error("连接已断开，请刷新页面");
                this.websocketclose1();
              } else {
                this.websocket1.send(JSON.stringify(fileSendAgain));
              }
            }
          }
        },
      });
    },
    //查看详情关闭复选框
    fileSendCancel() {
      let close = websocketSend(this.uuid1, actionType.close);
      if (this.websocket1 == null) {
        this.$message.error("连接已断开，请刷新页面");
        this.websocketclose1();
      } else {
        this.websocket1.send(JSON.stringify(close));
      }
    },
  },
  created() {
    this.getPushList();
  },
  destroyed() {
    this.websocketclose();

    this.websocketclose1();
  },
};
</script>

<style scoped lang="less">
.layout-content {
  width: 100%;
  height: 100%;
  .list-container {
    flex: 1;
    padding: 24px;
    .ant-layout {
      width: 100%;
      height: 100%;
      background: #ffffff;
      .ant-layout-header {
        height: 70px;
        line-height: 70px;
        padding: 0;
        background-color: #ffffff;
        position: relative;
        .ant-menu {
          height: 100%;
          display: flex;
          align-items: center;
          font-size: 16px;
          font-weight: bold;
        }
        .ant-menu-horizontal {
          line-height: 69px;
          padding: 0 24px;
        }
      }
      .ant-layout-content {
        width: 100%;
        padding: 24px;
      }
    }
  }
}
.ant-radio-group {
  width: 100%;
  .ant-radio-button-wrapper {
    width: 50%;
    text-align: center;
  }
}

::v-deep .ant-modal-body {
  height: 450px;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 6px;
    /*高宽分别对应横竖滚动条的尺寸*/
    height: 1px;
  }

  &::-webkit-scrollbar-thumb {
    // -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    background: #e3e3e6;
    border-radius: 6px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
    // -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
  }
}
::v-deep .ant-calendar-range-picker-input {
  cursor: default;
}
</style>
