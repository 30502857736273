import request from '@/utils/request'


//获取学生列表
export function getStudentList(params) {
    return request.get('/api-school/student', params)
}

//新增或修改学生
export function studentAddOrUpdate(params) {
    return request.post('/api-school/student/saveOrUpdate', params)
}

//获取学生详情
export function getStudent(id) {
    return request.get('/api-school/student/' + id)
}

//删除学生
export function studentRemove(params) {
    return request.remove('/api-school/student/deleteBatch',params)
}

//下载学生模板
export function studentDownloadTemplate(params,name) {
    return request.download('/api-school/student/downloadTemplate',params,name)
}

//导出学生数据
export function studentExport(params,name) {
    return request.download('/api-school/student/export',params,name)
}

//批量导入学生
export function studentImport(params) {
    return request.post('/api-school/student/import', params)
}