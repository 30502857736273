import request from '@/utils/request'

//获取通信方式列表
export function getCommunicationList(params) {
    return request.get('/api-device/device/communication-protocol', params)
}

//新增或修改通信方式
export function communicationAddOrUpdate(params) {
    return request.post('/api-device/device/communication-protocol/saveOrUpdate', params)
}

//获取通信方式详情
export function getCommunication(id) {
    return request.get('/api-device/device/communication-protocol/'+id)
}

//删除通信方式
export function communicationRemove(id) {
    return request.remove('/api-device/device/communication-protocol/'+ id)
}

//刷新缓存
export function refreshCache(params) {
    return request.post('/api-device/device/communication-protocol/cache/refresh', params)
}