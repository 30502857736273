<template>
  <div>
    <h1>修改密码</h1>
    <a-form-model
      class="setting-form"
      ref="baseSettingForm"
      :model="form"
      :rules="rules"
      hideRequiredMark
    >
      <a-form-model-item label="手机号" prop="mobile">
        <a-input v-model="form.mobile" placeholder="请输入手机号" disabled> </a-input>
      </a-form-model-item>
      <a-row>
        <a-col :span="12">
          <a-form-model-item label="验证码" prop="code">
            <a-input v-model="form.code" placeholder="请输入验证码"> </a-input>
          </a-form-model-item>
        </a-col>
        <a-col :span="2"></a-col>
        <a-col :span="10" style="margin-top: 32px">
          <send-code
            ref="codeRepairPassword"
            :canSend="canSend"
            :totalCount="totalCount"
            @canSendAgain="getSendMessage"
            @click.native="getCode($event)"
          ></send-code>
        </a-col>
      </a-row>
      <a-form-model-item label="新密码" prop="newPassword">
        <a-input
          v-model="form.newPassword"
          placeholder="6-20位密码，区分大小写"
          type="password"
          autocomplete="new-password"
        />
      </a-form-model-item>
      <a-form-model-item label="确认新密码" prop="confirmPassword">
        <a-input
          v-model="form.confirmPassword"
          placeholder="确认新密码"
          type="password"
        />
      </a-form-model-item>
      <br />
      <a-form-model-item>
        <a-button type="primary" @click="submit">确认修改</a-button>
      </a-form-model-item>
    </a-form-model>
  </div>
</template>

<script>
import SendCode from "@/components/SendCode.vue";
import { smsForgetSend, resetForgetPassword } from "@/api/forget.js";

export default {
  name: "RepairPassword",
  components: { SendCode },
  data() {
    return {
      form: {},
      rules: {
        code: [
          {
            required: true,
            message: "请输入验证码",
            whitespace: true,
            trigger: ["change", "blur"],
          },
          {
            pattern: /^\d{4}$/,
            message: "验证码格式错误",
            trigger: ["change", "blur"],
          },
        ],
        newPassword: [
          {
            required: true,
            message: "请输入密码",
            whitespace: true,
            trigger: ["change", "blur"],
          },
          {
            pattern: /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,20}$/,
            message: "6-20位，字母和数字组合",
            trigger: ["change", "blur"],
          },
        ],
        confirmPassword: [
          {
            required: true,
            message: "请输入密码",
            whitespace: true,
            trigger: ["change", "blur"],
          },
          {
            pattern: /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,20}$/,
            message: "6-20位，字母和数字组合",
            trigger: ["change", "blur"],
          },
          {
            validator: (rule, value, callback) => {
              if (value !== this.form.newPassword) {
                callback(new Error("两次密码不一样"));
              } else {
                callback();
              }
            },
            trigger: ["change", "blur"],
          },
        ],
      },
      canSend: true,
      totalCount: 60,
    };
  },
  methods: {
    //获取验证码
    getCode(event) {
      event.stopPropagation();
      this.$refs["baseSettingForm"].validateField(["mobile"], (Error) => {
        if (!Error) {
          if (this.canSend) {
            //发送短信验证码接口
            smsForgetSend(this.form.mobile).then((res) => {
              this.$message.success("验证码发送成功");
              this.canSend = false;
              this.$refs.codeRepairPassword.getCode();
            });
          }
        }
      });
    },
    //更新信息
    submit() {
      this.$refs.baseSettingForm.validate((valid) => {
        if (valid) {
          let params = {};
          params.id = this.form.id;
          params.mobile = this.form.mobile;
          params.code = this.form.code;
          params.newPassword = this.form.newPassword;
          params.confirmPassword = this.form.confirmPassword;
          resetForgetPassword(params).then((res) => {
            this.$message.success("修改成功，请重新登录");
            this.$ls.set("token", null);
            this.$ls.set("userInfo", null);
            this.$store.commit("setList", []);
            this.$router.push("/login");
          });
        }
      });
    },
    //倒计时结束可以重新发送验证码（子组件传值）
    getSendMessage(data) {
      this.canSend = data;
    },
  },
  created() {
    let userInfo = this.$ls.get("userInfo");
    this.form = userInfo;
  },
};
</script>

<style scoped lang="less">
h1 {
  font-size: 20px;
  font-family: "PingFang SC";
  font-weight: bold;
  color: rgba(0, 0, 0, 0.85);
  margin-bottom: 16px;
}
.setting-form {
  width: 400px;
  position: relative;
  .ant-form-item {
    margin-bottom: 5px;
  }
  ::v-deep .ant-form-item-label {
    line-height: 32px;
  }
}
</style>
