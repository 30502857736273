<template>
  <div class="analysis-container">
    <nav class="d-f j-b">
      <div class="d-f a-c">
        <img src="@/assets/images/layout_location.png" width="20px" />
        <span class="school">{{ location }}</span>
      </div>
      <div class="d-f a-c">
        <a-tooltip placement="bottom">
          <template slot="title">
            <span>返回主菜单</span>
          </template>
          <img
            src="@/assets/images/layout_home.png"
            width="20px"
            @click="toEnter"
            class="mr24"
          />
        </a-tooltip>
        <a-tooltip placement="bottom">
          <template slot="title">
            <span>消息通知</span>
          </template>
          <a-badge :count="count">
            <img
              src="@/assets/images/layout_bell.png"
              width="20px"
              @click="toMessageNotification"
            />
          </a-badge>
        </a-tooltip>
        <span class="role">{{ role }}</span>
        <a-dropdown placement="bottomCenter">
          <a-menu slot="overlay" @click="handleMenuClick">
            <a-menu-item key="1">个人中心</a-menu-item>
            <a-menu-item key="2">退出</a-menu-item>
          </a-menu>
          <div class="d-f a-c">
            <img :src="avatarUrl" width="32px" height="32px" style="border-radius: 50%" />
            <p class="username">{{ username }}</p>
          </div>
        </a-dropdown>
      </div>
    </nav>
    <ul>
      <li>
        <div class="title">设备总数（台）</div>
        <div class="num">{{ dataAnalysisNum.totalDeviceCount }}</div>
      </li>
      <li>
        <div class="title">在线终端（台）</div>
        <div class="num">{{ dataAnalysisNum.onlineDeviceCount }}</div>
      </li>
      <li>
        <div class="title">设备在线总时长（时）</div>
        <div class="num">{{ dataAnalysisNum.totalOnlineTime }}</div>
      </li>
      <li>
        <div class="title">用户数量（人）</div>
        <div class="num">{{ dataAnalysisNum.totalUserCount }}</div>
      </li>
    </ul>
    <div class="echarts echarts-one">
      <div class="box d-f f-d">
        <div class="title d-f j-b a-c">
          设备在线活跃度（台）
          <a-date-picker
            @change="dataPicker1"
            :allowClear="false"
            :disabledDate="disabledDate"
            :defaultValue="moment(getCurrentData(), 'YYYY-MM-DD')"
          />
        </div>
        <div ref="onlineNum" class="online-num"></div>
      </div>
      <div class="box">
        <div class="title d-f j-b a-c">
          <div>
            软件日均使用时长排名
            <a-tooltip placement="right">
              <template slot="title">
                <span
                  >软件日均使用时长排名是根据终端黑板软件日使用时长平均值做统计计算</span
                >
              </template>
              <a-icon type="question-circle" style="color: rgba(0, 0, 0, 0.45)" />
            </a-tooltip>
          </div>
          <a-range-picker
            style="width: 220px"
            @change="dataPicker2"
            :allowClear="false"
            :defaultValue="[
              moment(getCurrentData1(), 'YYYY-MM-DD'),
              moment(getCurrentData(), 'YYYY-MM-DD'),
            ]"
            :disabledDate="disabledDate"
          />
        </div>
        <div class="d-f" style="position: relative">
          <ol class="d-f f-d j-a">
            <li v-for="(item, index) in softwareList" :key="index">
              {{ index + 1 }}
            </li>
          </ol>
          <div class="software-rank" ref="softwareRank"></div>
          <a-empty
            v-if="softwareList.length == 0"
            style="
              position: absolute;
              left: 50%;
              top: 50%;
              transform: translate(-50%, -50%);
            "
          />
        </div>
      </div>
    </div>
    <div class="echarts echarts-two">
      <div class="box">
        <div class="title d-f j-b a-c">
          设备在线时长排名
          <a-range-picker
            style="width: 220px"
            @change="dataPicker3"
            :allowClear="false"
            :defaultValue="[
              moment(getCurrentData1(), 'YYYY-MM-DD'),
              moment(getCurrentData(), 'YYYY-MM-DD'),
            ]"
            :disabledDate="disabledDate"
          />
        </div>
        <div class="d-f" style="position: relative">
          <dl class="d-f f-d j-a">
            <li v-for="(item, index) in deviceList" :key="index">
              {{ index + 1 }}
            </li>
          </dl>
          <div class="device-online-rank" ref="deviceOnlineRank"></div>
          <a-empty
            v-if="deviceList.length == 0"
            style="
              position: absolute;
              left: 50%;
              top: 50%;
              transform: translate(-50%, -50%);
            "
          />
        </div>
      </div>
      <div class="box">
        <div class="title d-f a-c j-b">
          <div>
            设备状态评分排名&nbsp;
            <a-tooltip placement="right">
              <template slot="title">
                <span>
                  设备状态评分 =
                  设备cpu利用率记录均值、设备内存利用率均值、设备硬盘使用情况均值做的加权算法运算
                </span>
              </template>
              <a-icon type="question-circle" style="color: rgba(0, 0, 0, 0.45)" />
            </a-tooltip>
          </div>
          <div class="up-or-down d-f a-c">
            <button
              @click="down"
              :class="healthQueryForm.orderType == 'DESC' ? 'active' : ''"
            >
              降序
            </button>
            <div class="line"></div>
            <button
              @click="up"
              :class="healthQueryForm.orderType == 'ASC' ? 'active' : ''"
            >
              升序
            </button>
          </div>
          <a-range-picker
            style="width: 220px"
            @change="dataPicker4"
            :allowClear="false"
            :defaultValue="[
              moment(getCurrentData1(), 'YYYY-MM-DD'),
              moment(getCurrentData(), 'YYYY-MM-DD'),
            ]"
            :disabledDate="disabledDate"
          />
        </div>
        <div class="device-health" ref="deviceHealth"></div>
      </div>
    </div>
  </div>
</template>

<script>
let avatar = require("@/assets/images/enter_avatar.png");
import { getMessageNotificationCount } from "@/api/user-center/message-notification.js";
import { getSchoolNameBySchoolNum } from "@/api/basic-info/school.js";
import {
  getDeviceOnlineNumList,
  getDeviceUseRank,
  getDeviceOnlineRank,
  getDeviceStatusRank,
} from "@/api/data/analysis.js";
import { checkToken } from "@/api/login";
import { setCookie } from "@/utils/cookie.js";
import moment from "moment";
let echarts = require("echarts");
import { v4 as uuidv4 } from "uuid";
import actionType from "@/utils/action-type";
import { websocketSend } from "@/utils/websocket-send.js";
export default {
  data() {
    return {
      //消息通知的数量
      count: 0,
      //学校名称
      location: "",
      websocket: null,
      //心跳定时器
      timer: null,
      //查询数量定时器
      timer1: null,
      uuid: null,
      //数据分析数量统计
      dataAnalysisNum: {},
      //设备各个时间在线台数选择的日期
      dateString: moment().format("YYYY-MM-DD"),
      //设备各个时间在线台数列表
      deviceOnlineNumList: [],
      //软件排行列表
      softwareList: [],
      //软件使用时长排名选择的时间段中软件使用时长
      softwareOnlineTimeList: [],
      //软件使用时长排名选择的时间段中总时长
      totalTimeList: [],
      //软件使用时长排名表单
      useQueryForm: {
        page: 1,
        limit: 6,
        //软件使用时长排名开始时间
        startTime1: moment(new Date().getTime() - 24 * 7 * 60 * 60 * 1000).format(
          "YYYY-MM-DD"
        ),
        //软件使用时长排名结束时间
        endTime1: moment().format("YYYY-MM-DD"),
      },
      //设备在线时长排名开始时间
      startTime2: moment(new Date().getTime() - 24 * 7 * 60 * 60 * 1000).format(
        "YYYY-MM-DD"
      ),
      //设备在线时长排名结束时间
      endTime2: moment().format("YYYY-MM-DD"),
      //设备在线时长排名设备列表
      deviceList: [],
      //设备在线时长排名选择的时间段中总时长
      totalTimeList1: [],
      //设备在线时长排名选择的时间段中设备在线时长
      deviceOnlineRankList: [],
      //设备健康评分表单
      healthQueryForm: {
        //设备健康评分开始时间
        startTime3: moment(new Date().getTime() - 24 * 7 * 60 * 60 * 1000).format(
          "YYYY-MM-DD"
        ),
        //设备健康评分结束时间
        endTime3: moment().format("YYYY-MM-DD"),
        //选择升序or降序
        orderType: "DESC",
        page: 1,
        limit: 6,
      },
      //设备列表
      deviceHealthList: [],
      //设备健康评分
      healthScoreList: [],
    };
  },
  computed: {
    //头像地址
    avatarUrl() {
      if (this.$ls.get("userInfo").headImgUrl) {
        return this.$ls.get("userInfo").headImgUrl;
      } else {
        return avatar;
      }
    },
    //角色
    role() {
      let roleList = this.$ls.get("userInfo").roles;
      let roles = "";
      roleList.forEach((item) => {
        if (roles != "") {
          roles += "," + item.name;
        } else {
          roles += item.name;
        }
      });
      return roles;
    },
    //用户名
    username() {
      return this.$ls.get("userInfo").username;
    },
  },
  methods: {
    handleMenuClick(e) {
      if (e.key == "1") {
        this.$router.push("/user-center");
      } else {
        //退出登录的接口
        this.$confirm({
          title: "您确认退出登录吗？",
          onOk: () => {
            let baseUrl = process.env.VUE_APP_BASEURL;
            let accessToken = this.$ls.get("access_token");
            let refreshToken = this.$ls.get("refresh_token");
            let userId = this.$ls.get("userInfo").id;
            let hostname = location.origin;
            //判断token有没有失效
            checkToken({ token: this.$ls.get("access_token") }).then((response) => {
              if (response.active) {
                window.location = `${baseUrl}/api-uaa/oauth/remove/token?access_token=${accessToken}&refresh_token=${refreshToken}&userId=${userId}&redirect_uri=${hostname}/login`;

                setCookie("access_token", null);
                setCookie("token_type", null);
                setCookie("refresh_token", null);
                setCookie("scope", null);
                this.$ls.set("access_token", null);
                this.$ls.set("token_type", null);
                this.$ls.set("refresh_token", null);
                this.$ls.set("scope", null);
              }
            });
          },
        });
      }
    },
    toMessageNotification() {
      this.$router.push("/user-center/message-notification");
    },
    toEnter() {
      this.$router.push("/enter");
    },
    //获取消息通知数量
    getMessageNotificationCount() {
      getMessageNotificationCount().then((res) => {
        this.count = res.data;
      });
    },
    //根据schoolNum或者学校名称
    getSchoolNameBySchoolNum() {
      if (this.$ls.get("userInfo").schoolNum) {
        getSchoolNameBySchoolNum(this.$ls.get("userInfo").schoolNum).then((res) => {
          this.location = res.name;
        });
      } else {
        this.location = "";
      }
    },
    //webscoket 初始化
    initWebSocket() {
      const wsurl = process.env.VUE_APP_WSURL; //ws 相当于http 而wss 相当于https
      this.websocket = new WebSocket(wsurl); //实例对象
      console.log("websocket建立连接");
      this.websocket.onmessage = this.websocketonmessage;
      this.websocket.onopen = this.websocketonopen;
      this.websocket.onerror = this.websocketonerror;
      this.websocket.onclose = this.websocketclose;
    },
    //连接建立
    websocketonopen() {
      console.log("前端连接建立成功");
      this.uuid = uuidv4().replace(/-/g, "");
      let open = websocketSend(this.uuid, actionType.open);
      if (this.websocket == null) {
        this.$message.error("连接已断开，请刷新页面");
        this.websocketclose();
      } else {
        this.websocket.send(JSON.stringify(open));
      }
    },
    //发送心跳
    websocketHeart() {
      console.log("发送心跳");
      let heart = websocketSend(this.uuid, actionType.heart);
      if (this.websocket == null) {
        this.$message.error("连接已断开，请刷新页面");
        this.websocketclose();
      } else {
        this.websocket.send(JSON.stringify(heart));
        this.timer = setInterval(() => {
          if (this.websocket == null) {
            this.$message.error("连接已断开，请刷新页面");
            this.websocketclose();
          } else {
            this.websocket.send(JSON.stringify(heart));
          }
        }, 10 * 1000);
      }
    },
    //查询数据分析数量统计信息
    websocketQueryDataAnalysisNum() {
      console.log("请求查询设备列表信息");
      let roles = this.$ls.get("userInfo").roles;
      let roleAliases = roles.map((r) => r.roleAlias).join(",");
      let dataAnalysisNum = websocketSend(this.uuid, actionType.dataAnalysisNum, {
        roleAliases,
      });
      if (this.websocket == null) {
        this.$message.error("连接已断开，请刷新页面");
        this.websocketclose();
      } else {
        this.websocket.send(JSON.stringify(dataAnalysisNum));
        this.timer1 = setInterval(() => {
          if (this.websocket == null) {
            this.$message.error("连接已断开，请刷新页面");
            this.websocketclose();
          } else {
            this.websocket.send(JSON.stringify(dataAnalysisNum));
          }
        }, 5 * 1000);
      }
    },
    //数据接收
    websocketonmessage(e) {
      let data = JSON.parse(e.data);
      console.log("接收后端返回数据", data);
      //连接成功
      if (data.code === 8000) {
        this.websocketHeart();
        this.websocketQueryDataAnalysisNum();
      } else if (data.code === 8028) {
        this.dataAnalysisNum = data.data;
      } else if (data.code < 0) {
        this.$message.error(data.msg);
      }
    },
    //连接建立失败重连
    websocketonerror() {
      clearInterval(this.timer);
      clearInterval(this.timer1);
      this.timer = null;
      this.timer1 = null;
      this.websocket = null;
      this.initWebSocket();
    },
    //连接关闭
    websocketclose(e) {
      console.log("websocket断开连接");
      clearInterval(this.timer);
      clearInterval(this.timer1);
      this.timer = null;
      this.timer1 = null;
      this.websocket = null;
    },
    //设备在线活跃度日期选择变化
    dataPicker1(date, dateString) {
      this.dateString = dateString;
      this.getDeviceOnlineNumList();
    },
    //不可选择的日期
    disabledDate(current) {
      return current && current > moment().endOf("day");
    },
    moment,
    //当前的日期（今天）
    getCurrentData() {
      return new Date();
    },
    //得到各个时间段和设备在线台数
    getDeviceOnlineNumList() {
      let startTime = this.dateString + " " + "00:00:00";
      let endTime = this.dateString + " " + "23:59:59";
      getDeviceOnlineNumList({ startTime, endTime }).then((res) => {
        let arr = [];
        for (let i = 0; i < 24; i++) {
          arr[i] = 0;
        }
        for (let i = 0; i < 24; i++) {
          res.data.forEach((r) => {
            if (i === Number(r.hourInfo)) {
              arr[i] = r.onlineCount;
            }
          });
        }
        this.deviceOnlineNumList = arr;
        setTimeout(() => {
          this.myCharts1();
        });
      });
    },
    //设备在线活跃度图表
    myCharts1() {
      let myChart = echarts.getInstanceByDom(this.$refs.onlineNum);
      if (myChart == null) {
        myChart = echarts.init(this.$refs.onlineNum);
      }
      // 指定图表的配置项和数据
      let option = {
        grid: {
          top: "10%",
          left: "5%",
          right: "5%",
          bottom: "10%",
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          nameTextStyle: {
            fontSize: 12,
          },
          axisLine: {
            lineStyle: {
              color: "rgba(0, 0, 0, 0.45)",
            },
          },
          data: [
            "0:00",
            "1:00",
            "2:00",
            "3:00",
            "4:00",
            "5:00",
            "6:00",
            "7:00",
            "8:00",
            "9:00",
            "10:00",
            "11:00",
            "12:00",
            "13:00",
            "14:00",
            "15:00",
            "16:00",
            "17:00",
            "18:00",
            "19:00",
            "20:00",
            "21:00",
            "22:00",
            "23:00",
          ],
        },
        yAxis: {
          type: "value",
          boundaryGap: [0, "30%"],
          nameTextStyle: {
            fontSize: 12,
          },
          minInterval: 1,
          axisLine: {
            show: false,
            lineStyle: {
              color: "rgba(0, 0, 0, 0.45)",
            },
          },
          axisTick: {
            show: false,
          },
          splitLine: {
            lineStyle: {
              type: "dashed",
            },
          },
        },
        series: [
          {
            zlevel: 2,
            z: 2,
            type: "line",
            smooth: 0.3,
            symbol: "none",
            lineStyle: {
              color: "#5AD8A6",
              width: 3,
            },
            markLine: {
              symbol: ["none", "none"],
              label: { show: false },
            },
            areaStyle: {
              color: "rgba(90, 216, 166, 0.35)",
            },
            data: this.deviceOnlineNumList,
          },
        ],
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "line",
            lineStyle: {
              color: "#5AD8A6",
              type: "dashed",
            },
          },
          borderColor: "#ffffff",
          backgroundColor: "#ffffff",
          extraCssText: "box-shadow: 0px 2px 8px rgba(1,77,24,0.15)",
          padding: 10,
          formatter: (val) => {
            //返回tooltip的内容及布局样式
            // console.log(val);
            let obj = {};
            val.forEach((r) => {
              obj.time = r.name;
              obj.value = r.value;
            });
            return `
             <div>${this.dateString} ${obj.time}</div>
             <div style="margin-top:10px">设备在线数：${obj.value}</div>
           `;
          },
        },
      };
      // 使用刚指定的配置项和数据显示图表。
      myChart.setOption(option);
    },
    //前一星期的日期
    getCurrentData1() {
      return new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000);
    },
    //查询软件使用时长排名
    getDeviceUseRank() {
      let query = {};
      query.page = this.useQueryForm.page;
      query.limit = this.useQueryForm.limit;
      query.startTime = this.useQueryForm.startTime1 + " " + "00:00:00";
      query.endTime = this.useQueryForm.endTime1 + " " + "23:59:59";
      getDeviceUseRank(query).then((res) => {
        this.softwareList = res.data.map((r) => r.name);
        this.softwareOnlineTimeList = res.data.map((r) => Number(r.totalUseDuration));
        let arr = [];
        for (let i = 0; i < this.softwareList.length; i++) {
          arr[i] = 24;
        }
        this.totalTimeList = arr;
        setTimeout(() => {
          this.myCharts2();
        });
      });
    },
    //软件使用时长排名日期选择变化
    dataPicker2(dates, dateStrings) {
      this.useQueryForm.startTime1 = dateStrings[0];
      this.useQueryForm.endTime1 = dateStrings[1];
      this.getDeviceUseRank();
    },
    //软件使用时长排名
    myCharts2() {
      let myChart = echarts.getInstanceByDom(this.$refs.softwareRank);
      if (myChart == null) {
        myChart = echarts.init(this.$refs.softwareRank);
      }
      let option = {
        grid: {
          top: "0",
          bottom: "-7%",
          left: "4%",
          right: "5%",
          containLabel: true,
        },
        xAxis: {
          show: false,
          type: "value",
        },
        yAxis: {
          axisTick: {
            show: false,
          },
          inverse: true, //倒序
          // //坐标轴线
          axisLine: {
            show: false,
            lineStyle: {
              color: "rgba(0,0,0,.45)",
            },
          },
          type: "category",
          data: this.softwareList,
        },
        series: [
          {
            type: "bar",
            data: this.softwareOnlineTimeList,
            barWidth: 14,
            z: 10,
            tooltip: { show: false },
            label: {
              show: false, // 显示文本
            },
            itemStyle: {
              // 柱子样式
              color: "rgba(255, 157, 77, 1)", // 柱状图颜色
              borderRadius: [20],
              legendHoverLink: false,
            },
            emphasis: {
              itemStyle: {
                color: "rgba(255, 157, 77, 1)",
              },
            },
          },
          {
            type: "bar",
            data: this.totalTimeList,
            tooltip: { show: false },
            barWidth: 14,
            barGap: "-100%", // 两个柱子之间的距离，如果要重叠设置为-100%
            label: {
              show: true,
              position: ["100%", 2], // 数据值位置
              color: "rgba(0, 0, 0, 0.65)",
              fontSize: 12,
              formatter: (val) => {
                return `${this.softwareOnlineTimeList[val.dataIndex]}h`;
              },
            },
            itemStyle: {
              borderRadius: [20],
              color: "rgba(255, 235, 219, 1)", // 柱子颜色，作为底层背景
            },
            emphasis: {
              itemStyle: {
                color: "rgba(255, 235, 219, 1)",
              },
            },
          },
        ],
      };
      myChart.setOption(option);
    },
    //设备在线时长排名日期选择变化
    dataPicker3(dates, dateStrings) {
      this.startTime2 = dateStrings[0];
      this.endTime2 = dateStrings[1];
      this.getDeviceOnlineRank();
    },
    //获取设备在线时长排名
    getDeviceOnlineRank() {
      let startTime = this.startTime2 + " " + "00:00:00";
      let endTime = this.endTime2 + " " + "23:59:59";
      getDeviceOnlineRank({ startTime, endTime }).then((res) => {
        this.deviceList = res.data.map((r) => r.buildingName + "-" + r.classroomName);
        this.deviceOnlineRankList = res.data.map((r) => r.totalDuration);
        let arr = [];
        let startTime = new Date(this.startTime2).getTime();
        let endTime = new Date(this.endTime2).getTime();
        let days = (parseInt(endTime) - parseInt(startTime)) / (24 * 60 * 60 * 1000) + 1;
        for (let i = 0; i < this.deviceList.length; i++) {
          arr[i] = days * 24;
        }
        this.totalTimeList1 = arr;
        setTimeout(() => {
          this.myCharts3();
        });
      });
    },
    //设备在线时长排名
    myCharts3() {
      let myChart = echarts.getInstanceByDom(this.$refs.deviceOnlineRank);
      if (myChart == null) {
        myChart = echarts.init(this.$refs.deviceOnlineRank);
      }
      let option = {
        grid: {
          top: "1%",
          bottom: "-8%",
          left: "3%",
          right: "8%",
          containLabel: true,
        },
        xAxis: {
          show: false,
          type: "value",
          boundaryGap: [0, 0],
        },
        yAxis: {
          axisTick: {
            show: false,
          },
          inverse: true, //倒序
          axisLine: {
            show: false,
            lineStyle: {
              color: "rgba(0,0,0,.45)",
            },
          },
          type: "category",
          data: this.deviceList,
        },
        series: [
          {
            type: "bar",
            data: this.deviceOnlineRankList,
            barWidth: 14,
            z: 10,
            tooltip: { show: false },
            label: {
              show: false, // 显示文本
            },
            itemStyle: {
              // 柱子样式
              color: "rgba(90, 216, 166, 1)", // 柱状图颜色
              borderRadius: [20],
              legendHoverLink: false,
            },
            emphasis: {
              itemStyle: {
                color: "rgba(90, 216, 166, 1)",
              },
            },
          },
          {
            type: "bar",
            data: this.totalTimeList1,
            tooltip: { show: false },
            barWidth: 14,
            barGap: "-100%", // 两个柱子之间的距离，如果要重叠设置为-100%
            label: {
              show: true,
              position: ["100%", 2], // 数据值位置
              color: "rgba(0, 0, 0, 0.65)",
              fontSize: 12,
              formatter: (val) => {
                return `${this.deviceOnlineRankList[val.dataIndex]}h`;
              },
            },
            itemStyle: {
              borderRadius: [20],
              color: "rgba(219, 255, 224, 1)", // 柱子颜色，作为底层背景
            },
            emphasis: {
              itemStyle: {
                color: "rgba(219, 255, 224, 1)",
              },
            },
          },
        ],
      };
      myChart.setOption(option);
    },
    //设备状态评分排名日期选择变化
    dataPicker4(dates, dateStrings) {
      this.healthQueryForm.startTime3 = dateStrings[0];
      this.healthQueryForm.endTime3 = dateStrings[1];
      this.getDeviceStatusRank();
    },
    //降序
    down() {
      if (this.healthQueryForm.orderType != "DESC") {
        this.healthQueryForm.orderType = "DESC";
        this.getDeviceStatusRank();
      }
    },
    //升序
    up() {
      if (this.healthQueryForm.orderType != "ASC") {
        this.healthQueryForm.orderType = "ASC";
        this.getDeviceStatusRank();
      }
    },
    //获取设备状态评分排名
    getDeviceStatusRank() {
      let startTime = this.healthQueryForm.startTime3 + " " + "00:00:00";
      let endTime = this.healthQueryForm.endTime3 + " " + "23:59:59";
      let orderType = this.healthQueryForm.orderType;
      let page = this.healthQueryForm.page;
      let limit = this.healthQueryForm.limit;
      getDeviceStatusRank({ startTime, endTime, orderType, page, limit }).then((res) => {
        this.deviceHealthList = res.data.map(
          (r) => r.buildingName + "-" + r.classroomName
        );
        this.healthScoreList = res.data.map((r) => r.score);
        setTimeout(() => {
          this.myCharts4();
        });
      });
    },
    //设备状态评分排名
    myCharts4() {
      let myChart = echarts.getInstanceByDom(this.$refs.deviceHealth);
      if (myChart == null) {
        myChart = echarts.init(this.$refs.deviceHealth);
      }
      let option = {
        grid: {
          left: "0",
          right: "0%",
          top: "13%",
          bottom: "2%",
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            axisTick: {
              show: false,
            },
            data: this.deviceHealthList,
            axisLabel: {
              interval: 0, //interval为x轴两相邻数据之间所包含数据条数
              color: "rgba(0,0,0,0.45)",
            },
            axisLine: {
              lineStyle: {
                color: "#D9D9D9",
              },
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            axisLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            splitLine: {
              show: true,
              lineStyle: {
                type: "dashed",
                color: "#E8E8E8",
              },
            },
            axisLabel: {
              color: "rgba(0,0,0,0.45)",
            },
          },
        ],
        series: [
          {
            type: "bar",
            barWidth: 26,
            data: this.healthScoreList,
            label: {
              formatter: "{c}" + "分",
              show: true, //开启显示
              position: "top", //在上方显示
              //数值样式
              color: "rgba(0,0,0,0.65)",
              fontSize: 12,
            },
            itemStyle: {
              color: "#009FE8",
            },
          },
        ],
      };
      myChart.setOption(option);
    },
  },
  created() {
    this.getMessageNotificationCount();
    this.getSchoolNameBySchoolNum();
    this.initWebSocket();
  },
  mounted() {
    this.getDeviceOnlineNumList();
    this.getDeviceUseRank();
    this.getDeviceOnlineRank();
    this.getDeviceStatusRank();
  },
  destroyed() {
    this.websocketclose();
  },
};
</script>

<style scoped lang="less">
.analysis-container {
  width: 100%;
  min-height: 100vh;
  background: rgba(240, 242, 245, 0.39);

  nav {
    width: 100%;
    height: 64px;
    background: #ffffff;
    box-shadow: 0px 1px 4px 1px rgba(0, 21, 41, 0.12);
    padding: 0 24px;

    .school {
      margin-left: 12px;
      font-size: 14px;
      font-family: "PingFang SC";
      font-weight: 400;
      color: rgba(0, 0, 0, 0.65);
    }

    .role {
      font-size: 14px;
      font-family: "PingFang SC";
      font-weight: 400;
      color: rgba(0, 0, 0, 0.65);
      margin-right: 12px;
      margin-left: 24px;
    }

    .username {
      max-width: 100px;
      cursor: pointer;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      margin-left: 12px;
      margin-bottom: 0;
    }
  }

  ul {
    padding: 24px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 24px;

    li {
      position: relative;
      background-repeat: no-repeat;
      background-size: 100% 100%;

      .title {
        position: absolute;
        top: 12%;
        left: 6%;
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #ffffff;
      }

      .num {
        position: absolute;
        top: 32%;
        left: 6%;
        font-size: 38px;
        font-family: Arial;
        font-weight: 400;
        color: #ffffff;
        width: 90%;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    li:first-child {
      background-image: url("~@/assets/images/data_analysis_bac1.png");
    }

    li:nth-of-type(2) {
      background-image: url("~@/assets/images/data_analysis_bac2.png");
    }

    li:nth-of-type(3) {
      background-image: url("~@/assets/images/data_analysis_bac3.png");
    }

    li:nth-of-type(4) {
      background-image: url("~@/assets/images/data_analysis_bac4.png");
    }

    li::before {
      content: " ";
      /* 设为块元素，宽度撑满 */
      display: block;
      padding-bottom: 60%;
    }
  }

  .echarts-one {
    grid-template-columns: 4fr 2fr; // 列宽这样是分成5份各占 3 2份

    .box {
      height: 388px;
    }
  }

  .echarts-two {
    grid-template-columns: 2fr 4fr; // 列宽这样是分成5份各占 3 2份

    .box {
      height: 308px;

      .up-or-down {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        .line {
          width: 1px;
          height: 32px;
          background: #e8eaee;
        }

        button {
          width: 60px;
          height: 32px;
          background: #ffffff;
          border: 1px solid #e8eaee;
          font-size: 14px;
          font-family: Helvetica-Regular, Helvetica;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.65);
          cursor: pointer;
          outline: none;
        }

        button:first-child {
          border-radius: 4px 0px 0px 4px;
          border-right: none;
        }

        button:nth-of-type(2) {
          border-radius: 0px 4px 4px 0px;
          border-left: none;
        }

        .active {
          border-color: @primary-color;
          color: @primary-color;
        }
      }
    }
  }

  .echarts {
    padding: 0 24px 24px 24px;
    box-sizing: border-box;
    display: grid;
    gap: 24px;

    .box {
      padding: 16px;
      box-sizing: border-box;
      background: #ffffff;
      border-radius: 16px;
      font-size: 16px;
      font-family: PingFang SC;
      font-weight: bold;
      color: rgba(0, 0, 0, 0.85);

      .title {
        width: 100%;
        position: relative;
      }

      .online-num {
        width: 100%;
        height: 330px;
      }

      ol {
        height: 330px;
        margin: 0;

        li {
          width: 24px;
          height: 24px;
          background: rgba(240, 242, 245, 0.39);
          border-radius: 50%;
          font-size: 12px;
          font-family: Arial;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.65);
          line-height: 24px;
          text-align: center;
        }

        li:nth-child(1) {
          color: transparent;
          background-color: transparent;
          background-image: url("~@/assets/images/data_analysis_rank1.png");
          background-size: 24px;
          background-repeat: no-repeat;
          background-position: center;
        }

        li:nth-child(2) {
          color: transparent;
          background-color: transparent;
          background-image: url("~@/assets/images/data_analysis_rank2.png");
          background-size: 24px;
          background-repeat: no-repeat;
          background-position: center;
        }

        li:nth-child(3) {
          color: transparent;
          background-color: transparent;
          background-image: url("~@/assets/images/data_analysis_rank3.png");
          background-size: 24px;
          background-repeat: no-repeat;
          background-position: center;
        }
      }

      .software-rank {
        width: calc(~"100% - 24px");
        height: 330px;
      }

      dl {
        height: 250px;
        margin: 0;

        li {
          width: 20px;
          height: 20px;
          background: rgba(240, 242, 245, 0.39);
          border-radius: 50%;
          font-size: 12px;
          font-family: Arial;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.65);
          line-height: 20px;
          text-align: center;
        }

        li:nth-child(1),
        li:nth-child(2),
        li:nth-child(3) {
          background: @primary-color;
          color: #ffffff;
        }
      }

      .device-online-rank {
        width: calc(~"100% - 20px");
        height: 250px;
      }

      .device-health {
        width: 100%;
        height: 250px;
      }
    }
  }
}

.ant-dropdown-menu-item,
.ant-dropdown-menu-submenu-title {
  text-align: center;
}
::v-deep .ant-calendar-picker-input {
  cursor: default;
}
::v-deep .ant-calendar-range-picker-input {
  cursor: default;
}
</style>
