<template>
  <layout-content :total="total">
    <!-- 面包屑的插槽 -->
    <breadcrumb slot="breadcrumb"></breadcrumb>
    <div slot="content">
      <!-- 搜索和新增删除 -->
      <div class="d-f a-c j-b">
        <a-form-model ref="queryForm" :model="queryParams" layout="inline">
          <a-form-model-item label="学校名称" prop="name">
            <a-input
              v-model="queryParams.name"
              placeholder="请输入学校名称"
              allowClear
              @keyup.enter.native="handleQuery"
            />
          </a-form-model-item>
          <a-form-model-item>
            <a-button
              type="primary"
              @click="handleQuery"
              v-hasPermi="['basicInfo:school:query']"
              >搜索</a-button
            >
            <a-button
              @click="resetQuery"
              class="ml10"
              v-hasPermi="['basicInfo:school:query']"
              >重置</a-button
            >
          </a-form-model-item>
        </a-form-model>
        <div>
          <a-button
            type="primary"
            @click="handleAdd"
            icon="plus"
            v-hasPermi="['basicInfo:school:add']"
            >新增</a-button
          >
          <a-button
            @click="handleDelete"
            icon="delete"
            class="ml10"
            :disabled="multiple"
            v-hasPermi="['basicInfo:school:remove']"
          >
            批量删除
          </a-button>
        </div>
      </div>
      <!--表格-->
      <a-table
        :columns="columns"
        rowKey="id"
        :data-source="data"
        :pagination="false"
        :row-selection="{
          selectedRowKeys: selectedRowKeys,
          onChange: onSelectChange,
        }"
        class="mt20"
      >
        <span slot="address" slot-scope="text" :title="text">
          {{ text }}
        </span>
        <span slot="action" slot-scope="text, record">
          <a @click="handleUpdate(record)" v-hasPermi="['basicInfo:school:edit']">编辑</a>
          <a-divider type="vertical" />
          <a @click="handleDelete(record)" v-hasPermi="['basicInfo:school:remove']"
            >删除</a
          >
        </span>
      </a-table>
      <!--分页-->
      <div class="d-f mt20">
        <a-pagination
          v-model="queryParams.page"
          :pageSize="queryParams.limit"
          :default-current="1"
          :total="total"
          :hideOnSinglePage="true"
          showLessItems
          @change="onChange"
          :item-render="itemRender"
          style="margin-left: auto"
        />
      </div>
      <!--新增和编辑的弹出框-->
      <a-modal
        v-model="visible"
        :title="title"
        @ok="submit"
        @cancel="handleCancel"
        width="700px"
      >
        <a-form-model
          ref="ruleForm"
          :model="form"
          :rules="rules"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
        >
          <a-row>
            <a-col :span="24">
              <a-form-model-item
                label="学校名称"
                prop="name"
                :label-col="{ span: 4, offset: 0 }"
              >
                <a-input v-model="form.name" placeholder="请输入学校名称" />
              </a-form-model-item>
            </a-col>
            <a-col :span="24">
              <a-form-model-item
                label="logo"
                prop="logo"
                :label-col="{ span: 4, offset: 0 }"
              >
                <!-- 图片剪裁以及预览 -->
                <a-row>
                  <a-col :span="24" :style="{ height: '100px', width: '100px' }">
                    <vue-cropper
                      ref="cropper"
                      :img="options.logo"
                      :info="true"
                      :autoCrop="options.autoCrop"
                      :autoCropWidth="options.autoCropWidth"
                      :autoCropHeight="options.autoCropHeight"
                      :fixedBox="options.fixedBox"
                      @realTime="realTime"
                      outputType="png"
                    />
                    <div class="avatar-upload-preview" v-if="canShow">
                      <img :src="previews.url" :style="previews.img" />
                    </div>
                    <div class="avatar-upload-preview" v-else>
                      <img :src="form.logo" class="avatar" />
                    </div>
                  </a-col>
                </a-row>
                <br />
                <!-- 上传，上下左右旋转以及放大缩小 -->
                <a-row>
                  <a-col :lg="2" :md="2">
                    <a-upload
                      action="#"
                      :http-request="requestUpload"
                      :showUploadList="false"
                      :before-upload="beforeUpload"
                      accept="image/*"
                    >
                      <a-button>选择<a-icon type="upload" /></a-button>
                    </a-upload>
                  </a-col>
                  <a-col :lg="{ span: 1, offset: 7 }" :md="2">
                    <a-button icon="plus" @click="changeScale(1)"></a-button>
                  </a-col>
                  <a-col :lg="{ span: 1, offset: 3 }" :md="2">
                    <a-button icon="minus" @click="changeScale(-1)"></a-button>
                  </a-col>
                  <a-col :lg="{ span: 1, offset: 3 }" :md="2">
                    <a-button icon="undo" @click="rotateLeft()"></a-button>
                  </a-col>
                  <a-col :lg="{ span: 1, offset: 3 }" :md="2">
                    <a-button icon="redo" @click="rotateRight()"></a-button>
                  </a-col>
                </a-row>
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item label="省份/直辖市" prop="provinceCode">
                <a-select
                  v-model="form.provinceCode"
                  placeholder="请选择省份/直辖市"
                  style="width: 100%"
                  :getPopupContainer="(triggerNode) => triggerNode.parentNode"
                  @change="changeProvince"
                >
                  <a-select-option
                    :value="item.id"
                    v-for="item in provinceList"
                    :key="item.id"
                  >
                    {{ item.title }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item label="地市" prop="cityCode">
                <a-select
                  v-model="form.cityCode"
                  placeholder="请选择地市"
                  style="width: 100%"
                  :getPopupContainer="(triggerNode) => triggerNode.parentNode"
                  @change="changCity"
                >
                  <a-select-option
                    :value="item.id"
                    v-for="item in cityList"
                    :key="item.id"
                  >
                    {{ item.title }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item label="区县" prop="districtCode">
                <a-select
                  v-model="form.districtCode"
                  placeholder="请选择区县"
                  style="width: 100%"
                  :getPopupContainer="(triggerNode) => triggerNode.parentNode"
                  @change="changeDistrict"
                >
                  <a-select-option
                    :value="item.id"
                    v-for="item in districtList"
                    :key="item.id"
                  >
                    {{ item.title }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item label="学校地址" prop="address">
                <a-input
                  v-model="form.address"
                  placeholder="请输入学校的详细地址"
                  @change="
                    () => {
                      this.$forceUpdate();
                    }
                  "
                  @focus="focusAddress"
                >
                </a-input>
              </a-form-model-item>
            </a-col>
            <a-col :span="24">
              <a-form-model-item :label-col="{ span: 4, offset: 0 }">
                <baidu-map
                  class="map"
                  :center="location"
                  :zoom="15"
                  :scroll-wheel-zoom="true"
                >
                  <!-- <bm-geolocation
                    anchor="BMAP_ANCHOR_BOTTOM_RIGHT"
                    :showAddressBar="true"
                    :autoLocation="true"
                  ></bm-geolocation> -->
                  <bm-local-search
                    class="map-location"
                    :keyword="form.address"
                    :pageCapacity="3"
                    @infohtmlset="searchComplete"
                    :auto-viewport="true"
                  ></bm-local-search>
                </baidu-map>
              </a-form-model-item>
            </a-col>
          </a-row>
        </a-form-model>
      </a-modal>
    </div>
  </layout-content>
</template>

<script>
import LayoutContent from "@/views/layout/content";
import Breadcrumb from "~c/Breadcrumb";
import {
  getSchoolList,
  schoolAddOrUpdate,
  schoolRemove,
  getSchool,
} from "@/api/basic-info/school.js";
import { getRegionList } from "@/api/setting/region.js";
import { VueCropper } from "vue-cropper";
const columns = [
  {
    title: "学校名称",
    dataIndex: "name",
    align: "center",
  },
  {
    title: "学校编号",
    dataIndex: "schoolNum",
    align: "center",
  },
  {
    title: "学校地址",
    dataIndex: "address",
    align: "center",
    scopedSlots: { customRender: "address" },
    ellipsis: true,
  },
  {
    title: "操作",
    dataIndex: "action",
    // width: "25%",
    align: "center",
    scopedSlots: { customRender: "action" },
  },
];
export default {
  name: "School",
  components: { LayoutContent, Breadcrumb, VueCropper },
  data() {
    return {
      total: null,
      // 查询参数
      queryParams: {
        //当前页
        page: 1,
        //一页几个
        limit: 10,
      },
      //表格的表头文字
      columns,
      //表格的数据
      data: [],
      //多选的数据
      selectedRowKeys: [],
      // 非多个禁用
      multiple: true,
      //新增和编辑的表单
      form: {},
      //表单规则
      rules: {
        name: [
          {
            required: true,
            message: "请输入学校名称",
            whitespace: true,
            trigger: ["change", "blur"],
          },
        ],
        provinceCode: [
          {
            required: true,
            message: "请选择省份",
            whitespace: true,
            trigger: ["change", "blur"],
          },
        ],
        cityCode: [
          {
            required: true,
            message: "请选择地市",
            whitespace: true,
            trigger: ["change", "blur"],
          },
        ],
        districtCode: [
          {
            required: true,
            message: "请选择区县",
            whitespace: true,
            trigger: ["change", "blur"],
          },
        ],
        address: [
          {
            required: true,
            message: "请输入学校地址",
            whitespace: true,
            trigger: ["change", "blur"],
          },
        ],
      },
      //label和wrapper的占位
      labelCol: { span: 8 },
      wrapperCol: { span: 14 },
      //新增和编辑弹出框的标题
      title: "",
      //新增和编辑弹出框的显示隐藏
      visible: false,
      //省份列表
      provinceList: [],
      //地市列表
      cityList: [],
      //区县列表
      districtList: [],
      //地图的中心位置
      location: {
        //经度
        lng: 116.404,
        //纬度
        lat: 39.915,
      },
      //盒子图片裁剪
      options: {
        logo: "", //裁剪图片的地址
        autoCrop: true, // 是否默认生成截图框
        autoCropWidth: 32, // 默认生成截图框宽度
        autoCropHeight: 32, // 默认生成截图框高度
        fixedBox: true, // 固定截图框大小 不允许改变
      },
      //盒子图片预览
      previews: {},
      //盒子图片文件
      file: null,
      //是否展示预览图片
      canShow: true,
    };
  },
  methods: {
    //获取省份列表
    getProvinceList() {
      getRegionList({ parentCode: "00" }).then((res) => {
        this.provinceList = res.data;
      });
    },
    //获取学校列表
    getSchoolList() {
      getSchoolList(this.queryParams).then((res) => {
        this.data = res.data;
        this.total = res.count;
        this.multiple = !this.selectedRowKeys.length;
      });
    },
    //搜索
    handleQuery() {
      this.queryParams.page = 1;
      this.getSchoolList();
    },
    //搜索重置
    resetQuery() {
      this.queryParams.page = 1;
      this.$refs.queryForm.resetFields();
      this.getSchoolList();
    },
    //复选框状态改变
    onSelectChange(selectedRowKeys, records) {
      // console.log(selectedRowKeys);
      // console.log(records);
      this.selectedRowKeys = selectedRowKeys;
      this.multiple = !selectedRowKeys.length;
    },
    //省份改变
    changeProvince(value) {
      this.$forceUpdate();
      getRegionList({ parentCode: value }).then((res) => {
        this.cityList = res.data;
        this.form.cityCode = res.data[0].id;
        getRegionList({ parentCode: this.form.cityCode }).then((res) => {
          this.districtList = res.data;
          this.form.districtCode = res.data[0].id;
          this.form.address =
            this.provinceList.find((r) => r.id === this.form.provinceCode).title +
            this.cityList.find((r) => r.id === this.form.cityCode).title +
            this.districtList.find((r) => r.id === this.form.districtCode).title;
        });
      });
    },
    //地市改变
    changCity(value) {
      this.$forceUpdate();
      getRegionList({ parentCode: value }).then((res) => {
        this.districtList = res.data;
        this.form.districtCode = res.data[0].id;
        this.form.address =
          this.provinceList.find((r) => r.id === this.form.provinceCode).title +
          this.cityList.find((r) => r.id === this.form.cityCode).title +
          this.districtList.find((r) => r.id === this.form.districtCode).title;
      });
    },
    //区县改变
    changeDistrict(value) {
      this.$forceUpdate();
      this.form.address =
        this.provinceList.find((r) => r.id === this.form.provinceCode).title +
        this.cityList.find((r) => r.id === this.form.cityCode).title +
        this.districtList.find((r) => r.id === this.form.districtCode).title;
    },
    /** 新增按钮操作 */
    handleAdd() {
      this.form = {};
      this.options.logo = "";
      this.canShow = true;
      this.visible = true;
      this.title = "新增学校";
    },
    /** 修改按钮操作 */
    async handleUpdate(row) {
      this.form = {};
      let res = await getSchool(row.id);
      this.form = res.data;
      Object.keys(this.form).forEach((key) => {
        if (this.form[key] === null) {
          delete this.form[key];
        }
      });
      this.options.logo = "";
      this.canShow = false;
      let result = await getRegionList({ parentCode: this.form.provinceCode });
      this.cityList = result.data;
      let response = await getRegionList({ parentCode: this.form.cityCode });
      this.districtList = response.data;
      this.visible = true;
      this.title = "修改学校";
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      this.$confirm({
        title: "您确认删除您选中的数据吗？",
        onOk: () => {
          //单个删除
          if (row.id) {
            let arr = [];
            arr.push(row.id);
            schoolRemove(arr).then((res) => {
              this.$message.success("删除成功");
              this.getSchoolList();
            });
          }
          //批量删除
          else {
            schoolRemove(this.selectedRowKeys).then((res) => {
              this.$message.success("删除成功");
              this.selectedRowKeys = [];
              this.getSchoolList();
            });
          }
        },
      });
    },
    //地图完成搜索后的回调
    searchComplete(e) {
      this.$forceUpdate();
      this.form.longitude = e.point.lng;
      this.form.latitude = e.point.lat;
      this.form.address = e.address;
      let mapLocation = document.getElementsByClassName("map-location");
      mapLocation[0].style = "display:none";
    },
    //地图input获取焦点
    focusAddress(value) {
      let mapLocation = document.getElementsByClassName("map-location");
      mapLocation[0].style = "display:block";
    },
    //新增或编辑确定
    submit() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          if (this.form.longitude && this.form.latitude) {
            //修改
            if (this.form.id) {
              //没传图片
              if (!this.canShow) {
                let formdata = this.formdataify(this.form);
                schoolAddOrUpdate(formdata).then((res) => {
                  this.$message.success("修改成功");
                  this.visible = false;
                  this.getSchoolList();
                });
              } else {
                this.$refs.cropper.getCropBlob((data) => {
                  //修改命名
                  const time = new Date().getTime();
                  const randomName = time + "_" + this.file.name;
                  // blob转file
                  let file = new window.File([data], randomName, {
                    type: data.type,
                  });
                  this.form.file = file;
                  let formdata = this.formdataify(this.form);
                  schoolAddOrUpdate(formdata).then((res) => {
                    this.$message.success("新增成功");
                    this.visible = false;
                    this.getSchoolList();
                  });
                });
              }
            }
            //上传
            else {
              //没有上传盒子图片
              if (this.options.logo === "") {
                let formdata = this.formdataify(this.form);
                schoolAddOrUpdate(formdata).then((res) => {
                  this.$message.success("新增成功");
                  this.visible = false;
                  this.getSchoolList();
                });
              } else {
                this.$refs.cropper.getCropBlob((data) => {
                  const time = new Date().getTime();
                  const randomName = time + "_" + this.file.name;
                  // blob转file
                  let file = new window.File([data], randomName, {
                    type: data.type,
                  });
                  this.form.file = file;
                  let formdata = this.formdataify(this.form);
                  schoolAddOrUpdate(formdata).then((res) => {
                    this.$message.success("新增成功");
                    this.visible = false;
                    this.getSchoolList();
                  });
                });
              }
            }
          } else {
            this.$message.error("请根据地图搜索选项选择详细地址");
          }
        }
      });
    },
    //新增或编辑取消
    handleCancel() {
      this.$refs.ruleForm.resetFields();
      this.visible = false;
    },
    //分页状态改变
    onChange(pageNumber) {
      this.queryParams.page = pageNumber;
      this.getSchoolList();
    },
    //改变分页上一步下一步的文字链接
    itemRender(current, type, originalElement) {
      if (type === "prev") {
        return <a>上一页</a>;
      } else if (type === "next") {
        return <a class="next">下一页</a>;
      }
      return originalElement;
    },
    // 覆盖默认的上传行为
    requestUpload() {},
    // 向左旋转
    rotateLeft() {
      this.$refs.cropper.rotateLeft();
    },
    // 向右旋转
    rotateRight() {
      this.$refs.cropper.rotateRight();
    },
    // 图片缩放
    changeScale(num) {
      num = num || 1;
      this.$refs.cropper.changeScale(num);
    },
    // 上传预处理
    beforeUpload(file) {
      if (file.type.indexOf("image/") == -1) {
        this.$message.error("文件格式错误，请上传图片类型,如：JPG，PNG后缀的文件。");
      } else {
        //如果编辑的时侯上传头像
        if (this.canShow === false) {
          this.canShow = true;
        }
        this.file = file;
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          this.options.logo = reader.result;
        };
      }
    },
    // 实时预览
    realTime(data) {
      this.previews = data;
    },
    //json对象转换为formData格式
    formdataify(params) {
      const formData = new FormData();
      Object.keys(params).forEach((key) => {
        formData.append(key, params[key]);
      });
      return formData;
    },
  },
  created() {
    this.getSchoolList();
    this.getProvinceList();
  },
};
</script>

<style scoped lang="less">
.map {
  width: 645px;
  height: 200px; //必须给容器指高度，要不然地图将显示在一个高度为0的容器中，自然看不到
  position: relative;

  .map-location {
    position: absolute;
    top: -28px;
    left: 434px;
    z-index: 88;
  }

  ::v-deep .anchorBL {
    display: none; //隐藏左下角百度地图logo
  }
}

.avatar-upload-preview {
  position: absolute;
  top: 0px;
  left: 110px;
  width: 32px;
  height: 32px;
  box-shadow: 0 0 4px #ccc;
  overflow: hidden;

  .avatar {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
</style>
