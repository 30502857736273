<template>
  <layout-content :total="total">
    <!-- 面包屑的插槽 -->
    <breadcrumb slot="breadcrumb"></breadcrumb>
    <div slot="content">
      <!-- 搜索和新增删除 -->
      <div class="d-f a-c j-b">
        <a-form-model ref="queryForm" :model="queryParams" layout="inline">
          <a-form-model-item label="班级名称" prop="name">
            <a-input
              v-model="queryParams.name"
              placeholder="请输入班级名称"
              allowClear
              @keyup.enter.native="handleQuery"
            />
          </a-form-model-item>
          <a-form-model-item>
            <a-button
              type="primary"
              @click="handleQuery"
              v-hasPermi="['basicInfo:class:query']"
              >搜索</a-button
            >
            <a-button
              @click="resetQuery"
              class="ml10"
              v-hasPermi="['basicInfo:class:query']"
              >重置</a-button
            >
          </a-form-model-item>
        </a-form-model>
        <div>
          <a-button
            type="primary"
            @click="handleAdd"
            icon="plus"
            v-hasPermi="['basicInfo:class:add']"
            >新增</a-button
          >
          <a-button
            @click="handleDelete"
            icon="delete"
            class="ml10"
            :disabled="multiple"
            v-hasPermi="['basicInfo:class:remove']"
          >
            批量删除
          </a-button>
        </div>
      </div>
      <!--表格-->
      <a-table
        :columns="columns"
        rowKey="id"
        :data-source="data"
        :pagination="false"
        :row-selection="{
          selectedRowKeys: selectedRowKeys,
          onChange: onSelectChange,
        }"
        class="mt20"
      >
        <span slot="classroomName" slot-scope="text, record">
          <span v-if="text">
            {{ text }}
            <a
              @click="handleClassroomUnbind(record)"
              v-hasPermi="['basicInfo:class:unbindClassroom']"
            >
              解除绑定
            </a>
          </span>
          <span v-else>
            无
            <a
              @click="handleClassroomBind(record)"
              v-hasPermi="['basicInfo:class:bindClassroom']"
            >
              固定教室
            </a>
          </span>
        </span>
        <span slot="teacherName" slot-scope="text, record">
          <a
            @click="handleTeacherQuery(record.id)"
            v-hasPermi="['basicInfo:class:query']"
          >
            查看详情
          </a>
        </span>
        <span slot="action" slot-scope="text, record">
          <a @click="handleUpdate(record)" v-hasPermi="['basicInfo:class:edit']">编辑</a>
          <a-divider type="vertical" />
          <a @click="handleDelete(record)" v-hasPermi="['basicInfo:class:remove']">
            删除
          </a>
        </span>
      </a-table>
      <!--分页-->
      <div class="d-f mt20">
        <a-pagination
          v-model="queryParams.page"
          :pageSize="queryParams.limit"
          :default-current="1"
          :total="total"
          :hideOnSinglePage="true"
          showLessItems
          @change="onChange"
          :item-render="itemRender"
          style="margin-left: auto"
        />
      </div>
      <!--新增和编辑的弹出框-->
      <a-modal
        v-model="visible"
        :title="title"
        @ok="submit"
        @cancel="handleCancel"
        width="700px"
      >
        <a-form-model
          ref="ruleForm"
          :model="form"
          :rules="rules"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
        >
          <a-row>
            <a-col :span="24">
              <a-form-model-item
                label="班级名称"
                prop="name"
                :label-col="{ span: 4, offset: 0 }"
                :wrapper-col="{ span: 20, offset: 0 }"
              >
                <a-input
                  v-model="form.name"
                  placeholder="建议“一年级1班”，“一年级（一）班”"
                />
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item label="学段" prop="rankIdentity">
                <a-select
                  v-model="form.rankIdentity"
                  placeholder="请选择学段"
                  style="width: 100%"
                  :getPopupContainer="(triggerNode) => triggerNode.parentNode"
                  @change="changeRank"
                >
                  <a-select-option
                    :value="item.rankIdentity"
                    v-for="item in rankList"
                    :key="item.rankIdentity"
                  >
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item label="年级" prop="gradeIdentity">
                <a-select
                  v-model="form.gradeIdentity"
                  placeholder="请选择学段后选择年级"
                  style="width: 100%"
                  :getPopupContainer="(triggerNode) => triggerNode.parentNode"
                  @change="
                    () => {
                      this.$forceUpdate();
                    }
                  "
                >
                  <a-select-option
                    :value="item.gradeIdentity"
                    v-for="item in gradeList"
                    :key="item.gradeIdentity"
                  >
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item label="班级" prop="classNum">
                <a-select
                  v-model="form.classNum"
                  placeholder="请选择班级"
                  style="width: 100%"
                  :getPopupContainer="(triggerNode) => triggerNode.parentNode"
                >
                  <a-select-option
                    :value="item.valueName + '***' + item.valueData"
                    v-for="item in classList"
                    :key="item.id"
                  >
                    {{ item.valueName }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item label="所在楼栋" prop="buildingId">
                <a-select
                  v-model="form.buildingId"
                  placeholder="请选择所在楼栋"
                  style="width: 100%"
                  :getPopupContainer="(triggerNode) => triggerNode.parentNode"
                  @change="changeBuilding"
                >
                  <a-select-option
                    :value="item.id"
                    v-for="item in buildingList"
                    :key="item.id"
                  >
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item label="固定教室" prop="classroomId">
                <a-select
                  v-model="form.classroomId"
                  placeholder="请选择所在楼栋后选择教室"
                  style="width: 100%"
                  :getPopupContainer="(triggerNode) => triggerNode.parentNode"
                  @change="
                    () => {
                      this.$forceUpdate();
                    }
                  "
                >
                  <a-select-option
                    :value="item.id"
                    v-for="item in classroomList"
                    :key="item.id"
                  >
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item label="显示排序" prop="sortNum">
                <a-input-number
                  v-model="form.sortNum"
                  placeholder="请输入显示排序"
                  :min="1"
                  style="width: 100%"
                />
              </a-form-model-item>
            </a-col>
          </a-row>
        </a-form-model>
      </a-modal>
      <!--固定教室的弹出框-->
      <a-modal
        v-model="classroomVisible"
        title="固定教室"
        @ok="classroomSubmit"
        width="700px"
      >
        <a-form-model
          ref="classroomRuleForm"
          :model="classroomForm"
          :rules="classroomRules"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
        >
          <a-row>
            <a-col :span="12">
              <a-form-model-item label="所在楼栋" prop="buildingId">
                <a-select
                  v-model="classroomForm.buildingId"
                  placeholder="请选择所在楼栋"
                  style="width: 100%"
                  :getPopupContainer="(triggerNode) => triggerNode.parentNode"
                  @change="changeBuilding"
                >
                  <a-select-option
                    :value="item.id"
                    v-for="item in buildingList"
                    :key="item.id"
                  >
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item label="固定教室" prop="classroomId">
                <a-select
                  v-model="classroomForm.classroomId"
                  placeholder="请选择所在楼栋后选择教室"
                  style="width: 100%"
                  :getPopupContainer="(triggerNode) => triggerNode.parentNode"
                  @change="
                    () => {
                      this.$forceUpdate();
                    }
                  "
                >
                  <a-select-option
                    :value="item.id"
                    v-for="item in classroomList"
                    :key="item.id"
                  >
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
          </a-row>
        </a-form-model>
      </a-modal>
      <!--查看绑定教师的弹出框-->
      <a-modal v-model="teacherVisible" title="绑定教师" width="700px">
        <div class="d-f">
          <a-button
            type="primary"
            @click="handleTeacherBind"
            icon="plus"
            v-hasPermi="['basicInfo:class:bindTeacher']"
            style="margin-left: auto; margin-bottom: 10px"
            >绑定教师</a-button
          >
        </div>
        <a-table
          size="small"
          :columns="teacherColumns"
          rowKey="id"
          :data-source="teacherData"
          :pagination="false"
        >
          <span slot="action" slot-scope="text, record">
            <a
              @click="handleTeacherUnbind(record)"
              v-hasPermi="['basicInfo:class:unbindTeacher']"
            >
              解除绑定
            </a>
          </span>
        </a-table>
      </a-modal>
      <!--添加绑定教师的弹出框-->
      <a-modal
        v-model="addTeacherVisible"
        title="添加绑定教师"
        width="700px"
        @ok="addTeacher"
      >
        <a-form-model
          ref="addTeacherForm"
          :model="addTeacherForm"
          :rules="addTeacherRules"
          :label-col="{ span: 4 }"
          :wrapper-col="{ span: 20 }"
        >
          <a-form-model-item label="选择教师" prop="teacherIds">
            <a-select
              mode="multiple"
              v-model="addTeacherForm.teacherIds"
              placeholder="请选择教师"
              style="width: 100%"
              optionFilterProp="children"
              :getPopupContainer="(triggerNode) => triggerNode.parentNode"
            >
              <a-select-option v-for="item in teacherList" :key="item.id">
                {{ item.username }}
                <span v-if="item.realName">（{{ item.realName }}）</span>
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-form-model>
      </a-modal>
    </div>
  </layout-content>
</template>

<script>
import LayoutContent from "@/views/layout/content";
import Breadcrumb from "~c/Breadcrumb";
import {
  getClassList,
  classAddOrUpdate,
  classRemove,
  getClass,
  classBindClassroom,
  classBindTeacher,
  getTeacherData,
  classUnBindTeacher,
} from "@/api/basic-info/class.js";
import { getRankList } from "@/api/setting/rank.js";
import { getGradeList } from "@/api/setting/grade.js";
import { getBuildingList } from "@/api/basic-info/building.js";
import { getClassroomList } from "@/api/basic-info/classroom.js";
import { getTeacherList } from "@/api/teacher-student/teacher.js";
const columns = [
  {
    title: "班级名称",
    dataIndex: "name",
    align: "center",
  },
  {
    title: "学段",
    dataIndex: "rankName",
    align: "center",
  },
  {
    title: "年级",
    dataIndex: "gradeName",
    align: "center",
  },
  {
    title: "班级",
    dataIndex: "classNumName",
    align: "center",
  },
  {
    title: "固定教室",
    dataIndex: "classroomName",
    align: "center",
    scopedSlots: { customRender: "classroomName" },
  },
  {
    title: "绑定教师",
    dataIndex: "teacherName",
    align: "center",
    scopedSlots: { customRender: "teacherName" },
  },
  {
    title: "操作",
    dataIndex: "action",
    align: "center",
    scopedSlots: { customRender: "action" },
  },
];
const teacherColumns = [
  {
    title: "教师用户名",
    dataIndex: "username",
    align: "center",
  },
  {
    title: "教师姓名",
    dataIndex: "realName",
    align: "center",
  },
  {
    title: "操作",
    dataIndex: "action",
    align: "center",
    scopedSlots: { customRender: "action" },
  },
];
export default {
  name: "Class",
  components: { LayoutContent, Breadcrumb },
  data() {
    return {
      total: null,
      // 查询参数
      queryParams: {
        //当前页
        page: 1,
        //一页几个
        limit: 10,
      },
      //表格的表头文字
      columns,
      //表格的数据
      data: [],
      //多选的数据
      selectedRowKeys: [],
      // 非多个禁用
      multiple: true,
      //新增和编辑的表单
      form: {},
      //表单规则
      rules: {
        name: [
          {
            required: true,
            message: "请输入班级名称",
            whitespace: true,
            trigger: ["change", "blur"],
          },
        ],
        rankIdentity: [
          {
            required: true,
            message: "请选择学段",
            whitespace: true,
            trigger: ["change", "blur"],
          },
        ],
        gradeIdentity: [
          {
            required: true,
            message: "请选择年级",
            whitespace: true,
            trigger: ["change", "blur"],
          },
        ],
        classNum: [
          {
            required: true,
            message: "请选择班级",
            whitespace: true,
            trigger: ["change", "blur"],
          },
        ],
        sortNum: [
          {
            type: "number",
            required: true,
            message: "请输入显示排序",
            whitespace: true,
            trigger: ["change", "blur"],
          },
        ],
      },
      //label和wrapper的占位
      labelCol: { span: 7 },
      wrapperCol: { span: 17 },
      //新增和编辑弹出框的标题
      title: "",
      //新增和编辑弹出框的显示隐藏
      visible: false,
      //学段列表
      rankList: [],
      //年级列表
      gradeList: [],
      //班级列表
      classList: [],
      //楼栋列表
      buildingList: [],
      //教室列表
      classroomList: [],
      //固定教室弹出框的显示隐藏
      classroomVisible: false,
      //固定教室的表单
      classroomForm: {},
      //固定教室表单规则
      classroomRules: {
        buildingId: [
          {
            required: true,
            message: "请选择所在楼栋",
            whitespace: true,
            trigger: ["change", "blur"],
          },
        ],
        classroomId: [
          {
            required: true,
            message: "请选择固定教室",
            whitespace: true,
            trigger: ["change", "blur"],
          },
        ],
      },
      //查看教师详情弹出框的显示隐藏
      teacherVisible: false,
      //教师表格的表头文字
      teacherColumns,
      //教师的数据列表
      teacherData: [],
      //添加教师弹出框的显示隐藏
      addTeacherVisible: false,
      //添加教师的表单
      addTeacherForm: {},
      //添加教师表单规则
      addTeacherRules: {
        teacherIds: [
          {
            type: "array",
            required: true,
            message: "请选择教师",
            whitespace: true,
            trigger: ["change", "blur"],
          },
        ],
      },
      //教师列表
      teacherList: [],
    };
  },
  methods: {
    //获取班级列表
    getClassList() {
      getClassList(this.queryParams).then((res) => {
        this.data = res.data;
        this.total = res.count;
        this.multiple = !this.selectedRowKeys.length;
      });
    },
    //搜索
    handleQuery() {
      this.queryParams.page = 1;
      this.getClassList();
    },
    //搜索重置
    resetQuery() {
      this.queryParams.page = 1;
      this.$refs.queryForm.resetFields();
      this.getClassList();
    },
    //复选框状态改变
    onSelectChange(selectedRowKeys, records) {
      this.selectedRowKeys = selectedRowKeys;
      this.multiple = !selectedRowKeys.length;
    },
    /** 新增按钮操作 */
    async handleAdd() {
      this.form = {};
      let res = await getBuildingList({ isPage: false });
      let result = await getRankList({ isPage: false });
      let response = await this.getDictDataList("school_class");
      this.buildingList = res.data;
      this.rankList = result.data;
      this.classList = response.data;
      this.visible = true;
      this.title = "新增班级";
    },
    //学段改变
    changeRank(value) {
      this.form.gradeIdentity = undefined;
      this.gradeList = [];
      getGradeList({ isPage: false, rankIdentity: value }).then((res) => {
        this.gradeList = res.data;
      });
    },
    //楼栋改变
    changeBuilding(value) {
      this.form.classroomId = undefined;
      this.classroomForm.classroomId = undefined;
      this.classroomList = [];
      getClassroomList({ isPage: false, buildingId: value, roomType: "2" }).then(
        (res) => {
          this.classroomList = res.data;
        }
      );
    },
    /** 修改按钮操作 */
    async handleUpdate(row) {
      this.form = {};
      let r = await getClass(row.id);
      let res = await getBuildingList({ isPage: false });
      let result = await getRankList({ isPage: false });
      let response = await this.getDictDataList("school_class");
      this.form = r.data;
      this.form.classNum = this.form.classNumName + "***" + this.form.classNumData;
      this.buildingList = res.data;
      this.rankList = result.data;
      this.classList = response.data;
      let rr = await getGradeList({
        isPage: false,
        rankIdentity: this.form.rankIdentity,
      });
      this.gradeList = rr.data;
      if (this.form.buildingId) {
        let rrr = await getClassroomList({
          isPage: false,
          buildingId: this.form.buildingId,
          roomType: "2",
        });
        this.classroomList = rrr.data;
        if (!this.form.classroomId) {
          this.form.classroomId = undefined;
        }
      } else {
        this.form.buildingId = undefined;
        this.form.classroomId = undefined;
      }
      this.visible = true;
      this.title = "修改班级";
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      this.$confirm({
        title: "您确认删除您选中的数据吗？",
        onOk: () => {
          //单个删除
          if (row.id) {
            let arr = [];
            arr.push(row.id);
            classRemove(arr).then((res) => {
              this.$message.success("删除成功");
              this.getClassList();
            });
          }
          //批量删除
          else {
            classRemove(this.selectedRowKeys).then((res) => {
              this.$message.success("删除成功");
              this.selectedRowKeys = [];
              this.getClassList();
            });
          }
        },
      });
    },
    //新增或编辑确定
    submit() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          let params = JSON.parse(JSON.stringify({ ...this.form }));
          params.classNumName = params.classNum.substr(0, params.classNum.indexOf("***"));
          params.classNumData = params.classNum.substr(
            params.classNum.indexOf("***") + 3
          );
          delete params.classNum;
          classAddOrUpdate(params).then((res) => {
            //修改
            if (this.form.id) {
              this.$message.success("修改成功");
            }
            //新增
            else {
              this.$message.success("新增成功");
            }
            this.visible = false;
            this.getClassList();
          });
        }
      });
    },
    //新增或编辑取消
    handleCancel() {
      this.$refs.ruleForm.resetFields();
      this.visible = false;
    },
    //分页状态改变
    onChange(pageNumber) {
      this.queryParams.page = pageNumber;
      this.getClassList();
    },
    //改变分页上一步下一步的文字链接
    itemRender(current, type, originalElement) {
      if (type === "prev") {
        return <a>上一页</a>;
      } else if (type === "next") {
        return <a class="next">下一页</a>;
      }
      return originalElement;
    },
    //固定教室
    async handleClassroomBind(row) {
      this.classroomForm = {};
      this.classroomForm.classId = row.id;
      let res = await getBuildingList({ isPage: false });
      this.buildingList = res.data;
      this.classroomVisible = true;
    },
    //固定教室确定
    classroomSubmit() {
      this.$refs.classroomRuleForm.validate((valid) => {
        if (valid) {
          classBindClassroom(this.classroomForm).then((res) => {
            this.$message.success("绑定成功");
            this.getClassList();
            this.classroomVisible = false;
          });
        }
      });
    },
    //解绑教室
    handleClassroomUnbind(row) {
      this.$confirm({
        title: "您确认解除班级和教室的绑定吗？",
        onOk: () => {
          classBindClassroom({ classId: row.id }).then((res) => {
            this.$message.success("解绑成功");
            this.getClassList();
          });
        },
      });
    },
    //查看绑定教师详情
    handleTeacherQuery(classId) {
      getTeacherData(classId).then((res) => {
        this.teacherData = res.data;
        this.teacherVisible = true;
        this.addTeacherForm.classId = classId;
      });
    },
    //绑定教师
    handleTeacherBind() {
      getTeacherList({ isPage: false }).then((res) => {
        this.teacherList = res.data;
        this.addTeacherVisible = true;
        let classId = JSON.parse(JSON.stringify(this.addTeacherForm.classId));
        this.addTeacherForm = {};
        this.addTeacherForm.classId = classId;
      });
    },
    //添加绑定教师确定
    addTeacher() {
      this.$refs.addTeacherForm.validate((valid) => {
        if (valid) {
          classBindTeacher(this.addTeacherForm).then((res) => {
            this.$message.success("绑定成功");
            this.handleTeacherQuery(this.addTeacherForm.classId);
            this.addTeacherVisible = false;
          });
        }
      });
    },
    //解绑教师
    handleTeacherUnbind(row) {
      this.$confirm({
        title: "您确认解除该教师和班级的绑定吗？",
        onOk: () => {
          classUnBindTeacher({
            classId: this.addTeacherForm.classId,
            teacherId: row.id,
          }).then((res) => {
            this.$message.success("解绑成功");
            this.handleTeacherQuery(this.addTeacherForm.classId);
          });
        },
      });
    },
  },
  created() {
    this.getClassList();
  },
};
</script>

<style scoped lang="less"></style>
