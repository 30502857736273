import request from '@/utils/request'

//获取软件列表
export function getInstallList(params) {
    return request.get('/api-device/device/software-list', params)
}

//获取软件详情
export function getInstall(id) {
    return request.get('/api-device/device/software-list/'+id)
}

//新增或者编辑软件
export function installAddOrUpdate(params) {
    return request.post('/api-device/device/software-list/saveOrUpdate', params)
}

//删除软件
export function installRemove(params) {
    return request.remove('/api-device/device/software-list/deleteBatch', params)
}

//下载软件
export function installDownload(id) {
    return request.get('/api-device/device/software-list/download/'+id)
}

//获取sts临牌
export function getStsInfo(params) {
    return request.get('/api-device/device/software-list/oss/token', params)
}