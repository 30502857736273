import request from '@/utils/request'

//获取互动时长
export function getInteractionTime(params) {
    return request.get('/api-device/interact-school-time', params)
}

//获取互动房间列表
export function getInteractionRoomList(params) {
    return request.get('/api-device/interact-room/list', params)
}

//关闭房间
export function closeRoom(params) {
    return request.get('/api-device/interact-room/remote-close', params)
}