import request from '@/utils/request'

//获取楼栋列表
export function getSubjectList(params) {
    return request.get('/api-school/subject', params)
}

//新增或修改楼栋
export function subjectAddOrUpdate(params) {
    return request.post('/api-school/subject/saveOrUpdate', params)
}

//获取楼栋详情
export function getSubject(id) {
    return request.get('/api-school/subject/'+id)
}

//删除楼栋
export function subjectRemove(params) {
    return request.remove('/api-school/subject/deleteBatch', params)
}

//根据学科id获取绑定教师列表
export function getTeacherData(id) {
    return request.get('/api-school/subject/getBindTeacher/' + id)
}

//学科绑定教师
export function subjectBindTeacher(params) {
    return request.post('/api-school/subject/bindTeacher', params)
}

//学科解绑教师
export function subjectUnBindTeacher(params) {
    return request.remove('/api-school/subject/unbindTeacher', params)
}