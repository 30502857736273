import request from '@/utils/request'


//获取教师列表
export function getTeacherList(params) {
    return request.get('/api-user/teacher', params)
}

//新增或修改教师
export function teacherAddOrUpdate(params) {
    return request.post('/api-user/teacher/saveOrUpdate', params)
}

//获取教师详情
export function getTeacher(id) {
    return request.get('/api-user/teacher/' + id)
}

//删除教师
export function teacherRemove(id) {
    return request.remove('/api-user/teacher/'+id)
}

//下载教师模板
export function teacherDownloadTemplate(params,name) {
    return request.download('/api-user/teacher/downloadTemplate',params,name)
}

//导出教师数据
export function teacherExport(params,name) {
    return request.download('/api-user/teacher/export',params,name)
}

//批量导入教师
export function teacherImport(params) {
    return request.post('/api-user/teacher/import', params)
}