<template>
  <div class="layout-content d-f f-d">
    <div class="list-container">
      <div class="left">
        <a-tree
          :load-data="onLoadData"
          :loaded-keys="loadedKeys"
          :tree-data="treeData"
          @select="onSelect"
          @expand="onExpand"
          :expanded-keys="expandedKeys"
        />
      </div>
      <div class="right">
        <a-card style="width: 100%">
          <a-button
            type="primary"
            @click="handleAdd"
            icon="plus"
            v-hasPermi="['setting:region:add']"
            :disabled="notParentCode"
            >新增下级</a-button
          >
          <a-button
            @click="handleDelete"
            icon="delete"
            class="ml10"
            :disabled="notParentCode"
            v-hasPermi="['setting:region:remove']"
          >
            删除
          </a-button>
        </a-card>
        <div class="mt20">
          <a-card style="width: 100%">
            <a-form-model
              ref="ruleForm"
              :model="form"
              :rules="rules"
              :label-col="labelCol"
              :wrapper-col="wrapperCol"
            >
              <a-form-model-item label="父区域编号" prop="parentCode">
                <a-input
                  v-model="form.parentCode"
                  placeholder="请输入父区域编号"
                  disabled
                />
              </a-form-model-item>
              <a-form-model-item label="父区域名称" prop="parentName">
                <a-input
                  v-model="form.parentName"
                  placeholder="请输入父区域名称"
                  disabled
                />
              </a-form-model-item>
              <a-form-model-item label="区域编号" prop="selfCode">
                <a-input-group compact>
                  <a-input style="width: 20%" v-model="form.parentCode" disabled />
                  <a-input
                    style="width: 80%"
                    v-model="form.selfCode"
                    placeholder="请输入区域编号"
                  />
                </a-input-group>
              </a-form-model-item>
              <a-form-model-item label="区域名称" prop="name">
                <a-input v-model="form.name" placeholder="请输入区域名称" />
              </a-form-model-item>
              <a-form-model-item label="区域等级" prop="level">
                <a-radio-group v-model="form.level">
                  <!-- <a-radio :value="0"> 国家 </a-radio> -->
                  <a-radio :value="1"> 省份/直辖市 </a-radio>
                  <a-radio :value="2"> 地市 </a-radio>
                  <a-radio :value="3"> 区县 </a-radio>
                  <a-radio :value="4"> 乡镇 </a-radio>
                  <a-radio :value="5"> 村委 </a-radio>
                </a-radio-group>
              </a-form-model-item>
              <a-form-model-item label="区域排序" prop="sort">
                <a-input-number
                  v-model="form.sort"
                  placeholder="请输入区域排序"
                  :min="1"
                  style="width: 100%"
                />
              </a-form-model-item>
              <a-form-model-item label="区划备注" prop="remark">
                <a-input
                  v-model="form.remark"
                  type="textarea"
                  placeholder="请输入区划备注"
                  :rows="6"
                  :maxLength="200"
                />
              </a-form-model-item>
              <a-form-model-item :wrapper-col="{ span: 14, offset: 4 }">
                <a-button type="primary" @click="submit"> 提交 </a-button>
                <a-button class="ml20" @click="handleCancel"> 清空 </a-button>
              </a-form-model-item>
            </a-form-model>
          </a-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getRegionList,
  regionAddOrUpdate,
  regionRemove,
  getRegion,
} from "@/api/setting/region.js";
export default {
  name: "Region",
  data() {
    return {
      //树状结构的父id
      parentCode: "00",
      //树状结构数组
      treeData: [],
      //非选择区域禁用
      notParentCode: true,
      //新增和编辑的表单
      form: {},
      //表单规则
      rules: {
        parentCode: [
          {
            required: true,
            message: "请输入父区域编号",
            whitespace: true,
            trigger: ["change", "blur"],
          },
        ],
        parentName: [
          {
            required: true,
            message: "请输入父区域名称",
            whitespace: true,
            trigger: ["change", "blur"],
          },
        ],
        selfCode: [
          {
            required: true,
            message: "请输入区域编号",
            whitespace: true,
            trigger: ["change", "blur"],
          },
        ],
        name: [
          {
            required: true,
            message: "请输入区域名称",
            whitespace: true,
            trigger: ["change", "blur"],
          },
        ],
        level: [
          {
            type: "number",
            required: true,
            message: "请输入区域等级",
            whitespace: true,
            trigger: ["change", "blur"],
          },
        ],
        sort: [
          {
            type: "number",
            required: true,
            message: "请输入区域排序",
            whitespace: true,
            trigger: ["change", "blur"],
          },
        ],
      },
      //label和wrapper的占位
      labelCol: { span: 4 },
      wrapperCol: { span: 18 },
      //选中的数组
      selectedKeys: [],
      //展开的数组
      expandedKeys: [],
      //已经加载的节点
      loadedKeys: [],
    };
  },
  methods: {
    //获取区域数据
    getRegionList() {
      getRegionList({ parentCode: this.parentCode }).then((res) => {
        this.treeData = res.data;
      });
    },
    //懒加载获取下级地域
    onLoadData(treeNode) {
      return new Promise((resolve) => {
        if (treeNode.dataRef.children) {
          resolve();
          return;
        }
        getRegionList({ parentCode: treeNode.value }).then((res) => {
          treeNode.dataRef.children = res.data;
          this.treeData = [...this.treeData];
          resolve();
        });
      });
    },
    //点击节点选择获取详情
    onSelect(selectedKeys) {
      if (selectedKeys.length == 1) {
        this.selectedKeys = selectedKeys;
        getRegion({ code: selectedKeys[0] }).then((res) => {
          this.notParentCode = false;
          this.form = res.data;
          let length = this.form.parentCode.length;
          this.form.selfCode = this.form.code.slice(length);
        });
      }
    },
    //树状控件展开
    onExpand(expandedKeys) {
      this.expandedKeys = expandedKeys;
    },
    /** 新增按钮操作 */
    handleAdd() {
      let params = JSON.parse(JSON.stringify(this.form));
      params.parentCode = this.form.code;
      params.parentName = this.form.name;
      params.selfCode = null;
      params.name = null;
      params.level = this.form.level + 1;
      params.sort = null;
      this.form = params;
    },
    /** 删除按钮操作 */
    handleDelete() {
      this.$confirm({
        title: "您确认删除您选中的数据吗？",
        onOk: () => {
          regionRemove(this.selectedKeys[0]).then((res) => {
            this.$message.success("删除成功");
            this.$refs.ruleForm.resetFields();
            this.notParentCode = true;
            this.expandedKeys = [];
            this.loadedKeys = [];
            this.treeData = [];
            this.getRegionList();
          });
        },
      });
    },
    //新增或编辑确定
    submit() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.form.code = this.form.parentCode + this.form.selfCode;
          let params = JSON.parse(JSON.stringify({ ...this.form }));
          delete params.selfCode;
          regionAddOrUpdate(params).then((res) => {
            this.$message.success("操作成功");
            this.$refs.ruleForm.resetFields();
            this.notParentCode = true;
            this.expandedKeys = [];
            this.loadedKeys = [];
            this.treeData = [];
            this.getRegionList();
          });
        }
      });
    },
    //清空
    handleCancel() {
      this.$refs.ruleForm.resetFields();
      this.notParentCode = true;
    },
  },
  created() {
    this.getRegionList();
  },
};
</script>

<style scoped lang="less">
.layout-content {
  width: 100%;
  height: 100%;
  .list-container {
    width: 100%;
    height: 100%;
    padding: 24px;
    display: grid;
    grid-template-columns: 1fr 1.5fr;
    grid-column-gap: 20px;
    .left {
      background: #ffffff;
      border-radius: 4px;
      overflow-y: auto;
      height: 680px;
      padding: 24px;
      box-sizing: border-box;
    }
    .left::-webkit-scrollbar {
      /*滚动条整体样式*/
      width: 3px;
    }

    .left::-webkit-scrollbar-thumb {
      /*滚动条里面小方块*/
      border-radius: 2px;
      background: #b6b5b5;
    }

    .left::-webkit-scrollbar-track {
      /*滚动条里面轨道*/
      background: #dfe8e3;
    }
    .right {
      width: 100%;
    }
  }
}
</style>
