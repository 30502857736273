<template>
  <div class="layout-content d-f f-d">
    <breadcrumb></breadcrumb>
    <div class="list-container">
      <div class="list">
        <div class="title d-f j-b">
          <div>{{ currentMeta }}</div>
        </div>
        <div class="space d-f">
          <div class="classroom-left">
            <div class="all-check d-f a-c">
              <a-checkbox
                :indeterminate="indeterminate"
                :checked="checkAll"
                @change="onCheckAllChange"
                >全部教室（{{ checkedList.length }}/{{ total }}项）</a-checkbox
              >
            </div>
            <!-- 设备列表 -->
            <a-checkbox-group
              v-model="checkedList"
              @change="onCheckChange"
              class="switch-list"
            >
              <div
                class="box"
                v-for="item in plainOptions"
                :key="item.serialNum"
                :title="item.buildingName + '-' + item.classroomName"
              >
                <a-checkbox :value="item.serialNum" class="checkbox">
                  {{ item.buildingName + "-" + item.classroomName }}
                </a-checkbox>
              </div>
            </a-checkbox-group>
          </div>
          <div class="classroom-right">
            <div class="d-f a-c j-b">
              <a-form-model ref="queryForm" :model="queryParams" layout="inline">
                <a-form-model-item label="波特率" prop="bps">
                  <a-select
                    v-model="queryParams.bps"
                    placeholder="请选择波特率"
                    style="width: 144px"
                    :getPopupContainer="(triggerNode) => triggerNode.parentNode"
                  >
                    <a-select-option
                      v-for="item in baudList"
                      :key="item.valueData"
                      :value="item.valueData"
                      >{{ item.valueName }}</a-select-option
                    >
                  </a-select>
                </a-form-model-item>
                <a-form-model-item>
                  <a-button type="primary" @click="confirm">确定</a-button>
                </a-form-model-item>
              </a-form-model>
              <div>
                <a-button
                  type="primary"
                  @click="handleAdd"
                  icon="plus"
                  v-hasPermi="['device:switchCommand:add']"
                  >新增指令</a-button
                >
              </div>
            </div>
            <div class="command">
              <a-table :columns="columns" :data-source="data" :pagination="false">
                <template slot="commandName" slot-scope="text, record, index">
                  <a-select
                    :value="text"
                    style="width: 100%"
                    placeholder="请选择"
                    :getPopupContainer="(triggerNode) => triggerNode.parentNode"
                    @change="(e) => changeCommandName(e, record, index)"
                  >
                    <a-select-option
                      v-for="item in commandNameList"
                      :key="item.commandIdentification"
                      :value="item.commandIdentification"
                      >{{ item.commandName }}</a-select-option
                    >
                  </a-select>
                </template>
                <template slot="commandCode" slot-scope="text, record, index">
                  <a-input
                    :value="text"
                    @change="(e) => changeCommandCode(e.target.value, record, index)"
                  />
                </template>
                <template slot="model" slot-scope="text, record, index">
                  <a-select
                    :value="text"
                    style="width: 100%"
                    placeholder="请选择"
                    :getPopupContainer="(triggerNode) => triggerNode.parentNode"
                    @change="(e) => changeModel(e, record, index)"
                  >
                    <a-select-option
                      v-for="item in modelList"
                      :key="item.valueData"
                      :value="item.valueData"
                      >{{ item.valueName }}</a-select-option
                    >
                  </a-select>
                </template>
                <template slot="action" slot-scope="text, record, index">
                  <span>
                    <a @click="test(record)">调试</a>
                  </span>
                  <span class="ml10">
                    <a @click="remove(record, index)">删除</a>
                  </span>
                </template>
              </a-table>
            </div>
            <div class="save d-f j-c">
              <a-button
                type="primary"
                @click="handleSave"
                v-hasPermi="['device:switchCommand:add']"
                >保存</a-button
              >
              <a-button
                @click="handleReset"
                class="ml10"
                v-hasPermi="['device:switchCommand:add']"
                >重置</a-button
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumb from "~c/Breadcrumb";
import {
  getSwitchList,
  getCommandCodeByCommandNameAndModel,
  text,
  save,
  setBPS,
} from "@/api/device/switch-command.js";
import { getCommandList } from "@/api/device/command.js";
const columns = [
  {
    title: "指令名称",
    dataIndex: "commandName",
    scopedSlots: { customRender: "commandName" },
  },
  {
    title: "指令代码",
    dataIndex: "commandCode",
    width: "30%",
    scopedSlots: { customRender: "commandCode" },
  },
  {
    title: "协议类型",
    dataIndex: "model",
    scopedSlots: { customRender: "model" },
  },
  {
    title: "操作",
    dataIndex: "action",
    width: "15%",
    scopedSlots: { customRender: "action" },
  },
];
export default {
  name: "SwitchCommand",
  components: { Breadcrumb },
  computed: {
    currentMeta() {
      return this.$route.meta.title;
    },
  },
  data() {
    return {
      //智能开关数量
      total: 0,
      //负责样式控制
      indeterminate: false,
      //是否全选
      checkAll: false,
      //选中的复选框数组
      checkedList: [],
      //复选框数组
      plainOptions: [],
      columns,
      //表单数据
      data: [],
      //协议类型列表
      modelList: [],
      //指令名称列表
      commandNameList: [],
      //波特率列表
      baudList: [],
      //波特率表单
      queryParams: {},
    };
  },
  methods: {
    //获取所有智能开关列表
    getSwitchList() {
      getSwitchList({ isPage: false, deviceType: "switch" }).then((res) => {
        this.plainOptions = res.data;
        this.total = res.count;
      });
    },
    //获取协议类型列表
    getModelList() {
      this.getDictDataList("4g-instruct").then((res) => {
        this.modelList = res.data;
        this.getCommandNameList();
      });
    },
    //获取波特率列表
    getBaudList() {
      this.getDictDataList("switch_baud").then((res) => {
        this.baudList = res.data;
        this.queryParams.bps = res.data[0].valueData;
      });
    },
    //获取指令名称列表
    async getCommandNameList() {
      let res = await getCommandList({ isPage: false, commandType: "5" });
      this.commandNameList = res.data;
      let arr = JSON.parse(JSON.stringify(res.data));
      let arr1 = [];
      for (let index = 0; index < arr.length; index++) {
        let obj = {};
        obj.key = index;
        obj.commandName = arr[index].commandIdentification;
        obj.model = this.modelList[0].valueData;
        let commandCode = await this.getCommandCodeByCommandNameAndModel(
          obj.model,
          obj.commandName
        );
        obj.commandCode = commandCode;
        arr1.push(obj);
      }
      this.data = arr1;
    },
    //根据指令名称和协议类型获取指令代码
    async getCommandCodeByCommandNameAndModel(model, commandName) {
      let res = await getCommandCodeByCommandNameAndModel({
        tempId: model,
        code: commandName,
      });
      return res.data.instruct;
    },
    //选择指令名称改变
    async changeCommandName(value, record, index) {
      const newData = [...this.data];
      newData[index].commandName = value;
      newData[index].commandCode = await this.getCommandCodeByCommandNameAndModel(
        record.model,
        value
      );
      this.data = newData;
    },
    //输入指令代码改变
    changeCommandCode(value, record, index) {
      const newData = [...this.data];
      newData[index].commandCode = value;
      this.data = newData;
    },
    //选择协议类型改变
    async changeModel(value, record, index) {
      const newData = [...this.data];
      newData[index].model = value;
      newData[index].commandCode = await this.getCommandCodeByCommandNameAndModel(
        value,
        record.commandName
      );
      this.data = newData;
    },
    /** 新增按钮操作 */
    async handleAdd() {
      let newData = [...this.data];
      newData.forEach((r, i) => (r.key = i + 1));
      let commandName = this.commandNameList[0].commandIdentification;
      let model = this.modelList[0].valueData;
      let commandCode = await this.getCommandCodeByCommandNameAndModel(
        model,
        commandName
      );
      let item = {
        key: 0,
        commandName,
        commandCode,
        model,
      };
      newData.unshift(item);
      this.data = newData;
    },
    //删除
    remove(recoed, index) {
      let newData = [...this.data];
      newData.splice(index, 1);
      newData.map((r, i) => (r.key = i));
      this.data = newData;
    },
    /** 重置按钮操作 */
    async handleReset() {
      this.data = [];
      let arr = JSON.parse(JSON.stringify(this.commandNameList));
      let arr1 = [];
      for (let index = 0; index < arr.length; index++) {
        let obj = {};
        obj.key = index;
        obj.commandName = arr[index].commandIdentification;
        obj.model = this.modelList[0].valueData;
        let commandCode = await this.getCommandCodeByCommandNameAndModel(
          obj.model,
          obj.commandName
        );
        obj.commandCode = commandCode;
        arr1.push(obj);
      }
      this.data = arr1;
    },
    //全选改变
    onCheckAllChange(e) {
      let arr = this.plainOptions.map((r) => r.serialNum);
      this.checkedList = e.target.checked ? arr : [];
      this.indeterminate = false;
      this.checkAll = e.target.checked;
    },
    //单个复选框改变
    onCheckChange(checkedList) {
      this.indeterminate =
        !!checkedList.length && checkedList.length < this.plainOptions.length;
      this.checkAll = checkedList.length === this.plainOptions.length;
    },
    //调试
    test(record) {
      if (this.checkedList.length > 0) {
        text({
          serialNums: this.checkedList.join(","),
          transparent: record.commandCode,
        }).then((res) => {
          this.$message.success(res.msg);
        });
      } else {
        this.$message.error("请选择您想调试的教室");
      }
    },
    //保存
    handleSave() {
      if (this.checkedList.length > 0) {
        let list = JSON.parse(JSON.stringify(this.data));
        let arr = [];
        list.forEach((r) => {
          let obj = {};
          obj.instruct = r.commandCode;
          obj.code = r.commandName;
          arr.push(obj);
        });
        save({ serialNums: this.checkedList, sysSwitchInstructs: arr }).then((res) => {
          this.$message.success("保存成功");
        });
      } else {
        this.$message.error("请选择您想调试的教室");
      }
    },
    //波特率确定
    confirm() {
      if (this.checkedList.length > 0) {
        setBPS({ serialNums: this.checkedList, bps: this.queryParams.bps }).then(
          (res) => {
            this.$message.success("发送成功");
          }
        );
      } else {
        this.$message.error("请选择教室");
      }
    },
  },
  created() {
    this.getModelList();
    this.getBaudList();
    this.getSwitchList();
  },
};
</script>

<style scoped lang="less">
.layout-content {
  width: 100%;
  //   height: 100%;
  height: calc(~"100vh - 64px");

  .list-container {
    // flex: 1;
    width: 100%;
    padding: 24px;
    height: calc(~"100vh - 118px");
    box-sizing: border-box;

    .list {
      width: 100%;
      height: 100%;
      background: #ffffff;

      .title {
        width: 100%;
        height: 70px;
        border-bottom: 1px solid #e9e9e9;
        padding: 24px 32px;
        box-sizing: border-box;
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: bold;
        color: rgba(0, 0, 0, 0.85);
      }

      .space {
        width: 100%;
        height: calc(~"100% - 71px");

        .classroom-left {
          width: 250px;
          height: 100%;
          border-right: 1px solid #e9e9e9;

          .all-check {
            padding-left: 24px;
            width: 100%;
            height: 52px;
            box-sizing: border-box;
            border-bottom: 1px solid #e9e9e9;
          }

          .switch-list {
            width: calc(~"100% - 1px");
            height: calc(~"100% - 53px");
            padding-left: 24px;
            padding-top: 16px;
            box-sizing: border-box;
            overflow-y: auto;
            .box {
              height: 36px;
              width: 100%;
              font-size: 14px;
              font-family: PingFang SC-Regular, PingFang SC;
              font-weight: 400;
              color: rgba(0, 0, 0, 0.65);
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }

          .switch-list::-webkit-scrollbar {
            /*滚动条整体样式*/
            width: 1px;
          }

          .switch-list::-webkit-scrollbar-thumb {
            /*滚动条里面小方块*/
            background: rgb(180, 180, 182);
          }

          .switch-list::-webkit-scrollbar-track {
            /*滚动条里面轨道*/
            background: rgba(255, 255, 255, 0.15);
          }
        }
        .classroom-right {
          width: calc(~"100% - 250px");
          height: 100%;
          padding: 24px 24px 16px;
          box-sizing: border-box;
          .command {
            width: 100%;
            max-height: calc(~"100% - 113px");
            margin-top: 24px;
            overflow-y: auto;
          }
          .save {
            width: 100%;
            margin-top: 25px;
          }
        }
      }
    }
  }
}
</style>
