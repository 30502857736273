<template>
  <a-form-model ref="phoneForm" :model="form" :rules="rules" class="account-form">
    <a-form-model-item prop="mobile">
      <a-input v-model="form.mobile" placeholder="手机号">
        <img src="@/assets/images/login_phone.png" width="16px" slot="prefix" />
      </a-input>
    </a-form-model-item>
    <a-row>
      <a-col :span="12">
        <a-form-model-item prop="code">
          <a-input v-model="form.code" placeholder="验证码">
            <img src="@/assets/images/login_code.png" width="16px" slot="prefix" />
          </a-input>
        </a-form-model-item>
      </a-col>
      <a-col :span="2"></a-col>
      <a-col :span="10">
        <send-code
          ref="codeLogin"
          :canSend="canSend"
          :totalCount="totalCount"
          @canSendAgain="getSendMessage"
          @click.native="getCode($event)"
        ></send-code>
      </a-col>
    </a-row>
    <a-form-model-item>
      <div class="d-f j-b">
        <a></a>
        <router-link to="/forget">忘记密码</router-link>
      </div>
    </a-form-model-item>
    <a-form-model-item>
      <a-button type="primary" @click="submit"> 登录 </a-button>
    </a-form-model-item>
    <a-form-model-item>
      <div class="d-f j-b">
        <span>
          其他登录方式
          <img
            src="@/assets/images/login_wx.png"
            width="24px"
            class="wx"
            @click="wxLogin"
          />
          <!-- <img
            src="@/assets/images/login_dd.png"
            width="24px"
            class="wx"
            @click="wxLogin"
          /> -->
        </span>
        <router-link to="/register">注册账户</router-link>
      </div>
    </a-form-model-item>
  </a-form-model>
</template>

<script>
import SendCode from "@/components/SendCode.vue";
import { smsLoginSend, login } from "@/api/login.js";
import { getUserInfo } from "@/api/acl/user.js";
import { setCookie } from "@/utils/cookie.js";
export default {
  name: "Phone",
  components: { SendCode },
  data() {
    return {
      form: {},
      rules: {
        mobile: [
          {
            required: true,
            message: "请输入手机号",
            whitespace: true,
            trigger: ["change", "blur"],
          },
          {
            pattern: /^[1][3,4,5,6,7,8,9][0-9]{9}$/,
            message: "手机号格式错误",
            trigger: "blur",
          },
        ],
        code: [
          {
            required: true,
            message: "请输入验证码",
            whitespace: true,
            trigger: ["change", "blur"],
          },
          {
            pattern: /^\d{4}$/,
            message: "验证码格式错误",
            trigger: "blur",
          },
        ],
      },
      canSend: true,
      totalCount: 60,
    };
  },
  methods: {
    //获取验证码
    getCode(event) {
      event.stopPropagation();
      this.$refs["phoneForm"].validateField(["mobile"], (Error) => {
        if (!Error) {
          if (this.canSend) {
            //这里可以插入$axios调用后台接口
            //验证手机号,发送登录认证短信
            smsLoginSend(this.form.mobile).then((res) => {
              this.canSend = false;
              this.$refs.codeLogin.getCode();
              this.$message.success("验证码发送成功");
            });
          }
        }
      });
    },
    //倒计时结束可以重新发送验证码（子组件传值）
    getSendMessage(data) {
      this.canSend = data;
    },
    //登录
    submit() {
      this.$refs.phoneForm.validate((valid) => {
        if (valid) {
          this.form.grant_type = "mobile_sms";
          login(this.form).then((res) => {
            let { access_token, token_type, refresh_token, scope } = res.data;
            setCookie("access_token", access_token, 7);
            setCookie("token_type", token_type, 7);
            setCookie("refresh_token", refresh_token, 7);
            setCookie("scope", scope, 7);
            this.$ls.set("access_token", access_token, 7 * 24 * 60 * 60 * 1000); //有效7*24小时
            this.$ls.set("token_type", token_type, 7 * 24 * 60 * 60 * 1000); //有效7*24小时
            this.$ls.set("refresh_token", refresh_token, 7 * 24 * 60 * 60 * 1000); //有效7*24小时
            this.$ls.set("scope", scope, 7 * 24 * 60 * 60 * 1000); //有效7*24小时
            getUserInfo().then((result) => {
              this.$message.success("登录成功");
              this.$ls.set("userInfo", result.data, 7 * 24 * 60 * 60 * 1000); //有效7*24小时
              this.$router.push("/enter");
            });
          });
        }
      });
    },
    //微信扫码登录
    wxLogin() {
      this.$router.push("/wx-login");
    },
    //钉钉扫码登录
    ddLogin() {
      this.$router.push("/dd-login");
    },
  },
  created() {},
};
</script>

<style scoped lang="less">
.account-form {
  width: 100%;
  margin-top: 45px;
  ::v-deep .ant-input {
    border: 1px solid transparent;
    border-bottom: 1px solid #e8eaee;
  }
  ::v-deep .ant-input:hover {
    border-color: transparent;
    border-bottom: 1px solid #e8eaee;
    box-shadow: none;
  }
  ::v-deep .ant-input:focus {
    border-color: transparent;
    border-bottom: 1px solid #e8eaee;
    box-shadow: none;
  }
  ::v-deep .ant-input-affix-wrapper:hover {
    .ant-input:not(.ant-input-disabled) {
      border-color: transparent;
      border-bottom: 1px solid #e8eaee;
      box-shadow: none;
    }
  }
  ::v-deep .has-error {
    .ant-input {
      border-color: transparent;
      border-bottom: 1px solid #f5222d;
      box-shadow: none;
    }
    .ant-input:focus {
      border-color: transparent;
      border-bottom: 1px solid #f5222d;
      box-shadow: none;
    }
    .ant-input:hover {
      border-color: transparent;
      border-bottom: 1px solid #f5222d;
      box-shadow: none;
    }
  }
  ::v-deep .ant-input-affix-wrapper {
    .ant-input:not(:first-child) {
      padding-left: 40px;
    }
  }
  button {
    width: 100%;
    height: 36px;
    border-radius: 18px;
  }
  .wx {
    margin-left: 18px;
  }
}
</style>
