<template>
  <layout-content :total="total">
    <!-- 面包屑的插槽 -->
    <breadcrumb slot="breadcrumb"></breadcrumb>
    <div slot="content">
      <!-- 搜索和新增删除 -->
      <div class="d-f a-c j-b">
        <a-form-model ref="queryForm" :model="queryParams" layout="inline">
          <a-form-model-item label="指令名称" prop="commandName">
            <a-input
              v-model="queryParams.commandName"
              placeholder="请输入指令名称"
              allowClear
              @keyup.enter.native="handleQuery"
              style="width: 150px"
            />
          </a-form-model-item>
          <a-form-model-item label="指令标识" prop="commandIdentification">
            <a-input
              v-model="queryParams.commandIdentification"
              placeholder="请输入指令标识"
              allowClear
              @keyup.enter.native="handleQuery"
              style="width: 150px"
            />
          </a-form-model-item>
          <a-form-model-item label="指令类型" prop="commandType">
            <a-select
              v-model="queryParams.commandType"
              placeholder="请选择指令类型"
              style="width: 150px"
              allowClear
              :getPopupContainer="(triggerNode) => triggerNode.parentNode"
            >
              <a-select-option
                :value="item.valueData"
                v-for="item in commandTypeList"
                :key="item.id"
              >
                {{ item.valueName }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item>
            <a-button
              type="primary"
              @click="handleQuery"
              v-hasPermi="['device:command:query']"
              >搜索</a-button
            >
            <a-button
              @click="resetQuery"
              class="ml10"
              v-hasPermi="['device:command:query']"
              >重置</a-button
            >
          </a-form-model-item>
        </a-form-model>
        <div>
          <a-button
            type="primary"
            @click="handleAdd"
            icon="plus"
            v-hasPermi="['device:command:add']"
            >新增</a-button
          >
          <a-button
            @click="refreshCache"
            class="ml10"
            v-hasPermi="['device:command:query']"
            >刷新缓存</a-button
          >
        </div>
      </div>
      <!--表格-->
      <a-table
        :columns="columns"
        rowKey="id"
        :data-source="data"
        :pagination="false"
        class="mt20"
      >
        <span slot="deviceTypeIdentification" slot-scope="text">
          <span>
            {{ handleDeviceTypeIdentification(text) }}
          </span>
        </span>
        <span slot="commandExecutor" slot-scope="text">
          <a-tag color="#009FE8">
            {{ dictTranslation(commandExecutorList, text) }}
          </a-tag>
        </span>
        <span slot="commandType" slot-scope="text">
          {{ dictTranslation(commandTypeList, text) }}
        </span>
        <span slot="action" slot-scope="text, record">
          <a @click="handleUpdate(record)" v-hasPermi="['device:command:edit']">编辑</a>
          <a-divider type="vertical" />
          <a @click="handleDelete(record)" v-hasPermi="['device:command:remove']">删除</a>
        </span>
      </a-table>
      <!--分页-->
      <div class="d-f mt20">
        <a-pagination
          v-model="queryParams.page"
          :pageSize="queryParams.limit"
          :default-current="1"
          :total="total"
          :hideOnSinglePage="true"
          showLessItems
          @change="onChange"
          :item-render="itemRender"
          style="margin-left: auto"
        />
      </div>
      <!--新增和编辑的弹出框-->
      <a-modal
        v-model="visible"
        :title="title"
        @ok="submit"
        @cancel="handleCancel"
        width="700px"
      >
        <a-form-model
          ref="ruleForm"
          :model="form"
          :rules="rules"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
        >
          <a-row>
            <a-col :span="12">
              <a-form-model-item label="指令名称" prop="commandName">
                <a-input v-model="form.commandName" placeholder="请输入指令名称" />
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item label="指令标识" prop="commandIdentification">
                <a-input
                  v-model="form.commandIdentification"
                  placeholder="请输入指令标识"
                />
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item label="设备类型" prop="deviceTypeIdentification">
                <a-select
                  v-model="form.deviceTypeIdentification"
                  placeholder="请选择设备类型"
                  style="width: 100%"
                  :getPopupContainer="(triggerNode) => triggerNode.parentNode"
                >
                  <a-select-option
                    v-for="item in typeList"
                    :key="item.deviceTypeIdentification"
                    :value="item.deviceTypeIdentification"
                  >
                    {{ item.deviceTypeName }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item label="执行者" prop="commandExecutor">
                <a-select
                  v-model="form.commandExecutor"
                  placeholder="请选择执行者"
                  style="width: 100%"
                  :getPopupContainer="(triggerNode) => triggerNode.parentNode"
                >
                  <a-select-option
                    :value="item.valueData"
                    v-for="item in commandExecutorList"
                    :key="item.id"
                  >
                    {{ item.valueName }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item label="执行函数" prop="commandFunc">
                <a-input v-model="form.commandFunc" placeholder="请输入执行函数" />
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item label="显示排序" prop="orderNum">
                <a-input-number
                  v-model="form.orderNum"
                  placeholder="请输入显示排序"
                  :min="1"
                  style="width: 100%"
                />
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item label="指令类型" prop="commandType">
                <a-select
                  v-model="form.commandType"
                  placeholder="请选择指令类型"
                  style="width: 100%"
                  :getPopupContainer="(triggerNode) => triggerNode.parentNode"
                >
                  <a-select-option
                    :value="item.valueData"
                    v-for="item in commandTypeList"
                    :key="item.id"
                  >
                    {{ item.valueName }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="24">
              <a-form-model-item
                label="指令描述"
                prop="commandDesc"
                :label-col="{ span: 4, offset: 0 }"
              >
                <a-input v-model="form.commandDesc" type="textarea" :rows="6" />
              </a-form-model-item>
            </a-col>
          </a-row>
        </a-form-model>
      </a-modal>
    </div>
  </layout-content>
</template>

<script>
import LayoutContent from "@/views/layout/content";
import Breadcrumb from "~c/Breadcrumb";
import { getTypeList } from "@/api/device/type.js";
import {
  getCommandList,
  commandAddOrUpdate,
  commandRemove,
  getCommand,
  refreshCache,
} from "@/api/device/command.js";
const columns = [
  {
    title: "指令名称",
    dataIndex: "commandName",
    align: "center",
  },
  {
    title: "指令标识",
    dataIndex: "commandIdentification",
    align: "center",
  },
  {
    title: "设备类型",
    dataIndex: "deviceTypeIdentification",
    align: "center",
    scopedSlots: { customRender: "deviceTypeIdentification" },
  },
  {
    title: "执行者",
    dataIndex: "commandExecutor",
    align: "center",
    scopedSlots: { customRender: "commandExecutor" },
  },
  {
    title: "执行函数",
    dataIndex: "commandFunc",
    align: "center",
  },
  {
    title: "指令类型",
    dataIndex: "commandType",
    align: "center",
    scopedSlots: { customRender: "commandType" },
  },
  {
    title: "显示排序",
    dataIndex: "orderNum",
    align: "center",
  },
  {
    title: "操作",
    dataIndex: "action",
    align: "center",
    scopedSlots: { customRender: "action" },
  },
];
export default {
  name: "Command",
  components: { LayoutContent, Breadcrumb },
  data() {
    return {
      total: null,
      // 查询参数
      queryParams: {
        //当前页
        page: 1,
        //一页几个
        limit: 10,
      },
      //表格的表头文字
      columns,
      //表格的数据
      data: [],
      //新增和编辑的表单
      form: {},
      //表单规则
      rules: {
        commandName: [
          {
            required: true,
            message: "请输入指令名称",
            whitespace: true,
            trigger: ["change", "blur"],
          },
        ],
        commandIdentification: [
          {
            required: true,
            message: "请输入指令标识",
            whitespace: true,
            trigger: ["change", "blur"],
          },
        ],
        deviceTypeIdentification: [
          {
            required: true,
            message: "请选择指令类型",
            whitespace: true,
            trigger: ["change", "blur"],
          },
        ],
        commandExecutor: [
          {
            required: true,
            message: "请选择执行者",
            whitespace: true,
            trigger: ["change", "blur"],
          },
        ],
        commandFunc: [
          {
            required: true,
            message: "请输入指令函数",
            whitespace: true,
            trigger: ["change", "blur"],
          },
        ],
        orderNum: [
          {
            type: "number",
            required: true,
            message: "请输入显示排序",
            whitespace: true,
            trigger: ["change", "blur"],
          },
        ],
        commandType: [
          {
            required: true,
            message: "请选择状态",
            whitespace: true,
            trigger: ["change", "blur"],
          },
        ],
      },
      //label和wrapper的占位
      labelCol: { span: 8 },
      wrapperCol: { span: 16 },
      //新增和编辑弹出框的标题
      title: "",
      //新增和编辑弹出框的显示隐藏
      visible: false,
      //设备类型列表
      typeList: [],
      //设备指令执行者列表
      commandExecutorList: [],
      //设备指令类型列表
      commandTypeList: [],
    };
  },
  methods: {
    //获取通信列表
    getCommandList() {
      getCommandList(this.queryParams).then((res) => {
        this.data = res.data;
        this.total = res.count;
      });
    },
    //获取设备执行者列表
    getCommandExecutorList() {
      this.getDictDataList("device_command_executor").then((res) => {
        this.commandExecutorList = res.data;
      });
    },
    //获取设备指令类型列表
    getCommandTypeList() {
      this.getDictDataList("device_command_type").then((res) => {
        this.commandTypeList = res.data;
      });
    },
    //获取设备类型列表
    getTypeList() {
      getTypeList({ isPage: false }).then((res) => {
        this.typeList = res.data;
      });
    },
    //翻译设备类型
    handleDeviceTypeIdentification(val) {
      if (val) {
        return this.typeList.find((r) => r.deviceTypeIdentification === val)
          .deviceTypeName;
      }
    },
    //搜索
    handleQuery() {
      this.queryParams.page = 1;
      this.getCommandList();
    },
    //搜索重置
    resetQuery() {
      this.$refs.queryForm.resetFields();
      this.queryParams = {
        page: 1,
        limit: 10,
      };
      this.getCommandList();
    },
    //刷新缓存
    refreshCache() {
      refreshCache().then((res) => {
        this.$message.success("刷新成功");
      });
    },
    /** 新增按钮操作 */
    handleAdd() {
      this.form = {};
      this.visible = true;
      this.title = "新增指令";
    },
    /** 修改按钮操作 */
    async handleUpdate(row) {
      this.form = {};
      let res = await getCommand(row.id);
      this.form = res.data;
      this.visible = true;
      this.title = "修改指令";
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      this.$confirm({
        title: "您确认删除您选中的数据吗？",
        onOk: () => {
          commandRemove(row.id).then((res) => {
            this.$message.success("删除成功");
            this.getCommandList();
          });
        },
      });
    },
    //新增或编辑确定
    submit() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          commandAddOrUpdate(this.form).then((res) => {
            //修改
            if (this.form.id) {
              this.$message.success("修改成功");
            }
            //新增
            else {
              this.$message.success("新增成功");
            }
            this.visible = false;
            this.getCommandList();
          });
        }
      });
    },
    //新增或编辑取消
    handleCancel() {
      this.$refs.ruleForm.resetFields();
      this.visible = false;
    },
    //分页状态改变
    onChange(pageNumber) {
      this.queryParams.page = pageNumber;
      this.getCommandList();
    },
    //改变分页上一步下一步的文字链接
    itemRender(current, type, originalElement) {
      if (type === "prev") {
        return <a>上一页</a>;
      } else if (type === "next") {
        return <a class="next">下一页</a>;
      }
      return originalElement;
    },
  },
  created() {
    this.getCommandList();
    this.getCommandExecutorList();
    this.getCommandTypeList();
    this.getTypeList();
  },
};
</script>

<style scoped lang="less"></style>
