<template>
  <layout-content :total="total">
    <!-- 面包屑的插槽 -->
    <breadcrumb slot="breadcrumb"></breadcrumb>
    <div slot="content">
      <!-- 搜索和新增删除 -->
      <div class="d-f a-c j-b">
        <a-form-model ref="queryForm" :model="queryParams" layout="inline">
          <a-form-model-item label="软件名称" prop="softwareName">
            <a-input
              v-model="queryParams.softwareName"
              placeholder="请输入软件名称"
              allowClear
              @keyup.enter.native="handleQuery"
            />
          </a-form-model-item>
          <a-form-model-item>
            <a-button
              type="primary"
              @click="handleQuery"
              v-hasPermi="['software:install:query']"
              >搜索</a-button
            >
            <a-button
              @click="resetQuery"
              class="ml10"
              v-hasPermi="['software:install:query']"
              >重置</a-button
            >
          </a-form-model-item>
        </a-form-model>
        <div>
          <a-button
            type="primary"
            @click="handleAdd"
            icon="plus"
            v-hasPermi="['software:install:add']"
            >新增</a-button
          >
          <a-button
            @click="handleDelete"
            icon="delete"
            class="ml10"
            :disabled="multiple"
            v-hasPermi="['software:install:remove']"
          >
            批量删除
          </a-button>
        </div>
      </div>
      <!--表格-->
      <a-table
        :columns="columns"
        rowKey="id"
        :data-source="data"
        :pagination="false"
        :row-selection="{
          selectedRowKeys: selectedRowKeys,
          onChange: onSelectChange,
        }"
        class="mt20"
      >
        <span
          slot="softwareName"
          slot-scope="text, record"
          :title="record.softwareDescription"
          style="display: inline-block; position: relative"
        >
          <img
            :src="record.softwareLogo"
            width="50px"
            height="50px"
            style="border-radius: 10px"
          />
          <span
            style="
              font-weight: 600;
              margin-left: 20px;
              display: inline-block;
              position: absolute;
            "
            >{{ record.softwareName }}</span
          >
          <span
            style="
              width: 240px;
              overflow: hidden;
              text-overflow: ellipsis;
              display: inline-block;
              position: absolute;
              top: 25px;
              margin-left: 20px;
            "
          >
            {{ record.softwareDescription }}
          </span>
        </span>
        <span slot="action" slot-scope="text, record">
          <a @click="handleInstall(record)" v-hasPermi="['software:install:install']"
            >安装</a
          >
          <a-divider type="vertical" v-hasPermi="['software:install:uninstall']" />
          <a
            @click="handleUninstall(record)"
            v-hasPermi="['software:install:uninstall']"
            style="color: #f5222d"
            >卸载</a
          >
          <a-divider type="vertical" v-hasPermi="['software:install:download']" />
          <a @click="handleDownload(record)" v-hasPermi="['software:install:download']"
            >下载</a
          >
          <a-divider type="vertical" v-hasPermi="['software:install:edit']" />
          <a @click="handleUpdate(record)" v-hasPermi="['software:install:edit']">修改</a>
          <a-divider type="vertical" v-hasPermi="['software:install:remove']" />
          <a @click="handleDelete(record)" v-hasPermi="['software:install:remove']"
            >删除</a
          >
        </span>
      </a-table>
      <!--分页-->
      <div class="d-f mt20">
        <a-pagination
          v-model="queryParams.page"
          :pageSize="queryParams.limit"
          :default-current="1"
          :total="total"
          :hideOnSinglePage="true"
          showLessItems
          @change="onChange"
          :item-render="itemRender"
          style="margin-left: auto"
        />
      </div>
      <!--新增或编辑的弹出框-->
      <a-modal
        v-model="visible"
        :title="title"
        @ok="submit"
        @cancel="handleCancel"
        width="700px"
      >
        <a-spin :spinning="confirmLoading">
          <a-form-model
            ref="ruleForm"
            :model="form"
            :rules="rules"
            :label-col="labelCol"
            :wrapper-col="wrapperCol"
          >
            <a-row>
              <a-col :span="12">
                <a-form-model-item label="软件名称" prop="softwareName">
                  <a-input v-model="form.softwareName" placeholder="请输入软件名称" />
                </a-form-model-item>
              </a-col>
              <a-col :span="12">
                <a-form-model-item label="版本号" prop="softVersion">
                  <a-input v-model="form.softVersion" placeholder="请输入版本号" />
                </a-form-model-item>
              </a-col>
              <a-col :span="12">
                <a-form-model-item label="所属租户" prop="tenantId">
                  <a-select
                    v-model="form.tenantId"
                    placeholder="请选择所属租户"
                    style="width: 100%"
                    allowClear
                    :getPopupContainer="(triggerNode) => triggerNode.parentNode"
                  >
                    <a-select-option
                      :value="item.tenantId"
                      v-for="item in tenantList"
                      :key="item.tenantId"
                    >
                      {{ item.tenantName }}
                    </a-select-option>
                  </a-select>
                </a-form-model-item>
              </a-col>
              <a-col :span="12">
                <a-form-model-item label="版本状态" prop="versionStatus">
                  <a-input v-model="form.versionStatus" placeholder="请输入版本状态" />
                </a-form-model-item>
              </a-col>
              <a-col :span="12">
                <a-form-model-item label="所属平台" prop="softwarePlatform">
                  <a-radio-group v-model="form.softwarePlatform">
                    <a-radio value="windows"> Windows </a-radio>
                    <a-radio value="linux"> Linux </a-radio>
                  </a-radio-group>
                </a-form-model-item>
              </a-col>
              <a-col :span="12">
                <a-form-model-item label="发布状态" prop="publishFlag">
                  <a-radio-group v-model="form.publishFlag">
                    <a-radio :value="1"> 已发布 </a-radio>
                    <a-radio :value="0"> 未发布 </a-radio>
                  </a-radio-group>
                </a-form-model-item>
              </a-col>
              <a-col :span="24">
                <a-form-model-item
                  label="软件描述"
                  prop="softwareDescription"
                  :label-col="{ span: 4, offset: 0 }"
                >
                  <a-input
                    v-model="form.softwareDescription"
                    type="textarea"
                    placeholder="请输入软件描述"
                    :rows="6"
                  />
                </a-form-model-item>
              </a-col>
              <a-col :span="24">
                <a-form-model-item
                  label="软件包"
                  prop="softwareDescription"
                  :label-col="{ span: 4, offset: 0 }"
                >
                  <a-upload-dragger
                    name="file"
                    :file-list="fileList"
                    :remove="handleRemove"
                    :before-upload="beforeUploadFile"
                  >
                    <p class="ant-upload-drag-icon">
                      <a-icon type="inbox" />
                    </p>
                    <p class="ant-upload-text">
                      点击或将文件拖到这里上传(多个文件只上传第一个)
                    </p>
                  </a-upload-dragger>
                </a-form-model-item>
              </a-col>
              <a-col :span="24">
                <a-form-model-item
                  label="图标"
                  prop="logo"
                  :label-col="{ span: 4, offset: 0 }"
                >
                  <!-- 图片剪裁以及预览 -->
                  <a-row>
                    <a-col :span="24" :style="{ height: '100px', width: '100px' }">
                      <vue-cropper
                        ref="cropper"
                        :img="options.logo"
                        :info="true"
                        :autoCrop="options.autoCrop"
                        :autoCropWidth="options.autoCropWidth"
                        :autoCropHeight="options.autoCropHeight"
                        :fixedBox="options.fixedBox"
                        @realTime="realTime"
                        outputType="png"
                      />
                      <div class="logo-upload-preview" v-if="canShow">
                        <img :src="previews.url" :style="previews.img" />
                      </div>
                      <div class="logo-upload-preview" v-else>
                        <img :src="form.logo" class="logo" />
                      </div>
                    </a-col>
                  </a-row>
                  <br />
                  <!-- 上传，上下左右旋转以及放大缩小 -->
                  <a-row>
                    <a-col :lg="2" :md="2">
                      <a-upload
                        action="#"
                        :http-request="requestUpload"
                        :showUploadList="false"
                        :before-upload="beforeUpload"
                        accept="image/*"
                      >
                        <a-button>选择<a-icon type="upload" /></a-button>
                      </a-upload>
                    </a-col>
                    <a-col :lg="{ span: 1, offset: 7 }" :md="2">
                      <a-button icon="plus" @click="changeScale(1)"></a-button>
                    </a-col>
                    <a-col :lg="{ span: 1, offset: 3 }" :md="2">
                      <a-button icon="minus" @click="changeScale(-1)"></a-button>
                    </a-col>
                    <a-col :lg="{ span: 1, offset: 3 }" :md="2">
                      <a-button icon="undo" @click="rotateLeft()"></a-button>
                    </a-col>
                    <a-col :lg="{ span: 1, offset: 3 }" :md="2">
                      <a-button icon="redo" @click="rotateRight()"></a-button>
                    </a-col>
                  </a-row>
                </a-form-model-item>
              </a-col>
            </a-row>
          </a-form-model>
        </a-spin>
      </a-modal>

      <!--安装卸载的弹出框-->
      <a-modal
        v-model="installVisible"
        :title="installTitle"
        :footer="null"
        @cancel="installCancel"
        width="700px"
      >
        <div class="d-f">
          <img
            :src="record.softwareLogo"
            style="border-radius: 10px"
            width="65px"
            height="65px"
          />
          <div class="ml20">
            <div style="font-size: 14px; font-weight: 600; color: rgba(0, 0, 0, 0.9)">
              {{ record.softwareName }}
            </div>
            <div
              style="
                margin-top: 4px;
                font-size: 12px;
                font-weight: 600;
                color: rgba(0, 0, 0, 0.45);
              "
            >
              {{ record.softVersion }}<a-divider type="vertical" />
              {{ record.versionStatus }}<a-divider type="vertical" />
              {{ record.fileSize }}
            </div>
            <div style="margin-top: 4px; font-size: 12px; color: rgba(0, 0, 0, 0.65)">
              {{ record.softwareDescription }}
            </div>
          </div>
        </div>
        <div class="mt24" style="width: 100%; height: 1px; background: #d9dee5"></div>
        <div class="d-f mt20">
          <div style="margin-left: auto">
            在线设备<a-checkbox
              @change="changeOnlineStatus"
              style="margin-left: 4px"
              :checked="checked"
            ></a-checkbox>
            <a-button
              class="ml24"
              type="primary"
              @click="install"
              :disabled="installMultiple"
              >批量{{ installTitle }}</a-button
            >
          </div>
        </div>
        <a-table
          size="small"
          :columns="installColumns"
          rowKey="serialNum"
          :data-source="installData"
          :pagination="false"
          class="mt20"
          bordered
          :customHeaderRow="() => ({ class: 'tb-head' })"
          :rowSelection="{
            selectedRowKeys: installSelectedRowKeys,
            onChange: installOnSelectChange,
            getCheckboxProps,
          }"
        >
          <span slot="device" slot-scope="text, record">
            {{ record.buildingName + "-" + record.classroomName }}
          </span>
          <span slot="onlineFlag" slot-scope="text">
            {{ text ? "在线" : "离线" }}
          </span>
          <span slot="action" slot-scope="text, record">
            <a @click="install(record)" v-if="record.onlineFlag">{{ installTitle }}</a>
            <span v-else>——</span>
          </span>
        </a-table>
        <!--分页-->
        <div class="d-f mt20">
          <a-pagination
            v-model="installQueryParams.page"
            :pageSize="installQueryParams.limit"
            :default-current="1"
            :total="installTotal"
            :hideOnSinglePage="true"
            showLessItems
            @change="installOnChange"
            :item-render="itemRender"
            style="margin-left: auto"
          />
        </div>
      </a-modal>
    </div>
  </layout-content>
</template>

<script>
import md5 from "js-md5";
import LayoutContent from "@/views/layout/content";
import Breadcrumb from "~c/Breadcrumb";
import {
  getInstallList,
  getInstall,
  installRemove,
  installAddOrUpdate,
  installDownload,
  getStsInfo,
} from "@/api/software/install.js";
import { getTenantALlList } from "@/api/acl/tenant.js";
import { VueCropper } from "vue-cropper";
import Client from "@/utils/oss.js";
import actionType from "@/utils/action-type";
import { websocketSend } from "@/utils/websocket-send.js";
import { v4 as uuidv4 } from "uuid";
import { getTerminalList } from "@/api/device/terminal.js";
const installColumns = [
  {
    title: "设备",
    dataIndex: "device",
    align: "center",
    scopedSlots: { customRender: "device" },
  },
  {
    title: "设备状态",
    dataIndex: "onlineFlag",
    align: "center",
    scopedSlots: { customRender: "onlineFlag" },
  },
  {
    title: "操作",
    dataIndex: "action",
    align: "center",
    scopedSlots: { customRender: "action" },
  },
];
export default {
  name: "Install",
  components: { LayoutContent, Breadcrumb, VueCropper },
  data() {
    return {
      total: null,
      // 查询参数
      queryParams: {
        //当前页
        page: 1,
        //一页几个
        limit: 10,
      },
      //表格的表头文字
      columns: [],
      //多选的数据
      selectedRowKeys: [],
      // 非多个禁用
      multiple: true,
      //表格的数据
      data: [],
      //新增和编辑的表单
      form: {},
      //表单规则
      rules: {
        softwareName: [
          {
            required: true,
            message: "请输入软件名称",
            whitespace: true,
            trigger: ["change", "blur"],
          },
        ],
        softVersion: [
          {
            required: true,
            message: "请输入版本号",
            whitespace: true,
            trigger: ["change", "blur"],
          },
        ],
        tenantId: [
          {
            required: true,
            message: "请选择所属租户",
            whitespace: true,
            trigger: ["change", "blur"],
          },
        ],
        publishFlag: [
          {
            type: "number",
            required: true,
            message: "请选择发布状态",
            whitespace: true,
            trigger: ["change", "blur"],
          },
        ],
        softwarePlatform: [
          {
            required: true,
            message: "请选择所属平台",
            whitespace: true,
            trigger: ["change", "blur"],
          },
        ],
        versionStatus: [
          {
            required: true,
            message: "请输入版本状态",
            whitespace: true,
            trigger: ["change", "blur"],
          },
        ],
        softwareDescription: [
          {
            required: true,
            message: "请输入软件描述",
            whitespace: true,
            trigger: ["change", "blur"],
          },
        ],
      },
      //label和wrapper的占位
      labelCol: { span: 8 },
      wrapperCol: { span: 14 },
      //新增和编辑弹出框的标题
      title: "",
      //新增和编辑弹出框的显示隐藏
      visible: false,
      //新增或修改的弹框loading效果
      confirmLoading: false,
      //sts信息
      dataObj: null,
      //裁剪图片是否展示
      canShow: true,
      //盒子图片裁剪
      options: {
        logo: "", //裁剪图片的地址
        autoCrop: true, // 是否默认生成截图框
        autoCropWidth: 50, // 默认生成截图框宽度
        autoCropHeight: 50, // 默认生成截图框高度
        fixedBox: true, // 固定截图框大小 不允许改变
      },
      //盒子图片预览
      previews: {},
      //图片文件
      logoFile: null,
      //软件包列表
      fileList: [],
      //所有的租户列表
      tenantList: [],
      websocket: null,
      //发送心跳的定时器
      timer: null,
      //发送查询设备列表信息的定时器
      timer1: null,
      //websocket连接需要的标识
      uuid: "",
      //安装卸载的弹框显示与隐藏
      installVisible: false,
      //安装卸载的弹框标题
      installTitle: "",
      // 非多个禁用
      installMultiple: true,
      //安装卸载的表头
      installColumns,
      //安装卸载的数据
      installData: [],
      //安装卸载选择的数据
      installSelectedRowKeys: [],
      //安装卸载的表单
      installQueryParams: {
        page: 1,
        limit: 5,
      },
      installTotal: null,
      //设备列表
      deviceData: [],
      //选择安装或者卸载时的一行记录
      record: {},
      //安装或卸载在线状态的选中
      checked: false,
    };
  },
  methods: {
    //获取用户列表
    getInstallList() {
      getInstallList(this.queryParams).then((res) => {
        this.total = res.count;
        this.data = res.data;
        this.multiple = !this.selectedRowKeys.length;
      });
    },
    //搜索
    handleQuery() {
      this.queryParams.page = 1;
      this.getInstallList();
    },
    //搜索重置
    resetQuery() {
      this.queryParams.page = 1;
      this.$refs.queryForm.resetFields();
      this.getInstallList();
    },
    /** 新增按钮操作 */
    handleAdd() {
      //获取所有的租户列表
      getTenantALlList().then((res) => {
        this.tenantList = res.data;
        this.form = {};
        this.options.logo = "";
        this.logoFile = null;
        this.fileList = [];
        this.confirmLoading = false;
        this.visible = true;
        this.canShow = true;
        this.title = "新增软件";
      });
    },
    //修改
    async handleUpdate(row) {
      let result = await getInstall(row.id);
      this.form = result.data;
      let res = await getTenantALlList();
      this.tenantList = res.data;
      this.logoFile = null;
      this.fileList = [];
      this.canShow = false;
      this.confirmLoading = false;
      this.title = "修改软件";
      this.visible = true;
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      this.$confirm({
        title: "您确认删除您选中的数据吗？",
        onOk: () => {
          //单个删除
          if (row.id) {
            let arr = [];
            arr.push(row.id);
            installRemove(arr).then((res) => {
              this.$message.success("删除成功");
              this.getInstallList();
            });
          }
          //批量删除
          else {
            installRemove(this.selectedRowKeys).then((res) => {
              this.$message.success("删除成功");
              this.selectedRowKeys = [];
              this.getInstallList();
            });
          }
        },
      });
    },
    //新增确定
    submit() {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          //修改
          if (this.form.id) {
            //上传了文件和图片
            if (this.logoFile != null && this.fileList.length > 0) {
              this.confirmLoading = true;
              this.$refs.cropper.getCropBlob(async (data) => {
                //获取Token
                let res = await getStsInfo();
                const {
                  region,
                  bucket,
                  accessKeyId,
                  accessKeySecret,
                  securityToken,
                } = res.data;
                this.dataObj = {
                  region,
                  bucket,
                  accessKeyId,
                  accessKeySecret,
                  stsToken: securityToken,
                };

                //修改命名
                const dir = `software/${this.form.tenantId}/${this.form.softwareName}/${this.form.softVersion}/`;
                let index = this.logoFile.name.lastIndexOf(".");
                const logoFileName =
                  dir +
                  md5(this.logoFile.name.substring(0, index)) +
                  this.logoFile.name.substring(index);
                const fileName = dir + this.fileList[0].name;

                // blob转file
                let logoFile = new window.File([data], logoFileName, {
                  type: data.type,
                });

                try {
                  let result = await Client(this.dataObj).put(logoFileName, logoFile);
                  // console.log(result);
                  if (result.res.statusCode === 200) {
                    Client(this.dataObj)
                      .multipartUpload(fileName, this.fileList[0], {
                        partSize: 2 * 1024 * 1024,
                        progress: (p) => {},
                      })
                      .then((res) => {
                        if (res.res.statusCode === 200) {
                          this.form.softwareLogo = logoFileName;
                          this.form.downloadPath = fileName;
                          installAddOrUpdate(this.form).then((res) => {
                            this.$message.success("修改成功");
                            this.confirmLoading = false;
                            this.visible = false;
                            this.getInstallList();
                          });
                        }
                      })
                      .catch((error) => {
                        console.log(error);
                        this.$message.error(error);
                      });
                  }
                } catch (error) {
                  this.$message.error("上传失败");
                }
              });
            }
            //没传图片
            else if (this.logoFile == null && this.fileList.length > 0) {
              this.confirmLoading = true;
              //获取Token
              let res = await getStsInfo();
              const {
                region,
                bucket,
                accessKeyId,
                accessKeySecret,
                securityToken,
              } = res.data;
              this.dataObj = {
                region,
                bucket,
                accessKeyId,
                accessKeySecret,
                stsToken: securityToken,
              };

              //修改命名
              const dir = `software/${this.form.tenantId}/${this.form.softwareName}/${this.form.softVersion}/`;
              const fileName = dir + this.fileList[0].name;

              Client(this.dataObj)
                .multipartUpload(fileName, this.fileList[0], {
                  partSize: 2 * 1024 * 1024,
                  progress: (p) => {},
                })
                .then((res) => {
                  if (res.res.statusCode === 200) {
                    this.form.downloadPath = fileName;
                    installAddOrUpdate(this.form).then((res) => {
                      this.$message.success("修改成功");
                      this.confirmLoading = false;
                      this.visible = false;
                      this.getInstallList();
                    });
                  }
                })
                .catch((error) => {
                  console.log(error);
                  this.$message.error(error);
                });
            }
            //没传软件包
            else if (this.logoFile != null && this.fileList.length === 0) {
              this.confirmLoading = true;
              this.$refs.cropper.getCropBlob(async (data) => {
                //获取Token
                let res = await getStsInfo();
                const {
                  region,
                  bucket,
                  accessKeyId,
                  accessKeySecret,
                  securityToken,
                } = res.data;
                this.dataObj = {
                  region,
                  bucket,
                  accessKeyId,
                  accessKeySecret,
                  stsToken: securityToken,
                };

                //修改命名
                const dir = `software/${this.form.tenantId}/${this.form.softwareName}/${this.form.softVersion}/`;
                let index = this.logoFile.name.lastIndexOf(".");
                const logoFileName =
                  dir +
                  md5(this.logoFile.name.substring(0, index)) +
                  this.logoFile.name.substring(index);

                // blob转file
                let logoFile = new window.File([data], logoFileName, {
                  type: data.type,
                });

                try {
                  let result = await Client(this.dataObj).put(logoFileName, logoFile);
                  // console.log(result);
                  if (result.res.statusCode === 200) {
                    this.form.softwareLogo = logoFileName;
                    installAddOrUpdate(this.form).then((res) => {
                      this.$message.success("修改成功");
                      this.confirmLoading = false;
                      this.visible = false;
                      this.getInstallList();
                    });
                  }
                } catch (error) {
                  this.$message.error("上传失败");
                }
              });
            }
            //没传软件包也没传图片
            else {
              this.confirmLoading = true;
              installAddOrUpdate(this.form).then((res) => {
                this.$message.success("修改成功");
                this.confirmLoading = false;
                this.visible = false;
                this.getInstallList();
              });
            }
          }
          //新增
          else {
            //上传了文件和图片
            if (this.logoFile != null && this.fileList.length > 0) {
              this.confirmLoading = true;
              this.$refs.cropper.getCropBlob(async (data) => {
                //获取Token
                let res = await getStsInfo();
                const {
                  region,
                  bucket,
                  accessKeyId,
                  accessKeySecret,
                  securityToken,
                } = res.data;
                this.dataObj = {
                  region,
                  bucket,
                  accessKeyId,
                  accessKeySecret,
                  stsToken: securityToken,
                };

                //修改命名
                const dir = `software/${this.form.tenantId}/${this.form.softwareName}/${this.form.softVersion}/`;
                let index = this.logoFile.name.lastIndexOf(".");
                const logoFileName =
                  dir +
                  md5(this.logoFile.name.substring(0, index)) +
                  this.logoFile.name.substring(index);
                const fileName = dir + this.fileList[0].name;

                // blob转file
                let logoFile = new window.File([data], logoFileName, {
                  type: data.type,
                });

                try {
                  let result = await Client(this.dataObj).put(logoFileName, logoFile);
                  // console.log(result);
                  if (result.res.statusCode === 200) {
                    Client(this.dataObj)
                      .multipartUpload(fileName, this.fileList[0], {
                        partSize: 2 * 1024 * 1024,
                        progress: (p) => {},
                      })
                      .then((res) => {
                        if (res.res.statusCode === 200) {
                          this.form.softwareLogo = logoFileName;
                          this.form.downloadPath = fileName;
                          installAddOrUpdate(this.form).then((res) => {
                            this.$message.success("新增成功");
                            this.confirmLoading = false;
                            this.visible = false;
                            this.getInstallList();
                          });
                        }
                      })
                      .catch((error) => {
                        console.log(error);
                        this.$message.error(error);
                      });
                  }
                } catch (error) {
                  this.$message.error("上传失败");
                }
              });
            }
            //没传图片
            else if (this.logoFile == null && this.fileList.length > 0) {
              this.$message.error("请上传图标");
            }
            //没传软件包
            else if (this.logoFile != null && this.fileList.length === 0) {
              this.$message.error("请上传软件");
            } else {
              this.$message.error("请上传软件和图标");
            }
          }
        }
      });
    },
    //新增或修改取消
    handleCancel() {
      this.form = {};
      this.visible = false;
    },
    //下载
    handleDownload(row) {
      installDownload(row.id).then((res) => {
        let link = document.createElement("a");
        link.style.display = "none";
        link.href = res.data.downloadUrl;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
    },
    //复选框状态改变
    onSelectChange(selectedRowKeys, records) {
      // console.log(selectedRowKeys);
      // console.log(records);
      this.selectedRowKeys = selectedRowKeys;
      this.multiple = !selectedRowKeys.length;
    },
    //分页状态改变
    onChange(pageNumber) {
      this.queryParams.page = pageNumber;
      this.getInstallList();
    },
    //改变分页上一步下一步的文字链接
    itemRender(current, type, originalElement) {
      if (type === "prev") {
        return <a>上一页</a>;
      } else if (type === "next") {
        return <a class="next">下一页</a>;
      }
      return originalElement;
    },
    // 覆盖默认的上传行为
    requestUpload() {},
    // 向左旋转
    rotateLeft() {
      this.$refs.cropper.rotateLeft();
    },
    // 向右旋转
    rotateRight() {
      this.$refs.cropper.rotateRight();
    },
    // 图片缩放
    changeScale(num) {
      num = num || 1;
      this.$refs.cropper.changeScale(num);
    },
    // 上传预处理
    beforeUpload(file) {
      if (file.type.indexOf("image/") == -1) {
        this.$message.error("文件格式错误，请上传图片类型,如：JPG，PNG后缀的文件。");
      } else {
        //如果编辑的时侯上传头像
        if (this.canShow === false) {
          this.canShow = true;
        }
        this.logoFile = file;
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          this.options.logo = reader.result;
        };
      }
    },
    // 实时预览
    realTime(data) {
      this.previews = data;
    },
    //移除选择的文件
    handleRemove(file) {
      const index = this.fileList.indexOf(file);
      const newFileList = this.fileList.slice();
      newFileList.splice(index, 1);
      this.fileList = newFileList;
    },
    // 文件上传预处理
    beforeUploadFile(file) {
      // let index = file.name.lastIndexOf(".");
      // if (file.name.substr(index + 1) == "exe") {
      //   this.fileList = [...this.fileList, file];
      //   this.form.fileSize = file.size;
      //   return false;
      // } else {
      //   this.$message.error("请上传正确的文件格式");
      // }
      this.fileList = [...this.fileList, file];
      this.form.fileSize = file.size;
      return false;
    },
    //json对象转换为formData格式
    formdataify(params) {
      const formData = new FormData();
      Object.keys(params).forEach((key) => {
        formData.append(key, params[key]);
      });
      return formData;
    },
    //安装
    handleInstall(record) {
      this.installSelectedRowKeys = [];
      this.installMultiple = !this.installSelectedRowKeys.length;
      this.installQueryParams.page = 1;
      this.checked = false;
      this.installQueryParams.onlineStatus = null;
      getTerminalList(this.installQueryParams).then((res) => {
        this.deviceData = res.data;
        this.installTotal = res.count;
        this.initWebSocket();
        this.installVisible = true;
        this.installTitle = "安装";
        this.record = record;
      });
    },
    //卸载
    handleUninstall(record) {
      this.installSelectedRowKeys = [];
      this.installMultiple = !this.installSelectedRowKeys.length;
      this.installQueryParams.page = 1;
      this.checked = false;
      this.installQueryParams.onlineStatus = null;
      getTerminalList(this.installQueryParams).then((res) => {
        this.deviceData = res.data;
        this.installTotal = res.count;
        this.initWebSocket();
        this.installVisible = true;
        this.installTitle = "卸载";
        this.record = record;
      });
    },
    //安装卸载取消
    installCancel() {
      let close = websocketSend(this.uuid, actionType.close);
      if (this.websocket == null) {
        this.$message.error("连接已断开，请刷新页面");
        this.websocketclose();
      } else {
        this.websocket.send(JSON.stringify(close));
      }
    },
    //安装或卸载
    install(record) {
      this.$confirm({
        title: `确定${this.installTitle}${this.record.softwareName}（${this.record.softVersion}）吗？`,
        onOk: () => {
          if (this.installTitle == "安装") {
            //单个安装
            if (record.serialNum) {
              if (record.onlineFlag) {
                let receiverIds = [];
                receiverIds.push(record.serialNum);
                let install = websocketSend(
                  this.uuid,
                  actionType.install,
                  this.record,
                  receiverIds
                );
                console.log(install);
                if (this.websocket == null) {
                  this.$message.error("连接已断开，请刷新页面");
                  this.websocketclose();
                } else {
                  this.websocket.send(JSON.stringify(install));
                }
              } else {
                this.$message.error("设备已离线，不能重发消息");
              }
            } else {
              if (this.installSelectedRowKeys.length == 0) {
                this.$message.error("请选择推送的设备");
              } else {
                let install = websocketSend(
                  this.uuid,
                  actionType.install,
                  this.record,
                  this.installSelectedRowKeys
                );
                if (this.websocket == null) {
                  this.$message.error("连接已断开，请刷新页面");
                  this.websocketclose();
                } else {
                  this.websocket.send(JSON.stringify(install));
                }
              }
            }
          } else {
            //单个卸载
            if (record.serialNum) {
              if (record.onlineFlag) {
                let receiverIds = [];
                receiverIds.push(record.serialNum);
                let uninstall = websocketSend(
                  this.uuid,
                  actionType.uninstall,
                  this.record,
                  receiverIds
                );
                if (this.websocket == null) {
                  this.$message.error("连接已断开，请刷新页面");
                  this.websocketclose();
                } else {
                  this.websocket.send(JSON.stringify(uninstall));
                }
              } else {
                this.$message.error("设备已离线，不能重发消息");
              }
            } else {
              if (this.installSelectedRowKeys.length == 0) {
                this.$message.error("请选择推送的设备");
              } else {
                let uninstall = websocketSend(
                  this.uuid,
                  actionType.uninstall,
                  this.record,
                  this.installSelectedRowKeys
                );
                if (this.websocket == null) {
                  this.$message.error("连接已断开，请刷新页面");
                  this.websocketclose();
                } else {
                  this.websocket.send(JSON.stringify(uninstall));
                }
              }
            }
          }
        },
      });
    },
    //在线设备复选框改变
    changeOnlineStatus(e) {
      this.checked = e.target.checked;
      if (e.target.checked) {
        this.installQueryParams.onlineStatus = 0;
      } else {
        this.installQueryParams.onlineStatus = null;
      }
      this.installSelectedRowKeys = [];
      clearInterval(this.timer1);
      this.timer1 = null;
      getTerminalList(this.installQueryParams).then((res) => {
        this.deviceData = res.data;
        this.installTotal = res.count;
        this.installMultiple = !this.installSelectedRowKeys.length;
        this.websocketQueryList();
      });
    },
    //推送复选框改变
    installOnSelectChange(selectedRowKeys) {
      this.installSelectedRowKeys = selectedRowKeys;
      this.installMultiple = !this.installSelectedRowKeys.length;
    },
    //推送复选框不能选择
    getCheckboxProps(record) {
      return { props: { disabled: record.onlineFlag === false } };
    },
    //安装卸载分页状态改变
    installOnChange(pageNumber) {
      this.installQueryParams.page = pageNumber;
      clearInterval(this.timer1);
      this.timer1 = null;
      getTerminalList(this.installQueryParams).then((res) => {
        this.deviceData = res.data;
        this.installTotal = res.count;
        this.installMultiple = !this.installSelectedRowKeys.length;
        this.websocketQueryList();
      });
    },
    //webscoket 初始化
    initWebSocket() {
      const wsurl = process.env.VUE_APP_WSURL; //ws 相当于http 而wss 相当于https
      // let token = this.$ls.get("access_token");
      // this.websocket = new WebSocket(wsurl, [token]); //实例对象
      this.websocket = new WebSocket(wsurl); //实例对象
      console.log("websocket建立连接");
      this.websocket.onmessage = this.websocketonmessage;
      this.websocket.onopen = this.websocketonopen;
      this.websocket.onerror = this.websocketonerror;
      this.websocket.onclose = this.websocketclose;
    },
    //连接建立
    websocketonopen() {
      console.log("前端连接建立成功");
      this.uuid = uuidv4().replace(/-/g, "");
      let open = websocketSend(this.uuid, actionType.open);
      if (this.websocket == null) {
        this.$message.error("连接已断开，请刷新页面");
        this.websocketclose();
      } else {
        this.websocket.send(JSON.stringify(open));
      }
    },
    //发送心跳
    websocketHeart() {
      console.log("发送心跳");
      let heart = websocketSend(this.uuid, actionType.heart);
      if (this.websocket == null) {
        this.$message.error("连接已断开，请刷新页面");
        this.websocketclose();
      } else {
        this.websocket.send(JSON.stringify(heart));
        this.timer = setInterval(() => {
          if (this.websocket == null) {
            this.$message.error("连接已断开，请刷新页面");
            this.websocketclose();
          } else {
            this.websocket.send(JSON.stringify(heart));
          }
        }, 10 * 1000);
      }
    },
    //查询设备列表信息
    websocketQueryList() {
      console.log("请求查询设备列表信息");
      let queryTerminal = websocketSend(
        this.uuid,
        actionType.queryTerminal,
        this.deviceData
      );
      if (this.websocket == null) {
        this.$message.error("连接已断开，请刷新页面");
        this.websocketclose();
      } else {
        this.websocket.send(JSON.stringify(queryTerminal));
        this.timer1 = setInterval(() => {
          if (this.websocket == null) {
            this.$message.error("连接已断开，请刷新页面");
            this.websocketclose();
          } else {
            this.websocket.send(JSON.stringify(queryTerminal));
          }
        }, 5 * 1000);
      }
    },
    //数据接收
    websocketonmessage(e) {
      let data = JSON.parse(e.data);
      console.log("接收后端返回数据", data);
      //连接成功
      if (data.code === 8000) {
        this.websocketHeart();
        this.websocketQueryList();
      }
      //查询设备列表信息成功
      else if (data.code === 8001) {
        this.installData = data.data;
      }
      //安装
      else if (data.code === 8016) {
        this.$message.success(data.msg);
      }
      //卸载
      else if (data.code === 8017) {
        this.$message.success(data.msg);
      } else if (data.code < 0) {
        this.$message.error(data.msg);
      }
    },
    //连接建立失败重连
    websocketonerror() {
      clearInterval(this.timer);
      clearInterval(this.timer1);
      this.timer = null;
      this.timer1 = null;
      this.websocket = null;
      this.initWebSocket();
    },
    //连接关闭
    websocketclose(e) {
      console.log("websocket断开连接");
      clearInterval(this.timer);
      clearInterval(this.timer1);
      this.timer = null;
      this.timer1 = null;
      this.websocket = null;
      this.installVisible = false;
    },
  },
  created() {
    const columns = [
      {
        title: "软件名称",
        dataIndex: "softwareName",
        align: "left",
        scopedSlots: { customRender: "softwareName" },
        ellipsis: true,
        width: "350px",
      },
      {
        title: "版本号",
        dataIndex: "softVersion",
        align: "center",
      },
      {
        title: "版本状态",
        dataIndex: "versionStatus",
        align: "center",
      },
      {
        title: "文件大小",
        dataIndex: "fileSize",
        align: "center",
      },
      {
        title: "操作",
        dataIndex: "action",
        align: "center",
        scopedSlots: { customRender: "action" },
        width: "25%",
      },
    ];
    let obj = {
      title: "租户名称",
      dataIndex: "tenantName",
      align: "center",
    };
    let roles = this.$ls.get("userInfo").roles;
    if (roles.some((r) => r.roleAlias === "administrator")) {
      columns.splice(4, 0, obj);
    }
    this.columns = columns;
    this.getInstallList();
  },
  destroyed() {
    this.websocketclose();
  },
};
</script>

<style scoped lang="less">
.logo-upload-preview {
  position: absolute;
  top: 16px;
  left: 180px;
  width: 50px;
  height: 50px;
  box-shadow: 0 0 4px #ccc;
  overflow: hidden;
  border-radius: 10px;
}
::v-deep .ant-table-small > .ant-table-content > .ant-table-body {
  margin: 0;
  .tb-head {
    background: #f1f4fb;
  }
}
::v-deep .ant-modal-body {
  height: 500px;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 6px;
    /*高宽分别对应横竖滚动条的尺寸*/
    height: 1px;
  }

  &::-webkit-scrollbar-thumb {
    // -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    background: #e3e3e6;
    border-radius: 6px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
    // -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
  }
}
</style>
