<template>
  <layout-content :total="total">
    <!-- 面包屑的插槽 -->
    <breadcrumb slot="breadcrumb"></breadcrumb>
    <div slot="content">
      <!-- 搜索和新增删除 -->
      <div class="d-f a-c j-b">
        <a-form-model ref="queryForm" :model="queryParams" layout="inline">
          <a-form-model-item label="教室名称" prop="name">
            <a-input
              v-model="queryParams.name"
              placeholder="请输入教室名称"
              allowClear
              @keyup.enter.native="handleQuery"
            />
          </a-form-model-item>
          <a-form-model-item>
            <a-button
              type="primary"
              @click="handleQuery"
              v-hasPermi="['basicInfo:classroom:query']"
              >搜索</a-button
            >
            <a-button
              @click="resetQuery"
              class="ml10"
              v-hasPermi="['basicInfo:classroom:query']"
              >重置</a-button
            >
          </a-form-model-item>
        </a-form-model>
        <div>
          <a-button
            type="primary"
            @click="handleAdd"
            icon="plus"
            v-hasPermi="['basicInfo:classroom:add']"
            >新增</a-button
          >
          <a-button
            @click="handleDelete"
            icon="delete"
            class="ml10"
            :disabled="multiple"
            v-hasPermi="['basicInfo:classroom:remove']"
          >
            批量删除
          </a-button>
        </div>
      </div>
      <!--表格-->
      <a-table
        :columns="columns"
        rowKey="id"
        :data-source="data"
        :pagination="false"
        :row-selection="{
          selectedRowKeys: selectedRowKeys,
          onChange: onSelectChange,
        }"
        class="mt20"
      >
        <span slot="action" slot-scope="text, record">
          <a @click="handleUpdate(record)" v-hasPermi="['basicInfo:classroom:edit']"
            >编辑</a
          >
          <a-divider type="vertical" />
          <a @click="handleDelete(record)" v-hasPermi="['basicInfo:classroom:remove']"
            >删除</a
          >
          <a-divider type="vertical" />
          <a @click="handleDevice(record)" v-hasPermi="['basicInfo:classroom:query']"
            >设备</a
          >
        </span>
      </a-table>
      <!--分页-->
      <div class="d-f mt20">
        <a-pagination
          v-model="queryParams.page"
          :pageSize="queryParams.limit"
          :default-current="1"
          :total="total"
          :hideOnSinglePage="true"
          showLessItems
          @change="onChange"
          :item-render="itemRender"
          style="margin-left: auto"
        />
      </div>
      <!--新增和编辑的弹出框-->
      <a-modal
        v-model="visible"
        :title="title"
        @ok="submit"
        @cancel="handleCancel"
        width="500px"
      >
        <a-form-model
          ref="ruleForm"
          :model="form"
          :rules="rules"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
        >
          <a-row>
            <a-col :span="24">
              <a-form-model-item label="教室名称" prop="name">
                <a-input v-model="form.name" placeholder="请输入教室名称" />
              </a-form-model-item>
            </a-col>
            <a-col :span="24">
              <a-form-model-item label="所在楼栋" prop="buildingId">
                <a-select
                  v-model="form.buildingId"
                  placeholder="请选择所在楼栋"
                  style="width: 100%"
                  :getPopupContainer="(triggerNode) => triggerNode.parentNode"
                >
                  <a-select-option
                    :value="item.id"
                    v-for="item in buildingList"
                    :key="item.id"
                  >
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="24">
              <a-form-model-item label="教室类型" prop="type">
                <a-select
                  v-model="form.type"
                  placeholder="请选择教室类型"
                  style="width: 100%"
                  :getPopupContainer="(triggerNode) => triggerNode.parentNode"
                  @change="
                    () => {
                      this.$forceUpdate();
                    }
                  "
                >
                  <a-select-option
                    :value="item.valueName + '***' + item.valueData"
                    v-for="item in typeList"
                    :key="item.id"
                  >
                    {{ item.valueName }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
          </a-row>
        </a-form-model>
      </a-modal>
      <!--设备的弹出框-->
      <a-modal v-model="deviceVisible" title="设备" width="700px" :footer="null">
        <a-table
          size="small"
          :columns="deviceColumns"
          rowKey="id"
          :data-source="deviceData"
          :pagination="false"
        >
          <span slot="deviceType" slot-scope="text">
            {{ handleDeviceType(text) }}
          </span>
          <span slot="action" slot-scope="text, record">
            <a @click="handleUnbind(record)" v-hasPermi="['basicInfo:classroom:unbind']"
              >解绑</a
            >
          </span>
        </a-table>
      </a-modal>
    </div>
  </layout-content>
</template>

<script>
import LayoutContent from "@/views/layout/content";
import Breadcrumb from "~c/Breadcrumb";
import {
  getClassroomList,
  classroomAddOrUpdate,
  classroomRemove,
  getClassroom,
  getDeviceList,
  classroomUnbindDevice,
} from "@/api/basic-info/classroom.js";
import { getBuildingList } from "@/api/basic-info/building.js";
import { getTypeList } from "@/api/device/type.js";
const columns = [
  {
    title: "教室名称",
    dataIndex: "name",
    align: "center",
  },
  {
    title: "楼栋",
    dataIndex: "buildingName",
    align: "center",
  },
  {
    title: "教室类型",
    dataIndex: "roomTypeName",
    align: "center",
  },
  {
    title: "操作",
    dataIndex: "action",
    // width: "25%",
    align: "center",
    scopedSlots: { customRender: "action" },
  },
];
const deviceColumns = [
  {
    title: "设备类型",
    dataIndex: "deviceType",
    align: "center",
    scopedSlots: { customRender: "deviceType" },
  },
  {
    title: "序列号",
    dataIndex: "serialNum",
    align: "center",
  },
  {
    title: "操作",
    dataIndex: "action",
    align: "center",
    scopedSlots: { customRender: "action" },
  },
];
export default {
  name: "Classroom",
  components: { LayoutContent, Breadcrumb },
  data() {
    return {
      total: null,
      // 查询参数
      queryParams: {
        //当前页
        page: 1,
        //一页几个
        limit: 10,
      },
      //表格的表头文字
      columns,
      //表格的数据
      data: [],
      //多选的数据
      selectedRowKeys: [],
      // 非多个禁用
      multiple: true,
      //新增和编辑的表单
      form: {},
      //表单规则
      rules: {
        name: [
          {
            required: true,
            message: "请输入教室名称",
            whitespace: true,
            trigger: ["change", "blur"],
          },
        ],
        buildingId: [
          {
            required: true,
            message: "请选择所在楼栋",
            whitespace: true,
            trigger: ["change", "blur"],
          },
        ],
        type: [
          {
            required: true,
            message: "请选择教室类型",
            whitespace: true,
            trigger: ["change", "blur"],
          },
        ],
      },
      //label和wrapper的占位
      labelCol: { span: 5 },
      wrapperCol: { span: 19 },
      //新增和编辑弹出框的标题
      title: "",
      //新增和编辑弹出框的显示隐藏
      visible: false,
      //教室类型列表
      typeList: [],
      //楼栋列表
      buildingList: [],
      //设备弹框的显示隐藏
      deviceVisible: false,
      //设备表格的表头文字
      deviceColumns,
      //设备的数据列表
      deviceData: [],
      //设备类型列表
      deviceTypeList: [],
      //解绑设备的表单
      deviceForm: {},
    };
  },
  methods: {
    //获取教室列表
    getClassroomList() {
      getClassroomList(this.queryParams).then((res) => {
        this.data = res.data;
        this.total = res.count;
        this.multiple = !this.selectedRowKeys.length;
      });
    },
    //搜索
    handleQuery() {
      this.queryParams.page = 1;
      this.getClassroomList();
    },
    //搜索重置
    resetQuery() {
      this.queryParams.page = 1;
      this.$refs.queryForm.resetFields();
      this.getClassroomList();
    },
    //复选框状态改变
    onSelectChange(selectedRowKeys, records) {
      // console.log(selectedRowKeys);
      // console.log(records);
      this.selectedRowKeys = selectedRowKeys;
      this.multiple = !selectedRowKeys.length;
    },
    /** 新增按钮操作 */
    async handleAdd() {
      this.form = {};
      let res = await getBuildingList({ isPage: false });
      let response = await this.getDictDataList("school_classroom_type");
      this.buildingList = res.data;
      this.typeList = response.data;
      this.visible = true;
      this.title = "新增教室";
    },
    /** 修改按钮操作 */
    async handleUpdate(row) {
      this.form = {};
      let res = await getClassroom(row.id);
      let result = await getBuildingList({ isPage: false });
      let response = await this.getDictDataList("school_classroom_type");
      this.form = res.data;
      this.form.type =
        JSON.parse(JSON.stringify(this.form.roomTypeName)) +
        "***" +
        JSON.parse(JSON.stringify(this.form.roomType));
      this.buildingList = result.data;
      this.typeList = response.data;
      this.visible = true;
      this.title = "修改教室";
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      this.$confirm({
        title: "您确认删除您选中的数据吗？",
        onOk: () => {
          //单个删除
          if (row.id) {
            let arr = [];
            arr.push(row.id);
            classroomRemove(arr).then((res) => {
              this.$message.success("删除成功");
              this.getClassroomList();
            });
          }
          //批量删除
          else {
            classroomRemove(this.selectedRowKeys).then((res) => {
              this.$message.success("删除成功");
              this.selectedRowKeys = [];
              this.getClassroomList();
            });
          }
        },
      });
    },
    //新增或编辑确定
    submit() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          let params = JSON.parse(JSON.stringify({ ...this.form }));
          params.roomTypeName = params.type.substr(0, params.type.indexOf("***"));
          params.roomType = params.type.substr(params.type.indexOf("***") + 3);
          delete params.type;
          classroomAddOrUpdate(params).then((res) => {
            //修改
            if (this.form.id) {
              this.$message.success("修改成功");
            }
            //新增
            else {
              this.$message.success("新增成功");
            }
            this.visible = false;
            this.getClassroomList();
          });
        }
      });
    },
    //新增或编辑取消
    handleCancel() {
      this.$refs.ruleForm.resetFields();
      this.visible = false;
    },
    //设备
    handleDevice(row) {
      this.deviceForm.classroomId = row.id;
      getTypeList({ isPage: false }).then((result) => {
        getDeviceList({ classroomId: row.id }).then((res) => {
          this.deviceTypeList = result.data;
          this.deviceData = res.data;
          this.deviceVisible = true;
        });
      });
    },
    //解绑设备
    handleUnbind(record) {
      this.$confirm({
        title: "您确认解绑设备吗？",
        onOk: () => {
          this.deviceForm.serialNum = record.serialNum;
          classroomUnbindDevice(this.deviceForm).then(() => {
            this.$message.success("解绑成功");
            getDeviceList({ classroomId: this.deviceForm.classroomId }).then((res) => {
              this.deviceData = res.data;
            });
          });
        },
      });
    },
    //翻译设备类型
    handleDeviceType(text) {
      return this.deviceTypeList.find((r) => r.deviceTypeIdentification == text)
        .deviceTypeName;
    },
    //分页状态改变
    onChange(pageNumber) {
      this.queryParams.page = pageNumber;
      this.getClassroomList();
    },
    //改变分页上一步下一步的文字链接
    itemRender(current, type, originalElement) {
      if (type === "prev") {
        return <a>上一页</a>;
      } else if (type === "next") {
        return <a class="next">下一页</a>;
      }
      return originalElement;
    },
  },
  created() {
    this.getClassroomList();
  },
};
</script>

<style scoped lang="less"></style>
